import { useEventCalendarDates } from "@App/hooks";
import useEventsSearch from "@App/hooks/api/events/useEventsSearch";
import { useMemo } from "react";

type UseCalendarEventsParams = {
  calendarCurrentMonth: Date;
  selectedDate?: Date;
  selectedCategory?: string;
  managedByEntityId: string | null;
  searchKeyword?: string;
};

export default function useCalendarEvents({
  calendarCurrentMonth,
  selectedDate,
  searchKeyword,
  selectedCategory,
  managedByEntityId,
}: UseCalendarEventsParams) {
  // Month date range needs to be constant unless the month changes
  const monthDateRange = useMemo(() => {
    return {
      startDate: calendarCurrentMonth,
      endDate: getEndOfMonth(calendarCurrentMonth),
    };
  }, [calendarCurrentMonth]);

  // This is variable because it can change based on the selected date
  const selectedDateRange = useMemo(() => {
    if (!selectedDate) {
      return monthDateRange;
    }

    const endDate = new Date(selectedDate);
    endDate.setHours(23, 59, 59, 999);
    return {
      startDate: selectedDate,
      endDate: endDate,
    };
  }, [monthDateRange, selectedDate]);

  const eventSearchParams = useMemo(() => {
    return {
      startDate: selectedDateRange.startDate,
      endDate: selectedDateRange.endDate,
      managedById: managedByEntityId,
      categoryId: selectedCategory,
      isIncludeAdminEvents: true,
      searchKeyword,
    };
  }, [selectedDateRange, searchKeyword, selectedCategory, managedByEntityId]);

  // Calendar params are the same unless there's a selected date
  const calendarDatesParams = useMemo(() => {
    return {
      ...eventSearchParams,
      startDate: monthDateRange.startDate,
      endDate: monthDateRange.endDate,
    };
  }, [monthDateRange, eventSearchParams]);

  const { eventCalendarDates, isLoadingEventCalendarDates } =
    useEventCalendarDates(calendarDatesParams);

  const {
    eventsList,
    isLoadingEvents,
    isFetchingEvents,
    inViewRef: eventsListInViewRef,
    hasNextPage: eventsListHasNextPage,
  } = useEventsSearch(eventSearchParams, true, !selectedDate);

  return {
    eventCalendarDates,
    isLoadingEventCalendarDates,
    eventsList,
    isLoadingEvents,
    isFetchingCalendarEvents: isFetchingEvents,
    eventsListInViewRef,
    eventsListHasNextPage,
  };
}

function getEndOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
