class UserSession {
  private _accessToken: string | null = null;
  private _cognitoUsername: string = "";
  private _userData = {
    //TODO: Temp while we get the User Get API update to get the user by the cognito username (login).
    userId: "",
    firstname: "",
    lastname: "",
    email: "",
  };
  private _isMobileViewMode: boolean = false;

  get accessToken() {
    return this._accessToken;
  }

  set accessToken(token: string | null) {
    this._accessToken = token;
  }

  get cognitoUsername() {
    return this._cognitoUsername;
  }

  set cognitoUsername(username: string) {
    this._cognitoUsername = username;
  }

  get userData() {
    return this._userData;
  }

  set userData(data: any) {
    this._userData = data;
  }

  get isMobileViewMode() {
    return this._isMobileViewMode;
  }

  set isMobileViewMode(value: boolean) {
    this._isMobileViewMode = value;
  }
}

const userSession = new UserSession();

export default userSession;
