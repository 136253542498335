import { Navbar } from "@Layouts/index";
import "./Amenities.scss";
import { GenericListing } from "@Components/index";
import AmenitiesLogic from "./AmenitiesLogic";

const Amenities = () => {
  const {
    listingCategories,
    getCategoryItems,
    isLoading,
    onClick,
    searchText,
    onSearch,
  } = AmenitiesLogic();

  return (
    <Navbar>
      <div className={"amenities"}>
        <GenericListing
          title={"Amenities & Facilities"}
          onClick={onClick}
          isLoading={isLoading}
          listingCategories={listingCategories}
          getCategoryItems={getCategoryItems}
          searchText={searchText}
          onSearch={onSearch}
          moduleName={"amenities"}
        />
      </div>
    </Navbar>
  );
};

export default Amenities;
