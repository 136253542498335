export const getTenantCmsData = async (
  tenantId: string,
  dataType: "main-pages" | "detail-pages",
) => {
  if (!tenantId || !dataType) {
    return Promise.reject("One or more required params are missing");
  }

  const route = `/cms-data/${dataType}-${tenantId}.json`;
  const cmsData = await (await fetch(route)).json();

  if (!cmsData) {
    console.error("No cms data found at route: ", route);
    return Promise.reject("No cms data found");
  }

  return {
    data: cmsData,
  };
};
