import { ManagedEntity } from "@App/models/managedEntity";
import { PoiCategoryPagedResponse } from "@App/models/poi";
import { Tenant } from "@App/models/tenant";
import { baseServiceRequest } from "./baseService";

export const tenantConfigGet = async () => {
  return await baseServiceRequest<Tenant>({
    path: `/app/config/tenant`,
    type: "get",
  });
};

export const getPoiCategories = async () => {
  return await baseServiceRequest<PoiCategoryPagedResponse>({
    path: `/poicategory`,
    type: "get",
    useAuthHeader: true,
  });
};

export const getManagedBy = async () => {
  return await baseServiceRequest<ManagedEntity[]>({
    path: `/managedBy`,
    type: "get",
  });
};

export const getSubCommunities = async () => {
  return await baseServiceRequest({
    path: `/subcommunity`,
    type: "get",
  });
};
