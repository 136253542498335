import { Button } from "@Components/index";
import "./TicketPurchaseRows.scss";
import moment from "moment";
import TicketPurchaseRowsLogic from "./TicketPurchaseRowsLogic";
import { TicketPurchaseRowsProps } from "./TicketPurchaseRowsPropTypes";

const TicketPurchaseRows = (componentProps: TicketPurchaseRowsProps) => {
  const props = TicketPurchaseRowsLogic(componentProps);

  // TODO: Refactor component to update available rows depending on hideTime prop, and remove this ternary
  return (
    <>
      {componentProps.hideTime
        ? componentProps.recurringDates?.map((ticketingDate, index) => (
            <div className="ticket-purchase-rows-modal-row" key={index}>
              <div className="ticket-purchase-rows-ticket-date">
                <span className="ticket-purchase-rows-date">
                  {moment(ticketingDate.startDate).format("D")}
                </span>
                <span className="ticket-purchase-rows-day">
                  {moment(ticketingDate.startDate).format("ddd")}
                </span>
              </div>
              <div className={`ticket-purchase-rows-ticket-time-only`}>
                <p className="ticket-purchase-rows-time">
                  {moment(ticketingDate.startDate).format("h:mm A")}
                </p>
              </div>
              <div className="ticket-purchase-rows-button">
                <Button
                  text={
                    componentProps.eventType === "General" ? "RSVP" : "Book"
                  }
                  onClick={() => {
                    props.onBookClick(index);
                  }}
                  isSecondary
                  dataCy={`ticket-modal-book-btn-${index}`}
                />
              </div>
            </div>
          ))
        : componentProps.recurringDates?.map((ticketingDate, index) => (
            <div className="ticket-purchase-rows-modal-row" key={index}>
              <div className="ticket-purchase-rows-ticket-date">
                <span className="ticket-purchase-rows-date">
                  {moment(ticketingDate.startDate).format("D")}
                </span>
                <span className="ticket-purchase-rows-day">
                  {moment(ticketingDate.startDate).format("ddd")}
                </span>
              </div>
              <div
                className={`ticket-purchase-rows-ticket-time ${
                  props.selectedTime === index
                    ? "ticket-purchase-rows-ticket-time-selected"
                    : ""
                }`}
                onClick={() => {
                  props.setSelectedTime(index);
                }}
              >
                <p className="ticket-purchase-rows-time">
                  {moment(ticketingDate.startDate).format("h:mm A")}
                </p>
                <p className="ticket-purchase-rows-duration">
                  {`${moment(ticketingDate.endDate).diff(
                    moment(ticketingDate.startDate),
                    "minutes"
                  )} min`}
                </p>
              </div>
              <div className="ticket-purchase-rows-button">
                <Button
                  text={
                    componentProps.eventType === "General" ? "RSVP" : "Book"
                  }
                  isDisabled={props.selectedTime !== index}
                  onClick={() => {
                    props.onBookClick();
                  }}
                  isSecondary
                  dataCy={`ticket-modal-book-btn-${index}`}
                />
              </div>
            </div>
          ))}
    </>
  );
};

export default TicketPurchaseRows;
