import { confirmCode } from "@App/store/actions/userActions";
import { RegistrationState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

let isValidationReady = false;
const EmailConfirmationLogic = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const registrationData = useSelector<RootState, RegistrationState>(
    (state) => state.userRegistration,
  );
  const { isCodeVerificationInProgress, isCodeVerificationSuccess } =
    registrationData;

  useEffect(() => {
    if (!isValidationReady) {
      const url = new URL(window.location.href);
      const searchParams = url.searchParams;
      const code = searchParams.get("C");

      if (code) {
        isValidationReady = true;
        dispatch(confirmCode(code) as any);
      }
    }
    if (isValidationReady && isCodeVerificationSuccess) {
      const baseURL = window.location.origin;
      window.location.href = `${baseURL}?R=verification`;
    }
    setIsProcessing(isCodeVerificationInProgress);
    setIsSuccess(isCodeVerificationSuccess);
  }, [isCodeVerificationInProgress, isCodeVerificationSuccess, dispatch]);

  return { isProcessing, isSuccess };
};

export default EmailConfirmationLogic;
