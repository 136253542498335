import { IconProps } from "../iconTypes";

const Minus = ({ iconType, color, size, isActive }: IconProps) => {
  return (
    <svg
      width="14"
      height="3"
      viewBox="0 0 14 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 2.99805H0V0.998047H14V2.99805Z"
        fill={color ? color : "black"}
      />
    </svg>
  );
};

export default Minus;
