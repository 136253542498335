import LoginLogic from "./LoginLogic";
import {
  Authenticator,
  Button as AmplifyButton,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "./Login.scss";
import { Button, Checkbox, Image, LinkifyText } from "@Components/index";
import { LoginProps } from "./LoginProps";
import { CircularCheck, TriangleError } from "@Components/icons";

const Login = (componentProps: LoginProps) => {
  const props = LoginLogic(componentProps);

  return (
    <div className={"login-container"} data-cy="login-modal">
      <div className={"login"}>
        <div className={"login-form"}>
          <header className={"login-form-header"}>
            <Image
              className={"login-form-icon"}
              src={
                props.tenantConfig?.cmsSettings?.websiteSettings?.communityIcon
              }
              alt={""}
              height="132px"
              width="132px"
            />
            {(componentProps.errorMessage || !!props.errorMessage) && (
              <div className={"login-status-banner login-error-banner"}>
                <TriangleError />
                {props.errorMessage ? (
                  <LinkifyText
                    text={props.errorMessage}
                    dataCy="login-error-message"
                  />
                ) : (
                  <div>
                    <div>
                      {componentProps.errorMessage}
                      {` Please Sign in to continue.`}
                    </div>
                    <div>{`You can click 'Forgot your Password?' to reset the password.`}</div>
                  </div>
                )}
              </div>
            )}
            {!!props.passwordResetSuccessMessage && (
              <div
                className={"login-status-banner login-success-banner"}
                data-cy="pass-reset-status-banner">
                <CircularCheck size={32} />
                <div>{props.passwordResetSuccessMessage}</div>
              </div>
            )}
          </header>
          <section className={"login-form-fields"}>
            <Authenticator
              services={props.services}
              passwordSettings={{
                passwordPolicyCharacters: [
                  "REQUIRES_LOWERCASE",
                  "REQUIRES_NUMBERS",
                  "REQUIRES_SYMBOLS",
                  "REQUIRES_UPPERCASE",
                ],
                passwordPolicyMinLength: 8,
              }}
              hideSignUp={true}
              formFields={{
                signIn: {
                  username: {
                    labelHidden: false,
                    placeholder: "Enter your email",
                    isRequired: true,
                    label: "Email*",
                    order: 1,
                    type: "email",
                  },
                  password: {
                    labelHidden: false,
                    placeholder: "Enter your password",
                    isRequired: true,
                    label: "Password*",
                    order: 2,
                  },
                },
                resetPassword: {
                  username: {
                    labelHidden: false,
                    placeholder: "Enter your email",
                    isRequired: true,
                    label: "Email*",
                    order: 0,
                    type: "email",
                  },
                },
                confirmResetPassword: {
                  confirmation_code: {
                    placeholder: "Enter your Confirmation Code",
                    label: "Code*",
                    isRequired: true,
                  },
                  password: {
                    label: "New Password*",
                    isRequired: true,
                  },
                  confirm_password: {
                    label: "Enter New Password Again*",
                    placeholder: "Re-enter password",
                    isRequired: true,
                  },
                },
              }}
              components={{
                SignIn: {
                  Header() {
                    return (
                      <div className={"login-step-title login-sign-in-title"}>
                        Welcome back!
                      </div>
                    );
                  },

                  Footer() {
                    const { toResetPassword } = useAuthenticator();
                    return (
                      <div>
                        <div
                          className={"login-sign-in-reset-link"}
                          data-cy="forgot-pass-btn-wrapper">
                          <AmplifyButton
                            autoFocus
                            onFocus={(event) => {
                              props.handleSignInPasswordFocus(
                                event.currentTarget,
                              );
                            }}
                            fontWeight="normal"
                            onClick={() => {
                              props.setPasswordResetSuccessMessage(null);
                              props.setErrorMessage(null);
                              props.handleForgotPassword();
                              toResetPassword();
                            }}
                            size="small"
                            variation="link">
                            Forgot Password
                          </AmplifyButton>
                        </div>
                        {props.showRememberMyDetails && (
                          <div
                            className={"login-sign-in-remember-my-details"}
                            data-cy="remember-checkbox">
                            <Checkbox
                              text={"Remember my details"}
                              onChange={(value: any) => {
                                props.setRememberMyDetails(value);
                              }}
                              defaultChecked={!!props.rememberEmail}
                            />
                          </div>
                        )}
                        <div className={"login-form-register"}>
                          <button
                            className={"login-form-register-button"}
                            onClick={() => {
                              componentProps.onClose &&
                                componentProps.onClose(true);
                            }}>
                            Create an account
                          </button>
                        </div>
                      </div>
                    );
                  },
                },
                ResetPassword: {
                  Header() {
                    return (
                      <div className={"login-forgot-password"}>
                        <div
                          className={
                            "login-step-title login-forgot-password-title"
                          }>
                          Forgot Password
                        </div>
                        <div>
                          Enter your email below and we will send an email to
                          reset your password.
                        </div>
                      </div>
                    );
                  },
                  Footer() {
                    const { toSignIn } = useAuthenticator();
                    return (
                      <div>
                        <div className={"login-sign-in-reset-link"}>
                          <AmplifyButton
                            autoFocus
                            onFocus={(event) => {
                              props.handleResetPasswordFocus(
                                event.currentTarget,
                              );
                            }}
                            fontWeight="normal"
                            onClick={() => {
                              props.setPasswordResetSuccessMessage(null);
                              props.setErrorMessage(null);
                              props.setShowRememberMyDetails(true);
                              toSignIn();
                            }}
                            size="small"
                            variation="link"
                            className={"custom-back-to-sign-in-link"}>
                            Back to Sign In
                          </AmplifyButton>
                        </div>
                      </div>
                    );
                  },
                },
                ConfirmResetPassword: {
                  Header() {
                    return (
                      <div
                        className={
                          "login-forgot-password login-forgot-password-confirm"
                        }
                        data-cy="pass-reset-confirm">
                        <div
                          className={
                            "login-step-title login-forgot-password-title"
                          }>
                          Forgot Password Confirmation
                        </div>
                        <div>
                          A verification email has been sent to you. Please
                          check your inbox in order to proceed with entering the
                          required code below.
                        </div>
                      </div>
                    );
                  },
                },
              }}
            />
          </section>
        </div>
        <div className={"login-right-content"}>
          <div
            className={"login-image"}
            style={{
              backgroundImage: `url(${require("@Assets/images/lwr_registration.png")})`,
            }}>
            <Button
              type={"close"}
              onClick={() => {
                componentProps.onClose && componentProps.onClose();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
