import Modal from "@Components/modal/Modal";
import "./DevMenu.scss";
import DevMenuLogic from "./DevMenuLogic";
import { DevMenuProps } from "./DevMenuPropTypes";
import { Button, Dropdown } from "@Components/index";

const DevMenu = (componentProps: DevMenuProps) => {
  const props = DevMenuLogic();
  return (
    <Modal isVisible={componentProps.showDevMenu}>
      <div className={"dev-menu"}>
        <div className={"dev-menu-close"}>
          <Button
            type={"close"}
            style={{ color: "#000" }}
            onClick={componentProps.onClose}
          />
        </div>

        <div className={"dev-menu-section dev-menu-section-row"}>
          <Dropdown
            items={props.tenants}
            onChange={props.onTenantChange}
            initialValue={props.selectedTenant}
            label={"TENANT"}
          />
          <Button
            text={"Reset Tenant"}
            onClick={props.onTenantReset}
            style={{ maxWidth: 150 }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DevMenu;
