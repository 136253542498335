import { tenantConfigGet } from "@App/api/general";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { Tenant } from "@App/models/tenant";
import { sendVerificationEmail } from "@App/store/actions/userActions";
import { RegistrationState, UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ResendEmailLogic = (onClose: any) => {
  const [userEmail, setUserEmail] = useState("");
  const [isResendEmailProcessing, setIsResendEmailProcessing] = useState(false);
  const dispatch = useDispatch();
  const storeData = useSelector<
    RootState,
    { userData: UserState; registrationData: RegistrationState }
  >((state) => {
    return {
      userData: state.userProfile,
      registrationData: state.userRegistration,
    };
  });
  const { userData, registrationData } = storeData;

  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });

  useEffect(() => {
    if (userData?.userInfo?.email) {
      setUserEmail(userData.userInfo.email);
    }
    if (registrationData) {
      setIsResendEmailProcessing(
        registrationData.isResendVerificationInProgress,
      );
      if (registrationData.isReSendVerificationSuccess && onClose) {
        onClose();
      }
    }
  }, [userData, registrationData, onClose]);

  const resendVerificationEmail = () => {
    if (userData?.userInfo?.id) {
      dispatch(sendVerificationEmail(userData.userInfo.id) as any);
    }
  };
  return {
    userEmail,
    isResendEmailProcessing,
    tenantConfig,
    resendVerificationEmail,
  };
};

export default ResendEmailLogic;
