import { tenantConfigGet } from "@App/api/general";
import { DEFAULT_MOBILE_MEDIA_QUERY } from "@App/constants/appConstants";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { Tenant } from "@App/models/tenant";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const HomeLogic = () => {
  const [tenant, setTenant] = useState<Tenant>();
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY).matches
  );
  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });

  useEffect(() => {
    const onResize = () => {
      setIsMobileScreen(window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY).matches);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    setTenant(tenantConfig);
  }, [tenantConfig]);

  return { tenant, isMobileScreen };
};

export default HomeLogic;
