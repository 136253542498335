export const initConfig = async () => {
  return new Promise((resolve) => {
    //Gets the configuration from another place.
    fetch("/configuration.json")
      .then((response) => response.json())
      .then((data) => {
        //Overwrites the env configuration with any key that is specified in the configuration.json
        overwriteBaseConfiguration(data);
        resolve(true);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

const overwriteBaseConfiguration = (configuration: any) => {
  if (configuration) {
    for (const prop in configuration) {
      (config as any)[prop] = configuration[prop];
    }
  }
};

export const config = {
  appleClientId: process.env.REACT_APP_APPLE_CLIENT_ID,
  baseApiUrl: process.env.REACT_APP_BASE_API_URL,
  cbpTenantId: process.env.REACT_APP_CBP_TENANT_ID,
  defaultAdminEmail: "cbp@cecilianpartners.com",
  defaultPrivacyPolicy: "/cms-detail/privacy-policy",
  devMenuClicksToShow: Number(process.env.REACT_APP_DEV_MENU_CLICKS_TO_SHOW),
  devMenuEnabled: process.env.REACT_APP_DEV_MENU_ENABLED === "true",
  domain: process.env.REACT_APP_DOMAIN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  leadSource: process.env.REACT_APP_LEAD_SOURCE,
  logRocketAppId: process.env.REACT_APP_LOGROCKET_APPID ?? "",
  logRocketEnabled: process.env.REACT_APP_LOGROCKET_ENABLED === "true",
  mapBoxDirectionsApi: process.env.REACT_APP_MAPBOX_DIRECTIONS_API,
  maxFileSizeMB: Number(process.env.REACT_APP_MAX_FILE_SIZE),
  maxImagesCount: Number(process.env.REACT_APP_MAX_IMAGES_COUNT),
  redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
  redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
  registrationRedirectUri: process.env.REACT_APP_REGISTRATION_REDIRECT_URI,
  sentryEnabled: process.env.REACT_APP_SENTRY_ENABLED === "true",
  sentryKey: process.env.REACT_APP_SENTRY_KEY,
  sentryTracesSampleRate: Number(
    process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  ),
  upcomingEventsEndDateMonths: 6,
  defaultDirectionsTransport: "driving",
} as ConfigSettings;

export type ConfigSettings = {
  appleClientId: string;
  baseApiUrl: string;
  cbpTenantId: string;
  defaultAdminEmail: string;
  defaultPrivacyPolicy: string;
  devMenuClicksToShow: number;
  devMenuEnabled: boolean;
  domain: string;
  environment: string;
  googleClientId: string;
  leadSource: string;
  logRocketAppId: string;
  logRocketEnabled: boolean;
  mapBoxDirectionsApi: string;
  maxFileSizeMB: number;
  maxImagesCount: number;
  redirectSignIn: string;
  redirectSignOut: string;
  registrationRedirectUri: string;
  sentryEnabled: boolean;
  sentryKey: string;
  sentryTracesSampleRate: number;
  upcomingEventsEndDateMonths: number;
  defaultDirectionsTransport: "driving" | "walking";
};
