import { getEventCategories } from "@App/api/event";
import { QUERY_KEY_EVENT_CATEGORY_LIST } from "@App/constants/queryKeyConstants";
import { useQueryClient } from "@tanstack/react-query";

const queryList = [
  {
    queryKey: [QUERY_KEY_EVENT_CATEGORY_LIST],
    queryFn: () => getEventCategories(),
  },
];

// TODO: Add any goodies here!
// The current months events would be nice, but it's got a lot of dependencies
export default function useAppPrefetch() {
  const queryClient = useQueryClient();

  queryList.forEach((query) => {
    queryClient.prefetchQuery(query);
  });
}
