import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const supportedLngs = ["default", "lakewoodranch"];

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: supportedLngs,
    supportedLngs,
    ns: ["home"],
    react: {
      useSuspense: false,
    },
    debug: true,
  });

export default i18n;
