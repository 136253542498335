import { Heart } from "@Components/icons";
import {
  BackButton,
  Breadcrumb,
  Button,
  GenericModal,
  ImageFilesCarousel,
  Loader,
  Tag,
} from "@Components/index";
import { formatCurrency, handleExternalUrl } from "@Utils/utils";
import "./GenericDetails.scss";
import GenericDetailsLogic from "./GenericDetailsLogic";
import { GenericDetailsProps } from "./GenericDetailsPropTypes";

const GenericDetails = (props: GenericDetailsProps) => {
  const {
    isUserLogged,
    isMobileEmbed,
    isFav,
    showReservationRules,
    CTAButtonText,
    isCTAButtonDisabled,

    goBack,
    setShowReservationRules,
    handleCTAButton,
  } = GenericDetailsLogic(props);

  return (
    <div
      className={`generic-details ${
        isMobileEmbed ? "generic-details-embed" : ""
      }`}
    >
      <div className={"generic-details-breadcrumb"}>
        <Breadcrumb
          initialSection={{ text: "Home", path: "/" }}
          currentSectionName={props.details.title}
          hasIdentificator
          ignorePaths={props.breadcrumbIgnorePaths}
        />
      </div>
      {!isMobileEmbed && (
        <div className={"generic-details-back"}>
          <BackButton goBack={goBack} />
        </div>
      )}
      <div className={"generic-details-content"}>
        <div className={"generic-details-content-type"}>
          {props.details.type}
        </div>
        <div className={"generic-details-content-title"}>
          {props.details.title}
        </div>
        <div className={"generic-details-content-dates"}>
          {props.details.openDates}
        </div>
        <div className={"generic-details-content-images"}>
          <ImageFilesCarousel
            showIndicators={
              props.details.files && props.details.files.length > 1
            }
            files={props.details.files}
          />
        </div>
        {isUserLogged && props.onFavClick && (
          <div className={"generic-details-content-bottom-right-fav"}>
            <button
              className={"generic-details-content-bottom-right-fav-button"}
              disabled={props.isFavProcessing}
              onClick={props.onFavClick}
            >
              {props.isFavProcessing ? (
                <Loader
                  color={"silver"}
                  horizontallyCentered
                  width="20"
                  height="10"
                />
              ) : (
                <Heart isActive={isFav} />
              )}
            </button>
          </div>
        )}
        <div className={"generic-details-content-bottom"}>
          <div className={"generic-details-content-bottom-left"}>
            <div
              className={"generic-details-content-description"}
              dangerouslySetInnerHTML={{
                __html: props.details.description,
              }}
            />
            {props.details.location && (
              <div className={"generic-details-content-description-location"}>
                <span
                  className={
                    "generic-details-content-description-location-title"
                  }
                >
                  Location:
                </span>
                <p>{props.details.location}</p>
              </div>
            )}
            {props.detailsAction ? props.detailsAction : null}
            {props.details.tags && (
              <div className={"generic-details-content-description-tags"}>
                {props.details.tags.map((tag: string, index: number) => {
                  return <Tag key={`tag_item_${index}`} text={tag} />;
                })}
              </div>
            )}
            {props.details?.rulesandRegulation && (
              <div
                className={"generic-details-content-description-rules"}
                onClick={() => {
                  setShowReservationRules(true);
                }}
              >
                Rules & Regulations
              </div>
            )}
          </div>
          <div className={"generic-details-content-bottom-right"}>
            {props.pricingTitle && (
              <div className="generic-details-content-bottom-subscription">
                <div className="generic-details-content-bottom-subscription__title">
                  {props.pricingTitle}
                </div>
                <div className="generic-details-content-bottom-subscription__list">
                  {props.details.rates?.map(
                    (rate: { name: string; price: number }, i: number) => (
                      <div
                        className="generic-details-content-bottom-subscription__row"
                        key={i}
                      >
                        <div>{rate.name}</div>
                        <div>{`${formatCurrency(rate.price)}`}</div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            )}
            {(props.callsToAction?.length ?? 0) > 0
              ? props.callsToAction?.map((cta, index) => {
                  return (
                    <Button
                      key={`cta_action${index}`}
                      text={cta.displayText}
                      onClick={() => handleExternalUrl(cta.url)}
                      style={{ marginTop: index > 0 ? 10 : 0 }}
                    />
                  );
                })
              : props.primaryButton && (
                  <Button
                    text={CTAButtonText}
                    onClick={handleCTAButton}
                    isDisabled={isCTAButtonDisabled}
                  />
                )}
          </div>
        </div>
      </div>
      {props.details?.rulesandRegulation && (
        <GenericModal
          onClose={() => setShowReservationRules(false)}
          isVisible={showReservationRules}
          children={
            <div
              dangerouslySetInnerHTML={{
                __html: props.details.rulesandRegulation,
              }}
            ></div>
          }
          ctaText={"Close"}
          onCtaClick={() => setShowReservationRules(false)}
        />
      )}
    </div>
  );
};

export default GenericDetails;
