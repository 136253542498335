import { PoiItem } from "@Components/index";
import "./PoisList.scss";
import { PoisListProps } from "./PoisListPropTypes";
import PoisListLogic from "./PoisListLogic";

const PoisList = (componentProps: PoisListProps) => {
  const { elementRef, pois } = PoisListLogic(componentProps);
  return (
    <div
      className={`pois-list ${
        componentProps.hasViewMore ? "pois-list-view-more" : ""
      }`}
      ref={(ref) => {
        elementRef.current = ref;
        componentProps.onRef && componentProps.onRef(ref);
      }}>
      {pois.map((poi, index) => {
        const isSelected =
          componentProps.selectedPoi?.id === poi.id ||
          (!componentProps.selectedPoi &&
            componentProps.hoveredPoi?.id === poi.id);
        return (
          <div key={`poi_item${index}`} data-id={`poi-${poi.id}`}>
            <PoiItem
              poi={poi}
              isSelected={isSelected}
              onPoiClick={(poi) => {
                componentProps.onPoiClick && componentProps.onPoiClick(poi);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PoisList;
