import { IconProps } from "../iconTypes";
//@ts-ignore
import themedStyles from "@Assets/styles/themedStyles.scss";

const FamilyFinance = ({
  color = themedStyles.cmsMainIconsColor,
}: IconProps) => {
  return (
    <svg
      width="42"
      height="53"
      viewBox="0 0 42 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="9.36144"
        cy="24.0754"
        r="3.82444"
        stroke={color}
        strokeWidth="1.33523"
      />
      <circle
        cx="20.9335"
        cy="24.0754"
        r="3.82444"
        stroke={color}
        strokeWidth="1.33523"
      />
      <circle
        cx="32.6383"
        cy="24.0754"
        r="3.82444"
        stroke={color}
        strokeWidth="1.33523"
      />
      <path
        d="M24.4707 52.2528V37.1432C24.4707 33.8762 27.9827 30.6093 32.2297 30.6093C37.5385 30.6093 40.8055 33.4679 40.8055 37.1432C40.8055 40.0834 40.8055 48.4414 40.8055 52.2528"
        stroke={color}
        strokeWidth="1.52353"
      />
      <path
        d="M1.19434 52.2528V37.1432C2.01107 32.2428 4.70631 30.6093 8.95334 30.6093C14.2621 30.6093 16.7124 33.0595 17.5291 37.1432C18.1057 40.0263 17.5291 48.4414 17.5291 52.2528"
        stroke={color}
        strokeWidth="1.52353"
      />
      <path
        d="M15.3503 32.0455C16.6581 31.0195 18.3967 30.6093 20.525 30.6093C23.222 30.6093 25.1813 31.2417 26.5634 32.3993"
        stroke={color}
        strokeWidth="1.33523"
      />
      <path
        d="M23.1382 5.22033V5.43656L23.3483 5.48781C24.2533 5.70866 24.749 6.32749 24.8753 7.04303H24.2105C24.1593 6.75444 24.0408 6.48788 23.819 6.28078C23.5324 6.01326 23.118 5.88664 22.5876 5.88664C22.0849 5.88664 21.6522 5.99916 21.3367 6.22106C21.0135 6.44847 20.8254 6.78456 20.8254 7.1807C20.8254 7.34825 20.858 7.51219 20.9388 7.66763C21.0191 7.82223 21.1395 7.95381 21.2942 8.06833C21.5956 8.29134 22.052 8.46697 22.6835 8.63111C23.3036 8.79227 23.9052 8.99644 24.3499 9.3314C24.7791 9.65467 25.0655 10.1011 25.0655 10.7876C25.0655 11.2744 24.8848 11.6393 24.5927 11.91C24.294 12.1868 23.8653 12.3765 23.3622 12.4719L23.1382 12.5144V12.7424V13.651H22.0369V12.7314V12.5087L21.8191 12.4621C20.9295 12.2722 20.218 11.7592 20.0463 10.8977H20.7139C20.7844 11.1868 20.9346 11.4538 21.1928 11.6596C21.5188 11.9193 21.9828 12.0541 22.5876 12.0541C23.2394 12.0541 23.6861 11.8916 23.9722 11.6324C24.2594 11.3722 24.3497 11.0461 24.3497 10.7931C24.3497 10.4777 24.2605 10.1497 23.9532 9.86075C23.661 9.58612 23.1933 9.36785 22.4866 9.19824C21.7329 9.01722 21.1322 8.77842 20.724 8.45237C20.3288 8.13672 20.1096 7.73713 20.1096 7.19171C20.1096 6.31307 20.8159 5.69026 21.8198 5.47291L22.0369 5.42591V5.20381V4.28971H23.1382V5.22033Z"
        fill={color}
        stroke={color}
        strokeWidth="0.550665"
      />
      <mask id="path-8-inside-1_3964_49280" fill="white">
        <path d="M22.5577 16.8174C23.8943 16.8174 25.2092 16.4789 26.3797 15.8335C27.5503 15.1881 28.5383 14.2568 29.2517 13.1265C29.9651 11.9961 30.3807 10.7035 30.4596 9.36916C30.5386 8.03483 30.2784 6.70223 29.7032 5.49563C29.1281 4.28902 28.2568 3.2477 27.1706 2.46877C26.0843 1.68983 24.8185 1.19865 23.4912 1.04102C22.1638 0.883383 20.8182 1.06444 19.5797 1.56729C18.3412 2.07015 17.2503 2.87843 16.4085 3.91677L17.5583 4.84884C18.2427 4.00465 19.1296 3.3475 20.1365 2.93867C21.1434 2.52984 22.2375 2.38264 23.3166 2.5108C24.3958 2.63895 25.4249 3.0383 26.308 3.67158C27.1912 4.30487 27.8996 5.15148 28.3672 6.13248C28.8347 7.11347 29.0463 8.1969 28.9821 9.28173C28.9179 10.3666 28.5801 11.4175 28.0001 12.3365C27.42 13.2555 26.6167 14.0127 25.6651 14.5374C24.7134 15.0621 23.6444 15.3373 22.5577 15.3373V16.8174Z" />
      </mask>
      <path
        d="M22.5577 16.8174C23.8943 16.8174 25.2092 16.4789 26.3797 15.8335C27.5503 15.1881 28.5383 14.2568 29.2517 13.1265C29.9651 11.9961 30.3807 10.7035 30.4596 9.36916C30.5386 8.03483 30.2784 6.70223 29.7032 5.49563C29.1281 4.28902 28.2568 3.2477 27.1706 2.46877C26.0843 1.68983 24.8185 1.19865 23.4912 1.04102C22.1638 0.883383 20.8182 1.06444 19.5797 1.56729C18.3412 2.07015 17.2503 2.87843 16.4085 3.91677L17.5583 4.84884C18.2427 4.00465 19.1296 3.3475 20.1365 2.93867C21.1434 2.52984 22.2375 2.38264 23.3166 2.5108C24.3958 2.63895 25.4249 3.0383 26.308 3.67158C27.1912 4.30487 27.8996 5.15148 28.3672 6.13248C28.8347 7.11347 29.0463 8.1969 28.9821 9.28173C28.9179 10.3666 28.5801 11.4175 28.0001 12.3365C27.42 13.2555 26.6167 14.0127 25.6651 14.5374C24.7134 15.0621 23.6444 15.3373 22.5577 15.3373V16.8174Z"
        fill={color}
        stroke={color}
        strokeWidth="1.37666"
        mask="url(#path-8-inside-1_3964_49280)"
      />
      <mask id="path-9-inside-2_3964_49280" fill="white">
        <path d="M15.6622 5.01384C15.0201 6.15264 14.6701 7.43273 14.6434 8.73979C14.6166 10.0468 14.9141 11.3402 15.5091 12.5042C16.1041 13.6683 16.9781 14.6669 18.0532 15.4108C19.1282 16.1547 20.3708 16.6208 21.6698 16.7674L21.8159 15.4729C20.7306 15.3504 19.6926 14.961 18.7945 14.3396C17.8964 13.7181 17.1662 12.8838 16.6691 11.9113C16.172 10.9388 15.9235 9.85835 15.9458 8.76641C15.9682 7.67446 16.2606 6.60504 16.797 5.65366L15.6622 5.01384Z" />
      </mask>
      <path
        d="M15.6622 5.01384C15.0201 6.15264 14.6701 7.43273 14.6434 8.73979C14.6166 10.0468 14.9141 11.3402 15.5091 12.5042C16.1041 13.6683 16.9781 14.6669 18.0532 15.4108C19.1282 16.1547 20.3708 16.6208 21.6698 16.7674L21.8159 15.4729C20.7306 15.3504 19.6926 14.961 18.7945 14.3396C17.8964 13.7181 17.1662 12.8838 16.6691 11.9113C16.172 10.9388 15.9235 9.85835 15.9458 8.76641C15.9682 7.67446 16.2606 6.60504 16.797 5.65366L15.6622 5.01384Z"
        stroke={color}
        strokeWidth="1.37666"
        strokeDasharray="1.38 1.38"
        mask="url(#path-9-inside-2_3964_49280)"
      />
      <path
        d="M16.7717 1.78033C16.7717 2.1364 16.7715 3.56064 16.7715 4.45079H19.4419"
        stroke={color}
        strokeWidth="0.890152"
      />
    </svg>
  );
};

export default FamilyFinance;
