import React from "react";
import "./Button.scss";
import LoaderRing from "@Components/loader-ring/LoaderRing";

interface ButtonProps {
  text?: string;
  isDisabled?: boolean;
  type?: "close";
  isSecondary?: boolean;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  onClick: () => void;
  dataCy?: string;
  isLoading?: boolean;
  className?: string;
  isSubmit?: boolean;
}

const Button = ({
  text,
  isLoading = false,
  isDisabled,
  type,
  isSecondary,
  style,
  icon,
  onClick,
  dataCy,
  className = "",
  isSubmit,
}: ButtonProps) => {
  const isCloseButton = type === "close";
  return (
    <button
      className={`button  ${isDisabled && "button-disabled"} ${
        isCloseButton && "button-close"
      } ${isSecondary ? "button-secondary" : "button-primary"} ${className}`}
      style={style}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      type={isSubmit ? "submit" : "button"}
      data-cy={dataCy}>
      {isCloseButton ? (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.0988 2.22036L15.4496 0.429443L8.91145 7.52958L2.37328 0.429443L0.724121 2.22036L7.26229 9.3205L0.724121 16.4206L2.37328 18.2115L8.91145 11.1114L15.4496 18.2115L17.0988 16.4206L10.5606 9.3205L17.0988 2.22036Z"
            fill={style?.color ?? "#fff"}
          />
        </svg>
      ) : (
        <div className={"button-content"}>
          {isLoading ? (
            <span className="button-content-loader">
              <LoaderRing />
            </span>
          ) : (
            <>
              {icon && <div className={"button-content-icon"}></div>}
              {icon && <div className={"button-content-icon"}>{icon}</div>}
              {text && <div className={"button-content-text"}>{text}</div>}
            </>
          )}
        </div>
      )}
    </button>
  );
};

export default Button;
