import { EventCategory } from "@App/models/eventCategory";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function usePassedEventCategory(
  eventCategories: EventCategory[] | undefined,
  setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>>
) {
  const [hasSetCategory, setHasSetCategory] = useState(false);

  const [searchParams] = useSearchParams();
  const urlEventFilter = searchParams.get("eventFilterId");

  useEffect(() => {
    if (
      !eventCategories ||
      eventCategories.length === 0 ||
      hasSetCategory ||
      !urlEventFilter
    ) {
      return;
    }

    const matchedCategory = eventCategories.find(
      (cat) => cat.id === decodeURI(urlEventFilter)
    )?.id;

    if (!matchedCategory) {
      return;
    }

    setSelectedCategory(matchedCategory);
    setHasSetCategory(true);
  }, [eventCategories, setSelectedCategory, urlEventFilter, hasSetCategory]);
}
