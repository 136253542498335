import { baseServiceRequest } from "@Api/baseService";
import { Banner } from "@App/models/Banner";

export const getBanner = async () => {
  return await baseServiceRequest<Banner>({
    path: `/cms/banners`,
    type: "get",
    useAuthHeader: true,
  });
};
