import { useNavbarItems } from "@App/hooks";

const NavbarItemsLogic = () => {
  const { navbarItems, isLoadingNavbarItems } = useNavbarItems();

  return {
    navbarItems,
    isLoadingNavbarItems,
  };
};

export default NavbarItemsLogic;
