import { useEffect, useState } from "react";
import {
  getListingCategories,
  getAmenities as getCategoryItems,
} from "@App/api/amenities";
import { useNavigate } from "react-router-dom";
import { ListingCategory } from "@App/models/amenity";
import { sendMobileViewModePostMessage } from "@Utils/utils";

const AmenitiesLogic = () => {
  const [searchText, setSearchText] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [listingCategories, setListingCategories] =
    useState<ListingCategory[]>();
  const navigate = useNavigate();

  useEffect(() => {
    getListingCategories().then((response) => {
      setListingCategories(response.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: isLoading });
  }, [isLoading]);

  const onClick = (id: string) => {
    navigate(`/amenities/amenities-details/${id}`);
  };

  const onSearch = (text: string) => {
    setSearchText(text);
  };

  return {
    listingCategories,
    getCategoryItems,
    isLoading,
    searchText,
    onSearch,
    onClick,
  };
};

export default AmenitiesLogic;
