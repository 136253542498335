import "./Tabs.scss";
import { TabsProps } from "./TabsPropTypes";
import TabsLogic from "./TabsLogic";
import { HorizontalSlider } from "@Components/index";
import { Grading, Heart, Ticket } from "@Components/icons";

const Tabs = (componentProps: TabsProps) => {
  const props = TabsLogic(componentProps);

  return (
    <div
      className={`tabs ${componentProps.underlined ? "tabs-underlined" : ""}`}>
      {componentProps.showAll && (
        <div
          className={`tabs-tab tabs-tab-all ${
            props.isAllSelected ? "tabs-tab-active" : ""
          }`}
          onClick={() => {
            props.setIsAllSelected(true);
            props.handleSelectedTab(-1);
          }}>
          <div>
            <div>
              <Grading isActive={props.isAllSelected} />
            </div>
            <div>All</div>
          </div>
        </div>
      )}

      <HorizontalSlider>
        {props.tabs.map((tab, index) => {
          let tabIcon: React.ReactNode = null;
          if (tab.icon) {
            switch (tab.icon) {
              case "heart":
                tabIcon = <Heart isActive={tab.isActive} />;
                break;
              case "event":
                tabIcon = (
                  <Ticket
                    isActive={tab.isActive}
                    {...(tab.isActive && {
                      color: "#000",
                    })}
                  />
                );
                break;
              default:
                break;
            }
          }

          return (
            <div
              key={`tab_item${index}`}
              id={`TabItem${tab.index}`}
              className={`tabs-tab ${tab.isActive ? "tabs-tab-active" : ""}`}
              style={{ display: tab.isHidden ? "none" : "flex" }}
              onClick={() => {
                props.handleSelectedTab(tab.index ?? 0);
              }}>
              <div>
                {tabIcon && <div>{tabIcon}</div>}
                {tab.iconUrl && (
                  <img className={"tabs-tab-icon"} src={tab.iconUrl} alt={""} />
                )}
                <div>{tab.label}</div>
              </div>
            </div>
          );
        })}
      </HorizontalSlider>
    </div>
  );
};

export default Tabs;
