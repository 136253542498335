import { IconProps } from "../iconTypes";
import themedStyles from "@Assets/styles/themedStyles.scss";

const Paws = ({ color = themedStyles.cmsMainIconsColor }: IconProps) => {
  return (
    <svg
      width="74"
      height="56"
      viewBox="0 0 74 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.52231 31.8928C8.37689 32.3518 7.96373 32.7743 7.24383 33.0144C6.52513 33.2541 5.57593 33.2762 4.58535 32.9624C3.59478 32.6485 2.83154 32.0837 2.38204 31.4739C1.9318 30.863 1.83733 30.2797 1.98276 29.8207C2.12818 29.3617 2.54134 28.9393 3.26125 28.6992C3.97995 28.4594 4.92914 28.4373 5.91972 28.7512C6.91029 29.0651 7.67353 29.6298 8.12303 30.2397C8.57327 30.8506 8.66774 31.4339 8.52231 31.8928Z"
        stroke={color}
        strokeWidth="2.09576"
      />
      <path
        d="M11.8895 23.7824C11.5414 24.1151 10.9761 24.2871 10.2294 24.1519C9.48388 24.0168 8.64076 23.5802 7.92276 22.8291C7.20477 22.0779 6.80667 21.216 6.70543 20.4651C6.60402 19.713 6.80139 19.1561 7.14942 18.8234C7.49745 18.4907 8.06276 18.3187 8.8095 18.454C9.555 18.589 10.3981 19.0256 11.1161 19.7767C11.8341 20.5279 12.2322 21.3898 12.3335 22.1407C12.4349 22.8928 12.2375 23.4497 11.8895 23.7824Z"
        stroke={color}
        strokeWidth="2.09576"
      />
      <path
        d="M18.6679 19.8423C18.1872 19.8692 17.6456 19.6329 17.1656 19.0451C16.6863 18.4583 16.3287 17.5788 16.2705 16.5413C16.2123 15.5039 16.4693 14.5899 16.88 13.9531C17.2912 13.3154 17.803 13.02 18.2837 12.993C18.7644 12.9661 19.306 13.2023 19.786 13.7901C20.2653 14.3769 20.6229 15.2565 20.6811 16.2939C20.7393 17.3314 20.4823 18.2454 20.0717 18.8821C19.6604 19.5199 19.1486 19.8153 18.6679 19.8423Z"
        stroke={color}
        strokeWidth="2.09576"
      />
      <path
        d="M28.6623 21.0854C28.2043 20.937 27.7846 20.521 27.5492 19.7996C27.3142 19.0793 27.2983 18.13 27.6187 17.1415C27.9391 16.153 28.5088 15.3935 29.1217 14.948C29.7355 14.5018 30.3194 14.4112 30.7774 14.5596C31.2354 14.7081 31.6551 15.124 31.8905 15.8455C32.1255 16.5657 32.1414 17.515 31.821 18.5035C31.5006 19.492 30.9309 20.2515 30.3181 20.697C29.7042 21.1432 29.1203 21.2339 28.6623 21.0854Z"
        stroke={color}
        strokeWidth="2.09576"
      />
      <path
        d="M23.6126 36.5967C24.5896 35.2941 26.4622 35.5112 27.2763 35.7826C30.94 35.7826 31.7542 34.1542 31.7542 32.933C31.7542 31.7117 32.1613 31.3047 30.1259 28.4551C28.9046 26.7454 26.4622 25.1985 21.9843 24.7914C17.5064 24.3843 14.6568 27.641 13.8427 30.8977C13.0285 34.1543 14.2497 40.2605 16.2851 41.4818C18.3205 42.703 19.5418 42.2959 21.1701 41.4818C22.7984 40.6676 22.3914 38.2251 23.6126 36.5967Z"
        stroke={color}
        strokeWidth="2.09576"
      />
      <path
        d="M44.8891 20.6179C44.3919 20.7718 43.762 20.6654 43.1049 20.1742C42.4488 19.6838 41.8439 18.8546 41.5118 17.7815C41.1797 16.7084 41.2106 15.6824 41.475 14.9072C41.7398 14.1308 42.1995 13.6872 42.6967 13.5333C43.1939 13.3795 43.8238 13.4859 44.4809 13.977C45.137 14.4674 45.7419 15.2966 46.074 16.3698C46.406 17.4429 46.3752 18.4688 46.1108 19.244C45.846 20.0205 45.3863 20.464 44.8891 20.6179Z"
        stroke={color}
        strokeWidth="2.26563"
      />
      <path
        d="M52.8053 19.3492C52.3335 19.2531 51.8698 18.8868 51.5549 18.1963C51.2406 17.507 51.1183 16.5654 51.3258 15.5472C51.5333 14.5291 52.0142 13.7104 52.5732 13.199C53.1331 12.6867 53.7032 12.5312 54.1749 12.6273C54.6467 12.7234 55.1104 13.0896 55.4252 13.7801C55.7395 14.4695 55.8618 15.411 55.6544 16.4292C55.4469 17.4474 54.966 18.2661 54.407 18.7775C53.8471 19.2897 53.277 19.4453 52.8053 19.3492Z"
        stroke={color}
        strokeWidth="2.09576"
      />
      <path
        d="M59.9065 22.6695C59.6102 22.2901 59.4954 21.7104 59.7044 20.9809C59.913 20.2526 60.4315 19.4571 61.2505 18.8176C62.0694 18.178 62.9668 17.8678 63.724 17.8419C64.4824 17.816 65.017 18.0679 65.3133 18.4474C65.6096 18.8268 65.7244 19.4064 65.5154 20.136C65.3068 20.8643 64.7883 21.6597 63.9693 22.2993C63.1504 22.9388 62.253 23.249 61.4958 23.2749C60.7374 23.3009 60.2029 23.049 59.9065 22.6695Z"
        stroke={color}
        strokeWidth="2.09576"
      />
      <path
        d="M63.5142 31.01C63.3749 30.5491 63.4772 29.9671 63.9355 29.3623C64.3931 28.7585 65.1638 28.204 66.1585 27.9033C67.1531 27.6027 68.1019 27.6374 68.8174 27.8867C69.534 28.1364 69.9415 28.5643 70.0808 29.0252C70.2201 29.486 70.1179 30.068 69.6595 30.6729C69.2019 31.2767 68.4313 31.8312 67.4366 32.1319C66.4419 32.4325 65.4931 32.3978 64.7777 32.1485C64.061 31.8988 63.6535 31.4708 63.5142 31.01Z"
        stroke={color}
        strokeWidth="2.09576"
      />
      <path
        d="M47.8148 36.1296C49.4421 36.1891 50.3318 37.851 50.5733 38.6746C52.6629 41.6839 54.4648 41.4238 55.468 40.7273C56.4711 40.0307 57.0376 40.1329 58.2172 36.8358C58.925 34.8575 58.8025 31.9689 56.5828 28.0587C54.3631 24.1484 50.0628 23.6654 46.9235 24.8541C43.7841 26.0429 39.4652 30.5288 39.623 32.8972C39.7808 35.2656 40.8117 36.0366 42.4092 36.9097C44.0067 37.7828 45.7808 36.0552 47.8148 36.1296Z"
        stroke={color}
        strokeWidth="2.09576"
      />
    </svg>
  );
};

export default Paws;
