import { Chevron } from "@Components/icons";
import {
  CalendarSelect,
  EventsList,
  HorizontalSlider,
  SearchInput,
  Tag,
  UpcomingOrUserEventsSlider,
} from "@Components/index";
import { Navbar } from "@Layouts/index";
import React from "react";
import "./Calendar.scss";
import CalendarLogic from "./CalendarLogic";

const Calendar = () => {
  const {
    isLoadingEvents,
    isUserLogged,
    isSmallScreen,
    showCategoryRightScrollButton,
    eventsListHasNextPage,

    categoryFilters,

    eventCalendarDates,

    events,
    eventsListInViewRef,
    isFetchingCalendarEvents,

    selectedDate,
    setSelectedDate,
    selectedCategory,
    setSelectedCategory,
    showCategoryLeftScrollButton,
    setShowCategoryLeftScrollButton,
    setSearchText,

    handleMonthChange,
    handleScroll,
  } = CalendarLogic();

  const FiltersWrapper = isSmallScreen ? HorizontalSlider : React.Fragment;
  return (
    <Navbar>
      <div className={"calendar-container"}>
        <div className={"calendar-wrapper"}>
          <section>
            <div className={"calendar-top-content"}>
              {categoryFilters && (
                <div className={"calendar-category-filters"}>
                  <div className={"calendar-tags-all"}>
                    <Tag
                      key={`event_tag${-1}`}
                      text={"All"}
                      itemId={"0"}
                      multiple
                      onClick={() => {
                        setSelectedCategory(undefined);
                      }}
                      isSelected={!selectedCategory}
                    />
                  </div>
                  <FiltersWrapper>
                    {showCategoryLeftScrollButton && (
                      <div>
                        <button
                          className={"calendar-tags-scroll-button"}
                          onClick={() => {
                            handleScroll(true);
                          }}
                        >
                          <Chevron
                            position={"left"}
                            size={15}
                            color={"#323232"}
                          />
                        </button>
                      </div>
                    )}
                    <div
                      id={"calendar-category-filters"}
                      className={"calendar-tags"}
                      onWheel={(e) => {
                        e.preventDefault();
                        const el = document.getElementById(
                          "calendar-category-filters",
                        );

                        if (el) {
                          setShowCategoryLeftScrollButton(el.scrollLeft > 0);
                        }
                        return true;
                      }}
                    >
                      {categoryFilters?.map((categoryFilter, index) => {
                        return (
                          <Tag
                            key={`calendar_filter_tag${index}`}
                            text={categoryFilter.name}
                            itemId={categoryFilter.id}
                            multiple
                            onClick={(selectedTag) => {
                              setSelectedCategory(selectedTag);
                            }}
                            isSelected={categoryFilter.id === selectedCategory}
                          />
                        );
                      })}
                    </div>
                    {events && showCategoryRightScrollButton && (
                      <button
                        className={"calendar-tags-scroll-button"}
                        onClick={() => {
                          handleScroll();
                        }}
                      >
                        <Chevron
                          position={"right"}
                          size={15}
                          color={"#323232"}
                        />
                      </button>
                    )}
                  </FiltersWrapper>
                </div>
              )}
              <div className={"calendar-search"}>
                <SearchInput onSearch={setSearchText} />
              </div>
            </div>
          </section>
          <section>
            <div className={"calendar-select-wrapper"}>
              <div className={"calendar-select-picker"}>
                <CalendarSelect
                  selected={selectedDate}
                  availableDates={eventCalendarDates}
                  onMonthChange={(selectedMonthDate) => {
                    setSelectedDate(undefined);
                    handleMonthChange(selectedMonthDate);
                  }}
                  onChange={(selectedDate) => {
                    setSelectedDate(selectedDate);
                  }}
                  dataCy="events-calendar"
                />
              </div>
              <div className="calendar-events-wrapper">
                <EventsList
                  events={events}
                  inViewRef={eventsListInViewRef}
                  hasNextPage={eventsListHasNextPage}
                  notLoggedUser={!isUserLogged}
                  isProcessing={isLoadingEvents}
                  onShowAllClick={() => {
                    setSearchText(undefined);
                    setSelectedCategory(undefined);
                    setSelectedDate(undefined);
                  }}
                  maxHeight="100%"
                  isCard={isSmallScreen}
                  horizontalScroll={isSmallScreen}
                  showCardRSVP={isSmallScreen}
                  showEventDateTitleAlways={isSmallScreen}
                  showTimeOnly={isSmallScreen}
                  isFetchingEvents={isFetchingCalendarEvents}
                />
              </div>
            </div>
          </section>
          <UpcomingOrUserEventsSlider
            isSmallScreen={isSmallScreen}
            wrapperClassName="calendar-events-row"
          />
        </div>
      </div>
    </Navbar>
  );
};

export default Calendar;
