// This component wraps image carousel to convert files into slides.

import useSlidesFromFiles from "@App/hooks/helpers/useSlidesFromFiles";
import ImageCarousel from "./ImageCarousel";
import { ImageFilesCarouselProps } from "./ImageCarouselPropTypes";

export const ImageFilesCarousel = (props: ImageFilesCarouselProps) => {
  const slides = useSlidesFromFiles(props.files);

  return <ImageCarousel {...props} slides={slides} />;
};
