import { RootState } from "@App/store/store";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { updateUserAccountInfo } from "@Api/user";
import {
  QUERY_KEY_TENANT_CONFIG,
  QUERY_KEY_USER_DETAILS,
} from "@App/constants/queryKeyConstants";
import { UpdatedInfo, UserResponse } from "./EditProfilePropTypes";
import { tenantConfigGet } from "@App/api/general";
import { Tenant } from "@App/models/tenant";

const EditProfileLogic = (props: any) => {
  const queryClient = useQueryClient();
  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });
  const updateAccountMutation = useMutation({
    mutationFn: (updatedInfo: UpdatedInfo) =>
      updateUserAccountInfo(props.accountInfo!.id, updatedInfo),
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY_USER_DETAILS], { exact: true });
    },
  });
  const userProfile: UserResponse = useSelector(
    (state: RootState) => state.userProfile,
  );
  const { firstName, lastName } = userProfile?.userInfo;
  const { address1, address2, city, state, zip } =
    props.accountInfo?.address ?? {};
  const [userPhone, setUserPhone] = useState(props.accountInfo?.phone);

  const [birthDayDate, setBirthDayDate] = useState(
    props.accountInfo?.birthday
      ? moment(props.accountInfo?.birthday).toDate()
      : moment(new Date()).toDate(),
  );
  const handleSave = () => {
    const updatedInfo = {
      birthday: moment(birthDayDate).toISOString(),
      phone: userPhone,
    };
    updateAccountMutation.mutate(updatedInfo);
    props.onClose && props.onClose();
  };
  return {
    userPhone,
    address1,
    address2,
    city,
    state,
    zip,
    firstName,
    lastName,
    birthDayDate,
    tenantConfig,
    setBirthDayDate,
    setUserPhone,
    handleSave,
  };
};

export default EditProfileLogic;
