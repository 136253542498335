import { ResidentCenterServiceDetailDummy } from "@Utils/dummy";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ResidentCenterServiceDetailsLogic = () => {
  //TODO: Remove any from detail when we have the BE, use a type (model)
  const [details, setDetails] = useState<any>();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    const serviceDetail = ResidentCenterServiceDetailDummy.find(
      (detail) => detail.id === id,
    );
    if (serviceDetail) {
      setDetails(serviceDetail);
    }
  }, [id]);

  const goBack = () => {
    navigate(-1);
  };

  return { details, goBack };
};

export default ResidentCenterServiceDetailsLogic;
