import { MagnifyingGlass, Settings } from "@Components/icons";
import { Checkbox } from "@Components/index";
import { useEffect, useState } from "react";
import "./SearchInput.scss";
import {
  SearchInputFilterProps,
  SearchInputProps,
} from "./SearchInputPropTypes";

let searchWaiting: NodeJS.Timeout | null = null;

const SearchInput = ({
  showMagnifier = true,
  typeDelayMS = 500,
  initialValue = "",
  onSearch,
  placeholder,
  style,
  className,
  filterSections = [],
  onFilterChange,
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState(initialValue);
  const [hideMagnifierIcon, setHideMagnifierIcon] = useState(false);
  const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
  const [filters, setFilters] = useState<{ [key: string]: string }[]>([]);

  useEffect(() => {
    const allItems = filterSections.reduce(
      (acc: SearchInputFilterProps[], section) => {
        return acc.concat(section.items);
      },
      [],
    );

    // Filter items based on isSelected prop
    const selectedItems = allItems.filter((item) => item.isSelected);

    setFilters(() =>
      selectedItems.map((selectedItem) => ({
        [selectedItem.filterName]: selectedItem.value,
      })),
    );
  }, []);

  const handleFilterChange = (
    filterName: string,
    value: string,
    isSelected: boolean,
  ) => {
    const selectedFilters = !isSelected
      ? filters.filter((filter) => filter[filterName] !== value)
      : [...filters, { [filterName]: value }];
    setFilters(selectedFilters);
    onFilterChange && onFilterChange(selectedFilters);
  };

  return (
    <div className={`search-input ${className ? className : ""}`}>
      {showMagnifier && !hideMagnifierIcon && (
        <div className={"search-input-icon"}>
          <MagnifyingGlass />
        </div>
      )}
      <input
        className={"search-input-input"}
        type={"search"}
        style={style}
        placeholder={placeholder ? placeholder : ""}
        value={searchValue}
        onChange={(event) => {
          const textValue = event.currentTarget.value;
          if (searchWaiting) {
            clearTimeout(searchWaiting);
          }
          setSearchValue(textValue);

          searchWaiting = setTimeout(() => {
            searchWaiting = null;
            onSearch(textValue.trim());
          }, typeDelayMS);
        }}
        onFocus={() => {
          setHideMagnifierIcon(true);
        }}
        onBlur={() => {
          if (searchValue.length === 0) {
            setHideMagnifierIcon(false);
          }
        }}
      />
      {filterSections?.length > 0 && (
        <>
          <button
            className={"search-input-settings"}
            onClick={() => {
              setIsFilterMenuVisible(!isFilterMenuVisible);
            }}
          >
            <Settings />
          </button>

          <div
            className={`search-input-menu ${
              isFilterMenuVisible ? "" : "search-input-menu-hidden"
            }`}
          >
            <div className={"search-input-menu-title"}>FILTERS</div>

            {filterSections?.map((filterSection, index) => {
              return (
                <div
                  key={`filter_section_${index}`}
                  className={"search-input-menu-section"}
                >
                  <span className={"search-input-menu-section-title"}>
                    {filterSection.title}
                  </span>
                  <ul className={"search-input-menu-section-options"}>
                    {filterSection?.items?.map(
                      (filterItem, filterItemIndex) => {
                        return (
                          <li
                            key={`filter_item_${filterItemIndex}`}
                            className={
                              "search-input-menu-section-options-option"
                            }
                            role="menuitem"
                          >
                            {filterItem.type === "check" ? (
                              <Checkbox
                                text={filterItem.label}
                                onChange={(value) => {
                                  handleFilterChange(
                                    filterItem.filterName,
                                    filterItem.value,
                                    value,
                                  );
                                }}
                                defaultChecked={filterItem.isSelected}
                              />
                            ) : (
                              filterItem.label
                            )}
                          </li>
                        );
                      },
                    )}
                  </ul>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default SearchInput;
