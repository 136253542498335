import { CLOSE_INFORMATION_MODAL } from "@App/constants/appConstants";
import { Button, Image } from "@Components/index";
import { customEventPublish } from "@Utils/utils";
import "./ProfileUpdate.scss";
import ProfileUpdateLogic from "./ProfileUpdateLogic";
import { config } from "@App/config/config";

const ProfileUpdate = () => {
  const props = ProfileUpdateLogic();
  return (
    <div className={"user-details-information-modal"}>
      <div className={"user-details-information-modal-logo"}>
        <Image
          src={props.tenantConfig?.cmsSettings?.websiteSettings?.communityIcon}
          alt={""}
          height="132px"
          width="132px"
        />
      </div>
      <div className={"user-details-information-modal-text"}>
        {`To change your profile information please email ${
          props.tenantConfig?.cmsSettings?.websiteSettings?.adminEmail ??
          config.defaultAdminEmail
        }
     for further assistance.`}
      </div>
      <div className={"user-details-information-modal-buttons"}>
        <Button
          text={"Cancel"}
          onClick={() => {
            customEventPublish(CLOSE_INFORMATION_MODAL, {});
          }}
          isSecondary
        />
        <Button
          text={"Email Us"}
          onClick={() => {
            window.location.href = `mailto:${
              props.tenantConfig?.cmsSettings?.websiteSettings?.adminEmail ??
              config.defaultAdminEmail
            }?subject=Profile update&body=Hello, I want to update my profile..`;
          }}
        />
      </div>
    </div>
  );
};

export default ProfileUpdate;
