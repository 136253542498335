import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {
  userProfileReducer,
  userRegistrationReducer,
} from "@Store/reducers/userReducer";
import { generalDataReducer } from "@Store/reducers/generalReducer";

const store = configureStore({
  reducer: combineReducers({
    userProfile: userProfileReducer,
    userRegistration: userRegistrationReducer,
    communityInfo: generalDataReducer,
  }),
  middleware: [thunk],
  devTools: true,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
