import { useEffect, useState } from "react";
import {
  getAmenities as getCategoryItems,
  getListingCategories,
} from "@App/api/amenities";
import { useNavigate } from "react-router-dom";
import { ListingCategory } from "@App/models/amenity";
import { sendMobileViewModePostMessage } from "@Utils/utils";
import { getManagedBy, getSubCommunities } from "@App/api/general";

const VenuesLogic = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [listingCategories, setListingCategories] =
    useState<ListingCategory[]>();
  const [searchText, setSearchText] = useState<string>();
  const [searchFilters, setSearchFilters] = useState<any>([]);
  const [filterSections, setFilterSections] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    getListingCategories().then((response) => {
      setListingCategories(response.data);
    });
    const getFilterData = async (
      action: any,
      filterName: string,
      propName: string,
    ) => {
      return action().then((response: any) => {
        const subCommunityFilters = response.data?.map((sucommunity: any) => {
          return {
            filterName,
            label: sucommunity[propName],
            value: sucommunity.id,
            type: "check",
          };
        });
        return subCommunityFilters;
      });
    };

    let filters: any = [];
    getFilterData(getManagedBy, "ManagedByIds", "name").then(
      (managedByFilters) => {
        filters.push({
          title: "Management Entity",
          items: managedByFilters,
        });
        getFilterData(getSubCommunities, "SubCommunityIds", "title").then(
          (subCommunityFilters) => {
            filters.push({
              title: "Sub-Community",
              items: subCommunityFilters,
            });
            setFilterSections(filters ?? []);
          },
        );
      },
    );
    setIsLoading(false);
  }, []);

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: isLoading });
  }, [isLoading]);

  const getFilterRequestParams = (filtersArray: object[]) => {
    return filtersArray.reduce((acc: any, obj: any) => {
      // Iterate over the keys of the current object
      Object.keys(obj).forEach((key) => {
        // If the key already exists in the accumulator, push the value to the existing array
        if (acc.hasOwnProperty(key)) {
          if (Array.isArray(acc[key])) {
            acc[key].push(obj[key]);
          } else {
            acc[key] = [acc[key], obj[key]];
          }
        } else {
          // If the key doesn't exist in the accumulator, create a new array with the value
          acc[key] = obj[key];
        }
      });

      return acc;
    }, {});
  };

  const onClick = (id: string) => {
    navigate(`/venues/venues-details/${id}`);
  };

  const onSearch = (text: string) => {
    setSearchText(text);
  };

  const onFilterChange = (filters: any) => {
    setSearchFilters(filters);
  };

  return {
    isLoading,
    filterSections,
    listingCategories,
    getCategoryItems,
    onClick,
    searchText,
    onSearch,
    onFilterChange,
    searchFilters,
    getFilterRequestParams,
  };
};

export default VenuesLogic;
