import { IconProps } from "../iconTypes";
//@ts-ignore
import themedStyles from "@Assets/styles/themedStyles.scss";

const Clock = ({ color = themedStyles.cmsMainIconsColor }: IconProps) => {
  return (
    <svg
      width="75"
      height="57"
      viewBox="0 0 75 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.1571 33.2119C36.1571 33.948 36.7538 34.5448 37.49 34.5448C38.2261 34.5448 38.8229 33.948 38.8229 33.2119L36.1571 33.2119ZM36.1571 23.2483L36.1571 33.2119L38.8229 33.2119L38.8229 23.2483L36.1571 23.2483Z"
        fill={color}
      />
      <path
        d="M50.2517 43.1868C50.8535 43.6108 51.7137 43.4881 52.1729 42.9128C52.6322 42.3375 52.5167 41.5274 51.915 41.1034L50.2517 43.1868ZM36.1103 33.2232L50.2517 43.1868L51.915 41.1034L37.7735 31.1399L36.1103 33.2232Z"
        fill={color}
      />
      <path
        d="M20.6527 49.4026L17.1173 53.1389C16.8227 53.5541 16.4986 54.6335 17.5592 55.6298C18.6198 56.6262 20.0634 56.045 20.6527 55.6298L24.1881 51.8935"
        stroke={color}
        strokeWidth="1.34709"
      />
      <path
        d="M55.038 49.4429L58.5733 53.1792C58.868 53.5944 59.192 54.6737 58.1314 55.6701C57.0708 56.6664 55.6272 56.0852 55.038 55.6701L51.5026 51.9338"
        stroke={color}
        strokeWidth="1.34709"
      />
      <path
        d="M62.6353 33.2118C62.6353 46.0515 51.5555 56.4601 37.8878 56.4601C24.2202 56.4601 13.1404 46.0515 13.1404 33.2118C13.1404 20.3721 24.2202 9.9635 37.8878 9.9635C51.5555 9.9635 62.6353 20.3721 62.6353 33.2118ZM15.1723 33.2118C15.1723 45.1321 25.1989 54.7954 37.8878 54.7954C50.5768 54.7954 60.8632 45.1321 60.8632 33.2118C60.8632 21.2915 50.5768 11.6282 37.8878 11.6282C25.1989 11.6282 15.1723 21.2915 15.1723 33.2118Z"
        fill={color}
      />
      <path
        d="M59.0997 33.2119C59.0997 44.2173 49.6028 53.139 37.8876 53.139C26.1725 53.139 16.6755 44.2173 16.6755 33.2119C16.6755 22.2065 26.1725 13.2848 37.8876 13.2848C49.6028 13.2848 59.0997 22.2065 59.0997 33.2119ZM18.6301 33.2119C18.6301 43.2032 27.252 51.3028 37.8876 51.3028C48.5233 51.3028 57.1451 43.2032 57.1451 33.2119C57.1451 23.2206 48.5233 15.121 37.8876 15.121C27.252 15.121 18.6301 23.2206 18.6301 33.2119Z"
        fill={color}
      />
      <mask id="path-7-inside-1_5959_179" fill="white">
        <path d="M60.2226 17.2848C60.9503 16.2947 61.4764 15.1883 61.7711 14.0287C62.0658 12.8691 62.1232 11.6791 61.9401 10.5265C61.757 9.37395 61.3369 8.28145 60.7039 7.31137C60.0708 6.3413 59.2372 5.51266 58.2506 4.87276C57.264 4.23287 56.1437 3.79424 54.9537 3.58194C53.7638 3.36964 52.5274 3.38781 51.3153 3.63542C50.1031 3.88304 48.9389 4.35524 47.8891 5.02507C46.8393 5.6949 45.9245 6.54924 45.1968 7.53932L52.7097 12.4121L60.2226 17.2848Z" />
      </mask>
      <path
        d="M60.2226 17.2848C60.9503 16.2947 61.4764 15.1883 61.7711 14.0287C62.0658 12.8691 62.1232 11.6791 61.9401 10.5265C61.757 9.37395 61.3369 8.28145 60.7039 7.31137C60.0708 6.3413 59.2372 5.51266 58.2506 4.87276C57.264 4.23287 56.1437 3.79424 54.9537 3.58194C53.7638 3.36964 52.5274 3.38781 51.3153 3.63542C50.1031 3.88304 48.9389 4.35524 47.8891 5.02507C46.8393 5.6949 45.9245 6.54924 45.1968 7.53932L52.7097 12.4121L60.2226 17.2848Z"
        stroke={color}
        strokeWidth="2.90971"
        strokeLinejoin="round"
        mask="url(#path-7-inside-1_5959_179)"
      />
      <path
        d="M48.936 12.8697L50.7037 10.3788"
        stroke={color}
        strokeWidth="1.28992"
      />
      <path
        d="M52.9126 15.7757L54.6803 13.2848"
        stroke={color}
        strokeWidth="1.28992"
      />
      <mask id="path-10-inside-2_5959_179" fill="white">
        <path d="M14.2625 18.1151C13.5349 17.125 13.0087 16.0186 12.714 14.859C12.4193 13.6995 12.3619 12.5094 12.545 11.3568C12.7281 10.2043 13.1482 9.11177 13.7813 8.1417C14.4143 7.17162 15.2479 6.34298 16.2345 5.70309C17.2211 5.06319 18.3414 4.62457 19.5314 4.41226C20.7213 4.19996 21.9577 4.21813 23.1698 4.46575C24.382 4.71336 25.5462 5.18556 26.596 5.85539C27.6458 6.52522 28.5606 7.37957 29.2883 8.36964L21.7754 13.2424L14.2625 18.1151Z" />
      </mask>
      <path
        d="M14.2625 18.1151C13.5349 17.125 13.0087 16.0186 12.714 14.859C12.4193 13.6995 12.3619 12.5094 12.545 11.3568C12.7281 10.2043 13.1482 9.11177 13.7813 8.1417C14.4143 7.17162 15.2479 6.34298 16.2345 5.70309C17.2211 5.06319 18.3414 4.62457 19.5314 4.41226C20.7213 4.19996 21.9577 4.21813 23.1698 4.46575C24.382 4.71336 25.5462 5.18556 26.596 5.85539C27.6458 6.52522 28.5606 7.37957 29.2883 8.36964L21.7754 13.2424L14.2625 18.1151Z"
        stroke={color}
        strokeWidth="2.90971"
        strokeLinejoin="round"
        mask="url(#path-10-inside-2_5959_179)"
      />
      <path
        d="M25.5481 13.7L23.7804 11.2091"
        stroke={color}
        strokeWidth="1.28992"
      />
      <path
        d="M21.5715 16.6059L19.8039 14.115"
        stroke={color}
        strokeWidth="1.28992"
      />
    </svg>
  );
};

export default Clock;
