import "./ErrorBox.scss";
import { TriangleError } from "@Components/icons";

const ErrorBox = ({ message }: any) => {
  return (
    <div className={"error-box"}>
      <div>
        <TriangleError color={"#FA3E3E"} size={20} />
      </div>
      <div className={"error-box__text"}>{message}</div>
    </div>
  );
};

export default ErrorBox;
