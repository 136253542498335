import { tenantConfigGet } from "@App/api/general";
import userSession from "@App/auth/userSession";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { Tenant } from "@App/models/tenant";
import { sendMobileViewModePostMessage } from "@Utils/utils";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import noImagePlaceholder from "@Assets/images/image_not_available.png";
import { CmsDetailProps } from "./CmsDetailProps";
import useDetailPageData from "@App/hooks/api/cms/useDetailPageData";

const CmsDetailLogic = ({ hardCodedId }: CmsDetailProps) => {
  const navigate = useNavigate();
  const { id: idFromParams } = useParams<string>();

  const [isMobileEmbed] = useState(userSession.isMobileViewMode);

  const { data: tenant } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });

  const { detailPageData, isLoadingDetailPageData } = useDetailPageData(
    hardCodedId ?? idFromParams,
  );

  useEffect(() => {
    // Should revisit this if we add additional redirects
    if (
      hardCodedId === "payments" &&
      window.location.pathname !== "/cms-detail/payments"
    ) {
      window.history.pushState(
        { detailId: "payments" }, // This is superfluous but kept semantic.
        "", // Unused a la MDN
        "/cms-detail/payments",
      );
    }

    if (!isLoadingDetailPageData && !detailPageData) {
      navigate("/error");
    } else {
      // TECH: Let Mobile Embeded know that the page is ready to be displayed.
      sendMobileViewModePostMessage({ isLoading: false });
    }
  }, [
    hardCodedId,
    idFromParams,
    detailPageData,
    navigate,
    isLoadingDetailPageData,
  ]);

  const goBack = () => {
    navigate(-1);
  };

  const replaceFilePaths = useCallback(
    (markup: string, fileNames?: string[], placeholderPrefix?: string) => {
      if (!fileNames?.length) {
        return markup;
      }

      const prefix = placeholderPrefix ?? "image-path-";

      // determine how much we need to pad
      const maxIndex = fileNames.length - 1;
      const maxIndexLength = Math.floor(Math.log10(maxIndex)) + 1;

      // replace paths based on "image-path-[nn...n]"
      fileNames.forEach((name, index) => {
        // Zero-pad the index based on the maximum index length
        const indexString = index.toString().padStart(maxIndexLength, "0");

        // Replace invalid chars here so the file names are easier to write.
        const validCharsName = encodeURI(name);
        const path = validCharsName
          ? buildFileUrl(
              tenant?.awsSettings.assetStorageBaseURL,
              validCharsName,
            )
          : noImagePlaceholder;

        markup = markup.replaceAll(prefix + indexString, path);
      });

      return markup;
    },
    [tenant?.awsSettings.assetStorageBaseURL],
  );

  return {
    detailPageData,
    tenant,
    isMobileEmbed,
    goBack,
    replaceFilePaths,
    buildFileUrl,
  };
};

export default CmsDetailLogic;

const buildFileUrl = (s3BaseUrl?: string, fileName?: string) => {
  return fileName ? `${s3BaseUrl}/${fileName}` : "";
};
