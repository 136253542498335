import { CreateUserMembershipRequest } from "@App/api/requests/memberships";
import { config } from "@App/config/config";
import { useTenantConfig } from "@App/hooks";
import { FileMetadata } from "@App/models/File";
import { Membership } from "@App/models/membership";
import { PresignedUrl } from "@App/models/PresignedUrl";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { generateId } from "@Utils/utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dog } from "../membership-dog-row/MembershipDogRowPropTypes";
import useCreateUserMembershipMutations from "./useCreateUserMembershipMutations";

// TODO: Refactor to RHF
const MembershipDogFormLogic = (
  membership: Membership,
  goToNextStep: (userMembershipId: string) => void,
) => {
  const tenantConfig = useTenantConfig();
  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = userData;

  const [dogs, setDogs] = useState<Dog[]>([
    { id: generateId(), index: 0, dogName: "", dogBreed: "" },
  ]);

  const [preferredPark, setPreferredPark] = useState<{
    id: string;
    title: string;
  }>();
  const [isDogFormStepValid, setIsDogFormStepValid] = useState(false);
  const [files, setFiles] = useState<(File | FileMetadata)[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<PresignedUrl[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const adminEmail =
    tenantConfig?.cmsSettings?.websiteSettings?.adminEmail ??
    config.defaultAdminEmail;

  useEffect(() => {
    let validDogs = true;
    dogs.forEach((dog) => {
      if (
        files?.length === 0 ||
        (validDogs && dog.dogName.length <= 1) ||
        dog.dogBreed.length <= 1
      ) {
        validDogs = false;
      }
      setIsDogFormStepValid(validDogs && !!preferredPark);
    });
  }, [dogs, preferredPark, files]);

  const { getPresignedUrlMutation, createUserMembershipMutation } =
    useCreateUserMembershipMutations({
      localStorageBlob: {
        dogs,
        preferredPark,
        membership,
      },
      adminEmail,
      uploadedFiles,
      setUploadedFiles,
      setIsProcessing,
      setError,
      goToNextStep,
    });

  const onAddDogRow = () => {
    const dogsList = [
      ...dogs,
      {
        id: generateId(),
        index: dogs.length,
        dogName: "",
        dogBreed: "",
      },
    ];
    setDogs(dogsList);
  };

  const onDogDetailsChange = (dog: Dog) => {
    const updatedDogList = dogs?.map((updatedListDogItem) => {
      if (updatedListDogItem.index === dog.index) {
        return {
          id: dog.id,
          index: dog.index,
          dogName: dog.dogName,
          dogBreed: dog.dogBreed,
        };
      }
      return updatedListDogItem;
    });
    setDogs(updatedDogList);
  };

  const onDogRemove = (dogRemoveIndex: number) => {
    const filteredDogList: Dog[] = [];
    let dogIndex = 0;
    dogs.forEach((dogItem) => {
      if (dogItem.index !== dogRemoveIndex) {
        filteredDogList.push({
          id: dogItem.id,
          index: dogIndex,
          dogName: dogItem.dogName,
          dogBreed: dogItem.dogBreed,
        });
        dogIndex++;
      }
    });
    setDogs(filteredDogList);
  };

  const handleContinue = async () => {
    // TODO: isActive shouldn't be set in the payload, should be handled in the BE API
    // TODO: application.appliedDate probably should be set in the BE API too

    if (!userInfo) {
      throw new Error("User info not found. Unable to create membership");
    }

    if (!preferredPark) {
      throw new Error("Preferred park not found. Unable to create membership");
    }

    setIsProcessing(true);
    let fileUploadError = false;

    // instanceof not working with typescript
    // @ts-ignore
    const filesToUpload: File[] = files.filter((file) => file instanceof File);

    for (const file of filesToUpload) {
      try {
        await getPresignedUrlMutation.mutateAsync(file);
      } catch {
        fileUploadError = true;
        break;
      }
    }

    if (fileUploadError) {
      return;
    }

    const membershipRequest: CreateUserMembershipRequest = {
      communityAssetId: preferredPark.id,
      userId: userInfo.id,
      membershipId: membership.id,
      isActive: true,
      application: {
        appliedDate: new Date().toISOString(),
        formFields: [],
        groupedFormFields: [],
      },
    };

    // TODO: PLEASE fix me.
    const dogFormFields: any = [];

    dogs.forEach((dog) => {
      const fields = membership.application.groupedFormFields.map((group) => {
        return {
          ...group,
          fields: group.fields.map((field) => {
            if (field.order === 1) {
              return {
                id: field.id,
                value: dog.dogName,
                order: field.order,
              };
            } else {
              return {
                id: field.id,
                value: dog.dogBreed,
                order: field.order,
              };
            }
          }),
        };
      });
      dogFormFields.push(...fields);
    });

    membershipRequest.application.groupedFormFields = dogFormFields;

    createUserMembershipMutation.mutate(membershipRequest);
  };

  return {
    dogs,
    preferredPark,
    isDogFormStepValid,
    files,
    onAddDogRow,
    onDogDetailsChange,
    onDogRemove,
    setPreferredPark,
    handleContinue,
    setFiles,
    error,
    isProcessing,
  };
};

export default MembershipDogFormLogic;
