import { Navbar } from "@Layouts/index";
import "./ClubsAndSocials.scss";
import { GenericListing } from "@Components/index";
import ClubsAndSocialsLogic from "./ClubsAndSocialsLogic";

const ClubsAndSocials = () => {
  const {
    listingCategories,
    getCategoryItems,
    isLoading,
    onClick,
    searchText,
    onSearch,
  } = ClubsAndSocialsLogic();

  return (
    <Navbar>
      <div className={"clubs-and-socials"}>
        <GenericListing
          title={"Groups & Clubs"}
          onClick={onClick}
          isLoading={isLoading}
          listingCategories={listingCategories}
          getCategoryItems={getCategoryItems}
          searchText={searchText}
          onSearch={onSearch}
          moduleName={"clubs-and-socials"}
        />
      </div>
    </Navbar>
  );
};

export default ClubsAndSocials;
