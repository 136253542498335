import {
  Button,
  Checkbox,
  DropdownCheckbox,
  ProfileUpdate,
  TextInput,
  UserTypeInfo,
} from "@Components/index";
import { USStates } from "@Utils/dummy";
import React from "react";
import "./RegistrationDetails.scss";
import RegistrationDetailsLogic from "./RegistrationDetailsLogic";
import { RegistrationDetailsProps } from "./RegistrationDetailsPropTypes";
import { config } from "@App/config/config";
import { UserType } from "@App/models/types";
import { useTranslation } from "react-i18next";

const RegistrationDetails = ({
  onSave,
  onClose,
  tenantName,
}: RegistrationDetailsProps) => {
  const {
    showRegistrationDetails,
    address1,
    address2,
    city,
    state,
    zip,
    validAddress1,
    validCity,
    validZip,
    validForm,
    setAddress1,
    setAddress2,
    setState,
    setCity,
    setZip,
    setShowRegistrationDetails,
    setMemberType,
    marketingCommunicationsEnabled,
    setMarketingCommunicationsEnabled,
    saveRegistrationDetails,
    userDataLoaded,
    email,
    setEmail,
    validEmail,
    firstname,
    setFirstname,
    validFirstname,
    lastname,
    setLastname,
    validLastname,
    memberType,
    showUserTypeInfoModal,
    setShowUserTypeInfoModal,
  } = RegistrationDetailsLogic();
  const { t } = useTranslation();
  return (
    <div className={"user-details-wrapper"}>
      <Button type={"close"} style={{ color: "#000" }} onClick={onClose} />
      <label className={"user-details-title"}>
        Complete your account setup
      </label>
      <label className={"user-details-subtitle"}>
        Please provide the following details.
      </label>
      <div className={"user-details-top-section"}>
        <div className={"user-details-top-section-row"}>
          <div className={"user-details-row-field"}>
            <TextInput
              type={"text"}
              label={"First Name"}
              placeholder={"Enter First Name"}
              maxLength={50}
              withMargin
              required
              value={firstname}
              onChange={setFirstname}
              isValid={validFirstname}
              errorMessage={"Please enter your first name"}
              isDisabled={!userDataLoaded}
              isLoadingContent={!userDataLoaded}
              dataCy="user-deets-first-name"
            />
          </div>
          <div className={"user-details-row-field"}>
            <TextInput
              type={"text"}
              label={"Last Name"}
              placeholder={"Enter Last Name"}
              maxLength={50}
              withMargin
              required
              value={lastname}
              onChange={setLastname}
              isValid={validLastname}
              errorMessage={"Please enter your last name"}
              isDisabled={!userDataLoaded}
              isLoadingContent={!userDataLoaded}
              dataCy="user-deets-last-name"
            />
          </div>
        </div>
        <div className={"user-details-top-section-row"}>
          <div className={"user-details-row-field"}>
            <TextInput
              type={"email"}
              label={"Email"}
              placeholder={"Enter Email"}
              maxLength={100}
              withMargin
              required
              value={email}
              onChange={setEmail}
              isValid={validEmail}
              errorMessage={"Please enter a valid Email address"}
              isDisabled
              isLoadingContent={!userDataLoaded}
              informationModalContent={<ProfileUpdate />}
              dataCy="user-deets-email"
            />
          </div>
          <div className={"user-details-row-field user-details-top-section"}>
            <DropdownCheckbox
              label={`Resident or ${t("Community Member")}?`}
              options={[
                { label: "Resident", value: UserType.Resident },
                { label: t("Community Member"), value: UserType.CommunityMember },
              ]}
              handleChange={(selectedItem: any) => {
                setShowRegistrationDetails(
                  selectedItem?.value === UserType.Resident,
                );
                setMemberType(selectedItem);
              }}
              optionSelected={memberType}
              tooltip={"Which one are you?"}
              isInsideModal
              onTooltipClick={() => setShowUserTypeInfoModal(true)}
              closeMenuOnSelect
              dataCy="user-deets-type-dropdown"
            />
          </div>
        </div>
      </div>
      {showRegistrationDetails && (
        <React.Fragment>
          <div className={"user-details-top-section-row"}>
            <div className={"user-details-row-field"}>
              <TextInput
                type={"text"}
                label={"Address 1"}
                onChange={setAddress1}
                required
                placeholder={"Enter your address"}
                maxLength={70}
                withMargin
                value={address1}
                isValid={validAddress1}
                errorMessage={"Please enter your address"}
                dataCy="user-deets-address-1"
              />
            </div>
            <div className={"user-details-row-field"}>
              <TextInput
                type={"text"}
                label={"Address 2"}
                onChange={setAddress2}
                placeholder={"Enter your address"}
                maxLength={70}
                withMargin
                value={address2}
                dataCy="user-deets-address-2"
              />
            </div>
          </div>
          <div className={"user-details-top-section-row"}>
            <div
              className={"user-details-top-section-row user-details-row-field"}>
              <div className={"user-details-row-field"}>
                <TextInput
                  type={"text"}
                  label={"City"}
                  onChange={setCity}
                  required
                  placeholder={"Enter your city"}
                  maxLength={20}
                  value={city}
                  isValid={validCity}
                  errorMessage={"Please enter your city"}
                  dataCy="user-deets-city"
                />
              </div>
              <div className={"user-details-row-field"}>
                <DropdownCheckbox
                  options={USStates}
                  optionSelected={state}
                  handleChange={setState}
                  optionLabel="label"
                  optionValue="value"
                  label="State"
                  placeholder="Select a state"
                  isRequired
                  maxMenuHeight={350}
                  isClearable={false}
                  blurOnSelect={true}
                  isMulti={false}
                  dataCy="user-deets-state-dropdown"
                />
              </div>
            </div>
            <div className={"user-details-row-field"}>
              <TextInput
                type={"text"}
                label={"Zip"}
                onChange={setZip}
                required
                placeholder={"Enter your zip"}
                maxLength={10}
                withMargin
                value={zip}
                isValid={validZip}
                errorMessage={"Please enter your zip code"}
                dataCy="user-deets-zip"
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <div className={"user-details-bottom-marketing"}>
        <Checkbox
          text={`I would like to receive marketing communications and offers from ${
            tenantName ?? "the community"
          } and its selected partners.`}
          defaultChecked={marketingCommunicationsEnabled}
          onChange={(value) => {
            setMarketingCommunicationsEnabled(value);
          }}
        />
      </div>
      <div className={"user-details-bottom-disclaimer"}>
        By registering for this account and selecting 'Continue' I agree to the{" "}
        <a
          className={"user-details-bottom-disclaimer-link"}
          // TODO: We need to add the default Cecilian for now https://app.clickup.com/t/2242234/CBP-2616,
          // and https://app.clickup.com/t/2242234/CBP-2595 after it's ready to use first the tenant one
          // and if null, the default one.
          href={config.defaultPrivacyPolicy}
          target={"_blank"}
          rel="noreferrer">
          Privacy Policy
        </a>
        .
        {/* TODO: Terms will be enabled later and needs to be returned from the API response, same as Privacy*/}
        {/* {` and associated `}
        <a
          className={"user-details-bottom-disclaimer-link"}
          href="https://google.com"
          target={"_blank"}
          rel="noreferrer">
          Terms & Conditions.
        </a> */}
      </div>
      <div className={"user-details-save-button"}>
        <Button
          text={"Save"}
          onClick={() => {
            saveRegistrationDetails();
            onSave();
          }}
          isDisabled={!validForm}
          dataCy="user-deets-save-button"
        />
      </div>
      <UserTypeInfo
        isVisible={showUserTypeInfoModal}
        onClose={() => setShowUserTypeInfoModal(false)}
      />
    </div>
  );
};

export default RegistrationDetails;
