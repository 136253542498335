import { ImageProps } from "./ImageProps";
import "./Image.scss";

const Image = ({ src, alt, height, width, ...props }: ImageProps) => {
  if (!src || src === "") {
    return (
      <span
        className="image-skeleton"
        style={{ height: height ?? "124px", width: width ?? "124px" }}></span>
    );
  }
  return <img src={src} alt={alt} height={height} width={width} {...props} />;
};

export default Image;
