// Idea to get the column widths to play nice
// If we set all the sizes ahead of time, we can work out how to fill it to 100%
// Use this in the column definitions for any columns you don't want to explicitly set a size for
// You still have to do a little math. Default size is 100 / total columns. Use that as a starting point.

// Consider figuring out how to implement this in the table component
// If you can get all the alt sizes before the table is rendered, you can set the default size

export const DEFAULT_TABLE_RESIZE_SPEED = 10.0;

export type AlternateColumnSize = {
  type?: string;
  size: number;
  numberOfColumns: number;
};

export function getDefaultColumnSize(
  columnAlts: AlternateColumnSize[],
  totalColumns: number,
) {
  let totalAltSize = 0;
  let remainingColumns =
    totalColumns -
    columnAlts.reduce((acc, alt) => acc + alt.numberOfColumns, 0);

  columnAlts.forEach((alt) => {
    totalAltSize += alt.size * alt.numberOfColumns;
  });

  return ((100 - totalAltSize) / remainingColumns) * DEFAULT_TABLE_RESIZE_SPEED;
}

export function getAltColumnSize(cols: AlternateColumnSize[], type: string) {
  const size = cols.find((col) => col.type === type)?.size;
  if (!size) {
    throw new Error(`Column size not found for type: ${type}`);
  }

  return size * DEFAULT_TABLE_RESIZE_SPEED;
}
