import "./ShrinkableList.scss";
import { useState } from "react";

interface ShrinkableListProps {
  components: any[];
  startHidden?: boolean;
  maxShown: number;
}

const ShrinkableList = ({
  components,
  maxShown,
  startHidden = true,
}: ShrinkableListProps) => {
  const [isHidden, setIsHidden] = useState(startHidden);
  if (isHidden && components.length > maxShown) {
    return (
      <>
        {components.slice(0, maxShown)}
        <div
          className={"shrinkable-list-clickable"}
          onClick={() => setIsHidden(false)}
        >
          See more
        </div>
      </>
    );
  }
  return (
    <>
      {components}
      {components.length > maxShown && (
        <div
          className={"shrinkable-list-clickable"}
          onClick={() => setIsHidden(true)}
        >
          Hide
        </div>
      )}
    </>
  );
};

export default ShrinkableList;
