import { IconProps } from "@Components/icons/iconTypes";

const CircularInformation = ({ color = "#FFAE00", size }: IconProps) => {
  return (
    <svg
      {...(size && {
        style: { width: size, height: size },
      })}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 13.5181H11V15.5181H9V13.5181ZM9 5.51807H11V11.5181H9V5.51807ZM10 0.518066C4.47 0.518066 0 5.01807 0 10.5181C0 13.1702 1.05357 15.7138 2.92893 17.5891C3.85752 18.5177 4.95991 19.2543 6.17317 19.7569C7.38642 20.2594 8.68678 20.5181 10 20.5181C12.6522 20.5181 15.1957 19.4645 17.0711 17.5891C18.9464 15.7138 20 13.1702 20 10.5181C20 9.20485 19.7413 7.90449 19.2388 6.69123C18.7362 5.47798 17.9997 4.37558 17.0711 3.447C16.1425 2.51841 15.0401 1.78182 13.8268 1.27927C12.6136 0.776724 11.3132 0.518066 10 0.518066ZM10 18.5181C7.87827 18.5181 5.84344 17.6752 4.34315 16.1749C2.84285 14.6746 2 12.6398 2 10.5181C2 8.39633 2.84285 6.3615 4.34315 4.86121C5.84344 3.36092 7.87827 2.51807 10 2.51807C12.1217 2.51807 14.1566 3.36092 15.6569 4.86121C17.1571 6.3615 18 8.39633 18 10.5181C18 12.6398 17.1571 14.6746 15.6569 16.1749C14.1566 17.6752 12.1217 18.5181 10 18.5181Z"
        fill={color}
      />
    </svg>
  );
};

export default CircularInformation;
