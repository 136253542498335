import { useState } from "react";

const PromptsModalLogic = (onContinue: any) => {
  const [promptsResponses, setPromptsResponses] = useState<any>();

  const onTextChange = (promptId: string, value: string) => {
    const filtered = promptsResponses?.filter(
      (pr: any) => pr.promptId !== promptId,
    );
    const promptRes = {
      promptId: promptId,
      response: value,
    };
    if (filtered) {
      if (value?.length > 0) {
        setPromptsResponses([...filtered, promptRes]);
      } else {
        setPromptsResponses(filtered);
      }
    } else {
      setPromptsResponses([promptRes]);
    }
  };

  const selectedPromptRes = (promptId: string) => {
    const userPrompt = promptsResponses?.find(
      (pr: any) => pr.promptId === promptId,
    );
    return userPrompt ? userPrompt.response : null;
  };

  const onSelectionChange = (promptId: string, selection: any) => {
    const filtered = promptsResponses?.filter(
      (pr: any) => pr.promptId !== promptId,
    );
    const promptRes = {
      promptId: promptId,
      response: selection,
    };

    if (filtered) {
      if (
        !Array.isArray(selection) ||
        (Array.isArray(selection) && selection.length > 0)
      ) {
        setPromptsResponses([...filtered, promptRes]);
      } else {
        setPromptsResponses(filtered);
      }
    } else {
      setPromptsResponses([promptRes]);
    }
  };

  const handleContinue = () => {
    const responses = promptsResponses.map((promptsResponse: any) => {
      let responseValues = "";
      if (Array.isArray(promptsResponse.response)) {
        responseValues = promptsResponse.response
          .map((response: any) => response.value)
          .join(";");
      } else {
        responseValues =
          typeof promptsResponse.response === "string"
            ? promptsResponse.response
            : promptsResponse.response.value;
      }
      return { promptId: promptsResponse.promptId, response: responseValues };
    });
    onContinue(responses);
  };

  return {
    promptsResponses,
    onSelectionChange,
    selectedPromptRes,
    onTextChange,
    setPromptsResponses,
    handleContinue,
  };
};

export default PromptsModalLogic;
