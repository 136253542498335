import { IconProps } from "../iconTypes";

const ArrowRight = ({ color = "#000" }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 8.7501V11.2501H15L8.125 18.1251L9.9 19.9001L19.8 10.0001L9.9 0.100098L8.125 1.8751L15 8.7501H0Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowRight;
