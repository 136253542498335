import { Loader } from "@Components/index";
import "./EmailConfirmation.scss";
import EmailConfirmationLogic from "./EmailConfirmationLogic";

const EmailConfirmation = () => {
  const { isProcessing, isSuccess } = EmailConfirmationLogic();
  return (
    <div className={"email-confirmation"}>
      {isProcessing ? (
        <div className={"email-confirmation-processing"}>
          <div>Validating email, please wait...</div>
          <Loader color={"#000"} />
        </div>
      ) : (
        <div>
          {isSuccess
            ? `Email validated Successfully, redirecting...`
            : `Error validating code...`}
        </div>
      )}
    </div>
  );
};

export default EmailConfirmation;
