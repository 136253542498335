import { useState } from "react";
import { config } from "@App/config/config";
import {
  deleteTenantFromStore,
  getTenantId,
  storeTenantId,
} from "@App/auth/tenant";

const DevMenuLogic = () => {
  const [tenants] = useState([
    { label: "LakewoodRanch", value: "lakewoodranch" },
    { label: "Cecilian Partners", value: "cecilianpartners" },
    { label: "Rancho Sahuarita", value: "rancho" },
  ]);
  const [selectedTenant] = useState(getTenantId() ?? "");
  const [configuration] = useState(JSON.stringify(config, null, "\t"));

  const onTenantChange = (tenant: any) => {
    if (!!tenant?.value && tenant.value !== selectedTenant) {
      storeTenantId(tenant.value);
      window.location.reload();
    }
  };

  const onTenantReset = () => {
    deleteTenantFromStore();
    window.location.reload();
  };

  return {
    tenants,
    selectedTenant,
    configuration,
    onTenantChange,
    onTenantReset,
  };
};

export default DevMenuLogic;
