import { Button, Modal } from "@Components/index";
import { UserTypeInfoProps } from "@Components/modals/user-type-info/UserTypeInfoProps";
import { useTranslation } from "react-i18next";
import "./UserTypeInfo.scss";

const UserTypeInfo = ({ isVisible, onClose }: UserTypeInfoProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      isVisible={isVisible}
      onClose={() => onClose()}
      children={
        <div className={"user-type-info-modal"}>
          <p className={"user-type-info-modal-title"}>Which one are you?</p>
          <ul>
            <li>
              <p className={"user-type-info-modal-text"}>
                Residents are classified as individuals who have a physical
                residential address within Lakewood Ranch (one of its villages).
              </p>
            </li>
            <li>
              <p className={"user-type-info-modal-text"}>
                {`${t("Community Members")} are classified as individuals who may reside
                near Lakewood Ranch but not within the boundaries of the
                community (or villages), or are visiting the area and want to
                engage with events and activities that Lakewood Ranch has to
                offer.`}
              </p>
            </li>
          </ul>

          <div className={"user-type-info-modal-buttons"}>
            <Button text={"Close"} onClick={() => onClose()} isSecondary />
          </div>
        </div>
      }
    />
  );
};
export default UserTypeInfo;
