import "./MyEvents.scss";
import Table from "@Components/table/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { getDefaultColumnSize } from "@Components/table/utils";
import { QUERY_KEY_USER_EVENT_LIST } from "@App/constants/queryKeyConstants";
import { getUserEvents } from "@Api/event";
import { UserEvent } from "@App/models/user";
import { formatDate } from "@Utils/date";
import { useNavigate } from "react-router-dom";
import ShrinkableList from "@Components/shrinkable-list/ShrinkableList";

const defaultGeneralColumnSize = getDefaultColumnSize(
  [
    {
      type: "small",
      size: 5,
      numberOfColumns: 4,
    },
  ],
  8,
);

const MyEvents = () => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<UserEvent>();
  const columns = [
    columnHelper.accessor("startDate", {
      header: () => "Event Date",
      cell: (info) => <span>{formatDate(info.getValue(), "M/dd/yy")}</span>,
      size: defaultGeneralColumnSize,
    }),
    columnHelper.accessor("title", {
      header: () => "Event",
      size: defaultGeneralColumnSize,
    }),
    columnHelper.accessor("eventType", {
      header: () => "Type",
      size: defaultGeneralColumnSize,
    }),
    columnHelper.accessor("ticketsPurchased", {
      header: () => "No. of tickets",
      size: 2,
    }),
    columnHelper.accessor("prompts", {
      header: () => "Prompts",
      cell: (prompts) => (
        <ShrinkableList
          components={prompts.getValue()?.map((p, i) => (
            <span key={i} className="display-block">
              {p}
            </span>
          ))}
          startHidden={true}
          maxShown={2}
        />
      ),
      size: defaultGeneralColumnSize,
    }),
    columnHelper.display({
      id: "seeDetails",
      header: () => "Details",
      cell: (info) => (
        <span
          className="events-table-cell-details"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/calendar/event-details/${info.row.original.eventId}`);
          }}
        >
          See Details
        </span>
      ),
      size: defaultGeneralColumnSize,
    }),
  ];

  return (
    <div id={"user-profile-my-events"}>
      <Table
        columns={columns}
        queryKey={[QUERY_KEY_USER_EVENT_LIST]}
        queryFn={getUserEvents}
        highlightOnHover
      />
    </div>
  );
};

export default MyEvents;
