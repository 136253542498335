import EventsList from "@Components/events-list/EventsList";
import { Heart } from "@Components/icons";
import upcomingOrUserEventsSliderLogic from "./UpcomingOrUserEventsSliderLogic";

type UpcomingOrUserEventsSliderProps = {
  isSmallScreen?: boolean;
  useAllUserEvents?: boolean;
  wrapperClassName: "home-upcoming-events" | "calendar-events-row";
};

// Covers home page and calendar page
// If useAllUserEvents is true, it will show all user events: attending, RSVPd, and favorites
function UpcomingOrUserEventsSlider(props: UpcomingOrUserEventsSliderProps) {
  const { isSmallScreen, useAllUserEvents, wrapperClassName } = props;

  const {
    upcomingEvents,
    attendingEvents,
    favoriteEvents,
    allUserEvents,

    isLoadingEnabledUpcomingEvents,
    isLoadingEnabledAttendingEvents,
    isLoadingEnabledFavoriteEvents,
    isLoadingAnyEnabledEvents,

    hasNextPageUpcoming,
    hasNextPageAttending,
    hasNextPageFavorite,
    hasNextPageAllUserEvents,
    useUserEvents,
    isLoggedUser,

    inViewRefUpcoming,
    inViewRefAttending,
    inViewRefFavorite,
    inViewRefAllUserEvents,
  } = upcomingOrUserEventsSliderLogic({
    useAllUserEvents,
  });

  if (isLoadingAnyEnabledEvents) {
    return null;
  }

  return (
    <>
      {!useUserEvents ? (
        <section className={wrapperClassName}>
          <EventsList
            events={upcomingEvents}
            title="Upcoming Events"
            isCard={true}
            horizontalScroll={true}
            notLoggedUser={true}
            isProcessing={isLoadingEnabledUpcomingEvents}
            hasNextPage={hasNextPageUpcoming}
            inViewRef={inViewRefUpcoming}
          />
        </section>
      ) : (
        <>
          {useAllUserEvents ? (
            <section className={wrapperClassName}>
              <EventsList
                events={allUserEvents}
                title="Upcoming Events"
                isCard={true}
                horizontalScroll={true}
                hideLoadingStatus
                hasNextPage={hasNextPageAllUserEvents}
                inViewRef={inViewRefAllUserEvents}
                notLoggedUser={!isLoggedUser}
              />
            </section>
          ) : (
            <>
              <section className={wrapperClassName}>
                <EventsList
                  events={attendingEvents}
                  title="Attending Events"
                  isCard={!isSmallScreen}
                  horizontalScroll={!isSmallScreen}
                  isProcessing={isLoadingEnabledAttendingEvents}
                  hideLoadingStatus
                  hideDateGroupTitle={isSmallScreen}
                  showDateOnly={isSmallScreen}
                  hasNextPage={hasNextPageAttending}
                  inViewRef={inViewRefAttending}
                />
              </section>
              <section className={wrapperClassName}>
                <EventsList
                  events={favoriteEvents}
                  title="Favorites"
                  titleIcon={<Heart isActive size={isSmallScreen ? 22 : 30} />}
                  isCard={true}
                  horizontalScroll={true}
                  isProcessing={isLoadingEnabledFavoriteEvents}
                  hideLoadingStatus
                  hasNextPage={hasNextPageFavorite}
                  inViewRef={inViewRefFavorite}
                />
              </section>
            </>
          )}
        </>
      )}
    </>
  );
}

export default UpcomingOrUserEventsSlider;
