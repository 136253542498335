export type TagBubbleTypeMapping = {
  id: string;
  text: string;
  backgroundColor: string;
  fontColor: string;
};

export const DefaultTag: TagBubbleTypeMapping = {
  id: "Default",
  text: "Not Specified",
  backgroundColor: "#FFE7B3",
  fontColor: "#725517",
};

export const findTagBubbleTypeById = (
  tags: TagBubbleTypeMapping[],
  id?: string,
): TagBubbleTypeMapping => {
  if (!id) return DefaultTag;
  return Object.values(tags).find((tag) => tag.id === id) || DefaultTag;
};
