import { useEffect, useState } from "react";
import { MembershipDogRowProps } from "./MembershipDogRowPropTypes";

const MembershipDogRowLogic = (props: MembershipDogRowProps) => {
  const [dogName, setDogName] = useState(props.dogName);
  const [dogBreed, setDogBreed] = useState(props.dogBreed);

  const [dogNameValid, setDogNameValid] = useState(true);
  const [dogBreedValid, setDogBreedValid] = useState(true);

  useEffect(() => {
    const validateInput = (value: string) => {
      if (value.length === 0) {
        return true;
      }
      return !!value && value.length > 1;
    };
    setDogNameValid(validateInput(dogName));
    setDogBreedValid(validateInput(dogBreed));
  }, [dogName, dogBreed]);

  const onDogDetailsChange = ({
    value,
    isDogName,
  }: {
    value: string;
    isDogName?: boolean;
  }) => {
    isDogName ? setDogName(value) : setDogBreed(value);
    props.onChange({
      id: props.id,
      index: props.index,
      dogName: isDogName ? value : props.dogName,
      dogBreed: !isDogName ? value : props.dogBreed,
    });
  };

  return {
    dogName,
    dogNameValid,
    dogBreed,
    dogBreedValid,
    setDogName,
    setDogBreed,
    onDogDetailsChange,
  };
};

export default MembershipDogRowLogic;
