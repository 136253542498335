import { SvgsProps } from "../iconTypes";

const ChevronUp = ({ color = "#C4C4C4", size = "small" }: SvgsProps) => {
  const isSmall = size === "small";
  return (
    <svg
      width={isSmall ? "12" : "21"}
      height={isSmall ? "8" : "13"}
      viewBox={`0 0 ${isSmall ? "12 8" : "21 13"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          isSmall
            ? "M10.1611 7.64465L5.99464 2.9309L1.82819 7.64465C1.4094 8.11845 0.732887 8.11845 0.314095 7.64465C-0.104698 7.17084 -0.104698 6.40547 0.314095 5.93166L5.24296 0.355353C5.66175 -0.118451 6.33826 -0.118451 6.75705 0.355353L11.6859 5.93166C12.1047 6.40547 12.1047 7.17084 11.6859 7.64465C11.2671 8.1063 10.5799 8.11845 10.1611 7.64465Z"
            : "M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z"
        }
        fill={color}
      />
    </svg>
  );
};

export default ChevronUp;
