import {
  CompleteUserAccountDetailsRequest,
  RegisterUserRequest,
  SetUserPasswordRequest,
  UpdatedDataRequest,
  UserLoginValidationRequest,
} from "./requests/user";
import { baseServiceRequest } from "./baseService";

export const registerUser = async (data: RegisterUserRequest) => {
  return await baseServiceRequest({
    path: `/user`,
    type: "post",
    postData: data,
  });
};

export const completeUserAccountDetails = async (
  data: CompleteUserAccountDetailsRequest,
) => {
  return await baseServiceRequest({
    path: `/user/${data.id}`,
    type: "put",
    postData: data,
    useAuthHeader: true,
  });
};

export const getUser = async (cognitoUsername: string) => {
  return await baseServiceRequest({
    path: `/user/Cognito/${cognitoUsername}`,
    type: "get",
  });
};

export const verifyEmail = async (userId: string) => {
  return await baseServiceRequest({
    path: `/user/verificationEmail`,
    type: "post",
    postData: { id: userId },
    useAuthHeader: true,
  });
};

export const codeVerification = async (code: string) => {
  return await baseServiceRequest({
    path: `/user/CodeConfirmationByUser`,
    type: "post",
    postData: { UserVerificationCode: code },
    useAuthHeader: true,
  });
};

export const userLoginValidation = async (data: UserLoginValidationRequest) => {
  return await baseServiceRequest({
    path: `/user/Validation/${data.userVerificationCode}`,
    type: "post",
    postData: data,
  });
};

export const userPasswordSet = async (data: SetUserPasswordRequest) => {
  return await baseServiceRequest({
    path: `/user/password`,
    type: "post",
    postData: data,
  });
};

export const getUserAccountInfo = async (userId: string) => {
  return await baseServiceRequest({
    path: `/user/${userId}/AccountInfo`,
    type: "get",
    useAuthHeader: true,
  });
};

export const updateUserAccountInfo = async (
  userId: string,
  updatedData: UpdatedDataRequest,
) => {
  return await baseServiceRequest({
    path: `/user/${userId}`,
    type: "put",
    postData: { id: userId, ...updatedData },
    useAuthHeader: true,
  });
};

export const getVerifiedSignInEmail = async (email: string) => {
  return await baseServiceRequest({
    path: `/user/verifiedEmail/${email}`,
    type: "get",
    useAuthHeader: true,
  });
};

export const registerForUpdates = async (bodyData: {
  firstName: string;
  lastName: string;
  email: string;
}) => {
  return await baseServiceRequest({
    path: `/user/email-signup`,
    type: "post",
    postData: bodyData,
  });
};
