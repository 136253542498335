import { IconProps } from "@Components/icons/iconTypes";

const Ticket = ({ color = "#5F666D" }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none">
      <path
        d="M4 15.5L2 13.5L13.5 2L15.5 4C15 4.33333 14.3 5.3 15.5 6.5C16.7 7.7 17.6667 7.33333 18 7L19.5 8.5L8 20L6 18C6.5 17.5 7.3 16.3 6.5 15.5C5.7 14.7 4.5 15.1667 4 15.5Z"
        stroke={color}
        strokeWidth="1.7"
      />
      <line
        x1="10.3536"
        y1="5.64645"
        x2="16.3536"
        y2="11.6464"
        stroke={color}
        strokeDasharray="1 1"
      />
    </svg>
  );
};

export default Ticket;
