import "./PoiItem.scss";
import { PoiItemProps } from "./PoiItemPropTypes";
import { useEffect, useState } from "react";

const PoiItem = (componentProps: PoiItemProps) => {
  const { title, poiCategory, venue, address } = componentProps.poi;
  const { address1, city, state, zip } = address;
  const [isSelected, setIsSelected] = useState(componentProps.isSelected);

  useEffect(() => {
    setIsSelected(componentProps.isSelected);
  }, [componentProps.isSelected]);
  return (
    <div
      className={`poi-item ${isSelected ? "poi-item-selected" : ""}`}
      onClick={() => {
        componentProps.onPoiClick &&
          componentProps.onPoiClick(componentProps.poi);
      }}>
      <div className={"poi-item-type"}>{poiCategory}</div>
      <div className={"poi-item-title"}>{title}</div>

      {venue && <div>{`Venue: ${venue}`}</div>}
      <div
        className={
          "poi-item-location"
        }>{`Location: ${address1} ${city}, ${state} ${zip}`}</div>
    </div>
  );
};

export default PoiItem;
