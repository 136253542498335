import type { LayerProps } from "react-map-gl";

export const POI_SOURCE_NAME = "pois_source";
export const POI_DEFAULT_RGB_COLOR_VALUE = "75, 88, 115";

//Cluster Layer
export const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  source: POI_SOURCE_NAME,
  filter: ["has", "point_count"],
  paint: {
    "circle-color": `rgb(${POI_DEFAULT_RGB_COLOR_VALUE})`,
    "circle-radius": 15,
    "circle-opacity": 0.7,
    "circle-stroke-width": 2,
    "circle-stroke-color": "#fff",
  },
};

//Cluster counter layer
export const clusterCountLayer: LayerProps = {
  id: "cluster-count",
  type: "symbol",
  source: POI_SOURCE_NAME,
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
  paint: {
    "text-color": "#fff",
  },
};

//POI layer with cluster
export const unclusteredPointLayer: LayerProps = {
  id: "unclustered-point",
  type: "symbol",
  source: POI_SOURCE_NAME,
  filter: ["all", ["!", ["has", "point_count"]], ["get", "poi_use_icon"]],
  layout: {
    "icon-image": "poi-favorite",
    "icon-allow-overlap": true,
  },
  paint: {
    "text-color": "#000",
    "icon-opacity": ["get", "poi_opacity"],
  },
};

//POI layer without cluster
export const unclusteredPointSelectedLayer: LayerProps = {
  id: "unclustered-point-selected",
  type: "symbol",
  source: "poi_selected",
  filter: ["get", "poi_use_icon"],
  layout: {
    "icon-image": "poi-favorite",
    "icon-allow-overlap": true,
  },
  paint: {
    "text-color": "#000",
    "icon-opacity": ["get", "poi_opacity"],
  },
};

// POI layer with cluster source
export const unclusteredCirclePointLayer: LayerProps = {
  id: "unclustered-circle-point",
  type: "circle",
  source: POI_SOURCE_NAME,
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": [
      "case",
      ["get", "poi_use_icon"],
      "transparent",
      `rgb(${POI_DEFAULT_RGB_COLOR_VALUE})`,
    ],
    "circle-radius": 8,
    "circle-opacity": ["get", "poi_opacity"],
    "circle-stroke-width": 1,
    "circle-stroke-color": [
      "case",
      ["get", "poi_use_icon"],
      "transparent",
      "#fff",
    ],
  },
};

//POI layer without cluster source
export const unclusteredCirclePointSelectedLayer: LayerProps = {
  id: "unclustered-circle-point-selected",
  type: "circle",
  source: "poi_selected",
  filter: ["!", ["get", "poi_use_icon"]],
  paint: {
    "circle-color": `rgb(${POI_DEFAULT_RGB_COLOR_VALUE})`,
    "circle-radius": 8,
    "circle-opacity": ["get", "poi_opacity"],
    "circle-stroke-width": 1,
    "circle-stroke-color": "#fff",
  },
};

//Pulsing dot animation layer
export const pulsingDotsLayer: LayerProps = {
  id: "layer-with-pulsing-dot",
  type: "symbol",
  source: POI_SOURCE_NAME,
  filter: [
    "all",
    ["!", ["get", "poi_use_icon"]],
    ["any", ["==", ["get", "poi_opacity"], 0.8], ["has", "point_count"]],
  ],
  layout: {
    "icon-image": "pulsing-dot",
    "icon-allow-overlap": true,
  },
};

//User location layer
export const userLocationLayer: LayerProps = {
  id: "user-location",
  type: "circle",
  paint: {
    "circle-color": `#005776`,
    "circle-radius": 8,
    "circle-stroke-width": 5,
    "circle-stroke-color": "#3DA9FF",
    "circle-stroke-opacity": 0.5,
  },
};

//Route layer
export const routeLayer: LayerProps = {
  id: "bestRoute",
  source: "route",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-width": 5,
    "line-color": "#3DA9FF",
  },
};
