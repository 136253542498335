import { useState } from "react";
import {
  deleteNotificationForUserAsync,
  updateIsReadAsync,
  updateIsStarredAsync,
} from "@App/api/notifications";
import { NotificationItemProps } from "./NotificationItemProps";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_NOTIFICATIONS } from "@App/constants/queryKeyConstants";

const useNotificationItemLogic = (props: NotificationItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [canExpand] = useState(props.canExpand);
  const [isMenuItem] = useState(props.isMenuItem);
  const [maxWidth] = useState(props.maxWidth);
  const { notification } = props;

  // If we manage our mutation with react-query, we have easy access to an onSuccess handler to change local state to keep things feeling snappy
  const isStarredMutation = useMutation({
    mutationFn: ({ id, isStarred }: { id: string; isStarred: boolean }) =>
      updateIsStarredAsync(id, isStarred),
    onSuccess: renderStarChange,
  });

  const queryClient = useQueryClient();

  const handleIsReadClickAsync = async () => {
    // Mark notification as read.  If we want to flip to undread, we shoud have a more explicit way on the UI to do that vs just clicking.
    // Also check if notification is already read, if so, don't call API if user is just clicking.
    if (!notification?.userDetails?.isRead) {
      await updateIsReadAsync(notification.id, true);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NOTIFICATIONS] });
    }
  };

  function renderStarChange() {
    // TODO: is there a cleaner way to do this?  I don't like having to check if userDetails is null or not.
    if (!notification.userDetails) {
      notification.userDetails = {};
    }

    notification.userDetails.isStarred = !notification?.userDetails?.isStarred;
  }

  const handleIsStarredClickAsync = async () => {
    await isStarredMutation.mutateAsync({
      id: notification.id,
      isStarred: !notification.userDetails?.isStarred,
    });
    // Invalidate the query keys so the rest of the UI updates
    // Note we're using partial matching here to invalidate the filters as well
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NOTIFICATIONS] });
  };

  const handleDeleteAsync = async () => {
    await deleteNotificationForUserAsync(notification.id);

    // Invalidate the query so it will re-fetch the data from the server.
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NOTIFICATIONS] });
  };

  return {
    notification,
    canExpand,
    isMenuItem,
    maxWidth,
    isExpanded,
    setIsExpanded,
    handleIsReadClickAsync,
    handleIsStarredClickAsync,
    handleDeleteAsync,
  };
};

export default useNotificationItemLogic;
