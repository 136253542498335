import "./TenantAdminEmail.scss";
import { useTenantFooter } from "@App/hooks";

const TenantAdminEmail = () => {
  const { tenantFooter } = useTenantFooter();
  if (!tenantFooter?.adminEmail) return null;
  return (
    <div className={"tenant-admin-email"}>
      <a href={`mailto:${tenantFooter.adminEmail}`}>
        {tenantFooter.adminEmail}
      </a>
    </div>
  );
};

export default TenantAdminEmail;
