import { GenericDetails, Loader } from "@Components/index";
import { Navbar } from "@Layouts/index";
import "./AmenitiesDetail.scss";
import AmenitiesDetailLogic from "./AmenitiesDetailLogic";

const AmenitiesDetail = () => {
  const {
    amentity,
    isLoading,
    isFavLoading,
    updateFavorite,
    location,
    handleBooking,
    handleNotLoggedUserBook,
  } = AmenitiesDetailLogic();

  return (
    <Navbar>
      {isLoading ? (
        <Loader
          text={"Loading, please wait"}
          color={"#000"}
          fontColor={"#000"}
          horizontallyCentered
          padding={"100px 20px"}
        />
      ) : (
        <>
          {!amentity ? (
            <div>Amenity not found</div>
          ) : (
            <GenericDetails
              details={{
                id: amentity.id,
                title: amentity.title,
                description: amentity.description,
                location: location,
                tags: ["Amenities"],
                files: amentity.files,
                user: { isFavorite: amentity.user?.isFavorite },
                rulesandRegulation: amentity.financeRules?.rulesandRegulation,
              }}
              {...(amentity.canBeBooked && {
                primaryButton: {
                  loggedText: "Book Now",
                  notLoggedText: "Login to Book",
                  loggedAction: () => handleBooking(amentity.id),
                  notLoggedAction: () => handleNotLoggedUserBook(),
                },
              })}
              onFavClick={updateFavorite}
              isFavProcessing={isFavLoading}
              callsToAction={amentity.callsToAction}
            />
          )}
        </>
      )}
    </Navbar>
  );
};

export default AmenitiesDetail;
