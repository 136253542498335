import { baseServiceRequest } from "./baseService";
import { AxiosResponse } from "axios";
import { Prompt } from "@App/models/prompt";

export const getPromptById = async (promptId: string) => {
  return (await baseServiceRequest({
    path: `prompts/${promptId}`,
    type: "get",
  })) as AxiosResponse<Prompt, any>;
};
