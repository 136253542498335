import "./WaiversModal.scss";
import WaiversModalLogic from "./WaiversModalLogic";
import { WaiversModalProps } from "./WaiversModalPropTypes";
import { Button, Checkbox, Modal } from "@Components/index";

const WaiversModal = ({
  waivers,
  isVisible,
  isWaiverProcessing,
  userInfo,
  onContinue,
  ctaText,
}: WaiversModalProps) => {
  const props = WaiversModalLogic();
  return (
    <Modal isVisible={!!isVisible}>
      <div className={"waivers-modal"} data-cy="waivers-modal">
        {waivers.map((waiverItem, index) => {
          return (
            <div key={`waiver_item${index}`}>
              <div className={"waivers-modal__title"}>{waiverItem.title}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: waiverItem.textContent,
                }}></div>
            </div>
          );
        })}
        <div>
          <Checkbox
            text={`I agree on behalf of ${userInfo.firstname} ${userInfo.lastname}`}
            onChange={props.onCheckboxChange}
            dataCy="waivers-modal-checkbox"
          />
        </div>
        <div className={"waivers-modal__cta"}>
          <Button
            text={ctaText ?? "Proceed"}
            onClick={onContinue}
            isDisabled={!props.termsAccepted}
            isLoading={isWaiverProcessing}
            dataCy="waivers-modal-btn"
          />
        </div>
      </div>
    </Modal>
  );
};

export default WaiversModal;
