import { Button } from "@Components/index";
import { useNavigate } from "react-router-dom";
import "./Error.scss";
import { goBackToHome } from "@Utils/utils";

const Error = () => {
  const navigate = useNavigate();
  return (
    <div className="error-page">
      <h1>The page does not exist.</h1>
      <Button onClick={() => goBackToHome(navigate)} text="Go Back to Home" />
    </div>
  );
};

export default Error;
