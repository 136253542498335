import {
  getAmenitiesDetails,
  updateAmenityPreference,
} from "@App/api/amenities";
import { SHOW_LOGIN_MODAL } from "@App/constants/appConstants";
import { QUERY_KEY_USER_AMENITIES } from "@App/constants/queryKeyConstants";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@Store/store";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  customEventPublish,
  sendMobileViewModePostMessage,
} from "@Utils/utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const AmenitiesDetailLogic = () => {
  const { amenityId } = useParams<{ amenityId: string }>();
  const storeData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = storeData;

  const navigate = useNavigate();

  const { data: amentity, isLoading } = useQuery({
    queryKey: [QUERY_KEY_USER_AMENITIES, amenityId, userInfo?.id],
    queryFn: () => getAmenitiesDetails(amenityId!),
    enabled: !!amenityId,
    select: (data) => data.data,
  });

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: isLoading });
  }, [isLoading]);

  const { mutate: updateFavorite, isLoading: isFavLoading } = useMutation({
    mutationFn: () =>
      updateAmenityPreference({
        amenityId: amenityId!,
        userId: userInfo?.id!,
        preference: { isFavorite: !amentity?.user?.isFavorite },
      }),
  });

  const handleBooking = (communityAssetId: string) => {
    navigate(`/venues/venues-details/${communityAssetId}`);
  };

  const handleNotLoggedUserBook = () => {
    customEventPublish(SHOW_LOGIN_MODAL, {});
  };

  const location = amentity?.address?.address1
    ? `${amentity?.address?.address1} ${amentity?.address?.city}, ${amentity?.address?.state} ${amentity?.address?.zip}`
    : "";

  return {
    amentity,
    isLoading,
    isFavLoading,
    location,
    updateFavorite,
    handleBooking,
    handleNotLoggedUserBook,
  };
};

export default AmenitiesDetailLogic;
