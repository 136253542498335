import { SvgsProps } from "../iconTypes";

const ChevronLeft = ({ color = "#212B36", opacity = 1, size = "small" }: SvgsProps) => {
  const isSmall = size === "small";
  return (
    <svg
      width={isSmall ? "7" : "19"}
      height={isSmall ? "13" : "29"}
      viewBox={`0 0 ${isSmall ? "7 13" : "19 29"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          isSmall
            ? "M5.48884 12.3333C5.23668 12.3342 4.9977 12.2208 4.83884 12.025L0.813841 7.02501C0.561077 6.71751 0.561077 6.27418 0.813841 5.96668L4.98051 0.966681C5.27506 0.612299 5.80112 0.563796 6.15551 0.858348C6.50989 1.1529 6.55839 1.67897 6.26384 2.03335L2.53884 6.50001L6.13884 10.9667C6.34692 11.2164 6.39076 11.5644 6.25113 11.858C6.1115 12.1516 5.8139 12.3371 5.48884 12.3333Z"
            : "M15.2006 0.164551L18.0049 2.96886L6.51789 14.4558L18.0049 25.9428L15.2006 28.7471L0.909275 14.4558L15.2006 0.164551Z"
        }
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default ChevronLeft;
