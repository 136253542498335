import { AmenityType, Space } from "@App/models/amenity";
import { UserType } from "@App/models/types";
import { PaymentsProps } from "@Components/payments/PaymentsPropTypes";

type BuildPaymentDataParams = {
  venueId: string;
  space?: Space | null;
  communityAsset?: AmenityType;
  bookingData: {
    rateId: string;
    rateName: string;
    selectedDate: string;
    startTime: string;
    endTime: string;
    cost: string;
  };
};

function isCommunityAsset(
  resource?: Space | AmenityType,
): resource is AmenityType {
  if (!resource) return false;

  return (resource as AmenityType).title !== undefined;
}

export const buildPaymentData = (params: BuildPaymentDataParams) => {
  const { venueId, space, communityAsset, bookingData } = params;

  const venue = space ?? communityAsset;
  if (!venue) {
    return;
  }

  const builtPaymentData: PaymentsProps = {
    items: null,
    initialStep: 0,
    paymentData: {
      id: venueId!,
      spaceId: isCommunityAsset(venue) ? undefined : venue.id,
      title: isCommunityAsset(venue) ? venue.title : venue.name,
      files: venue.files,
      type: "booking",
      isAdminApprovalRequired: venue.financeRules?.isAdminApprovalRequired,
      addOnIds: venue.financeRules?.addOns,
      promptIds: venue.financeRules?.prompts,
      waiverIds: venue.waiverIds,
    },
    bookingDate: bookingData,
  };

  return builtPaymentData;
};

export const findIsCTAButtonDisabled = (
  isUserLogged: boolean,
  userType: UserType,
  selectedRate: any,
  selectedStartTime: any,
  selectedEndTime: any,
) => {
  // Leave button enabled if not logged in or initated so the button opens a modal to change the user state
  // Early return to avoid invalid user states needing to fill out the form to open the modal
  if (!isUserLogged) {
    return false;
  }

  if (userType === UserType.Initiated) {
    return false;
  }

  if (
    !selectedRate ||
    !selectedStartTime ||
    !selectedEndTime ||
    userType === UserType.ResidentPending
  ) {
    return true;
  }

  return false;
};
