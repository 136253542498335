import "./DateCalendarSelect.scss";

import DatePicker from "react-datepicker";
import React from "react";
import DateCalendarSelectLogic from "./DateCalendarSelectLogic";
import { DateCalendarSelectProps } from "./DateCalendarSelectProps";

const DateCalendarSelect = ({
  label,
  initialDate,
  placeholder,
  required,
  useIcon,
  popperPlacementTop,
  onChange,
  inline = false,
  showTimeSelectOnly = false,
  maxDate,
}: DateCalendarSelectProps) => {
  const { startDate, setStartDate } = DateCalendarSelectLogic(initialDate);
  return (
    <React.Fragment>
      {!!label && (
        <label className={"date-calendar-select-label"}>{`${label}${
          required ? "*" : ""
        }`}</label>
      )}
      <div className={"date-calendar-select"}>
        <DatePicker
          className="date-calendar-select__input"
          showPopperArrow={false}
          inline={inline}
          selected={startDate}
          onChange={(date) => {
            onChange && onChange(date);
            if (date) {
              setStartDate(date);
            }
          }}
          placeholderText={placeholder}
          showYearDropdown
          dateFormatCalendar="MMMM"
          dropdownMode="select"
          {...(true && {
            popperPlacement: "top-end",
          })}
          {...(showTimeSelectOnly && {
            showTimeSelect: true,
            showTimeSelectOnly,
            timeIntervals: 15,
            timeCaption: "Time",
            dateFormat: "h:mm aa",
          })}
          {...(maxDate && {
            maxDate,
          })}
        />
        {useIcon && (
          <div className="date-calendar-select__icon">
            {!showTimeSelectOnly ? (
              <svg
                width="18"
                height="21"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 2.92285H15V0.922852H13V2.92285H5V0.922852H3V2.92285H2C0.89 2.92285 0.00999999 3.82285 0.00999999 4.92285L0 18.9229C0 20.0229 0.89 20.9229 2 20.9229H16C17.1 20.9229 18 20.0229 18 18.9229V4.92285C18 3.82285 17.1 2.92285 16 2.92285ZM16 18.9229H2V8.92285H16V18.9229ZM16 6.92285H2V4.92285H16V6.92285ZM6 12.9229H4V10.9229H6V12.9229ZM10 12.9229H8V10.9229H10V12.9229ZM14 12.9229H12V10.9229H14V12.9229ZM6 16.9229H4V14.9229H6V16.9229ZM10 16.9229H8V14.9229H10V16.9229ZM14 16.9229H12V14.9229H14V16.9229Z"
                  fill="#868E96"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 18.9229C14.4 18.9229 18 15.3229 18 10.9229C18 6.52285 14.4 2.92285 10 2.92285C5.6 2.92285 2 6.52285 2 10.9229C2 15.3229 5.6 18.9229 10 18.9229ZM10 0.922852C15.5 0.922852 20 5.42285 20 10.9229C20 16.4229 15.5 20.9229 10 20.9229C4.5 20.9229 0 16.4229 0 10.9229C0 5.42285 4.5 0.922852 10 0.922852ZM13.3 15.1229L12 15.9229L9 10.7229V5.92285H10.5V10.3229L13.3 15.1229Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DateCalendarSelect;
