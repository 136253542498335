import "./TenantFooterLinks.scss";
import { HoverListMenu } from "@Components/index";
import { toKebabCase } from "@Utils/utils";
import { useTenantFooter } from "@App/hooks";

const TenantFooterLinks = () => {
  const { tenantFooter } = useTenantFooter();
  if (!tenantFooter?.links) return null;
  return (
    <div
      className={`tenant-footer-links ${
        tenantFooter.links.length > 4 && "tenant-footer-links-split"
      }`}>
      {tenantFooter.links.map((item, index) => {
        return (
          <HoverListMenu
            key={`footer-links-${index}`}
            text={item.text}
            classHooks={toKebabCase(item.text)}
            className={"tenant-footer-links-menu-item"}
            route={item.route}
            isRouteExternal={item.isRouteExternal}
            isLoginRequired={item.isLoginRequired}
            subNavItems={item.subNavItems}
          />
        );
      })}
    </div>
  );
};

export default TenantFooterLinks;
