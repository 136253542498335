import { useState } from "react";

const DateCalendarSelectLogic = (initialDate?: Date) => {
  const [startDate, setStartDate] = useState(initialDate);
  return {
    startDate,
    setStartDate,
  };
};

export default DateCalendarSelectLogic;
