import { getEventAvailableTickets } from "@App/api/event";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_EVENT_TICKETS_DATA } from "@App/constants/queryKeyConstants";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { sendMobileViewModePostMessage } from "@Utils/utils";
import useEventDetails from "@App/hooks/api/events/useEventDetails";

const EventTicketPurchaseLogic = () => {
  const [params] = useSearchParams();
  const eventId = params.get("id");
  const { event: currentEvent, isLoadingEvent } = useEventDetails({ eventId });

  const { data: availableTickets, isLoading: isLoadingTicketsData } = useQuery(
    [QUERY_KEY_EVENT_TICKETS_DATA, eventId],
    () =>
      getEventAvailableTickets(eventId!).then(
        (res) => res.data?.availableTickets
      ),
    {
      enabled: !!eventId,
    }
  );

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: isLoadingEvent });
  }, [isLoadingEvent]);

  return {
    currentEvent,
    availableTickets,
    isLoadingTicketsData,
    isLoadingEvent,
  };
};

export default EventTicketPurchaseLogic;
