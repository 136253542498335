import { useLocation } from "react-router";

const BreadcrumbLogic = (ignorePaths?: boolean, ignoreArray?: string[]) => {
  const location = useLocation();
  let pathSections = ignorePaths ? [""] : location.pathname.split("/");

  if (ignoreArray) {
    ignoreArray.forEach((ignore) => {
      pathSections = pathSections.filter((section) => section !== ignore);
    });
  }

  const pathSectionsTotal = pathSections.length - 1;
  const searchParams = location.search;
  return { pathSections, pathSectionsTotal, searchParams };
};

export default BreadcrumbLogic;
