import { AddOn } from "@App/models/addOn";
import { baseServiceRequest } from "./baseService";
import { AxiosResponse } from "axios";

export const getAddOnById = async (addonId: string) => {
  // TODO: We need the app version of this service, or if we this supports multiple Ids, that's even better so that we don't need to loop and get one by one.
  return (await baseServiceRequest({
    path: `hub/addons/${addonId}`,
    type: "get",
  })) as AxiosResponse<AddOn, any>;
};
