import { IconProps } from "../iconTypes";
import themedStyles from "@Assets/styles/themedStyles.scss";

const CalendarAlt = ({ color = themedStyles.cmsMainIconsColor }: IconProps) => {
  return (
    <svg
      width="75"
      height="56"
      viewBox="0 0 75 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_5959_913" fill="white">
        <rect
          width="5.80392"
          height="6.76873"
          rx="1.23998"
          transform="matrix(-1 0 0 1 11.3821 25.1289)"
        />
      </mask>
      <rect
        width="5.80392"
        height="6.76873"
        rx="1.23998"
        transform="matrix(-1 0 0 1 11.3821 25.1289)"
        stroke={color}
        strokeWidth="3.21918"
        mask="url(#path-1-inside-1_5959_913)"
      />
      <mask id="path-2-inside-2_5959_913" fill="white">
        <rect
          width="5.80392"
          height="6.76873"
          rx="1.23998"
          transform="matrix(-1 0 0 1 11.3821 34.6052)"
        />
      </mask>
      <rect
        width="5.80392"
        height="6.76873"
        rx="1.23998"
        transform="matrix(-1 0 0 1 11.3821 34.6052)"
        stroke={color}
        strokeWidth="3.21918"
        mask="url(#path-2-inside-2_5959_913)"
      />
      <mask id="path-3-inside-3_5959_913" fill="white">
        <rect
          width="5.80392"
          height="6.76873"
          rx="1.23998"
          transform="matrix(-1 0 0 1 11.3821 44.0814)"
        />
      </mask>
      <rect
        width="5.80392"
        height="6.76873"
        rx="1.23998"
        transform="matrix(-1 0 0 1 11.3821 44.0814)"
        stroke={color}
        strokeWidth="3.21918"
        mask="url(#path-3-inside-3_5959_913)"
      />
      <mask id="path-4-inside-4_5959_913" fill="white">
        <rect
          width="5.80392"
          height="6.76873"
          rx="1.23998"
          transform="matrix(-1 0 0 1 21.5388 34.6052)"
        />
      </mask>
      <rect
        width="5.80392"
        height="6.76873"
        rx="1.23998"
        transform="matrix(-1 0 0 1 21.5388 34.6052)"
        stroke={color}
        strokeWidth="3.21918"
        mask="url(#path-4-inside-4_5959_913)"
      />
      <mask id="path-5-inside-5_5959_913" fill="white">
        <rect
          width="5.80392"
          height="6.76873"
          rx="1.23998"
          transform="matrix(-1 0 0 1 21.5388 25.1289)"
        />
      </mask>
      <rect
        width="5.80392"
        height="6.76873"
        rx="1.23998"
        transform="matrix(-1 0 0 1 21.5388 25.1289)"
        stroke={color}
        strokeWidth="3.21918"
        mask="url(#path-5-inside-5_5959_913)"
      />
      <mask id="path-6-inside-6_5959_913" fill="white">
        <rect
          width="5.80392"
          height="6.76873"
          rx="1.23998"
          transform="matrix(-1 0 0 1 31.6956 34.6052)"
        />
      </mask>
      <rect
        width="5.80392"
        height="6.76873"
        rx="1.23998"
        transform="matrix(-1 0 0 1 31.6956 34.6052)"
        stroke={color}
        strokeWidth="3.21918"
        mask="url(#path-6-inside-6_5959_913)"
      />
      <mask id="path-7-inside-7_5959_913" fill="white">
        <rect
          width="5.80392"
          height="6.76873"
          rx="1.23998"
          transform="matrix(-1 0 0 1 21.5388 44.0814)"
        />
      </mask>
      <rect
        width="5.80392"
        height="6.76873"
        rx="1.23998"
        transform="matrix(-1 0 0 1 21.5388 44.0814)"
        stroke={color}
        strokeWidth="3.21918"
        mask="url(#path-7-inside-7_5959_913)"
      />
      <mask id="path-8-inside-8_5959_913" fill="white">
        <rect
          width="5.80392"
          height="6.76873"
          rx="1.23998"
          transform="matrix(-1 0 0 1 31.6956 25.1289)"
        />
      </mask>
      <rect
        width="5.80392"
        height="6.76873"
        rx="1.23998"
        transform="matrix(-1 0 0 1 31.6956 25.1289)"
        stroke={color}
        strokeWidth="3.21918"
        mask="url(#path-8-inside-8_5959_913)"
      />
      <mask id="path-9-inside-9_5959_913" fill="white">
        <rect
          width="5.80392"
          height="6.76873"
          rx="1.23998"
          transform="matrix(-1 0 0 1 31.6956 44.0814)"
        />
      </mask>
      <rect
        width="5.80392"
        height="6.76873"
        rx="1.23998"
        transform="matrix(-1 0 0 1 31.6956 44.0814)"
        stroke={color}
        strokeWidth="3.21918"
        mask="url(#path-9-inside-9_5959_913)"
      />
      <line
        x1="1.52783"
        y1="21.2501"
        x2="35.4447"
        y2="21.2501"
        stroke={color}
        strokeWidth="1.60959"
      />
      <path
        d="M73.57 17.8356C73.57 27.1126 65.4893 34.7412 55.3833 34.7412C45.2772 34.7412 37.1966 27.1126 37.1966 17.8356C37.1966 8.55859 45.2772 0.929985 55.3833 0.929985C65.4893 0.929985 73.57 8.55859 73.57 17.8356Z"
        stroke={color}
        strokeWidth="1.85997"
      />
      <path
        d="M68.6364 17.8353C68.6364 24.5703 62.7645 30.1382 55.383 30.1382C48.0016 30.1382 42.1297 24.5703 42.1297 17.8353C42.1297 11.1003 48.0016 5.5324 55.383 5.5324C62.7645 5.5324 68.6364 11.1003 68.6364 17.8353Z"
        stroke={color}
        strokeWidth="1.85997"
      />
      <line
        x1="55.6971"
        y1="11.5068"
        x2="55.6971"
        y2="18.4109"
        stroke={color}
        strokeWidth="1.85997"
      />
      <line
        y1="-0.929985"
        x2="10.0981"
        y2="-0.929985"
        transform="matrix(0.834613 0.550837 -0.604177 0.796851 54.7668 18.4103)"
        stroke={color}
        strokeWidth="1.85997"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9942 13.4247H4.21994C2.16547 13.4247 0.5 15.0902 0.5 17.1446V52.28C0.5 54.3345 2.16548 56 4.21994 56H58.4467C60.5012 56 62.1667 54.3345 62.1667 52.28V34.9952C61.6029 35.1948 61.0265 35.3703 60.4387 35.5203V52.28C60.4387 53.3802 59.5468 54.272 58.4467 54.272H4.21994C3.11982 54.272 2.22799 53.3801 2.22799 52.28V17.1446C2.22799 16.0445 3.11981 15.1527 4.21994 15.1527H36.5662C36.6787 14.5662 36.8219 13.9897 36.9942 13.4247Z"
        fill={color}
      />
    </svg>
  );
};

export default CalendarAlt;
