import { PresignedUrl } from "@App/models/PresignedUrl";
import axios from "axios";
import { baseServiceRequest } from "./baseService";
import { S3UploadRequest } from "./requests/S3UploadRequest";

export const presignedUrlGet = async (fileName: string, mimeType: string) => {
  return await baseServiceRequest<PresignedUrl>({
    path: `/hub/file/presignedUrl?fileName=${fileName}&mimeType=${mimeType}&isCms=true`,
    type: "get",
  });
};

export const uploadGenericFile = async (
  uploadFileRequest: S3UploadRequest,
  mimeType: string,
) => {
  return await axios.put(
    `${uploadFileRequest.presignedUrl}`,
    uploadFileRequest.file,
    {
      headers: {
        "Content-Type": mimeType,
        "enclosure-type": "multipart/form-data",
      },
    },
  );
};
