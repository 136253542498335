import { Waiver } from "@App/models/waiver";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { Button, Checkbox } from "@Components/index";
import { MembershipDogTermsDummy } from "@Utils/dummy";
import { useState } from "react";
import { useSelector } from "react-redux";
import "./MembershipDogTerms.scss";

type MembershipDogTermsProps = {
  waiver: Waiver;
  proceedToPayment: () => void;
};

const MembershipDogTerms = ({
  waiver,
  proceedToPayment,
}: MembershipDogTermsProps) => {
  // Could just use a ref
  const [termsAccepted, setTermsAccepted] = useState(false);

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = userData;

  return (
    <div className={"membership-dog-terms"}>
      <div className={"membership-dog-terms-title"}>
        {MembershipDogTermsDummy.title}
      </div>
      <div
        className={"membership-dog-terms-description"}
        dangerouslySetInnerHTML={{
          __html: waiver.textContent,
        }}
      />
      <div className={"membership-dog-terms-checkbox"}>
        <Checkbox
          text={`I agree on behalf of ${userInfo?.firstName}`}
          onChange={() => setTermsAccepted(!termsAccepted)}
        />
      </div>
      <div className={"membership-dog-terms-proceed"}>
        <Button
          isDisabled={!termsAccepted}
          text={"Proceed to Payment"}
          onClick={proceedToPayment}
        />
      </div>
    </div>
  );
};

export default MembershipDogTerms;
