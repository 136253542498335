import { Navbar } from "@Layouts/index";
import "./ResidentCenter.scss";
import { SearchInput, Loader, SideScroll } from "@Components/index";
import ResidentCenterLogic from "./ResidentCenterLogic";
import * as Accordion from "@radix-ui/react-accordion";
import { Chevron, House } from "@Components/icons";
import { formatPhoneNumber } from "@Utils/utils";
import { ResidentCenterServicesAndDocDummy } from "@Utils/dummy";
import { ResidentCenterServicesProps } from "./ResidentCenterPropTypes";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { tenantConfigGet } from "@App/api/general";

const ResidentCenterServices = ({
  title,
  services,
  isList,
  onClick,
}: ResidentCenterServicesProps) => {
  const replacePlaceholders = (
    placeholders: string[],
    newValues: (string | undefined)[],
    text?: string,
  ) => {
    if (!text) {
      return undefined;
    }

    if (!placeholders?.length || !newValues.length) {
      return text;
    }

    placeholders.forEach((placeholder, index) => {
      text = text?.replaceAll(placeholder, newValues[index] ?? "");
    });

    return text;
  };

  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data);
  });

  const Children = (
    <>
      {services.map((service, index) => {
        return (
          <div key={`service_item_${index}`}>
            <button
              className={"resident-center-services-item"}
              onClick={() => {
                onClick({
                  id: !!service.link ? undefined : service.id,
                  link: replacePlaceholders(
                    ["~~ASSET_STORAGE_BASE_URL~~"],
                    [tenantConfig?.awsSettings?.assetStorageBaseURL],
                    service.link,
                  ),
                });
              }}>
              {service.imageUrl && (
                <img
                  className={"resident-center-services-item-image"}
                  src={service.imageUrl}
                  alt={""}
                />
              )}

              <div
                className={`resident-center-services-item-title ${
                  service.link ? "resident-center-services-item-title-link" : ""
                }`}>
                {service.title}
              </div>
            </button>
          </div>
        );
      })}
    </>
  );

  return (
    <>
      <div className={"resident-center-services-name"}>{title}</div>
      {isList ? Children : <SideScroll>{Children}</SideScroll>}
    </>
  );
};

const ResidentCenter = () => {
  const props = ResidentCenterLogic();
  return (
    <Navbar>
      <div
        className={`resident-center-container ${
          props.isMobileEmbed ? "resident-center-container-mobile-embed" : ""
        }`}>
        {!props.isMobileEmbed && (
          <section>
            <div className={"resident-center-top-content"}>
              <div className={"resident-center-top-content-left"}>
                <div className={"resident-center-top-content-left-title"}>
                  Resident Center
                </div>
              </div>

              <div className={"resident-center-search"}>
                <SearchInput
                  onSearch={props.setSearchText}
                  showMagnifier
                  placeholder="Search"
                  style={{ width: "300px" }}
                  className="resident-center-search-input"
                />
              </div>
            </div>
          </section>
        )}

        <section>
          <div className={"resident-center-main-content"}>
            {props.showHouseServices && (
              <div className="resident-center-card">
                <div className="resident-center-card__title">
                  Your House Only
                </div>
                {props.isLoading ? (
                  <Loader color={"#000"} horizontallyCentered />
                ) : (
                  <Accordion.Root
                    className="AccordionRoot"
                    type="multiple"
                    defaultValue={["item-1"]}>
                    {props.data?.data?.items?.map((item, i) => (
                      <Accordion.Item
                        className="AccordionItem"
                        value={`item-${i + 1}`}
                        key={item.id}>
                        <Accordion.Header>
                          {item.theme?.logoUrl ? (
                            <img
                              className="provider-logo"
                              src={item.theme.logoUrl}
                              alt="logo"
                            />
                          ) : (
                            <House size={55} />
                          )}
                          <Accordion.Trigger className="AccordionTrigger">
                            <span className="provider-type">
                              {item.theme.title}
                            </span>
                            <Chevron
                              position="down"
                              className="AccordionChevron"
                              aria-hidden
                            />
                          </Accordion.Trigger>
                        </Accordion.Header>
                        <Accordion.Content className="AccordionContent">
                          <div className="AccordionContentText">
                            {item.items.map((detail, accordionItemIndex) => (
                              <div
                                key={`accordion_item${accordionItemIndex}`}
                                className="detail-container">
                                <div className="detail-title">
                                  {detail.title}
                                </div>
                                <div className="detail-information">
                                  <div
                                    className={
                                      "resident-center-information-item"
                                    }>
                                    <span className="label">{`Website: `}</span>
                                    <a
                                      className="url"
                                      target="_blank"
                                      rel="noreferrer"
                                      href={detail.websiteURL}>
                                      {detail.websiteURL}
                                    </a>
                                  </div>
                                  <div
                                    className={
                                      "resident-center-information-item"
                                    }>
                                    <span className="label">{`Phone: `}</span>
                                    <a
                                      className="url"
                                      href={`tel:${detail.phone}`}>
                                      {formatPhoneNumber(detail.phone)}
                                    </a>
                                  </div>
                                  <div
                                    className={
                                      "resident-center-information-item-last"
                                    }>
                                    <span className="label">{`Email: `}</span>
                                    <a
                                      className="email"
                                      href={`mailto:${detail.email}`}>
                                      {detail.email}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Accordion.Content>
                      </Accordion.Item>
                    ))}
                  </Accordion.Root>
                )}
              </div>
            )}
            <div
              className={`resident-center-services ${
                !props.showHouseServices
                  ? "resident-center-services-no-padding"
                  : ""
              }`}>
              {/* <ResidentCenterServices
                title={"Recommended Services"}
                services={ResidentCenterServicesAndDocDummy.recommendedServices}
                onClick={onServiceOrLinkClick}
              />
              <ResidentCenterServices
                title={"Cleaning Services"}
                services={ResidentCenterServicesAndDocDummy.cleaningServices}
                onClick={onServiceOrLinkClick}
              /> */}
              <ResidentCenterServices
                title={"Documentation"}
                services={ResidentCenterServicesAndDocDummy.documentation}
                isList={true}
                onClick={props.onServiceOrLinkClick}
              />
            </div>
          </div>
        </section>
      </div>
    </Navbar>
  );
};

export default ResidentCenter;
