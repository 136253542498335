import { getEventCalendarDates } from "@App/api/event";
import { QUERY_KEY_EVENT_CALENDAR_DATES } from "@App/constants/queryKeyConstants";
import { useQuery } from "@tanstack/react-query";
import { UseEventsSearchParams } from "./UseEventsSearchParams";
import getFormattedDateRange from "./getFormattedDateRange";

export default function useEventCalendarDates(params: UseEventsSearchParams) {
  const queryParams = {
    ...params,
    managedById: params.managedById === null ? undefined : params.managedById,
    ...getFormattedDateRange(params.startDate, params.endDate),
  };

  const queryKeyParams = Object.values({
    ...params,
    startDate: queryParams.startDate,
    endDate: queryParams.endDate,
  });

  const { data: eventCalendarDates, isLoading: isLoadingEventCalendarDates } =
    useQuery({
      queryKey: [QUERY_KEY_EVENT_CALENDAR_DATES, ...queryKeyParams],
      queryFn: () => getEventCalendarDates(queryParams),
      select: (data) => data.data,
      staleTime: Infinity,
    });

  return {
    eventCalendarDates,
    isLoadingEventCalendarDates,
  };
}
