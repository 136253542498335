import userSession from "@App/auth/userSession";
import { useEffect, useState } from "react";

const ImageCarouselLogic = (slidesTotal: number) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesIterator, setSlidesIterator] = useState<number[]>([]);
  const [isMobileEmbed] = useState(userSession.isMobileViewMode);

  useEffect(() => {
    let slideIndicatorsIterable: number[] = [];
    for (let i = 0; i < slidesTotal; i++) {
      slideIndicatorsIterable.push(i);
    }
    setSlidesIterator(slideIndicatorsIterable);
  }, [slidesTotal]);

  return {
    slidesIterator,
    currentSlide,
    isMobileEmbed,
    setCurrentSlide,
  };
};

export default ImageCarouselLogic;
