import { Navbar } from "@Layouts/index";
import "./Memberships.scss";
import { GenericListing } from "@Components/index";
import MembershipsLogic from "./MembershipsLogic";

const Memberships = () => {
  const props = MembershipsLogic();
  return (
    <Navbar>
      <div className={"memberships"}>
        <GenericListing
          title={"Memberships"}
          onClick={props.onClick}
          isLoading={props.isLoading}
          listingCategories={props.listingCategories}
          getCategoryItems={props.getCategoryItems}
          searchText={props.searchText}
          onSearch={props.onSearch}
          moduleName={"memberships"}
        />
      </div>
    </Navbar>
  );
};

export default Memberships;
