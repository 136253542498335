import { Button } from "@Components/index";
import { BackButtonProps } from "./BackButtonPropTypes";
import { Chevron } from "@Components/icons";
import { useCanGoBack } from "@App/hooks";

const BackButton = (componentProps: BackButtonProps) => {
  return useCanGoBack() ? (
    <Button
      onClick={componentProps.goBack}
      text={"Back"}
      style={{ padding: "4px 2px 4px 0px" }}
      icon={<Chevron position={"left"} size={15} color={"#fff"} />}
    />
  ) : null;
};

export default BackButton;
