import "./GenericModal.scss";
import { GenericModalProps } from "./GenericModalPropTypes";
import { Button, Modal } from "@Components/index";

const GenericModal = (componentProps: GenericModalProps) => {
  return (
    <Modal isVisible={componentProps.isVisible}>
      <div className={"generic-modal"}>
        <div className={"generic-modal__top"}>
          {componentProps.onClose && (
            <Button
              type={"close"}
              onClick={componentProps.onClose}
              style={{ padding: 0, color: "#000" }}
            />
          )}
        </div>
        {componentProps.title && (
          <div className={"generic-modal__title"}>{componentProps.title}</div>
        )}
        <div>{componentProps.children}</div>
        {componentProps.ctaText && componentProps.onCtaClick && (
          <div className={"generic-modal__cta"}>
            <Button
              text={componentProps.ctaText}
              onClick={componentProps.onCtaClick}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default GenericModal;
