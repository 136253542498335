import { PasswordRegx } from "@Utils/utils";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { PasswordResetProps } from "./PasswordResetProps";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { tenantConfigGet } from "@App/api/general";
import { Tenant } from "@App/models/tenant";

const usePasswordResetLogic = (props: PasswordResetProps) => {
  const [oldPassword, setOldPassword] = useState(String);
  const [password, setPassword] = useState(String);
  const [passwordConfirmation, setPasswordConfirmation] = useState(String);
  const [validOldPassword, setValidOldPassword] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validPasswordConfirmation, setValidPasswordConfirmation] =
    useState(true);
  const [validForm, setValidForm] = useState(false);
  const [isPasswordResetProcessing, setIsPasswordResetProcessing] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });

  useEffect(() => {
    const validateFields = () => {
      if (oldPassword?.length > 0) {
        setValidOldPassword(PasswordRegx.test(password));
      }
      if (password?.length > 0) {
        setValidPassword(PasswordRegx.test(password));
      }
      if (password?.length > 0 && passwordConfirmation?.length > 0) {
        setValidPasswordConfirmation(password === passwordConfirmation);
      }
      setValidForm(
        PasswordRegx.test(password) &&
          PasswordRegx.test(oldPassword) &&
          password === passwordConfirmation,
      );
    };
    validateFields();
  }, [oldPassword, password, passwordConfirmation]);

  // Update user password using Amplify Auth
  const updatePassword = async () => {
    try {
      setIsPasswordResetProcessing(true);
      setErrorMessage("");
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, password);
      setIsPasswordResetProcessing(false);
      props.onClose && props.onClose();
    } catch (error) {
      setErrorMessage(
        `Error while trying to change the password. Please try again, or contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail}`,
      );
      setIsPasswordResetProcessing(false);
    }
  };

  return {
    password,
    oldPassword,
    setOldPassword,
    passwordConfirmation,
    validOldPassword,
    validPassword,
    validPasswordConfirmation,
    validForm,
    isPasswordResetProcessing,
    errorMessage,
    setPassword,
    setPasswordConfirmation,
    updatePassword,
  };
};

export default usePasswordResetLogic;
