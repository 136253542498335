import { IconProps } from "../iconTypes";

const Flag = ({ color = "#C4C4C4", fill = "none" }: IconProps) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.77628C0 0.34755 0.336615 0 0.751845 0H8.50525C8.92048 0 9.2571 0.347552 9.2571 0.77628V1.98922H14.5471C15.1225 1.98922 15.4846 2.62942 15.2019 3.1469L13.3981 6.44845L15.1956 9.65418C15.4858 10.1717 15.1241 10.8194 14.545 10.8194H5.54486C5.12963 10.8194 4.79301 10.4719 4.79301 10.0431V8.97574H1.31573V17.3208C1.31573 17.6959 1.02119 18 0.657865 18C0.294536 18 0 17.6959 0 17.3208V0.77628ZM1.31573 7.61725H7.94137V1.35849H1.31573V7.61725ZM6.10874 8.97574V9.46092H13.5669L12.098 6.84115C11.9646 6.60324 11.963 6.31024 12.0938 6.0708L13.5816 3.34771H9.2571V8.19946C9.2571 8.62819 8.92048 8.97574 8.50525 8.97574H6.10874Z"
        fill={color}
      />
    </svg>
  );
};

export default Flag;
