import { useCallback, useEffect, useRef, useState } from "react";
import { MapRef } from "react-map-gl";
import { MapProps, PoiIcon, PoiNavigationDetails } from "./MapPropTypes";
import { PoiIcons } from "./MapIcons";
import { customEventSubscribe, getLocationRoutes } from "@Utils/utils";
import mapboxgl from "mapbox-gl";
import { Poi, PoiFile } from "@App/models/poi";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { tenantConfigGet } from "@App/api/general";
import { MapBoxSettings, Tenant } from "@App/models/tenant";
import { MAP_RESIZE } from "@App/constants/appConstants";
import { getAmenitiesDetails } from "@App/api/amenities";
import { useNavigate } from "react-router-dom";
import { getEventDetails } from "@App/api/event";

// TODO: Clean up this mess
// Waaaaay too much in here
// Poi details should be in... The poi details component. Crazy I know.
let popupCloseTimeout: any = null;
let preventPopupClose: any = null;
let hovered_poi: Poi | null = null;
let currentPoiDetailsId: string | null = null;

const VALID_NAVIGATIONS = [
  0, // 0 is for Events
  "AmenitiesAndFacilities",
  "ClubsAndSocials",
  "Services",
];

const MapLogic = (props: MapProps) => {
  const [poiRoute, setPoiRoute] = useState<any>();
  const [showCluster, setShowCluster] = useState(true);
  const [selectedPoiSource, setselectedPoiSource] = useState<any>();
  const [mapboxSettings, setMapboxSettings] = useState<MapBoxSettings | null>();
  const [poisGeojson, setPoisGeojson] =
    useState<GeoJSON.FeatureCollection<GeoJSON.Geometry>>();
  const [showPopup, setShowPopup] = useState(false);
  const [contextPoi, setContextPoi] = useState<Poi | null>();
  const mapRef = useRef<MapRef | null>(null);
  const [poiNavigationDetails, setPoiNavigationDetails] =
    useState<PoiNavigationDetails | null>();
  const [poiDetails, setPoiDetails] = useState<Poi | null>();
  const [isPoiDetailsModalVisible, setIsPoiDetailsModalVisible] =
    useState(false);
  const [popupImages, setPopupImages] = useState<PoiFile[]>([]);
  const [showLoader, setShowLoader] = useState(props.useLoader);
  const [showMask, setShowMask] = useState(true);

  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });
  const [hasValidDetailsNavigation, setHasValidDetailsNavigation] =
    useState(false);
  const navigate = useNavigate();

  const closePopup = () => {
    popupCloseTimeout = setTimeout(() => {
      props.onHoverReset && props.onHoverReset();
      setShowPopup(false);
      setContextPoi(null);
    }, 300);
  };

  const cancelPopupClose = () => {
    if (popupCloseTimeout) {
      clearTimeout(popupCloseTimeout);
      popupCloseTimeout = null;
    }
  };

  useEffect(() => {
    const geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features:
        props.pois?.map((poi) => {
          return {
            type: "Feature",
            properties: {
              title: poi.title,
              poi_type: poi.poiCategory,
              poi_use_icon: poi.user?.isFavorite ?? false,
              poi_opacity:
                !poiRoute || props.selectedPoi?.id === poi.id
                  ? poi.user?.isFavorite
                    ? 1
                    : 0.8
                  : 0.5,
              ...(props.selectedPoi?.id === poi.id
                ? { poi_selected: true }
                : {}),
              poi,
            },
            geometry: {
              type: "Point",
              coordinates: [
                poi.address?.geoCoordinates?.longitude ?? 0,
                poi.address?.geoCoordinates?.latitude ?? 0,
                0.0,
              ],
            },
          };
        }) ?? [],
    };
    setPoisGeojson(geojson);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pois, poiRoute]);

  useEffect(() => {
    setMapboxSettings(tenantConfig?.mapBoxSettings);
  }, [tenantConfig]);

  useEffect(() => {
    if (mapRef && mapboxSettings) {
      if (mapRef && props.selectedPoi && mapboxSettings) {
        const selectedPoiSrc = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                title: props.selectedPoi.title,
                poi_type: props.selectedPoi.poiCategory,
                poi_use_icon: props.selectedPoi.user?.isFavorite ?? false,
                poi_opacity:
                  !poiRoute || props.selectedPoi?.id === props.selectedPoi.id
                    ? 1
                    : 0.2,
                ...(props.selectedPoi?.id === props.selectedPoi.id
                  ? { poi_selected: true }
                  : {}),
              },
              geometry: {
                type: "Point",
                coordinates: [
                  props.selectedPoi.address.geoCoordinates.longitude,
                  props.selectedPoi.address.geoCoordinates.latitude,
                  0.0,
                ],
              },
            },
          ],
        };

        setselectedPoiSource(selectedPoiSrc);
        mapRef.current?.flyTo({
          center: [
            props.selectedPoi.address.geoCoordinates.longitude,
            props.selectedPoi.address.geoCoordinates.latitude,
          ],
          zoom: 17,
        });

        if (!document.getElementById("resetMapCustomControl")) {
          mapRef.current?.addControl(ResetMapCustomControl());
        }

        getLocationRoutes({
          accessToken: mapboxSettings.accessToken,
          directions: {
            origin: {
              latitude: mapboxSettings.latitude,
              longitude: mapboxSettings.longitude,
            },
            destination: {
              latitude: props.selectedPoi.address.geoCoordinates.latitude,
              longitude: props.selectedPoi.address.geoCoordinates.longitude,
            },
          },
        })
          .then((res) => {
            if (props.selectedPoi) {
              const routeDistanceAndDuration = getRouteDistanceAndDuration(
                res.routes[0].distance,
                res.routes[0].duration
              );
              setPoiNavigationDetails({
                id: props.selectedPoi.id,
                distance: routeDistanceAndDuration.distance,
                duration: routeDistanceAndDuration.duration,
              });
            }

            setPoiRoute(res.routes[0].geometry.coordinates);
            const mapBounds = new mapboxgl.LngLatBounds(
              res.routes[0].geometry.coordinates[0],
              res.routes[0].geometry.coordinates[0]
            );
            for (const coord of res.routes[0].geometry.coordinates) {
              mapBounds.extend(coord);
            }

            mapRef.current?.fitBounds(mapBounds, { padding: 50 });

            // Show the pop up when the user selects (and set the first image).
            const poiFiles = [{ url: props.selectedPoi?.coverImageUrl }];
            const poi = { ...props.selectedPoi, files: poiFiles };
            setContextPoi(poi as Poi);
            setShowPopup(true);
          })
          .catch((err) => console.log(err));
      } else if (mapRef && mapboxSettings) {
        setPoiNavigationDetails(null);
        removeResetControl();
        preventPopupClose = false;
        setselectedPoiSource(null);
        setShowCluster(true);
        setPoiRoute(null);
        setContextPoi(null);
        setShowPopup(false);
        currentPoiDetailsId = null;
        mapRef.current?.flyTo({
          center: [mapboxSettings.longitude, mapboxSettings.latitude],
          zoom: mapboxSettings.zoom,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedPoi]);

  useEffect(() => {
    if (contextPoi) {
      //Check if the navigation is currently supported or not to show or hide the CTA button.
      const viewMoreVisible =
        VALID_NAVIGATIONS.indexOf(contextPoi.section) > -1;

      setHasValidDetailsNavigation(viewMoreVisible);
    }
  }, [contextPoi]);

  // TODO: Figure out what the hell is going on here
  const getPoiDetails = ({
    poiCategory,
    eventId,
    communityAssetId,
  }: {
    poiCategory: string;
    eventId?: string;
    communityAssetId?: string;
  }) => {
    if (eventId && (!poiDetails || currentPoiDetailsId !== eventId)) {
      currentPoiDetailsId = eventId;
      getEventDetails(eventId).then((response) => {
        if (response) {
          const event = response.data as Poi;
          event.poiCategory = poiCategory;
          setPoiDetails(event);
          setPopupImages(event.files ?? []);
        }
      });
    } else if (communityAssetId) {
      getAmenitiesDetails(communityAssetId).then((response) => {
        if (response) {
          let tempPoi: Poi = response.data as Poi;
          tempPoi.poiCategory = poiCategory;
          setPoiDetails(tempPoi);
          setPopupImages((response.data as Poi).files ?? []);
        }
      });
    }
  };

  const onViewDetailClick = () => {
    if (hasValidDetailsNavigation) {
      currentPoiDetailsId = null;

      //  Tech: The user will navigate to the correct section (Events, Amenities or Services).
      //  0 - Events
      //  AmenitiesAndFacilities
      //  ClubsAndSocials
      //  Services
      const section = contextPoi?.section;
      let path = null;

      // Using "as string" because Events is not a string, we get a 0 as a number
      switch (section?.toString()) {
        case "AmenitiesAndFacilities":
          path = `amenities/amenities-details/${contextPoi?.id}`;
          break;
        case "ClubsAndSocials":
          path = `groups-and-clubs/groups-and-clubs-details/${contextPoi?.id}`;
          break;
        case "Services":
          path = `service-details/${contextPoi?.id}`;
          break;
        case "0":
          path = `calendar/event-details/${contextPoi?.id}`;
          break;
        default:
          break;
      }

      if (path) {
        navigate(`/${path}`);
      }
    }

    // Commenting the deatils modal for now. If we are not using this anymore, we should remove the related code.
    // setIsPoiDetailsModalVisible(true);
  };

  const getRouteDistanceAndDuration = (
    routeDistance: number,
    routeDuration: number
  ) => {
    const distance = routeDistance * 0.000621371192; // (miles)
    const hours = Math.floor(routeDuration / 3600);
    const minutes = Math.floor((routeDuration % 3600) / 60);
    let duration = "";
    if (hours > 0) {
      duration = hours + " hs, ";
    }
    duration += minutes + " min";
    return {
      duration,
      distance: distance.toFixed(1) + " mi",
    };
  };

  const loadImage = ({ image, name }: PoiIcon) => {
    if (mapRef.current) {
      if (!mapRef.current.hasImage(name)) {
        let img = new Image(24, 24);
        img.src = image;
        img.onload = () => {
          mapRef.current?.addImage(name, img, { sdf: false });
        };
      }
    }
  };

  const removeResetControl = () => {
    const resetMapCustomControl = document.getElementById(
      "resetMapCustomControl"
    );
    if (resetMapCustomControl) {
      resetMapCustomControl.remove();
    }
  };

  const ResetMapCustomControl = (): any => {
    return {
      onAdd() {
        const el = document.createElement("div");
        el.id = "resetMapCustomControl";
        el.className = "mapboxgl-ctrl";
        el.textContent = "Clear Selection";
        el.style.color = "#fff";
        el.style.backgroundColor = "#000";
        el.style.padding = "5px 10px";
        el.style.borderRadius = "4px";
        el.style.fontWeight = "600";
        el.style.cursor = "pointer";

        el.onclick = () => {
          props.onSelectionReset && props.onSelectionReset();
          el.remove();
        };
        return el;
      },
      onRemove() {},
    };
  };

  const mapResize = () => {
    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    }, 100);
  };

  const mapRefCallback = useCallback((ref: MapRef | null) => {
    if (ref !== null) {
      //Set the actual ref we use elsewhere
      mapRef.current = ref;
      const map = ref;
      if (map) {
        mapRef.current?.addControl(
          new mapboxgl.NavigationControl(),
          "top-left"
        );

        // https://docs.mapbox.com/mapbox-gl-js/example/add-image-animated/
        const size = 200;
        let mapContext: any;
        const pulsingDot = {
          width: size,
          height: size,
          data: new Uint8Array(size * size * 4),
          onAdd: function () {
            const canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;
            mapContext = canvas.getContext("2d", { willReadFrequently: true });
          },
          render: function () {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;
            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = mapContext;
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
            );
            context.fillStyle = `rgba(133, 133, 133, ${1 - t})`;
            context.fill();
            this.data = context.getImageData(
              0,
              0,
              this.width,
              this.height
            ).data;
            map.triggerRepaint();
            return true;
          },
        };
        if (!map.hasImage("pulsing-dot")) {
          map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });
        }
      }

      PoiIcons.forEach((poiImage) => {
        loadImage(poiImage);
      });
      let isPoiClick = false;
      map.on("mousemove", "unclustered-circle-point", (event) => {
        if (event.features && event.features.length > 0) {
          let poi = JSON.parse(event.features[0].properties?.poi);
          if (poi && poi.id !== hovered_poi?.id) {
            hovered_poi = poi;
            props.onPoiHover && props.onPoiHover(poi);
            if (poi.id !== currentPoiDetailsId) {
              const isEvent = poi.poiCategory === "Events";
              setPopupImages((poi as Poi).files ?? []);
              getPoiDetails({
                poiCategory: poi.poiCategory,
                eventId: isEvent ? poi.id : null,
                communityAssetId: isEvent ? null : poi.id,
              });
            }
          }
          poi.files = [{ url: poi.coverImageUrl }];
          setContextPoi(poi as Poi);
          setShowPopup(true);
        }
      });
      map.on("click", "unclustered-circle-point", (event) => {
        if (event.features && event.features.length > 0) {
          const poi = JSON.parse(event.features[0].properties?.poi);
          preventPopupClose = true;
          isPoiClick = true;
          props.onPoiClick && props.onPoiClick(poi);
        }
      });
      map.on("click", (event) => {
        if (!isPoiClick) {
          preventPopupClose = false;
          closePopup();
        } else {
          isPoiClick = false;
        }
      });
      map.on("mouseleave", "unclustered-circle-point", (event) => {
        if (!preventPopupClose) {
          hovered_poi = null;
          closePopup();
        } else {
          cancelPopupClose();
        }
      });
      customEventSubscribe(MAP_RESIZE, () => mapResize());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    poisGeojson,
    selectedPoiSource,
    showCluster,
    poiRoute,
    mapboxSettings,
    showPopup,
    contextPoi,
    poiNavigationDetails,
    poiDetails,
    isPoiDetailsModalVisible,
    popupImages,
    showLoader,
    showMask,
    hasValidDetailsNavigation,
    closePopup,
    cancelPopupClose,
    mapRefCallback,
    setShowPopup,
    setContextPoi,
    setPoiDetails,
    setIsPoiDetailsModalVisible,
    setPopupImages,
    onViewDetailClick,
    setShowLoader,
    setShowMask,
  };
};

export default MapLogic;
