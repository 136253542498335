import { updateAmenityPreference } from "@App/api/amenities";
import { UserPreferenceMutationParams } from "@App/hooks/api/events/UserPreferenceMutationParams";
import useUpdateEventUserPreference from "@App/hooks/api/events/useUpdateEventUserPreference";
import { Poi } from "@App/models/poi";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PoiDetailProps } from "./PoiDetailPropTypes";

const PoiDetailLogic = (props: PoiDetailProps) => {
  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = userData;

  const [poiDetail, setPoiDetail] = useState<Poi | null>(props.poi);

  const [isVisible, setIsVisible] = useState(false);
  const [isFavPreferenceProcessing, setIsFavPreferenceProcessing] =
    useState(false);
  const [isFav, setIsFav] = useState(props.poi?.user?.isFavorite ?? false);
  const isEvent = props.poi?.poiCategory === "Event";

  const onMutate = () => {
    setIsFavPreferenceProcessing(true);
  };

  const onSettled = () => {
    setIsFavPreferenceProcessing(false);
  };

  const handleMutationSuccess = (params: UserPreferenceMutationParams) => {
    setIsFav(params.preference.value);

    props.onRefreshData && props.onRefreshData();
  };

  const userPreferenceMutation = useUpdateEventUserPreference({
    eventTitle: props.poi?.title ?? "",
    onMutate,
    onSettled,
    onSuccess: handleMutationSuccess,
  });

  useEffect(() => {
    setPoiDetail(props.poi);
  }, [props.poi]);

  // Not used?
  const updateEventPreference = (preferenceValue: boolean) => {
    setIsFavPreferenceProcessing(true);
    if (userInfo?.id && props.poi) {
      userPreferenceMutation.mutate({
        eventId: props.poi.id,
        userId: userInfo.id,
        preference: { name: "isFavorite", value: preferenceValue },
      });
    }
  };

  // TODO: Not used? If it is, refactor to tanstack
  const updateCommunityAssetPreference = (preferenceValue: boolean) => {
    setIsFavPreferenceProcessing(true);
    if (userInfo?.id && props.poi) {
      updateAmenityPreference({
        amenityId: props.poi.id,
        userId: userInfo.id,
        preference: { isFavorite: preferenceValue },
      }).then((response) => {
        setIsFavPreferenceProcessing(false);
        if (response.status === 204) {
          setIsFav(preferenceValue);
          props.onRefreshData && props.onRefreshData();
        }
      });
    }
  };

  const onFavClick = () => {
    if (isEvent) {
      updateEventPreference(!isFav);
    } else {
      updateCommunityAssetPreference(!isFav);
    }
  };

  return {
    isVisible,
    poiDetail,
    isFavPreferenceProcessing,
    isFav,
    userInfo,
    setIsVisible,
    onFavClick,
  };
};

export default PoiDetailLogic;
