import { useEffect, useRef, useState } from "react";
import { PoisListProps } from "./PoisListPropTypes";

const PoisListLogic = (props: PoisListProps) => {
  const [pois, setPois] = useState(props.pois ?? []);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    if (!props.selectedPoi && props.hoveredPoi) {
      const el = document.querySelectorAll(
        `[data-id=poi-${props.hoveredPoi?.id}]`,
      )[0];

      if (el && (el as any).parentNode) {
        (el as any).parentNode.scrollTop = (el as any).offsetTop - 200;
      }
    }
  }, [props.selectedPoi, props.hoveredPoi]);

  useEffect(() => {
    if (props.selectedPoi) {
      const notSelectedPois = props.pois.filter(
        (poi) => poi.id !== props.selectedPoi?.id,
      );
      const poisListSorted = [props.selectedPoi, ...notSelectedPois];
      setPois(poisListSorted);
    } else {
      setPois(props.pois);
    }

    elementRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [props.pois, props.selectedPoi]);

  return { elementRef, pois };
};

export default PoisListLogic;
