import { SvgsProps } from "../iconTypes";

const ChevronRight = ({ color = "#212B36", opacity = 1, size = "small" }: SvgsProps) => {
  const isSmall = size === "small";
  return (
    <svg
      width={isSmall ? "7" : "19"}
      height={isSmall ? "13" : "29"}
      viewBox={`0 0 ${isSmall ? "7 13" : "19 29"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          isSmall
            ? "M1.18881 12.3333C0.994097 12.3337 0.8054 12.2659 0.655473 12.1417C0.485051 12.0004 0.37785 11.7971 0.357526 11.5766C0.337202 11.3562 0.405424 11.1367 0.54714 10.9667L4.28047 6.50001L0.680473 2.025C0.540664 1.85284 0.475249 1.63205 0.49871 1.41152C0.522171 1.19098 0.632573 0.988899 0.805473 0.850005C0.979779 0.696639 1.21016 0.622942 1.44112 0.64667C1.67207 0.670399 1.88266 0.789401 2.02214 0.975005L6.04714 5.97501C6.2999 6.28251 6.2999 6.72584 6.04714 7.03334L1.88047 12.0333C1.71092 12.2379 1.454 12.3493 1.18881 12.3333Z"
            : "M3.70629 28.7466L0.901977 25.9423L12.389 14.4553L0.901976 2.96829L3.70628 0.163986L17.9976 14.4553L3.70629 28.7466Z"
        }
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default ChevronRight;
