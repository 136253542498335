import { IconProps } from "../iconTypes";
//@ts-ignore
import themedStyles from "@Assets/styles/themedStyles.scss";

const Envelope = ({ color = themedStyles.cmsMainIconsColor }: IconProps) => {
  return (
    <svg
      width="74"
      height="56"
      viewBox="0 0 74 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5993_1394)">
        <rect
          x="7.15825"
          y="5.42021"
          width="57.7075"
          height="44.545"
          rx="3.33198"
          stroke={color}
          strokeWidth="1.52353"
        />
        <path
          d="M34.4088 31.0333C35.598 31.9365 37.2433 31.9365 38.4324 31.0333L63.8774 11.7076C66.4148 9.78043 65.0519 5.73265 61.8656 5.73265H10.9756C7.78932 5.73265 6.42642 9.78043 8.96383 11.7076L34.4088 31.0333Z"
          stroke={color}
          strokeWidth="1.53516"
        />
        <path
          d="M7.71167 41.5134L31.4041 28.351"
          stroke={color}
          strokeWidth="1.53516"
        />
        <path
          d="M64.3118 41.5134L41.5289 28.8812"
          stroke={color}
          strokeWidth="1.53516"
        />
      </g>
      <defs>
        <clipPath id="clip0_5993_1394">
          <rect width="74" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Envelope;
