import { Button, ImageFilesCarousel, Loader } from "@Components/index";
import "./PoiDetail.scss";
import PoiDetailLogic from "./PoiDetailLogic";
import { PoiDetailProps } from "./PoiDetailPropTypes";
import { Heart } from "@Components/icons";
import { useTenantConfig } from "@App/hooks";

const PoiDetail = (componentProps: PoiDetailProps) => {
  const props = PoiDetailLogic(componentProps);

  const placeholderUrl = useTenantConfig()?.features.defaultImagePlaceholder;

  if (!props.poiDetail) {
    return null;
  }
  const { title, address, description, files } = props.poiDetail;
  const { address1, city, state, zip } = address;

  return (
    <div className={"poi-detail"}>
      <div className={"poi-detail-image"}>
        {files && files.length > 0 ? (
          <ImageFilesCarousel
            files={files}
            showArrows={false}
            useDefaultLabel={false}
            customHeight={"400px"}
            showIndicators
          />
        ) : (
          <div
            style={{
              height: 400,
              background: `url(${placeholderUrl ?? ""})`,
            }}></div>
        )}
        <div className={"poi-detail-image-close"}>
          <Button
            onClick={() => componentProps.onClose && componentProps.onClose()}
            type={"close"}
            style={{ color: "#000" }}
          />
        </div>
      </div>
      <div className={"poi-detail-content"}>
        <div className={"poi-detail-content-description"}>
          <div className={"poi-detail-content-description-top"}>
            <div className={"poi-detail-content-description-top-title"}>
              {title}
            </div>

            {props.userInfo && (
              <button
                className={"poi-detail-content-description-top-fav"}
                style={{ marginTop: props.isFavPreferenceProcessing ? 5 : 0 }}
                onClick={props.onFavClick}>
                {props.isFavPreferenceProcessing ? (
                  <Loader
                    color={"silver"}
                    horizontallyCentered
                    width="20"
                    height="10"
                  />
                ) : (
                  <Heart isActive={props.isFav} />
                )}
              </button>
            )}
          </div>

          <div className={"poi-detail-content-description-contact"}>
            <div
              className={
                "poi-detail-content-description-contact-location"
              }>{`Location: ${address1} ${city}, ${state} ${zip}`}</div>
          </div>

          <div
            className={"poi-detail-content-description-html"}
            dangerouslySetInnerHTML={{
              __html: description ?? "",
            }}></div>
        </div>
        <div className={"poi-detail-content-cta"}>
          <Button
            text={"Close"}
            onClick={() => {
              componentProps.onClose && componentProps.onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PoiDetail;
