import "./StripePaymentError.scss";
import { TriangleError } from "@Components/icons";
const StripePaymentError = ({ message }: any) => {
  return (
    <div className={"stripe-payments-error"}>
      <div>
        <TriangleError color={"#FA3E3E"} size={20} />
      </div>
      <div className={"stripe-payments-error__text"}>{message}</div>
    </div>
  );
};

export default StripePaymentError;
