import { getTenantCmsData } from "@App/api/mock/tenantCmsData";
import { QUERY_KEY_CMS_DETAIL_PAGES } from "@App/constants/queryKeyConstants";
import { CmsDetailPageData } from "@App/models/CmsPageData";
import { useQuery } from "@tanstack/react-query";
import { getTenantId } from "@App/auth/tenant";

export default function useDetailPageData(pageId?: string) {
  const tenantId = getTenantId();
  if (!tenantId) {
    throw new Error("Tenant Id not found in local storage");
  }

  const { data: detailPageDataList, isLoading: isLoadingDetailPageData } =
    useQuery({
      queryKey: [QUERY_KEY_CMS_DETAIL_PAGES],
      queryFn: () => getTenantCmsData(tenantId, "detail-pages"),
      select: (data: { data: CmsDetailPageData[] }) => data?.data,
      staleTime: Infinity,
      cacheTime: Infinity,
    });

  const detailPageData = detailPageDataList?.find((page) => page.id === pageId);

  return {
    detailPageData,
    isLoadingDetailPageData,
  };
}
