import "./Footer.scss";
import { useTenantFooter } from "@App/hooks";
import { FooterTemplateType } from "@App/models/tenant/TenantFooter";
import { LazyExoticComponent, lazy } from "react";
import { useMemo } from "react";

// This needs to be done in this file so webpack can properly lazy load the components
export const templatePathMapping: Record<
  FooterTemplateType,
  LazyExoticComponent<() => JSX.Element>
> = {
  0: lazy(
    () =>
      import(
        "@Components/footer-templates/basic-3-column-footer-template/Basic3ColumnFooterTemplate"
      ),
  ),
  1: lazy(
    () =>
      import(
        "@Components/footer-templates/enhanced-3-column-footer-template/Enhanced3ColumnFooterTemplate"
      ),
  ),
};

const Footer = () => {
  const { tenantFooter } = useTenantFooter();

  const TemplateComponent = useMemo(() => {
    if (!tenantFooter?.templateType && tenantFooter?.templateType !== 0) {
      return null;
    }

    const Component = templatePathMapping[tenantFooter.templateType];

    return <Component />;
  }, [tenantFooter?.templateType]);

  if (!TemplateComponent) return null;
  return <footer className={"footer"}>{TemplateComponent}</footer>;
};

export default Footer;
