import { IconProps } from "@Components/icons/iconTypes";

const MagnifyingGlass = ({ color = "#878686", size }: IconProps) => {
  return (
    <svg
      {...(size && {
        style: { width: size, height: size },
      })}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.21212 0.772705C8.88379 0.772705 10.487 1.43677 11.669 2.61882C12.8511 3.80087 13.5151 5.40407 13.5151 7.07574C13.5151 8.63695 12.943 10.0721 12.0024 11.1776L12.2642 11.4394H13.0303L17.8788 16.2879L16.4242 17.7424L11.5758 12.8939V12.1279L11.3139 11.866C10.2085 12.8066 8.77333 13.3788 7.21212 13.3788C5.54045 13.3788 3.93725 12.7147 2.7552 11.5327C1.57316 10.3506 0.909088 8.7474 0.909088 7.07574C0.909088 5.40407 1.57316 3.80087 2.7552 2.61882C3.93725 1.43677 5.54045 0.772705 7.21212 0.772705ZM7.21212 2.7121C4.78788 2.7121 2.84848 4.65149 2.84848 7.07574C2.84848 9.49998 4.78788 11.4394 7.21212 11.4394C9.63636 11.4394 11.5758 9.49998 11.5758 7.07574C11.5758 4.65149 9.63636 2.7121 7.21212 2.7121Z"
        fill={color}
      />
    </svg>
  );
};

export default MagnifyingGlass;
