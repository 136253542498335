import { MapPois } from "@Components/index";
import "./Locations.scss";
import { Navbar } from "@Layouts/index";

const Locations = () => {
  return (
    <Navbar>
      <div className={"locations-container"}>
        <MapPois isExpandableMap customHeight={862} />
      </div>
    </Navbar>
  );
};

export default Locations;
