import { handleEmbeddedTokenUpdate } from "@App/api/baseService";
import ProtectedRoute from "@App/auth/ProtectedRoute";
import userSession from "@App/auth/userSession";
import { config } from "@App/config/config";
import { useAppPrefetch } from "@App/hooks";
import { getUserDetails } from "@App/store/actions/userActions";
import ScrollToTop from "@Components/scroll-to-top/ScrollToTop";
import { Home } from "@Pages/index";
import { Auth } from "aws-amplify";
import Logrocket from "logrocket";
import { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import {
  NavigationProvider,
  useNavigationContext,
} from "./contexts/NavigationContext";

const Amenities = lazy(() => import("@Pages/amenities/Amenities"));
const AmenitiesDetail = lazy(
  () => import("@Pages/amenities-detail/AmenitiesDetail"),
);
const Calendar = lazy(() => import("@Pages/calendar/Calendar"));
const ClassSchedule = lazy(() => import("@Pages/class-schedule/ClassSchedule"));
const ClubsAndSocials = lazy(
  () => import("@Pages/clubs-and-socials/ClubsAndSocials"),
);
const ClubsAndSocialsDetails = lazy(
  () => import("@Pages/clubs-and-social-details/ClubsAndSocialsDetails"),
);
const CmsDetail = lazy(() => import("@Pages/cms-main/cms-detail/CmsDetail"));
const CmsV2 = lazy(() => import("@Pages/cms-v2/CmsV2"));
const EmailConfirmation = lazy(
  () => import("@Pages/email-confirmation/EmailConfirmation"),
);
const Error = lazy(() => import("@Pages/error/Error"));
const EventDetails = lazy(() => import("@Pages/event-details/EventDetails"));
const EventTicketPurchase = lazy(
  () => import("@Pages/event-ticket-purchase/EventTicketPurchase"),
);
const Locations = lazy(() => import("@Pages/map/Locations"));
const MembershipDetails = lazy(
  () => import("@Pages/membership-details/MembershipDetails"),
);
const MembershipPayment = lazy(
  () => import("@Pages/membership-payment/MembershipPayment"),
);
const Memberships = lazy(() => import("@Pages/memberships/Memberships"));
const NewsAndArticles = lazy(() => import("@Pages/news/NewsAndArticles"));
const Notifications = lazy(() => import("@Pages/notifications/Notifications"));
const Profile = lazy(() => import("@Pages/profile/Profile"));
const Registration = lazy(() => import("@Pages/registration/Registration"));
const ResidentCenter = lazy(
  () => import("@Pages/resident-center/ResidentCenter"),
);
const ResidentCenterServiceDetails = lazy(
  () =>
    import(
      "@Pages/resident-center-service-details/ResidentCenterServiceDetails"
    ),
);
const ServiceDetails = lazy(
  () => import("@Pages/service-details/ServiceDetails"),
);
const Venues = lazy(() => import("@Pages/venues/Venues"));
const VenuesDetail = lazy(() => import("@Pages/venues/details/VenuesDetail"));
const ReservationPayment = lazy(() => import("@Pages/reservation-payment/ReservationPayment"));

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  }
}

const Redirect = ({ url }: { url: string }) => {
  window.location.replace(url);
  return <></>;
};

function App() {
  const dispatch = useDispatch();

  const [sessionChecked, setSessionChecked] = useState(false);

  useEffect(() => {
    if (!sessionChecked) {
      //TODO: TBD where can we get all the enums at the start.
      Auth.currentSession()
        .then((session) => {
          userSession.accessToken = session.getAccessToken().getJwtToken();
          Auth.currentUserInfo()?.then((info) => {
            userSession.cognitoUsername = info.username;
            if (config.logRocketEnabled && !!config.logRocketAppId) {
              Logrocket.identify(userSession.cognitoUsername);
            }
            setSessionChecked(true);
            dispatch(getUserDetails() as any);
          });
        })
        .catch((error) => {
          setSessionChecked(true);
        })
        .finally(() => {
          if (userSession.isMobileViewMode && userSession.accessToken) {
            dispatch(getUserDetails() as any);
          }
        });
    }
  }, [sessionChecked, dispatch]);

  const NavigationUpdater = () => {
    const location = useLocation();
    const { history, addToHistory } = useNavigationContext();

    useEffect(() => {
      if (history[history.length - 1] !== location.pathname) {
        addToHistory(location.pathname);
      }
    }, [location.pathname, history, addToHistory]);

    return null;
  };

  // Listen for react native message events
  useEffect(() => {
    const handleMessageFromReactNative = (event: MessageEvent) => {
      const eventData = event.data;
      if (eventData) {
        const message = JSON.parse(eventData);
        if (message.type === "tokenUpdate") {
          const accessToken = message.accessToken;
          handleEmbeddedTokenUpdate(accessToken);
        }
      }
    };

    if (window.ReactNativeWebView) {
      window.addEventListener("message", handleMessageFromReactNative);
    }

    return () => {
      if (window.ReactNativeWebView) {
        window.removeEventListener("message", handleMessageFromReactNative);
      }
    };
  }, []);

  // Prefetch common queries
  useAppPrefetch();

  if (!sessionChecked) {
    return <p>Checking session, please wait...</p>;
  }

  return (
    <div
      className={`app ${
        userSession.isMobileViewMode ? "app-mobile-view-mode" : ""
      }`}
    >
      <Router>
        <ScrollToTop />
        <NavigationProvider>
          <NavigationUpdater />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route element={<ProtectedRoute />}>
                {/* Protected Routes here... */}
                <Route path={"/profile"} element={<Profile />} />
                <Route path={"/resident-center"} element={<ResidentCenter />} />
                <Route
                  path={"/resident-center/resident-center-service-details/:id"}
                  element={<ResidentCenterServiceDetails />}
                />
                <Route
                  path={
                    "/memberships/membership-payment/:userMembershipId/:rateId"
                  }
                  element={<MembershipPayment />}
                />
                <Route path={"/notifications"} element={<Notifications />} />
              </Route>
              {/* Error page */}
              <Route path={"*"} element={<Error />} />
              <Route path={"/"} element={<Home />} />
              <Route path={"/registration"} element={<Registration />} />
              <Route
                path={"/emailconfirmation"}
                element={<EmailConfirmation />}
              />
              <Route path={"/calendar"} element={<Calendar />} />
              <Route
                path={"/calendar/event-details/:eventId"}
                element={<EventDetails />}
              />
              {/* TODO: Fix this one to use dynamic routes too */}
              <Route
                path={"/calendar/event-details/tickets"}
                element={<EventTicketPurchase />}
              />

              <Route path={"/locations"} element={<Locations />} />
              <Route
                path="/amenities/amenities-details/:amenityId"
                element={<AmenitiesDetail />}
              />
              <Route path={"/amenities"} element={<Amenities />} />
              <Route path={"/memberships"} element={<Memberships />} />
              <Route path={"/groups-and-clubs"} element={<ClubsAndSocials />} />
              <Route
                path={"/memberships/membership-details/:id"}
                element={<MembershipDetails />}
              />
              <Route
                path={
                  "/groups-and-clubs/groups-and-clubs-details/:communityGroupId"
                }
                element={<ClubsAndSocialsDetails />}
              />
              <Route path={"/venues"} element={<Venues />} />
              <Route
                path={"/venues/venues-details/:venueId"}
                element={<VenuesDetail />}
              />
              <Route
                path={"/complete-payment/:reservationId"}
                element={<ReservationPayment />}
              />

              <Route path="/cms/:pageSlug" element={<CmsV2 />} />
              <Route path="/cms-detail/:pageSlug" element={<CmsV2 />} />
              <Route path="/service-details/:id" element={<ServiceDetails />} />
              <Route path="/class-schedule" element={<ClassSchedule />} />
              <Route path="/news" element={<NewsAndArticles />} />

              {/* REDIRECTS */}
              {/* Add tenant checks here? */}

              {/* LWR */}
              <Route
                path="/173/payments"
                element={<CmsDetail hardCodedId="payments" />}
              />
              <Route
                path="/934/LWR-Parks-Rec-Guide-September-December-2"
                element={
                  <Redirect url="https://cp-customerblueprint-prod-documents-by-tenant-public.s3.amazonaws.com/Prod/lakewoodranch/Media/Parks-and-Rec-Guide.pdf" />
                }
              />

              {/* Rancho */}
              <Route path="/events" element={<Redirect url="/calendar" />} />
              <Route
                path="/classes"
                element={<Redirect url="/class-schedule" />}
              />
            </Routes>
          </Suspense>
        </NavigationProvider>
      </Router>
    </div>
  );
}

export default App;
