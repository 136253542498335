import "./NavbarItems.scss";
import { HoverListMenu } from "@Components/index";
import NavbarItemsLogic from "@Components/nav-bar-items/NavbarItemsLogic";
import { toKebabCase } from "@Utils/utils";

const NavbarItems = () => {
  const props = NavbarItemsLogic();

  return (
    <>
      {props.navbarItems?.map((item, index) => {
        return (
          <HoverListMenu
            key={`nav-bar-item-${index}`}
            text={item.text}
            classHooks={toKebabCase(item.text)}
            className={"nav-bar-menu-item"}
            route={item.route}
            isRouteExternal={item.isRouteExternal}
            isLoginRequired={item.isLoginRequired}
            subNavItems={item.subNavItems}
          />
        );
      })}
    </>
  );
};

export default NavbarItems;
