import { IconProps } from "../iconTypes";
import "./Heart.scss";

const Heart = ({ iconType, color, size, isActive }: IconProps) => {
  return (
    <i
      className={`heart-icon fa-${
        iconType ? iconType : isActive ? "solid" : "regular"
      } fa-heart ${isActive ? "heart-icon-active" : ""}`}
      style={{ color: color, fontSize: size }}></i>
  );
};

export default Heart;
