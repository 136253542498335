import { getMapCommunityAssets } from "@App/api/amenities";
import { getPoiCategories } from "@App/api/general";
import {
  QUERY_KEY_MAP_POIS,
  QUERY_KEY_POI_CATEGORIES,
} from "@App/constants/queryKeyConstants";
import { Poi } from "@App/models/poi";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { Tab } from "@Components/tabs/TabsPropTypes";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const DEFAULT_OBJECT_ID_HEX = "000000000000000000000000";

const MapPoisLogic = () => {
  const [selectedPoi, setSelectedPoi] = useState<Poi | null>(null);
  const [hoveredPoi, setHoveredPoi] = useState<Poi | null>(null);
  const [isPoiDetailVisible, setIsPoiDetailVisible] = useState(false);
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [selectedTab, setSelectedTab] = useState(-1);
  const [isPoisListVisible, setIsPoisListVisible] = useState(true);
  const poisListRef = useRef<any>(null);

  const [searchText, setSearchText] = useState(String);
  const storeData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const selectedPoiCategoryId = useRef<string>(DEFAULT_OBJECT_ID_HEX);
  const { userInfo } = storeData;

  const {
    data: pois,
    isLoading: isLoadingPois,
    isRefetching: isRefetchingPois,
    refetch: refetchPois,
  } = useQuery({
    queryKey: [QUERY_KEY_MAP_POIS, selectedPoiCategoryId.current],
    queryFn: () =>
      getMapCommunityAssets({
        eventStartDate: moment().format("YYYY-MM-DD"),
        eventEndDate: moment().add(6, "M").format("YYYY-MM-DD"),
        isIncludeEvents:
          selectedPoiCategoryId.current === DEFAULT_OBJECT_ID_HEX,
        poiCategoryIds: selectedPoiCategoryId.current,
      }),
    staleTime: 1000 * 60 * 30, // 30 minutes
    select: (data) => {
      const flattenedPoiItems = data.data.flatMap((poiGroup) => {
        if (!poiGroup.items) {
          return [];
        }
        return poiGroup.items.reduce((poiList: Poi[], poiItem) => {
          const hasValidSearch =
            isSearchValid(searchText, poiItem) ||
            poiGroup.poiCategory.toLowerCase().indexOf(searchText) > -1;

          if (poiItem.address?.geoCoordinates?.latitude && hasValidSearch) {
            poiItem.poiCategory = poiGroup.poiCategory;
            poiList.push(poiItem);
          }

          return poiList;
        }, []);
      });

      return flattenedPoiItems;
    },
  });

  const { data: poisCategories } = useQuery({
    queryKey: [QUERY_KEY_POI_CATEGORIES],
    queryFn: () => getPoiCategories(),
    select: (data) => data.data.items,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (poisCategories && poisCategories?.length > 0) {
      let tabsList: any = [
        {
          label: "Events",
          index: -1,
          icon: "event",
          isHidden: false,
          isActive: true,
        },
      ];

      poisCategories.forEach((poiCategory: any, index: number) => {
        tabsList.push({
          label: poiCategory.name,
          index,
          ...((poiCategory.name === "Favorites" ||
            poiCategory.name === "Event") && {
            icon: poiCategory.name === "Favorites" ? "heart" : "event",
          }),
          isHidden: false,
          isActive: false,
          iconUrl: poiCategory.logoURL,
        });
      });
      setTabs(tabsList);
    }
  }, [poisCategories]);

  useEffect(() => {
    onRefreshPois(); // Needs to refetch if the user login state changes (segmentation).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const onTabChange = (tabIndex: number) => {
    setSelectedTab(tabIndex);
    onPoiSelectionChange(null);
    if (tabIndex === 0) {
      selectedPoiCategoryId.current = DEFAULT_OBJECT_ID_HEX;
    } else {
      const currentPoiCategorySelected =
        poisCategories && poisCategories[tabIndex - 1].id;
      if (
        currentPoiCategorySelected &&
        selectedPoiCategoryId.current !== currentPoiCategorySelected
      ) {
        selectedPoiCategoryId.current = currentPoiCategorySelected;
      }
    }
  };

  const onPoiSelectionChange = (poi: Poi | null) => {
    setHoveredPoi(null);
    setSelectedPoi(poi);
    if (!poi) {
      if (poisListRef?.current) {
        poisListRef.current.scrollTop = 0;
      }
    }
  };

  // Swapped to useCallback to prevent closure of old refetch function
  const onRefreshPois = useCallback(() => refetchPois(), [refetchPois]);

  return {
    poisListRef,
    selectedPoi,
    hoveredPoi,
    isPoiDetailVisible,
    tabs,
    pois,
    selectedTab,
    isPoisListVisible,
    isLoadingPois,
    isRefetchingPois,
    onTabChange,
    setSelectedPoi,
    setHoveredPoi,
    setIsPoiDetailVisible,
    onPoiSelectionChange,
    setIsPoisListVisible,
    onRefreshPois,
    setSearchText,
  };
};

export default MapPoisLogic;

const isSearchValid = (searchText: string, poiItem: Poi) => {
  if (!searchText || searchText.length === 0) {
    return true;
  }
  let isPoiItemSearched =
    poiItem.title?.toLowerCase().indexOf(searchText) > -1 ||
    poiItem.address.address1?.toLowerCase().indexOf(searchText) > -1;
  if (!isPoiItemSearched) {
    if (
      poiItem.description &&
      poiItem.description?.toLowerCase().indexOf(searchText) > -1
    ) {
      isPoiItemSearched = true;
    } else if (
      poiItem.venue &&
      poiItem.venue?.toLowerCase().indexOf(searchText) > -1
    ) {
      isPoiItemSearched = true;
    } else if (
      poiItem.poiCategory &&
      poiItem.poiCategory?.toLowerCase().indexOf(searchText) > -1
    ) {
      isPoiItemSearched = true;
    }
  }

  return isPoiItemSearched;
};
