import "./Registration.scss";
import {
  Button,
  Checkbox,
  LinkifyText,
  Loader,
  TextInput,
} from "@Components/index";
import useRegistrationLogic from "./RegistrationLogic";
import { RegistrationProps } from "./RegistrationProps";
import { UserType } from "@App/models/types";

const Registration = (componentProps: RegistrationProps) => {
  const props = useRegistrationLogic(componentProps);
  return (
    <div className={"registration"} data-cy="registration-modal">
      <div className={"registration-form"}>
        <header className={"registration-form-header"}>
          <label className={"registration-title"}>
            {/* TODO: Use community Info data for the name, or config, etc */}
            Welcome to Lakewood Ranch!
          </label>
          <label className={"registration-subtitle"}>
            Let´s get started. Please enter your details
          </label>
          {(componentProps.duplicateAccountError || !!props.errorMessage) && (
            <div className={"registration-error-banner"}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={"registration-error-banner-icon"}>
                <path
                  d="M16.0002 7.98669L26.0402 25.3334H5.96016L16.0002 7.98669ZM16.0002 2.66669L1.3335 28H30.6668L16.0002 2.66669ZM17.3335 21.3334H14.6668V24H17.3335V21.3334ZM17.3335 13.3334H14.6668V18.6667H17.3335V13.3334Z"
                  fill="#FA3E3E"
                />
              </svg>
              <div>
                <LinkifyText
                  text={
                    props.errorMessage
                      ? props.errorMessage
                      : "This email is already associated with a registered account."
                  }
                />
                {componentProps.duplicateAccountError && (
                  <div>
                    {`Please `}
                    <label
                      className={"registration-error-banner-link"}
                      onClick={() => {
                        componentProps.onClose && componentProps.onClose(true);
                      }}>
                      {`Log In`}
                    </label>
                    {` to continue.`}
                  </div>
                )}
              </div>
            </div>
          )}
        </header>
        <section className={"registration-form-fields"}>
          {!componentProps.userData && (
            <div>
              <TextInput
                type={"text"}
                label={"First Name"}
                placeholder={"Enter First Name"}
                maxLength={50}
                withMargin
                required
                value={props.firstname}
                onChange={props.setFirstname}
                isValid={props.validFirstname}
                errorMessage={"Please enter your first name"}
                dataCy="reg-first-name"
              />

              <TextInput
                type={"text"}
                label={"Last Name"}
                placeholder={"Enter Last Name"}
                maxLength={50}
                withMargin
                required
                value={props.lastname}
                onChange={props.setlastname}
                isValid={props.validLastname}
                errorMessage={"Please enter your last name"}
                dataCy="reg-last-name"
              />
            </div>
          )}

          <TextInput
            type={"email"}
            label={"Email"}
            placeholder={"Enter Email"}
            maxLength={100}
            withMargin
            required
            value={props.email}
            onChange={props.setEmail}
            isValid={props.validEmail}
            errorMessage={"Please enter a valid Email address"}
            isDisabled={!!componentProps.userData}
            dataCy="reg-email"
          />

          <TextInput
            type={"password"}
            label={"Password"}
            maxLength={50}
            withMargin
            required
            value={props.password}
            onChange={props.setPassword}
            isValid={props.validPassword}
            errorMessage={
              "The password must contain at least 8 characters, upper and lowercase letters, numerical value and special character."
            }
            dataCy="reg-pass"
          />

          <TextInput
            type={"password"}
            label={"Repeat Password"}
            maxLength={50}
            withMargin
            required
            value={props.passwordConfirmation}
            onChange={props.setPasswordConfirmation}
            isValid={props.validPasswordConfirmation}
            errorMessage={
              "Passwords should match and must contain at least 8 characters, upper and lowercase letters, numerical value and special character."
            }
            pasteDisabled
            dataCy="reg-pass-repeat"
          />
          {componentProps.userData && props.userType !== UserType.Initiated && (
            <div
              className={
                "registration-form-bottom-text registration-form-marketing"
              }>
              <Checkbox
                text={`I would like to receive marketing communications and offers from ${
                  props.tenantConfig?.name ?? "the community"
                } and its selected partners.`}
                defaultChecked={props.marketingCommunicationsEnabled}
                onChange={(value) => {
                  props.setMarketingCommunicationsEnabled(value);
                }}
              />
            </div>
          )}

          <div
            className={
              "registration-form-bottom-text registration-form-disclaimer"
            }>
            By registering for this account and selecting 'Create an account' I
            agree to the{" "}
            <a
              className={"registration-form-disclaimer-link"}
              // TODO: We need to add the default Cecilian for now https://app.clickup.com/t/2242234/CBP-2616,
              // and https://app.clickup.com/t/2242234/CBP-2595 after it's ready to use first the tenant one
              // and if null, the default one.
              href="/cms-detail/privacy-policy"
              target="_blank"
              rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
            {/* TODO: Terms will be enabled later and needs to be returned from the API response, same as Privacy*/}
            {/* {` and associated `}
            <a
              className={"registration-form-disclaimer-link"}
              href="https://google.com"
              target={"_blank"}
              rel="noreferrer">
              Terms & Conditions.
            </a> */}
          </div>

          <div className={"registration-create-account"}>
            <Button
              text={"Create an account"}
              onClick={() => {
                props.onRegisterUser(componentProps.userData);
              }}
              isDisabled={!props.validForm || props.isPasswordResetProcessing}
              dataCy="create-account-button"
            />
          </div>
          {/* <div className={"registration-optional-social-text"}>
            Or Sign in with
          </div>
          <div className={"registration-social-buttons"}>
            <SocialButton
              text={"Apple"}
              isApple={true}
              isDisabled={!validFormSso}
              onClick={() => {
                handleSocialButtonClick({ isGoogle: false });
              }}
            />

            <SocialButton
              text={"Google"}
              isGoogle={true}
              isDisabled={!validFormSso}
              onClick={() => {
                handleSocialButtonClick({ isGoogle: true });
              }}
            />
          </div> */}
        </section>
      </div>
      <div className={"registration-right-content"}>
        <div
          className={"registration-image"}
          style={{
            backgroundImage: `url(${require("@Assets/images/lwr_registration.png")})`,
          }}>
          <Button
            type={"close"}
            onClick={() => {
              componentProps.onClose && componentProps.onClose();
            }}
          />
        </div>
      </div>
      {props.isPasswordResetProcessing && (
        <Loader text={"Processing, please wait"} modal />
      )}
    </div>
  );
};

export default Registration;
