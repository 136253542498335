import { useTenantConfig } from "@App/hooks";
import { getImageCarouselSlides } from "@Utils/utils";
import { useMemo } from "react";

export default function useSlidesFromFiles(files: any) {
  const tenantConfig = useTenantConfig();
  const defaultImagePlaceholder =
    tenantConfig?.features.defaultImagePlaceholder || "";

  const slides = useMemo(() => {
    return getImageCarouselSlides({
      files: files,
      placeholderUrl: defaultImagePlaceholder,
    });
  }, [files, defaultImagePlaceholder]);
  return slides;
}
