import { useSelector } from "react-redux";
import { RootState } from "@Store/store";
import { UserState } from "@App/store/reducers/userReducer";
import { useEffect, useState } from "react";
import { getResidentCenterSearchByGroupByTheme } from "@App/api/residentCenter";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_RESIDENT_CENTER_INFO } from "@App/constants/queryKeyConstants";
import { useNavigate } from "react-router-dom";
import { sendMobileViewModePostMessage } from "@Utils/utils";
import userSession from "@App/auth/userSession";

const ResidentCenterLogic = () => {
  const [isMobileEmbed] = useState(userSession.isMobileViewMode);
  const [showHouseServices, setShowHouseServices] = useState(false);
  const navigate = useNavigate();
  const storeData = useSelector<
    RootState,
    {
      storeUser: UserState;
    }
  >((state) => {
    return {
      storeUser: state.userProfile,
    };
  });
  const { storeUser } = storeData;
  const [searchText, setSearchText] = useState(String);

  const { data, isLoading } = useQuery(
    [QUERY_KEY_RESIDENT_CENTER_INFO],
    () => {
      if (storeUser.userInfo?.subCommunityId) {
        return getResidentCenterSearchByGroupByTheme({
          subCommunityId: storeUser.userInfo.subCommunityId,
          page: 1,
          pageSize: 100,
        });
      }
      return null;
    },
    {
      staleTime: Infinity,
      enabled: !!storeUser.userInfo?.subCommunityId,
    },
  );

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: isLoading });
  }, [isLoading]);

  useEffect(() => {
    console.log(!!storeUser.userInfo?.subCommunityId);
    if (data?.data?.items?.length) {
      setShowHouseServices(
        !!storeUser.userInfo?.subCommunityId && data?.data?.items?.length > 0,
      );
    }
  }, [storeUser, data?.data]);

  const onServiceOrLinkClick = ({
    id,
    link,
  }: {
    id?: string;
    link?: string;
  }) => {
    if (id) {
      navigate(`resident-center-service-details/${id}`);
    } else if (!!link) {
      window.open(link, "_blank");
    }
  };

  return {
    searchText,
    isMobileEmbed,
    data,
    isLoading,
    showHouseServices,
    setSearchText,
    onServiceOrLinkClick,
  };
};

export default ResidentCenterLogic;
