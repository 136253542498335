import { Chevron } from "@Components/icons/index";
import { EventItem, Loader } from "@Components/index";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import "./EventsList.scss";
import { EventsListProps } from "./EventsListPropTypes";

const SCROLL_AMOUNT = 500;

// TODO: Consider fetching next page before reaching the end of the list
// TODO: This could use more refactoring. The column view and the row view should be separated +
// the events are segmented by date, but not in state, so it's awkwardly done here with a list of labels +
// all of that is only needed for the column view.
const EventsList = (props: EventsListProps) => {
  // TODO: This is waaay too many props.
  const {
    title,
    titleIcon,
    events,
    selectedDate,
    isCard,
    horizontalScroll,
    notLoggedUser,
    isProcessing,
    hideLoadingStatus,
    hideDate,
    maxHeight,
    showDateInformation,
    showCardRSVP,
    showEventDateTitleAlways,
    showTimeOnly,
    showDateOnly,
    hideDateGroupTitle,
    inViewRef,
    hasNextPage,
    isFetchingEvents,
    onShowAllClick,
  } = props;

  const [showEventsLeftScrollButton, setShowEventsLeftScrollButton] =
    useState(false);
  const [showEventsRightScrollButton, setShowEventsRightScrollButton] =
    useState(false);

  const eventsRowType = useMemo(() => (isCard ? "favorites" : ""), [isCard]);

  useEffect(() => {
    const filterHtmlElement = document.getElementById(
      `events-list-wrapper-${eventsRowType}`,
    );

    if (filterHtmlElement) {
      setShowEventsRightScrollButton(
        filterHtmlElement.scrollWidth !== filterHtmlElement.clientWidth,
      );
    }
  }, [events]);

  const noEvents = !events || events.length === 0;

  if (!hideLoadingStatus && isProcessing) {
    return <Loader color={"#000"} />;
  }

  if (!hideLoadingStatus && !isProcessing && noEvents) {
    return (
      <div className={"event-list-header"}>
        <div
          className={"event-list-header-no-events"}
        >{`no events for the selected ${
          // Add a bool for if a single date is selected
          !!selectedDate ? "date" : "month"
        }`}</div>
        {onShowAllClick && (
          <button
            className={"event-list-show-all-events"}
            onClick={() => {
              onShowAllClick && onShowAllClick();
            }}
          >
            reset calendar
          </button>
        )}
      </div>
    );
  }

  if (noEvents && hideLoadingStatus) {
    return null;
  }

  const createdDateLabels: string[] = [];

  return (
    <>
      {(titleIcon || title) && (
        <div className={"events-list-title"}>
          {titleIcon && (
            <div className={"events-list-title-icon"}>{titleIcon}</div>
          )}
          {title && <span className={"events-list-title-text"}>{title}</span>}
        </div>
      )}
      <div
        className={`event-list`}
        {...(maxHeight && { style: { maxHeight: maxHeight } })}
      >
        <div className={"event-list-items"}>
          {isCard && (
            <div className={"event-list-items-scroll-control"}>
              <button
                className={`event-list-items-scroll-control-button event-list-items-scroll-control-button-left ${
                  showEventsLeftScrollButton
                    ? ""
                    : "event-list-items-scroll-control-button-hidden"
                }
                `}
                onClick={() => {
                  const el = document.getElementById(
                    `events-list-wrapper-${eventsRowType}`,
                  );
                  if (el) {
                    el.scrollLeft -= 500;
                    setShowEventsLeftScrollButton(
                      el.scrollLeft - SCROLL_AMOUNT > 0,
                    );
                  }
                }}
              >
                <Chevron position={"left"} size={15} color={"#323232"} />
              </button>
            </div>
          )}
          <div
            id={`events-list-wrapper-${eventsRowType}`}
            onWheel={() => {
              const el = document.getElementById(
                `events-list-wrapper-${eventsRowType}`,
              );

              if (el) {
                setShowEventsLeftScrollButton(el.scrollLeft > 0);
              }
            }}
            className={`events-list-items-wrapper ${
              horizontalScroll ? "events-list-items-wrapper-inline" : ""
            }`}
            data-cy="events-list-wrapper"
          >
            {events?.map((event, index) => {
              let showLabel = false;
              const isLast = events?.length === index + 1;

              const eventDate = new Date(event.startDate);
              const eventDateShort = format(eventDate, "P");
              const isToday = format(new Date(), "P") === eventDateShort;
              const dateHasCreatedLabel =
                createdDateLabels.includes(eventDateShort);

              if (
                !hideDateGroupTitle &&
                (showEventDateTitleAlways || (!isCard && !dateHasCreatedLabel))
              ) {
                createdDateLabels.push(eventDateShort);
                showLabel = true;
              }

              return (
                <div
                  key={`event_item_${index}`}
                  className={`event-list-item-container ${
                    isCard && isLast ? "events-list-item-last" : ""
                  }`}
                >
                  {showLabel && (
                    <div className={"event-list-item-header"}>
                      <div className={"event-list-item-date-label"}>
                        {isToday ? "Today" : `${format(eventDate, "EEEE do")}`}
                      </div>
                      {index === 0 && !!selectedDate && onShowAllClick && (
                        <button
                          className={"event-list-item-show-all-events"}
                          onClick={() => {
                            onShowAllClick();
                          }}
                        >
                          reset calendar
                        </button>
                      )}
                    </div>
                  )}
                  <EventItem
                    event={event}
                    isCard={isCard}
                    isToday={isToday}
                    ignoreAttending={false}
                    showLabel={showLabel && !hideDate}
                    notLoggedUser={notLoggedUser}
                    showDateInformation={showDateInformation}
                    showCardRSVP={showCardRSVP}
                    showTimeOnly={showTimeOnly}
                    showDateOnly={showDateOnly}
                    isFetchingEvents={isFetchingEvents}
                  />
                </div>
              );
            })}
            {hasNextPage && (
              <Loader
                inViewRef={inViewRef}
                color={"#000"}
                verticalCentered={isCard}
                horizontallyCentered={!isCard}
              />
            )}
          </div>
          {isCard && showEventsRightScrollButton && (
            <div className={"event-list-items-scroll-control"}>
              <button
                className={`event-list-items-scroll-control-button`}
                onClick={() => {
                  const el = document.getElementById(
                    `events-list-wrapper-${eventsRowType}`,
                  );
                  if (el) {
                    el.scrollLeft += SCROLL_AMOUNT;
                    setShowEventsLeftScrollButton(
                      el.scrollWidth > el.clientWidth,
                    );
                  }
                }}
              >
                <Chevron position={"right"} size={15} color={"#323232"} />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EventsList;
