import { Tag } from "@Components/index";
import { Chevron } from "@Components/icons";
import moment from "moment";
import { NotificationItemProps } from "./NotificationItemProps";
import useNotificationItemLogic from "./NotificationItemLogic";
import "./NotificationItem.scss";

import { IconButton } from "@Components/index";

const NotificationItem: React.FC<NotificationItemProps> = (props) => {
  const {
    notification,
    canExpand,
    isMenuItem,
    maxWidth,
    isExpanded,
    setIsExpanded,
    handleIsReadClickAsync,
    handleIsStarredClickAsync,
    handleDeleteAsync,
  } = useNotificationItemLogic(props);

  const date = moment(notification.createDate).format("MMMM Do");

  return (
    <div
      className={`notification-item ${
        notification?.userDetails?.isRead ? "" : "notification-item__unread"
      }`}
      onClick={() => handleIsReadClickAsync()}>
      <div className="notification-item__header">
        <h2 className="notification-item__title">{notification.title}</h2>
        {canExpand && notification.message?.length > 164 && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`notification-item__expand ${
              isExpanded ? "expanded" : ""
            }`}>
            <Chevron color="#323232" position="down" size={20} />
          </button>
        )}
        {!isMenuItem && <span className="notification-item__date">{date}</span>}
      </div>
      <div
        style={{ maxWidth: `${maxWidth ? maxWidth : ""}` }}
        className={`notification-item__body ${isExpanded ? "expanded" : ""}`}
        dangerouslySetInnerHTML={{ __html: notification.message }}></div>
      <div className="notification-item__footer">
        {notification.category && (
          <Tag text={notification.category} size="small" />
        )}
        <div className="notification-item__footer__container">
          {!isMenuItem && (
            <IconButton
              iconId="star"
              isActive={notification?.userDetails?.isStarred}
              onClickAsync={handleIsStarredClickAsync}
            />
          )}

          {!isMenuItem && (
            <IconButton
              iconId="delete"
              isActive={notification?.userDetails?.isStarred}
              onClickAsync={handleDeleteAsync}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
