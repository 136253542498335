import "./Enhanced3ColumnFooterTemplate.scss";
import {
  TenantAddress,
  TenantAmenitiesStatus,
  TenantFooterLinks,
  TenantLogo,
  TenantMobileAppLinks,
  TenantSubscribe,
  TenantCopyright,
  VersionNumber,
} from "@Components/index";

const Enhanced3ColumnFooterTemplate = () => {
  return (
    <>
      <div className={"enhanced-3-column-footer-template"}>
        <TenantLogo />
        <TenantFooterLinks />
        <TenantAddress />
        <TenantCopyright />
        <TenantSubscribe />
        <TenantAmenitiesStatus />
        <TenantMobileAppLinks />
      </div>
      <VersionNumber />
    </>
  );
};

export default Enhanced3ColumnFooterTemplate;
