import { baseServiceRequest } from "./baseService";
import { AxiosResponse } from "axios";
import { Waiver } from "@App/models/waiver";

export const getWaiverById = async (waiverId: string) => {
  return (await baseServiceRequest({
    path: `waiver/${waiverId}`,
    type: "get",
    useAuthHeader: true,
  })) as AxiosResponse<Waiver, any>;
};

export const addWaiver = async (data: any) => {
  const { eventId, waiverId, userId } = data;
  return (await baseServiceRequest({
    path: `/event/${eventId}/waiver`,
    type: "post",
    useAuthHeader: true,
    postData: { waiverId, userId, id: eventId },
  })) as AxiosResponse<any, any>;
};
