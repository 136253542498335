import { updateEventPreference } from "@App/api/event";
import { EventUserPreference } from "@App/api/requests/events";
import { SHOW_RESULT_BANNER } from "@App/constants/appConstants";
import {
  QUERY_KEY_EVENT_DETAILS,
  QUERY_KEY_EVENTS_SEARCH,
  QUERY_KEY_USER_EVENT_LIST,
} from "@App/constants/queryKeyConstants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { customEventPublish } from "@Utils/utils";
import { UserPreferenceMutationParams } from "./UserPreferenceMutationParams";

type UseUpdateEventUserPreferenceParams = {
  eventTitle: string;
  onMutate: (params: UserPreferenceMutationParams) => void;
  onSettled?: (params: UserPreferenceMutationParams) => void;
  onSuccess?: (params: UserPreferenceMutationParams) => void;
};

export default function useUpdateEventUserPreference({
  eventTitle,
  onMutate,
  onSettled,
  onSuccess,
}: UseUpdateEventUserPreferenceParams) {
  const queryClient = useQueryClient();

  const preferenceMutation = useMutation({
    mutationFn: async (mutationParams: UserPreferenceMutationParams) => {
      const preference: EventUserPreference = {
        [mutationParams.preference.name]: mutationParams.preference.value,
      };

      return await updateEventPreference({
        eventId: mutationParams.eventId,
        userId: mutationParams.userId,
        preference,
      });
    },
    onMutate: onMutate,
    onSettled: (data, error, mutationParams) => {
      onSettled && onSettled(mutationParams);
    },
    onSuccess: (data, mutationParams) => {
      renderToast(eventTitle);
      onSuccess && onSuccess(mutationParams);

      queryClient.invalidateQueries([QUERY_KEY_EVENTS_SEARCH]);
      queryClient.invalidateQueries([QUERY_KEY_USER_EVENT_LIST]);
      queryClient.invalidateQueries([
        QUERY_KEY_EVENT_DETAILS,
        mutationParams.eventId,
      ]);
    },
  });

  return preferenceMutation;
}

function renderToast(eventTitle: string) {
  const action = eventTitle === "isRSVPd" ? "RSVP'd" : "favorited";

  // Refactor to promise toast
  customEventPublish(SHOW_RESULT_BANNER, `You've ${action} ${eventTitle}`);
}
