import { GenericPageItem } from "@App/models/BasePagedResponse";
import { flexRender, Row, Table } from "@tanstack/react-table";
import { memo } from "react";
import "./Table.scss";

function TableBody<TData>({
  table,
  resizeSpeed,
  highlightOnHover,
  pointerOnHover,
  onClick,
}: {
  table: Table<GenericPageItem<TData>>;
  resizeSpeed: number;
  highlightOnHover?: boolean;
  pointerOnHover?: boolean;
  onClick?: (row: Row<GenericPageItem<TData>>) => void;
}) {
  const highlightClass = highlightOnHover ? `table-body-row-highlight` : "";

  return (
    <tbody className={"table-body"}>
      {table.getRowModel().rows.map((row) => {
        const empty = row.original?.empty;
        return (
          <tr
            key={row.id}
            className={`table-body-row ${highlightClass} ${
              empty
                ? ""
                : `${!!onClick && !empty && pointerOnHover ? "cursor-pointer" : ""}`
            }`}
            onClick={() => !!onClick && !empty && onClick(row)}
          >
            {row.getVisibleCells().map((cell) => {
              return (
                <td
                  key={cell.id}
                  className={`table-body-row-cell`}
                  style={{
                    width: `var(--col-${cell.column.id}-size)`,
                  }}
                >
                  {empty
                    ? null
                    : flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

// Memoized during resizing to prevent expensive re-renders
const MemoizedTableBody = memo(
  TableBody,
  (prevProps, nextProps) =>
    prevProps.table.options.data === nextProps.table.options.data,
) as typeof TableBody;

export { MemoizedTableBody, TableBody };
