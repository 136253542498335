import "./PasswordReset.scss";
import {
  Button,
  LinkifyText,
  Loader,
  Modal,
  TextInput,
} from "@Components/index";
import { Warning } from "@Components/icons";
import usePasswordResetLogic from "./PasswordResetLogic";
import { PasswordResetProps } from "./PasswordResetProps";

const PasswordReset = (componentProps: PasswordResetProps) => {
  const {
    password,
    passwordConfirmation,
    validPassword,
    validPasswordConfirmation,
    validForm,
    isPasswordResetProcessing,
    errorMessage,
    setPassword,
    setPasswordConfirmation,
    updatePassword,
    oldPassword,
    setOldPassword,
  } = usePasswordResetLogic(componentProps);
  return (
    <Modal
      onClose={componentProps.onClose}
      isVisible={!!componentProps.isVisible}>
      <div className={"password-reset"}>
        <div className={"password-reset-form"}>
          <header className={"password-reset-form-header"}>
            <div className={"password-reset-form-header-top"}>
              <div className={"password-reset-form-header-top-title"}>
                Change Password
              </div>
              <Button
                onClick={() =>
                  componentProps.onClose && componentProps.onClose()
                }
                type={"close"}
                style={{ color: "#000" }}
              />
            </div>

            {(componentProps.resetPasswordError || !!errorMessage) && (
              <div className={"password-reset-error-banner"}>
                <Warning className="password-reset-error-banner-icon" />
                <div>
                  <LinkifyText
                    text={
                      errorMessage
                        ? errorMessage
                        : "There was an error resetting your password please try again."
                    }
                  />
                </div>
              </div>
            )}
          </header>
          <section className={"password-reset-form-fields"}>
            <TextInput
              type={"password"}
              label={"Enter Current Password"}
              maxLength={50}
              withMargin
              required
              value={oldPassword}
              onChange={setOldPassword}
              isValid={validPassword}
              errorMessage={
                "The password must contain at least 8 characters, upper and lowercase letters, numerical value and special character."
              }
            />

            <TextInput
              type={"password"}
              label={"Enter New Password"}
              maxLength={50}
              withMargin
              required
              value={password}
              onChange={setPassword}
              isValid={validPasswordConfirmation}
              errorMessage={
                "Passwords must contain at least 8 characters, upper and lowercase letters, numerical value and special character."
              }
            />
            <TextInput
              type={"password"}
              label={"Re-enter New Password"}
              maxLength={50}
              withMargin
              required
              value={passwordConfirmation}
              onChange={setPasswordConfirmation}
              isValid={validPasswordConfirmation}
              errorMessage={
                "Passwords should match and must contain at least 8 characters, upper and lowercase letters, numerical value and special character."
              }
            />
            <div className="card-actions">
              <Button
                style={{
                  width: "fit-content",
                  height: "45px",
                  padding: "5px 20px",
                }}
                text="Cancel"
                onClick={() =>
                  componentProps.onClose && componentProps.onClose()
                }
                isSecondary
              />
              <Button
                style={{
                  width: "fit-content",
                  height: "45px",
                  padding: "5px 20px",
                }}
                text="Save"
                onClick={updatePassword}
                isDisabled={!validForm || isPasswordResetProcessing}
              />
            </div>
          </section>
        </div>
        {isPasswordResetProcessing && (
          <Loader text={"Processing, please wait"} modal />
        )}
      </div>
    </Modal>
  );
};

export default PasswordReset;
