import { registerForUpdates } from "@App/api/user";
import { EmailRegx } from "@Utils/utils";
import { FormEvent, useMemo, useState } from "react";

export const TenantSubscribeLogic = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

  const [formState, setFormState] = useState({
    firstName: {
      value: "",
      touched: false,
    },
    lastName: {
      value: "",
      touched: false,
    },
    email: {
      value: "",
      touched: false,
    },
  });

  const formErrors = useMemo(() => {
    const { firstName, lastName, email } = formState;

    const errors: {
      firstName?: string;
      lastName?: string;
      email?: string;
      formFilled?: string;
    } = {};

    if (firstName.touched && !firstName.value) {
      errors.firstName = "First name is required";
    }

    if (lastName.touched && !lastName.value) {
      errors.lastName = "Last name is required";
    }

    if (email.touched && !email.value) {
      errors.email = "Email is required";
    }

    if (email.touched && !EmailRegx.test(email.value)) {
      errors.email = "Please enter a valid email address";
    }

    if (!firstName.value || !lastName.value || !email.value) {
      errors.formFilled = "Please fill out all fields";
    }

    // This was supposed to return the object, but individual error messages mess with the layout
    return (
      errors.firstName ??
      errors.lastName ??
      errors.email ??
      (hasAttemptedSubmit && errors.formFilled) ??
      undefined
    );
  }, [formState, hasAttemptedSubmit]);

  const hasTouchedAllFields = useMemo(() => {
    return !Object.entries(formState).some(([key, value]) => !value.touched);
  }, [formState]);

  const isValid = useMemo(() => {
    if (!formErrors && hasTouchedAllFields) {
      setButtonIsDisabled(false);
      return true;
    }

    setButtonIsDisabled(true);
    return false;
  }, [formErrors, hasTouchedAllFields]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) {
      setHasAttemptedSubmit(true);
      return;
    }

    const bodyData = {
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      email: formState.email.value,
    };

    setHasSubmitted(true);
    setButtonIsDisabled(true);
    const res = await registerForUpdates(bodyData);

    if (res.status >= 400) {
      // Could show a different message.
      return;
    }
  };

  return {
    hasSubmitted,
    isValid,
    buttonIsDisabled,
    formState,
    setFormState,
    formErrors,
    handleSubmit,
  };
};
