import React from "react";
import {
  Flag,
  Heart,
  StarFill,
  StarOutline,
  Trashcan,
} from "@Components/icons";
import { Loader } from "@Components/index";
import { IconButtonProps } from "./IconButtonProps";
import useIconButtonLogic from "./IconButtonLogic";

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const { isProcessingClick, handleClickAsync } = useIconButtonLogic(props);

  const renderIcon = () => {
    switch (props.iconId) {
      case "delete":
        return <Trashcan color="#C4C4C4" />;
      case "flag":
        // TODO: Figure out consistent way to handle color and fill on icons.
        //       The Flag doesn't seem to use fill.
        //const fillColor = props.isActive ? "#3da9ff" : "#C4C4C4";
        return (
          <Flag
            color={props.isActive ? "#3da9ff" : "#C4C4C4"}
            {...(props.isActive && {
              fill: "#3da9ff",
            })}
          />
        );
      case "star":
        return props.isActive ? (
          <StarFill color="#3da9ff" fill="#3da9ff" />
        ) : (
          <StarOutline color="#C4C4C4" />
        );
      case "heart":
        return <Heart isActive={props.isActive} />;
      default:
        return <div>Invalid icon id '{props.iconId}'</div>;
    }
  };

  return (
    <button
      className={`${props.iconId}-button`}
      onClick={() => handleClickAsync()}>
      {isProcessingClick ? (
        <Loader color={"silver"} horizontallyCentered width="20" height="10" />
      ) : (
        renderIcon()
      )}
    </button>
  );
};
export default IconButton;
