import { tenantConfigGet } from "@App/api/general";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { Tenant } from "@App/models/tenant";
import { useQuery } from "@tanstack/react-query";

const ProfileUpdateLogic = () => {
  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });
  return { tenantConfig };
};

export default ProfileUpdateLogic;
