export const EVENTS_REQUEST_GET = "EVENTS_GET_REQUEST";
export const EVENTS_REQUEST_SUCCESS = "EVENTS_SUCCESS_REQUEST";
export const EVENTS_REQUEST_FAIL = "EVENTS_FAIL_REQUEST";

export const EVENT_CATEGORIES_REQUEST_GET = "EVENT_CATEGORIES_REQUEST_GET";
export const EVENT_CATEGORIES_REQUEST_SUCCESS =
  "EVENT_CATEGORIES_REQUEST_SUCCESS";
export const EVENT_CATEGORIES_REQUEST_FAIL = "EVENT_CATEGORIES_REQUEST_FAIL";

export const UPDATE_EVENT_PREFERENCES_REQUEST_GET =
  "UPDATE_EVENT_PREFERENCES_REQUEST_GET";
export const UPDATE_EVENT_PREFERENCES_REQUEST_SUCCESS =
  "UPDATE_EVENT_PREFERENCES_REQUEST_SUCCESS";
export const UPDATE_EVENT_PREFERENCES_REQUEST_FAIL =
  "UPDATE_EVENT_PREFERENCES_REQUEST_FAIL";

export const UPCOMING_EVENTS_REQUEST_GET = "UPCOMING_EVENTS_REQUEST_GET";
export const UPCOMING_EVENTS_REQUEST_SUCCESS =
  "UPCOMING_EVENTS_REQUEST_SUCCESS";
export const UPCOMING_EVENTS_REQUEST_FAIL = "UPCOMING_EVENTS_REQUEST_FAIL";

export const DEFAULT_EVENTS_PAGE_SIZE = 10;
