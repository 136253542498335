import "./VersionNumber.scss";
import VersionNumberLogic from "./VersionNumberLogic";
import { config } from "@App/config/config";
import { DevMenu } from "@Components/index";

const VersionNumber = () => {
  const props = VersionNumberLogic();
  return (
    <div className={"version-number"}>
      <div
        onClick={() => {
          props.onFooterVersionClick();
        }}>
        Version: <strong>{process.env.REACT_APP_VERSION}</strong>
      </div>
      {config.devMenuEnabled && (
        <DevMenu
          showDevMenu={props.showDevMenu}
          onClose={props.onDevMenuClose}
        />
      )}
    </div>
  );
};

export default VersionNumber;
