import { Reservation } from "@App/models/reservation";
import { baseServiceRequest } from "./baseService";
import {
  UserReservationsPagedRequest,
  UserReservationsPagedResponse,
} from "@Api/requests/reservations";

export const getReservationDetails = async (reservationId: string) => {
  return await baseServiceRequest<Reservation>({
    path: `/reservation/${reservationId}`,
    type: "get",
    useAuthHeader: true,
  });
};

export const getTemporaryReservationDetails = async (reservationId: string) => {
  return await baseServiceRequest<Reservation>({
    path: `/reservation/${reservationId}/Temporary`,
    type: "get",
    useAuthHeader: true,
  });
};

export const getUserReservations = async (
  params: UserReservationsPagedRequest,
) => {
  const unwoundParams = {
    ...params.body.pageParams,
    ...params.body.sortOptions,
    ...params.body.filterOptions,
  };
  return await baseServiceRequest<UserReservationsPagedResponse>({
    path: `/reservation/user`,
    type: "get",
    useAuthHeader: true,
    params: unwoundParams,
  });
};
