import { presignedUrlGet, uploadGenericFile } from "@App/api/file";
import {
  addManyUserMembershipFiles,
  createUserMembership,
} from "@App/api/memberships";
import { CreateUserMembershipRequest } from "@App/api/requests/memberships";
import { MEMBERSHIP_DOGS } from "@App/constants/appConstants";
import { Membership } from "@App/models/membership";
import { PresignedUrl } from "@App/models/PresignedUrl";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";
import { Dog } from "../membership-dog-row/MembershipDogRowPropTypes";

type UseCreateUserMembershipParams = {
  localStorageBlob: {
    dogs: Dog[];
    preferredPark?: { id: string; title: string };
    membership: Membership;
  };
  adminEmail: string;
  uploadedFiles: PresignedUrl[];
  setUploadedFiles: Dispatch<SetStateAction<PresignedUrl[]>>;
  setIsProcessing: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string | null>>;
  goToNextStep: (userMembershipId: string) => void;
};

export default function useCreateUserMembershipMutations({
  localStorageBlob: { dogs, preferredPark, membership },
  adminEmail,
  uploadedFiles,
  setUploadedFiles,
  setIsProcessing,
  setError,
  goToNextStep,
}: UseCreateUserMembershipParams) {
  const getPresignedUrlMutation = useMutation({
    mutationFn: (file: File) => presignedUrlGet(file.name, file.type),
    onSuccess: (data, file) => {
      setUploadedFiles((prev) => [...prev, data.data]);
      uploadImageToS3Mutation.mutate({
        file,
        presignedUrl: data.data,
      });
    },
    onError: (error, file) => {
      setError(
        `There was an error uploading one of your images. File name: ${file.name} Please make sure the image is a valid format. If the issue persists, please contact us at ${adminEmail}`,
      );
      setIsProcessing(false);
    },
  });

  const uploadImageToS3Mutation = useMutation({
    mutationFn: async (params: { file: File; presignedUrl: PresignedUrl }) => {
      const { file, presignedUrl } = params;
      return await uploadGenericFile(
        {
          presignedUrl: presignedUrl.preSignedURL,
          file: file,
        },
        file.type,
      );
    },
    onError: (error, params) => {
      setError(
        `There was an error uploading one of your images. File name: ${params.file.name} Please make sure the image is a valid format. If the issue persists, please contact us at ${adminEmail}`,
      );
      setIsProcessing(false);
    },
  });

  const createUserMembershipMutation = useMutation({
    mutationFn: (params: CreateUserMembershipRequest) =>
      createUserMembership(params),
    onMutate: () => {
      localStorage.setItem(
        MEMBERSHIP_DOGS,
        JSON.stringify({
          dogs,
          preferredPark: preferredPark?.title,
          title: membership.title,
          files: membership.files,
        }),
      );
    },
    onSuccess: (data) => {
      const userMembershipId = data.data;

      attachFilesToUserMembershipMutation.mutate(userMembershipId);
      goToNextStep(userMembershipId);
    },
    onError: (error: AxiosError<any>) => {
      if (error?.response?.status === 409) {
        setError(
          `It appears that you already have an existing membership application associated with your profile. 
          Please contact us at ${adminEmail} for additional memberships.`,
        );
      } else {
        setError(
          `There was a problem wrapping up your membership application. Please contact us at ${adminEmail} for help with your membership`,
        );
      }
      setIsProcessing(false);
    },
  });

  const attachFilesToUserMembershipMutation = useMutation({
    mutationFn: (userMembershipId: string) => {
      const body = uploadedFiles.map((file) => {
        return {
          id: file.id,
          title: file.fileName,
        };
      });
      return addManyUserMembershipFiles(userMembershipId, body);
    },
    onSettled: () => {
      setIsProcessing(false);
    },
    onError: (error) => {
      console.error("Failed to attach files to user membership", error);
      setError(
        `There was a problem wrapping up your membership application. Please contact us at ${adminEmail} for help with your membership`,
      );
    },
  });

  return {
    getPresignedUrlMutation,
    createUserMembershipMutation,
  };
}
