import { IconProps } from "../iconTypes";

const Grading = ({ color = "#5F666D", isActive }: IconProps) => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4.5H16V6.5H0V4.5ZM0 10.5H16V8.5H0V10.5ZM0 14.5H7V12.5H0V14.5ZM0 18.5H7V16.5H0V18.5ZM11.41 15.67L10 14.25L8.59 15.66L11.41 18.5L16 13.92L14.58 12.5L11.41 15.67ZM0 0.5V2.5H16V0.5H0Z"
        fill={isActive ? "#000" : color}
      />
    </svg>
  );
};

export default Grading;
