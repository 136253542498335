import "./TenantMobileAppLinks.scss";
import { Image } from "@Components/index";
import { useTenantFooter } from "@App/hooks";

const TenantMobileAppLinks = () => {
  const { tenantFooter } = useTenantFooter();
  if (!tenantFooter?.mobileDownload) return null;
  return (
    <div className={"tenant-mobile-app-links"}>
      <span className={"tenant-mobile-app-links-title"}>
        {tenantFooter.mobileDownload.sectionTitle}
      </span>
      <div className={"tenant-mobile-app-links-images"}>
        <a
          href={tenantFooter.mobileDownload.googleStoreUrl}
          target={"_blank"}
          rel="noreferrer">
          <Image
            src={require("@Assets/images/googleplay.png")}
            alt={"google-play-store"}
            width={"140px"}
          />
        </a>
        <a
          href={tenantFooter.mobileDownload.appleStoreUrl}
          target={"_blank"}
          rel="noreferrer">
          <Image
            src={require("@Assets/images/applestore.png")}
            alt={"apple-store"}
            width={"140px"}
          />
        </a>
      </div>
    </div>
  );
};

export default TenantMobileAppLinks;
