import {
  Modal,
  Button,
  LabelWithValue,
  TextInput,
  DateCalendarSelect,
  LinkifyText,
} from "@Components/index";
import "./EditProfile.scss";
import EditProfileLogic from "./EditProfileLogic";
import { EditProfileProps } from "./EditProfilePropTypes";
import { formatPhoneNumber } from "@Utils/utils";
import { config } from "@App/config/config";

const EditProfile = (props: EditProfileProps) => {
  const {
    userPhone,
    address1,
    address2,
    city,
    state,
    zip,
    firstName,
    lastName,
    birthDayDate,
    tenantConfig,
    setBirthDayDate,
    setUserPhone,
    handleSave,
  } = EditProfileLogic(props);
  return (
    <Modal
      isVisible={props.isVisible}
      onClose={() => {
        props.onClose && props.onClose();
      }}>
      <div className="edit-profile-modal">
        <div className="card">
          <div className="card-header__close">
            <Button
              onClick={() => props.onClose && props.onClose()}
              type={"close"}
              style={{ color: "#000" }}
            />
          </div>
          <h2 className="card-title">Edit Profile</h2>
          <div className="card-content">
            <div className="card-content-row">
              <div className="edit-profile-avatar">
                <img src={require("@Assets/images/avatar.png")} alt={""} />
                <button
                  className="edit-profile-avatar__editButton"
                  onClick={() => alert("TBD")}>
                  {"(Edit Photo)"}
                </button>
              </div>
            </div>
            <div className="card-content-row">
              <LabelWithValue useBold label="First Name" value={firstName} />
              <LabelWithValue useBold label="Last Name" value={lastName} />
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="spacer"></div>
            </div>
            <div className="card-content-row">
              <LabelWithValue useBold label="Address 1" value={address1} />
              <LabelWithValue useBold label="Address 2" value={address2} />
              <LabelWithValue useBold label="City" value={city} />
              <LabelWithValue useBold label="State" value={state} />
              <LabelWithValue useBold label="Zip" value={zip} />
            </div>

            <div className="card-content-row input-row">
              <TextInput
                type={"text"}
                label={"Phone"}
                placeholder={"Enter Phone Number"}
                maxLength={20}
                withMargin
                value={formatPhoneNumber(userPhone)}
                onChange={setUserPhone}
                errorMessage={"Please a valid phone number"}
              />
              <div className="date-calendar-container">
                <DateCalendarSelect
                  label={"Select Birthday"}
                  initialDate={birthDayDate}
                  placeholder={"Select Birthday"}
                  useIcon
                  onChange={(date) => {
                    if (date) {
                      setBirthDayDate(date);
                    }
                  }}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="card-content-row">
              <p className="bottom-text">
                <LinkifyText
                  text={`To update locked profile information (Name and Address) please contact ${
                    tenantConfig?.cmsSettings?.websiteSettings?.adminEmail ??
                    config.defaultAdminEmail
                  }`}
                />
              </p>
            </div>
          </div>
          <div className="card-footer">
            <Button
              style={{ width: "fit-content", height: "56px" }}
              text="Cancel"
              onClick={() => props.onClose && props.onClose()}
              isSecondary
            />
            <Button
              style={{ width: "fit-content", height: "56px" }}
              text="Save"
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfile;
