import { Image } from "@Components/index";
import { useTenantFooter } from "@App/hooks";
import "./TenantLogo.scss";

const TenantLogo = () => {
  const { tenantFooter } = useTenantFooter();
  if (!tenantFooter?.tenantImageUrl) return null;

  return (
    <div className={"tenant-logo"}>
      <Image src={tenantFooter?.tenantImageUrl} />
    </div>
  );
};

export default TenantLogo;
