import { Loader } from "@aws-amplify/ui-react";
import Dropdown from "@Components/dropdown/Dropdown";
import { ChevronLeft, ChevronRight } from "@Components/icons";
import { Table } from "@tanstack/react-table";
import { PaginationPageSizes } from "@App/constants/pageParamConstants";

type PaginationControlsV2Props<TData> = {
  table: Table<TData>;
  isFetching?: boolean;
};

// TODO: Add first and last page buttons
export default function PaginationControlsV2<TData>({
  table,
  isFetching,
}: PaginationControlsV2Props<TData>) {
  const { pageIndex, pageSize } = table.getState().pagination;
  const totalItems = table.getRowCount();

  const start = pageIndex * pageSize + 1;
  const end = Math.min((pageIndex + 1) * pageSize, totalItems);

  return (
    <div className="table-pagination">
      <div className="table-pagination-dropdown-container">
        <span className="table-pagination-dropdown-container-text">
          Rows per page:
        </span>
        <Dropdown
          items={PaginationPageSizes}
          initialValue={pageSize.toString()}
          borderStyle="none"
          paddingStyle={1}
          fontColor="#212B36"
          fontSize={16}
          topMenu
          onChange={(selectedItem) => {
            table.setPageSize(Number(selectedItem.value));
          }}
        />
      </div>
      <PageStats
        start={start}
        end={end}
        total={totalItems}
        isLoading={!!isFetching}
      />
      <div
        className="cursor-pointer"
        onClick={() => {
          if (table.getCanPreviousPage()) {
            table.setPageIndex(pageIndex - 1);
          }
        }}
      >
        <ChevronLeft opacity={table.getCanPreviousPage() ? 1 : 0.3} />
      </div>
      <div
        className="cursor-pointer"
        onClick={() => {
          if (table.getCanNextPage()) {
            table.setPageIndex(pageIndex + 1);
          }
        }}
      >
        <ChevronRight opacity={table.getCanNextPage() ? 1 : 0.3} />
      </div>
    </div>
  );
}

type PageStatsProps = {
  start: number;
  end: number;
  total: number;
  isLoading: boolean;
};

function PageStats({ start, end, total, isLoading }: PageStatsProps) {
  if (isLoading) {
    return (
      <span className="text-base flex flex-row align-center">
        <span className="my-auto w-[6ch] text-center h-[24px]">
          <Loader
            // TODO: Update UI package to latest and remove this.
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        </span>
        <span>of {total}</span>
      </span>
    );
  }

  return <span className="text-base">{`${start} - ${end} of ${total}`}</span>;
}
