import {
  default as UserSession,
  default as userSession,
} from "@App/auth/userSession";
import { config } from "@App/config/config";
import { SentryLog } from "@App/models/SentryLog";
import { TenantTheme } from "@App/models/TenantTheme";
import { Event } from "@App/models/event";
import { Tenant } from "@App/models/tenant";
import themedStyles from "@Assets/styles/themedStyles.scss";
import { ImageCarouselSlide } from "@Components/image-carousel/ImageCarouselPropTypes";
import { PaymentContext } from "@Components/payments/PaymentsPropTypes";
import * as Sentry from "@sentry/react";
import * as DateFns from "date-fns";
import moment from "moment";

export const PasswordRegx =
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

export const EmailRegx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EmailSearchRegx =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))/gi;

export const PhoneRegx = /(?:[-+() ]*\d){10,13}/gi;

//https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
export const ZipRegx = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const fileExtensionRegx = /(?:\.([^.]+))?$/;

export const customEventSubscribe = (eventName: string, listener: any) => {
  document.addEventListener(eventName, listener);
};

export const customEventUnsubscribe = (eventName: string, listener: any) => {
  document.removeEventListener(eventName, listener);
};

export const customEventPublish = (eventName: string, data: any) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

export const getTime = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getUTCHours()}:${date.getUTCMinutes()}`;
};

export const isSameDay = (dateA: string, dateB: string): boolean => {
  return moment(dateA).format("YY/MM/DD") === moment(dateB).format("YY/MM/DD");
};

export const isSameMonth = (dateA: Date, dateB: Date): boolean => {
  return (
    `${dateA.getFullYear()}-${dateB.getMonth()}` ===
    `${dateB.getFullYear()}-${dateB.getMonth()}`
  );
};

export const formatThousands = (value: string | number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const getLocationRoutes = async ({
  accessToken,
  directions,
  transport = config.defaultDirectionsTransport, //https://docs.mapbox.com/api/navigation/directions/#route-step-object
  alternatives = false,
  steps = false,
}: {
  accessToken: string;
  directions: {
    origin: {
      latitude: number;
      longitude: number;
    };
    destination: {
      latitude: number;
      longitude: number;
    };
  };
  transport?: string;
  alternatives?: boolean;
  steps?: boolean;
}) => {
  const url = `${config.mapBoxDirectionsApi}/${transport}/${directions.origin.longitude},${directions.origin.latitude};${directions.destination.longitude},${directions.destination.latitude}?alternatives=${alternatives}&geometries=geojson&language=en&overview=full&&steps=${steps}&access_token=${accessToken}`;
  return fetch(url)
    .then((response) => response.json())
    .then((routes) => {
      return routes;
    })
    .catch((error) => console.log("Error while getting routes: ", error));
};

export const setSentryContext = () => {
  Sentry.setContext("CONTEXT DETAILS", {
    tags: {
      "cognito.username": UserSession.cognitoUsername ?? "Not Logged User",
      version: process.env.REACT_APP_VERSION,
    },
  });
};

export const sentryLog = (logData: SentryLog) => {
  Sentry.captureException(new Error(logData.message ?? "Error Log"), logData);
};

export const generateId = () => {
  return (Math.random() + 1).toString(36).substring(7);
};

export const getImageCarouselSlides = ({
  files,
  placeholderUrl,
}: {
  files: any;
  placeholderUrl: string;
}) => {
  let slides: ImageCarouselSlide[] = [];
  if (files && files.length > 0) {
    slides = Array.from(
      files.map((file: any) => {
        const hasImage = !!file.url;
        return {
          image: hasImage ? file.url?.replace(" ", "%20") : placeholderUrl,
        };
      }),
    );
  } else {
    slides.push({
      image: placeholderUrl,
    });
  }
  return slides;
};

//Communication between the apps (mobile React Native and web React).
export const sendMobileViewModePostMessage = (messageObject: object) => {
  const isMobileEmbed = userSession.isMobileViewMode;
  //Checking (window as any).ReactNativeWebView?.postMessage as well in case we try the web app as it's being loaded from mobile so it does not fail.
  if (isMobileEmbed && window.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(JSON.stringify(messageObject));
  }
};

export const formatCurrency = (value: any) => {
  const numericValue = typeof value === "number" ? value : 0;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(numericValue);
};

export const formatPercentage = (value: any) => {
  const numericValue = typeof value === "number" ? value : 0;
  return `${new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(numericValue)}%`;
};

export const setTenantTheme = (theme: TenantTheme, tenant: Tenant) => {
  Object.entries(theme).forEach(([key, value]) => {
    if (typeof value !== "object" && value !== null) {
      document.documentElement.style.setProperty(`--${key}`, value);
    }
  });

  setTenantFonts(theme, tenant);
};

const setTenantFonts = (theme: TenantTheme, tenant: Tenant) => {
  if (!theme.fontSettings) {
    return;
  }

  const style = document.createElement("style");
  const rootPath = `${tenant.awsSettings.assetStorageBaseURL}/Fonts`;

  style.textContent = `
  ${
    theme.fontSettings.primaryFilename &&
    `@font-face {
      font-family: ${themedStyles.fontFamilyPrimary};
      src: url('${rootPath}/${theme.fontSettings.primaryFilename}');
    }`
  }
  ${
    theme.fontSettings.primaryBoldFilename &&
    `@font-face {
      font-family: ${themedStyles.fontFamilyPrimaryBold};
      src: url('${rootPath}/${theme.fontSettings.primaryBoldFilename}');
    }`
  }
  ${
    theme.fontSettings.secondaryFilename &&
    `@font-face {
      font-family: ${themedStyles.fontFamilySecondary};
      src: url('${rootPath}/${theme.fontSettings.secondaryFilename}');
    }`
  }
  ${
    theme.fontSettings.secondaryBoldFilename &&
    `@font-face {
      font-family: ${themedStyles.fontFamilySecondaryBold};
      src: url('${rootPath}/${theme.fontSettings.secondaryBoldFilename}');
    }`
  }`;

  document.head.appendChild(style);
};

// If web, this will open the external url in another tab for web.
// If mobile, send the message to the native app to open the external
// with the in-app modal browser.
// mobile
export const handleExternalUrl = (externalUrl: string) => {
  if (userSession.isMobileViewMode) {
    sendMobileViewModePostMessage({
      type: "externalUrl",
      url: externalUrl,
    });
  } else {
    window.open(externalUrl, "_blank");
  }
};

export const toKebabCase = (str: string) => {
  if (!str) return str;
  return str
    .replace(/[^a-zA-Z\d\s:]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase();
};

export const goBackToHome = (navigate: any) => {
  if (userSession.isMobileViewMode) {
    sendMobileViewModePostMessage({ navigateToHome: true });
  } else {
    navigate("/");
  }
};

export const dateISOToLongLocal = (date: string) => {
  return DateFns.format(DateFns.parseISO(date), "PPPP");
};

// TODO: Remove PaymentContext when the app makes sense.
export const getEventDateOrRange = (
  event: Event | PaymentContext,
  single?: boolean,
) => {
  if (!event.startDate || !event.endDate) {
    return "";
  }
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
  };

  const startString = new Date(event.startDate).toLocaleDateString(
    [],
    dateOptions,
  );
  const endString = new Date(event.endDate).toLocaleDateString([], dateOptions);

  if (single || isSameDay(event.startDate, event.endDate)) {
    return startString;
  }

  return `${startString} - ${endString}`;
};

export const getEventTimeRange = (event: Event | PaymentContext) => {
  if (!event.startDate || !event.endDate) {
    return "";
  }
  const startTime = new Date(event.startDate).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const endTime = new Date(event.endDate).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${startTime} - ${endTime}`;
};
