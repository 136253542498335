import "./TenantCopyright.scss";
import { useTenantFooter } from "@App/hooks";

const TenantCopyright = () => {
  const { tenantFooter } = useTenantFooter();
  if (!tenantFooter?.copyright) return null;
  return (
    <div className={"tenant-copyright"}>
      <span>{`©Copyright ${new Date().getFullYear()} ${
        tenantFooter.copyright
      }`}</span>
      <span>{"All Rights Reserved"}</span>
    </div>
  );
};

export default TenantCopyright;
