import { useSelector } from "react-redux";
import { RootState } from "@Store/store";
import { UserState } from "@App/store/reducers/userReducer";
import { useEffect, useImperativeHandle, useMemo, useState } from "react";
import {
  getCommunityAssetAvailableDates,
  getCommunityAssetAvailableRatesAndTimes,
} from "@App/api/amenities";
import { useQuery, QueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  QUERY_KEY_USER_VENUE_SPACES_RATES,
  QUERY_KEY_VENUE_SPACE_AVAILABILITY,
} from "@App/constants/queryKeyConstants";
import { startOfMonth, endOfMonth, addWeeks, formatISO } from "date-fns";
import { Space } from "@App/models/amenity";
import { VenuesSpacesProps } from "./VenuesSpacesPropTypes";
import { buildPaymentData, findIsCTAButtonDisabled } from "../utils/utils";
import { useRateDropdowns } from "../utils/useRateDropdowns";

const VenuesSpacesLogic = (props: VenuesSpacesProps) => {
  const queryClient = new QueryClient();

  const { venueId } = useParams<{ venueId: string }>();

  const storeData = useSelector<
    RootState,
    {
      storeUser: UserState;
    }
  >((state) => {
    return {
      storeUser: state.userProfile,
    };
  });
  const { storeUser } = storeData;

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const [selectedSpace, setSelectedSpace] = useState<Space | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedRate, setSelectedRate] = useState<any>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<any>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<any>(null);

  const isUserLogged = useMemo(
    () => !!userData.userInfo?.firstName,
    [userData],
  );

  const userType = useMemo(() => userData.userInfo?.userType, [userData]);

  const isCTAButtonDisabled = findIsCTAButtonDisabled(
    isUserLogged,
    userType!,
    selectedRate,
    selectedStartTime,
    selectedEndTime,
  );

  const { data: availableRates, isLoading: isLoadingRates } = useQuery({
    queryKey: [
      QUERY_KEY_USER_VENUE_SPACES_RATES,
      selectedSpace?.id!,
      selectedDate!,
    ],
    queryFn: () =>
      getCommunityAssetAvailableRatesAndTimes(
        venueId!,
        formatISO(selectedDate!, {
          format: "extended",
          representation: "date",
        }),
        `Space/${selectedSpace?.id!}`,
      ),
    enabled: !!selectedDate && !!selectedSpace?.id,
    select: (data) => data.data,
  });

  const {
    calendarCurrentMonth,
    handleMonthChange,
    resetDropdowns,
    handleRateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    getRateOptions,
    getStartTimes,
    getEndTimes,
  } = useRateDropdowns({
    rates: availableRates?.rates,
    selectedRate,
    selectedStartTime,
    setSelectedRate,
    setSelectedStartTime,
    setSelectedEndTime,
  });

  const { data: availableDates } = useQuery({
    queryKey: [
      QUERY_KEY_VENUE_SPACE_AVAILABILITY,
      selectedSpace?.id!,
      calendarCurrentMonth,
    ],
    queryFn: () =>
      getCommunityAssetAvailableDates(
        venueId!,
        formatISO(addWeeks(startOfMonth(calendarCurrentMonth), -1), {
          format: "extended",
          representation: "date",
        }),
        formatISO(addWeeks(endOfMonth(calendarCurrentMonth), 1), {
          format: "extended",
          representation: "date",
        }),
        `Space/${selectedSpace?.id!}`,
      ),
    enabled: !!selectedSpace?.id,
  });

  useEffect(() => {
    if (selectedSpace?.id) {
      try {
        queryClient.invalidateQueries([
          QUERY_KEY_VENUE_SPACE_AVAILABILITY,
          calendarCurrentMonth!,
        ]);
        queryClient.invalidateQueries([
          QUERY_KEY_USER_VENUE_SPACES_RATES,
          selectedSpace?.id!,
        ]);
        resetDropdowns();
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeUser, selectedSpace, calendarCurrentMonth]);

  useEffect(() => {
    if (selectedSpace?.id) {
      try {
        queryClient.invalidateQueries([
          QUERY_KEY_VENUE_SPACE_AVAILABILITY,
          calendarCurrentMonth!,
        ]);
        queryClient.invalidateQueries([
          QUERY_KEY_USER_VENUE_SPACES_RATES,
          selectedSpace?.id!,
        ]);
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeUser, selectedSpace, calendarCurrentMonth]);

  useImperativeHandle(props.reference, () => ({
    collapseSpaceBookingDetails() {
      resetDropdowns();
      setSelectedSpace(null);
    },
  }));

  const onSpaceChange = (space: Space) => {
    props.onShowBookingOptions();
    setSelectedSpace(space);
  };

  const spacePaymentData = useMemo(() => {
    return buildPaymentData({
      venueId: venueId!,
      space: selectedSpace,
      bookingData: {
        rateId: selectedRate?.value,
        rateName: selectedRate?.rateName,
        selectedDate: selectedDate?.toISOString()!,
        startTime: selectedStartTime?.value,
        endTime: selectedEndTime?.value,
        cost: selectedEndTime?.cost,
      },
    });
  }, [
    venueId,
    selectedSpace,
    selectedDate,
    selectedRate,
    selectedStartTime,
    selectedEndTime,
  ]);

  return {
    selectedSpace,
    setSelectedSpace,
    selectedDate,
    setSelectedDate,

    selectedRate,
    selectedStartTime,
    selectedEndTime,

    availableDates,

    isUserLogged,
    isLoadingRates,
    isCTAButtonDisabled,

    userType,
    spacePaymentData,

    handleMonthChange,
    handleRateChange,
    handleStartTimeChange,
    handleEndTimeChange,

    getRateOptions,
    getStartTimes,
    getEndTimes,
    resetDropdowns,
    onSpaceChange,
  };
};

export default VenuesSpacesLogic;
