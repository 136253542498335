import { Navbar } from "@Layouts/index";
import "./VenuesDetail.scss";
import {
  Loader,
  ImageFilesCarousel,
  CalendarSelect,
  DropdownCheckbox,
  Button,
  Breadcrumb,
  Payments,
  BackButton,
  GenericModal,
} from "@Components/index";
import VenuesDetailLogic from "./VenuesDetailLogic";
import VenuesSpaces from "./venues-spaces/VenuesSpaces";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const VenuesDetail = () => {
  const props = VenuesDetailLogic();
  const childref = useRef<any>();
  const { t } = useTranslation(); 

  return (
    <Navbar>
      {props.isContentLoading ? (
        <Loader
          text={"Loading, please wait"}
          color={"#000"}
          fontColor={"#000"}
          horizontallyCentered
          padding={"100px 20px"}
        />
      ) : props.showVenueBookingPayment ? (
        <div
          className={"venue-detail-booking-payment"}
          data-cy="venue-booking-payment-view">
          <Payments
            {...props.paymentData!}
            goBack={() => props.setShowVenueBookingPayment(false)}
          />
        </div>
      ) : (
        <div className={"venues-detail-container"}>
          <div className={"venue-detail-breadcrumb"}>
            <Breadcrumb
              initialSection={{ text: "Home", path: "/" }}
              currentSectionName={props.communityAsset?.title}
              hasIdentificator
            />
          </div>
          {!props.isMobileEmbed && (
            <div className={"venue-detail-back"}>
              <BackButton goBack={props.goBack} />
            </div>
          )}
          <div className="venue-detail-card-wrapper">
            <div className={"venue-detail-card"}>
              <div className={"venue-detail-card-content-images"}>
                <ImageFilesCarousel
                  showIndicators={
                    props.communityAsset?.files &&
                    props.communityAsset?.files.length > 1
                  }
                  customHeight="155px"
                  files={props.communityAsset?.files || []}
                />
              </div>
              <div className={"venue-detail-card-content"}>
                <div>
                  <div className={"venue-detail-card-content-title"}>
                    {props.communityAsset?.title}
                  </div>
                  <div className={"venue-detail-card-content-body"}>
                    <div
                      className={"venue-detail-card-content-description"}
                      dangerouslySetInnerHTML={{
                        __html: props.communityAsset?.description ?? "",
                      }}
                    />
                  </div>
                </div>
              </div>
              {props.communityAsset?.canBeBooked && (
                <div>
                  <Button
                    text={"Check Dates and Rates"}
                    onClick={() => {
                      childref &&
                        childref.current &&
                        childref.current?.collapseSpaceBookingDetails();
                      props.setShowVenueBooking(true);
                    }}
                    isSecondary
                    dataCy="venue-details-parent-check-dates-btn"
                  />
                </div>
              )}
            </div>
          </div>

          {props.showVenueBooking && (
            <div className="venues-detail-booking">
              <div className="venues-detail-booking-content">
                <div className="venues-detail-booking-content-images">
                  <ImageFilesCarousel
                    showIndicators={
                      props.communityAsset?.files &&
                      props.communityAsset?.files.length > 1
                    }
                    customHeight="248px"
                    files={props.communityAsset?.files || []}
                  />
                </div>
                <div className="venues-detail-booking-content-rates">
                  <div className="venues-detail-booking-content-rates-calendar">
                    <CalendarSelect
                      availableDates={props.availableDates?.data?.dates!}
                      selected={props.selectedDate}
                      onMonthChange={(selectedMonthDate) => {
                        props.setSelectedDate(null);
                        props.handleMonthChange(selectedMonthDate);
                      }}
                      onChange={(selectedDate) => {
                        props.setSelectedDate(selectedDate);
                        // Update the month as well in case the user selects a day of the next/prev month.
                        props.handleMonthChange(selectedDate);
                        props.resetDropdowns();
                      }}
                      isVenuesView
                      dataCy="calendar-select-venue-details"
                    />
                  </div>
                  <div
                    className="venues-detail-booking-content-rates-dropdowns"
                    data-cy="venue-rate-dropdowns">
                    <DropdownCheckbox
                      label="Select Rate"
                      handleChange={props.handleRateChange}
                      optionSelected={props.selectedRate}
                      options={props.getRateOptions(t)}
                      isLoading={props.isLoadingRates}
                      isClearable={false}
                      isMulti={false}
                      blurOnSelect={true}
                    />
                    <DropdownCheckbox
                      label="From:"
                      handleChange={props.handleStartTimeChange}
                      optionSelected={props.selectedStartTime}
                      options={props.getStartTimes()}
                      isLoading={props.isLoadingRates}
                      isDisabled={!props.selectedRate}
                      isClearable={false}
                      isMulti={false}
                      blurOnSelect={true}
                    />
                    <DropdownCheckbox
                      label="To:"
                      handleChange={props.handleEndTimeChange}
                      optionSelected={props.selectedEndTime}
                      options={props.getEndTimes()}
                      isLoading={props.isLoadingRates}
                      isDisabled={!props.selectedStartTime}
                      isClearable={false}
                      isMulti={false}
                      blurOnSelect={true}
                    />
                  </div>
                </div>
              </div>
              {!props.isUserLogged && (
                <div className={"venues-spaces-items-item-right-banner"}>
                  A valid account is needed to make a reservation. Please{" "}
                  <span
                    className="venues-spaces-items-item-right-banner-link"
                    onClick={() => props.handleNotLoggedUserLogin()}>
                    Log In
                  </span>{" "}
                  or{" "}
                  <span
                    className="venues-spaces-items-item-right-banner-link"
                    onClick={() => props.handleNotLoggedUserRegister()}>
                    Sign Up
                  </span>
                  .
                </div>
              )}
              <div className={"venues-detail-booking-actions"}>
                <Button
                  onClick={props.handleCTAButton}
                  text={props.bookingCtaLabel}
                  isDisabled={props.isCTAButtonDisabled}
                  dataCy="venues-details-proceed-btn"
                />
              </div>
              {/* Tech Note: Didn't see a use for this currently. It's only here and not on spaces */}
              {/* {props.communityAsset?.financeRules?.rulesandRegulation && (
                  <div
                    className={"venues-detail-booking-actions-rules"}
                    onClick={() => {
                      props.setShowReservationRules(true);
                    }}>
                    Rules & Regulations
                  </div>
                )} */}
            </div>
          )}
          {props.hasSpaceBooking && (
            <VenuesSpaces
              reference={childref}
              // In this section we only want to show the spaces that can be booked.
              spaces={props.communityAsset?.spaces?.filter(
                (space) => space.canBeBooked,
              )}
              handleCTAButton={props.handleCTAButton}
              onShowBookingOptions={() => {
                props.setShowVenueBooking(false);
              }}
              bookingCtaLabel={props.bookingCtaLabel}
              handleNotLoggedUserLogin={props.handleNotLoggedUserLogin}
              handleNotLoggedUserRegister={props.handleNotLoggedUserRegister}
            />
          )}
        </div>
      )}
      {props.communityAsset?.financeRules?.rulesandRegulation && (
        <GenericModal
          onClose={() => props.setShowReservationRules(false)}
          isVisible={props.showReservationRules}
          children={
            <div
              dangerouslySetInnerHTML={{
                __html: props.communityAsset?.financeRules?.rulesandRegulation,
              }}></div>
          }
          ctaText={"Close"}
          onCtaClick={() => props.setShowReservationRules(false)}
        />
      )}
    </Navbar>
  );
};

export default VenuesDetail;
