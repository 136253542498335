import userSession from "@App/auth/userSession";
import { useRef, useEffect, useState, ReactNode } from "react";

const SideScrollLogic = (children?: ReactNode | undefined) => {
  const scrollElement = useRef<HTMLInputElement>(null);
  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [isMobileEmbed] = useState(userSession.isMobileViewMode);
  const scroll = (shift: number) => {
    if (!scrollElement?.current) return;

    scrollElement.current.scrollLeft += shift;
    setScrollX(scrollX + shift);
    if (
      Math.floor(
        scrollElement.current.scrollWidth - scrollElement.current.scrollLeft,
      ) <= scrollElement.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };
  const scrollCheck = () => {
    if (!scrollElement?.current) return;

    setScrollX(scrollElement.current.scrollLeft);
    if (
      Math.floor(
        scrollElement.current.scrollWidth - scrollElement.current.scrollLeft,
      ) <= scrollElement.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  useEffect(() => {
    if (
      scrollElement.current &&
      scrollElement?.current?.scrollWidth ===
        scrollElement?.current?.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
    return () => {};
  }, [
    scrollElement?.current?.scrollWidth,
    scrollElement?.current?.offsetWidth,
    children,
  ]);

  return {
    scrollElement,
    scrollX,
    scrollEnd,
    isMobileEmbed,
    scroll,
    scrollCheck,
  };
};

export default SideScrollLogic;
