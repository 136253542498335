import "./CmsDetail.scss";
import { Navbar } from "@Layouts/index";
import CmsDetailLogic from "./CmsDetailLogic";
import { BackButton, Breadcrumb, Loader } from "@Components/index";
import { HtmlWrapper } from "@Components/html-wrapper/HtmlWrapper";
import { CmsDetailProps } from "./CmsDetailProps";

const CmsDetail = (cmsDetailProps: CmsDetailProps) => {
  const props = CmsDetailLogic(cmsDetailProps);

  return (
    <Navbar>
      <div className={`cms-detail`}>
        {props.detailPageData ? (
          <>
            {props.detailPageData.breadcrumb && (
              <>
                <div className={"cms-detail__breadcrumb"}>
                  <Breadcrumb
                    initialSection={
                      props.detailPageData.breadcrumb.initialSection
                    }
                    currentSectionName={props.detailPageData.breadcrumb.name}
                    ignorePaths
                  />
                </div>
                {!props.isMobileEmbed && (
                  <div className={"cms-detail__back"}>
                    <BackButton goBack={props.goBack} />
                  </div>
                )}
              </>
            )}
            <div className={`cms-detail__content`}>
              <div className={"cms-detail__content__left"}>
                {!!props.detailPageData.title && (
                  <div className={"cms-detail__content__left__title"}>
                    {props.detailPageData.title}
                  </div>
                )}
                <HtmlWrapper
                  htmlContent={
                    props.detailPageData.fileNames
                      ? props.replaceFilePaths(
                          props.detailPageData.contentHtml,
                          props.detailPageData.fileNames,
                          "file-path-",
                        )
                      : props.detailPageData.contentHtml
                  }
                />
                {props.detailPageData.bottomContentHtml && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.replaceFilePaths(
                        props.detailPageData.bottomContentHtml,
                        props.detailPageData.imageNames,
                      ),
                    }}
                  />
                )}
              </div>
              <div
                className={`cms-detail__content__right ${
                  props.detailPageData.alignImageTop
                    ? "cms-detail__content__right__image-top"
                    : ""
                }
                `}>
                <img
                  className={"cms-detail__content__right__image"}
                  src={
                    props.detailPageData.rightContentImageName &&
                    `${props.tenant?.awsSettings.assetStorageBaseURL}/${props.detailPageData.rightContentImageName}`
                  }
                  alt={""}
                />
              </div>
            </div>
            {props.detailPageData.lowerSectionHtml && (
              <HtmlWrapper
                htmlContent={props.replaceFilePaths(
                  props.detailPageData.lowerSectionHtml,
                  props.detailPageData.imageNames,
                  "image-path-", // Make sure not to use this with above too.
                )}
              />
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Navbar>
  );
};

export default CmsDetail;
