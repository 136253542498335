import {
  Button,
  TicketPurchaseRows,
  DropdownCheckbox,
} from "@Components/index";
import "./TicketPurchase.scss";
import { TicketPurchaseProps } from "./TicketPurchasePropTypes";
import TicketPurchaseLogic from "./TicketPurchaseLogic";
import { format, parseISO } from "date-fns";

// TODO: Just ignore this component for now
// We will refactor this component to handle time slotted events in the future and not recurring events
// Also rename it... It handles event *selection* not ticket purchasing doh
const TicketPurchase = (componentProps: TicketPurchaseProps) => {
  const props = TicketPurchaseLogic(componentProps);
  return (
    <div className="ticket-purchase-modal">
      <div className="top-right-actions">
        <Button
          type="close"
          style={{ color: "#000000" }}
          onClick={() => componentProps.onClose()}
        />
      </div>
      <div className="ticket-purchase-modal-header">
        <div className="ticket-purchase-modal-header-select">
          <DropdownCheckbox
            options={props.getLabelValuesMonths()}
            optionSelected={props.selectedMonth}
            handleChange={props.setSelectedMonth}
            isMulti={false}
            isClearable={false}
            blurOnSelect
            hideBorder
            fontSize="24px"
            fontWeight={"bold"}
            isInsideModal
          />
        </div>
      </div>
      <div className="ticket-purchase-modal-info">
        <p>
          <b>Dates & Hours:</b>
        </p>
        <p>
          {componentProps.eventData.recurring?.type},{" "}
          {format(parseISO(componentProps.eventData?.startDate), "h:mm aaa")} -
          {format(parseISO(componentProps.eventData?.endDate), "h:mm aaa")}
        </p>
        {componentProps.eventData.recurring?.terminationDate && (
          <p>
            Event ends:{" "}
            {format(
              parseISO(componentProps.eventData.recurring?.terminationDate),
              "P"
            )}
          </p>
        )}
      </div>
      <TicketPurchaseRows
        eventType={
          props.recurringEventDates?.length
            ? props.recurringEventDates[0].eventType
            : componentProps.eventData.eventType
        }
        event={componentProps.eventData}
        hideTime={true}
        recurringDates={props.recurringEventDates}
        onClose={(eventId) => {
          componentProps.onClose(eventId);
        }}
      />
    </div>
  );
};

export default TicketPurchase;
