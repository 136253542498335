import "./Modal.scss";
import ReactModal from "react-modal";

interface ModalProps {
  children: any;
  isVisible: boolean;
  onClose?: () => void;
}
const Modal = ({ children, isVisible, onClose }: ModalProps) => {
  return (
    <ReactModal
      isOpen={isVisible}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={{
        content: {
          inset: "auto",
          padding: 0,
          borderStyle: "none",
          borderWidth: 0,
          borderRadius: 8,
        },
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#000000c7",
          zIndex: 500,
        },
      }}>
      <div className={"modal-content"}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
