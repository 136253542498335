import { getRecurringEventDates } from "@App/api/event";
import { RecurringEventDatesRequest } from "@App/api/requests/events";
import { QUERY_KEY_EVENT_RECURRING_DATES } from "@App/constants/queryKeyConstants";
import { useQuery } from "@tanstack/react-query";

/**
  @deprecated
  Only used for ticket purchase modal which has been turned off.
  Remove once puchase modal is refactored
*/
export default function useRecurringEventDates(
  eventData: RecurringEventDatesRequest,
) {
  const { eventId, startDate, endDate } = eventData;
  const { data: recurringEventDates, isLoading: isLoadingRecurringEventDates } =
    useQuery({
      queryKey: [QUERY_KEY_EVENT_RECURRING_DATES, eventId, startDate, endDate],
      queryFn: () => getRecurringEventDates(eventData),
      staleTime: Infinity,
      enabled: !!eventId,
      select: (data) => data.data,
    });

  return {
    recurringEventDates,
    isLoadingRecurringEventDates,
  };
}
