import { IconProps } from "../iconTypes";

const House = ({ color = "#000000", size }: IconProps) => {
  return (
    <svg
      {...(size && {
        style: { width: size, height: size },
      })}
      width="50"
      height="47"
      viewBox="0 0 50 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 2L2 25.4228H7.75V45H16.375H20.6875V33.8519H22.8438H25H27.1562H29.3125V45H33.625H42.25V25.4228H48L38.0862 15.304V4.44715H32.3362V9.44828L25 2Z"
        stroke={color}
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default House;
