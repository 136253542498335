import "./Stepper.scss";

interface StepperProps {
  steps: number[];
  currentStep: number;
}

const Stepper = ({ steps, currentStep }: StepperProps) => {
  return (
    <div className={"stepper"}>
      <div className={"stepper-steps"}>
        {steps.map((stepNumber, index) => {
          return (
            <div
              key={`stepper_step_${index}`}
              className={`stepper-step ${
                stepNumber <= currentStep ? "stepper-step-active" : ""
              }`}></div>
          );
        })}
      </div>
      <div className={"stepper-text"}>
        {`Step ${currentStep} of ${steps.length}`}
      </div>
    </div>
  );
};

export default Stepper;
