import { Article } from "@App/models/Article";
import { baseServiceRequest } from "./baseService";
import { ArticlesRequest } from "./requests/Article";
import { GenericPagedResponse } from "@App/models/BasePagedResponse";

export const getPagedArticles = async (request: ArticlesRequest) => {
  return await baseServiceRequest<GenericPagedResponse<Article>>({
    path: `/articles`,
    type: "get",
    params: request,
  });
};
