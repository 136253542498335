import "./LinkifyText.scss";
import LinkifyTextLogic from "./LinkifyTextLogic";
import { LinkifyTextProps } from "./LinkifyTextPropTypes";

const LinkifyText = (componentProps: LinkifyTextProps) => {
  const props = LinkifyTextLogic(componentProps);
  return (
    <div
      className={"linkify-text"}
      dangerouslySetInnerHTML={{ __html: props.linkifyElement }}
      data-cy={componentProps.dataCy}
    />
  );
};

export default LinkifyText;
