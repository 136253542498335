import { getListingCategories } from "@App/api/amenities";
import { getMemberships as getCategoryItems } from "@App/api/memberships";
import { QUERY_KEY_USER_LISTING_CATEGORIES } from "@App/constants/queryKeyConstants";
import { ListingCategory } from "@App/models/amenity";
import { sendMobileViewModePostMessage } from "@Utils/utils";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MembershipsLogic = () => {
  const [searchText, setSearchText] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { data: listingCategories, isLoading: categoriesLoading } = useQuery(
    [QUERY_KEY_USER_LISTING_CATEGORIES],
    () => {
      return getListingCategories().then((res) => {
        setIsLoading(false);
        return res;
      });
    },
    {
      select: (data) => (data.data as ListingCategory[]) ?? [],
    },
  );

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: isLoading });
  }, [isLoading]);

  const onClick = (id: string) => {
    navigate(`membership-details/${id}`);
  };

  const onSearch = (text: string) => {
    setSearchText(text);
  };

  return {
    listingCategories,
    getCategoryItems,
    isLoading: categoriesLoading,
    searchText,
    setSearchText,
    onClick,
    onSearch,
  };
};

export default MembershipsLogic;
