import { Chevron } from "@Components/icons";
import "./HoverListMenu.scss";
import {
  HoverListMenuItem,
  HoverListMenuProps,
} from "./HoverListMenuPropTypes";
import HoverListMenuLogic from "./HoverListMenuLogic";
import { Link } from "react-router-dom";
import React from "react";
import { useAuthUser } from "@App/hooks";

const IconDiv = (props: { isExpanded: boolean; onIconClick: () => void }) => (
  <div onClick={props.onIconClick}>
    <div className={"hover-list-menu-icons"}>
      <div className={"hover-list-menu-icon"}>
        <Chevron position={"down"} size={15} color={"#502f2d"} />
      </div>

      <div className={"hover-list-menu-icon-expanded"}>
        <Chevron position={"up"} size={15} color={"#502f2d"} />
      </div>
    </div>

    <div className={"hover-list-menu-icons-min"}>
      {!props.isExpanded ? (
        <div className={"hover-list-menu-icon"}>
          <Chevron position={"down"} size={15} color={"#502f2d"} />
        </div>
      ) : (
        <div className={"hover-list-menu-icon-expanded"}>
          <Chevron position={"up"} size={15} color={"#502f2d"} />
        </div>
      )}
    </div>
  </div>
);

const MenuItem = (props: HoverListMenuItem & { className?: string }) => {
  if (props.route && props.isRouteExternal) {
    return (
      <a
        className={props.className}
        href={props.route}
        target="_blank"
        rel="noopener noreferrer">
        {props.text}
      </a>
    );
  } else if (props.route && !props.isRouteExternal) {
    return (
      <Link
        className={props.className}
        to={props.route}
        data-cy={`hover-list-link-${props.route.substring(1)}`}>
        {props.text}
      </Link>
    );
  } else {
    return <div className={props.className}>{props.text}</div>;
  }
};

const HoverListMenu = (componentProps: HoverListMenuProps) => {
  const props = HoverListMenuLogic(componentProps);
  const { isUserLoggedIn } = useAuthUser();

  if (props.isLoginRequired && !isUserLoggedIn) {
    return null;
  }
  return (
    <div className={`hover-list-menu ${props.className ?? ""}`}>
      <div className={"hover-list-menu-item-button"}>
        <MenuItem
          text={props.text}
          className={"hover-list-menu-item-button-text"}
          route={props.route}
          isRouteExternal={props.isRouteExternal}
          isLoginRequired={props.isLoginRequired}
        />
        {props.subNavItems.length > 0 && (
          <IconDiv
            isExpanded={props.isExpanded}
            onIconClick={props.onIconClick}
          />
        )}
      </div>
      <div
        className={`hover-list-menu-item-default hover-list-menu-item-${
          props.classHooks
        } 
        ${
          props.isExpanded
            ? "hover-list-menu-item-visible"
            : "hover-list-menu-item-hidden"
        }`}>
        {props.subNavItems.map((item, index) => {
          if (item.isLoginRequired && !isUserLoggedIn) return null;
          return (
            <MenuItem
              key={`hover-list-menu-item-${index}`}
              text={item.text}
              className={"hover-list-menu-item-text"}
              route={item.route}
              isRouteExternal={item.isRouteExternal}
              isLoginRequired={item.isLoginRequired}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HoverListMenu;
