import { TableRequest } from "@App/api/requests/table";
import {
  AxiosGenericPagedResponse,
  GenericPageItem,
} from "@App/models/BasePagedResponse";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PaginationState } from "@tanstack/react-table";
import { useEffect, useMemo } from "react";

type UsePagedTableQueryParams<TData, TSortOptions, TFilterOptions> = {
  queryKey: string[];
  queryFn: (
    request: TableRequest<TSortOptions, TFilterOptions>,
  ) => Promise<AxiosGenericPagedResponse<TData>>;
  id?: string;
  pagination: PaginationState;
  sortOptions?: TSortOptions;
  filterOptions?: TFilterOptions;
  enabled?: boolean;
};

export default function useTablePagedQuery<
  TData,
  TSortOptions,
  TFilterOptions,
>({
  queryKey,
  queryFn,
  id,
  pagination,
  sortOptions,
  filterOptions,
  enabled = true,
}: UsePagedTableQueryParams<TData, TSortOptions, TFilterOptions>) {
  const queryClient = useQueryClient();
  const page = useMemo(() => pagination.pageIndex + 1, [pagination.pageIndex]);

  const { data, error, isLoading, isFetching, isPreviousData } = useQuery<
    AxiosGenericPagedResponse<TData>
  >({
    queryKey: [
      ...queryKey,
      ...Object.values(id ? { id: id } : {}),
      ...Object.values(filterOptions ?? {}),
      ...Object.values(sortOptions ?? {}),
      page,
      pagination.pageSize,
    ],
    queryFn: () =>
      queryFn({
        id,
        body: {
          pageParams: {
            page,
            pageSize: pagination.pageSize,
          },
          sortOptions,
          filterOptions,
        },
      }),
    keepPreviousData: true,
    enabled,
    staleTime: 1000 * 60 * 5,
  });

  const unwrappedData = useMemo(() => {
    if (!data) return undefined;
    if (data.data.items.length < pagination.pageSize && page !== 1) {
      return {
        ...data.data,
        items: data.data.items.concat(
          Array(pagination.pageSize - data.data.items.length)
            .fill(null)
            .map(
              (_, index) =>
                ({
                  id: `empty-${index}`,
                  empty: true,
                }) as unknown as GenericPageItem<TData>,
            ),
        ),
      };
    }
    return data.data;
  }, [data]);

  useEffect(() => {
    if (!enabled || !data || data.data?.isLastPage) return;

    queryClient.prefetchQuery({
      queryKey: [
        ...queryKey,
        ...Object.values(id ? { id: id } : {}),
        ...Object.values(sortOptions ?? {}),
        ...Object.values(filterOptions ?? {}),
        page + 1,
        pagination.pageSize,
      ],
      queryFn: () =>
        queryFn({
          id,
          body: {
            pageParams: {
              page: page + 1,
              pageSize: pagination.pageSize,
            },
            sortOptions,
            filterOptions,
          },
        }),
      staleTime: 1000 * 60 * 5,
    });
  }, [
    enabled,
    data?.data.isLastPage,
    queryFn,
    queryKey,
    id,
    page,
    pagination.pageSize,
    sortOptions,
    filterOptions,
  ]);

  return {
    data: unwrappedData,
    error,
    isLoading,
    isFetching,
    isPreviousData,
  };
}
