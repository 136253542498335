import { useEffect, useState, useCallback } from "react";
import { getNotifications } from "@App/api/notifications";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { QUERY_KEY_NOTIFICATIONS } from "@App/constants/queryKeyConstants";

const NotificationsLogic = () => {
  const [selectedFilter, setSelectedFilter] = useState("all");

  const {
    data: notifcationsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY_NOTIFICATIONS, selectedFilter],
    queryFn: ({ pageParam }) =>
      getNotifications({
        page: pageParam,
        isRead: selectedFilter === "unread" ? false : undefined,
        isStarred: selectedFilter === "starred" ? true : undefined,
      }).then((res) => res.data),

    getNextPageParam: (lastPage) => {
      if (lastPage.isLastPage) {
        return;
      } else {
        return lastPage.page + 1;
      }
    },
  });

  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: "100px",
  });

  const onIntersect = useCallback(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    onIntersect();
  }, [onIntersect]);

  const handleSelectFilter = (filter: string) => {
    setSelectedFilter(filter);
  };

  return {
    notifcationsData,
    isLoading,
    ref,
    selectedFilter,
    handleSelectFilter,
    hasNextPage,
  };
};

export default NotificationsLogic;
