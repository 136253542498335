import { useEffect, useState } from "react";
import { EmailSearchRegx, PhoneRegx } from "@Utils/utils";
import { LinkifyTextProps } from "./LinkifyTextPropTypes";

const LinkifyTextLogic = (props: LinkifyTextProps) => {
  const [linkifyElement, setLinkifyElement] = useState<string>("");

  useEffect(() => {
    let text = props.text;
    const emailMatches = text.match(EmailSearchRegx);
    const phoneMatches = text.match(PhoneRegx);

    emailMatches?.forEach((email) => {
      text = text.replace(
        email,
        `<a class="${
          props.useBoldLinks ? "linkify-text-bold" : ""
        }" href=mailto:${email}>${email}</a>`,
      );
    });
    phoneMatches?.forEach((phone) => {
      text = text.replace(
        phone,
        ` <a class="${
          props.useBoldLinks ? "linkify-text-bold" : ""
        }" href=tel:${phone}>${phone.trim()}</a>`,
      );
    });

    setLinkifyElement(text);
  }, [props.text, props.useBoldLinks]);

  return { linkifyElement };
};

export default LinkifyTextLogic;
