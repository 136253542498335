import "./CmsActions.scss";
import {
  CalendarAlt,
  Cards,
  Checklist,
  Clock,
  District,
  Envelope,
  Family,
  FamilyFinance,
  Handshake,
  House,
  NiceHouse,
  Notebook,
  Paws,
} from "@Components/icons";
import { useMemo } from "react";
import { CmsActionIcons } from "@App/models/CmsPageData";
import { useNavigate } from "react-router-dom";

const CmsActions = ({
  actionIconsList,
}: {
  actionIconsList: CmsActionIcons[];
}) => {
  const navigate = useNavigate();

  const iconsMapping: { [key: string]: JSX.Element } = useMemo(() => {
    // Add logic to use tenant specific icons
    return {
      paws: <Paws />,
      checklist: <Checklist />,
      cards: <Cards />,
      familyFinance: <FamilyFinance />,
      clock: <Clock />,
      district: <District />,
      family: <Family />,
      calendarAlt: <CalendarAlt />,
      envelope: <Envelope />,
      handshake: <Handshake />,
      house: <House />,
      niceHouse: <NiceHouse />,
      notebook: <Notebook />,
    };
  }, []);

  return (
    <div className={`cms-actions`}>
      <div className={"cms-actions__wrapper"}>
        {actionIconsList?.map((actionIcon) => {
          return (
            <a
              key={actionIcon.label}
              href={actionIcon.uri}
              onClick={(e) => {
                if (!actionIcon.isExternal) {
                  e.preventDefault();
                  navigate(actionIcon.uri);
                }
              }}
              className={"cms-actions__action"}
              {...(actionIcon.isExternal && {
                target: "_blank",
                rel: "noreferrer",
              })}>
              {iconsMapping[actionIcon.icon]}
              <div className={"cms-actions__text"}>{actionIcon.label}</div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default CmsActions;
