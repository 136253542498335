export const TagBubbleIcon = () => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33333 1.86285L8.39333 0.922852L4.66667 4.64952L0.94 0.922852L0 1.86285L3.72667 5.58952L0 9.31618L0.94 10.2562L4.66667 6.52952L8.39333 10.2562L9.33333 9.31618L5.60667 5.58952L9.33333 1.86285Z"
      fill="black"
    />
  </svg>
);
