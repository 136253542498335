import { Cross } from "@Components/icons";
import { TextInput } from "@Components/index";
import "./MembershipDogRow.scss";
import MembershipDogRowLogic from "./MembershipDogRowLogic";
import { MembershipDogRowProps } from "./MembershipDogRowPropTypes";

const MembershipDogRow = (componentProps: MembershipDogRowProps) => {
  const props = MembershipDogRowLogic(componentProps);
  return (
    <div className={"membership-dog-row"}>
      {componentProps.showRemove && (
        <button
          className={
            "membership-dog-row-input-remove membership-dog-row-input-remove-min"
          }
          disabled={!componentProps.removeEnabled}
          onClick={() => {
            componentProps.onRemove(componentProps.index);
          }}
        >
          <Cross
            color={componentProps.removeEnabled ? "#000" : "#fff"}
            size={15}
          />
        </button>
      )}
      <div className={"membership-dog-row-input"}>
        <TextInput
          type={"text"}
          label={"Please list your dog's name"}
          placeholder={"Enter Name"}
          maxLength={50}
          withMargin
          required
          value={props.dogName}
          onChange={(value) => {
            props.onDogDetailsChange({ value, isDogName: true });
          }}
          isValid={props.dogNameValid}
          errorMessage={"Required"}
        />
      </div>
      <div className={"membership-dog-row-input"}>
        <TextInput
          type={"text"}
          label={"What is the breed of your dog?"}
          placeholder={"Enter Breed"}
          maxLength={50}
          withMargin
          value={props.dogBreed}
          onChange={(value) => {
            props.onDogDetailsChange({ value });
          }}
          isValid={props.dogBreedValid}
          errorMessage={"Please enter the breed of your dog"}
        />
      </div>
      {componentProps.showRemove && (
        <button
          className={"membership-dog-row-input-remove"}
          disabled={!componentProps.removeEnabled}
          onClick={() => {
            componentProps.onRemove(componentProps.index);
          }}
        >
          <Cross
            color={componentProps.removeEnabled ? "#000" : "#fff"}
            size={15}
          />
        </button>
      )}
    </div>
  );
};

export default MembershipDogRow;
