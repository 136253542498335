export interface DomainMap {
  domain: string;
  tenantId: string;
}

export const domainMap: DomainMap[] = [
  { domain: "mylwr.com", tenantId: "lakewoodranch" },
  { domain: "lakewoodranch-appui", tenantId: "lakewoodranch" },
  { domain: "myranchosahuarita.com", tenantId: "rancho" },
  { domain: "rancho-appui", tenantId: "rancho" },
];

export const validTenantIds = domainMap
  .map((d) => d.tenantId)
  .filter((tenantId, i, arr) => arr.indexOf(tenantId) === i);
