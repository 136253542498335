import {
  Loader,
  Map,
  MapMini,
  PoisList,
  SearchInput,
  Tabs,
} from "@Components/index";
import "./MapPois.scss";
import MapPoisLogic from "./MapPoisLogic";
import { MapPoisProps } from "./MapPoisPropTypes";
import { Chevron } from "@Components/icons";
import { customEventPublish } from "@Utils/utils";
import { MAP_RESIZE } from "@App/constants/appConstants";
import { Link } from "react-router-dom";

const MapPois = (componentProps: MapPoisProps) => {
  const props = MapPoisLogic();

  return (
    <div
      className={`map-pois ${
        (componentProps.isExpandableMap && props.isPoisListVisible) ||
        (!componentProps.isExpandableMap && !props.isLoadingPois)
          ? "map-pois-default"
          : "map-pois-wide"
      }`}
      {...(componentProps.customHeight && {
        style: { height: componentProps.customHeight },
      })}
      {...(!componentProps.isExpandableMap && {
        style: { marginLeft: 0 },
      })}>
      <div className={"map-pois__map__min"}>
        <MapMini
          pois={props.pois}
          selectedPoi={props.selectedPoi}
          onPoiClick={(poi) => {
            props.onPoiSelectionChange(poi);
          }}
          onPoiHover={(poi) => {
            if (!props.selectedPoi) {
              props.setHoveredPoi(poi);
            }
          }}
          onViewDetailClick={(poi) => {
            props.setIsPoiDetailVisible(true);
            if (!props.selectedPoi || props.selectedPoi.id !== poi.id) {
              props.onPoiSelectionChange(poi);
            }
          }}
          onSelectionReset={() => {
            props.onPoiSelectionChange(null);
          }}
          onHoverReset={() => {
            props.setHoveredPoi(null);
          }}
          onRefreshPois={props.onRefreshPois}
          {...(componentProps.isExpandableMap && {
            useLoader: true,
          })}
          defaultExapnded
        />
      </div>

      {props.isPoisListVisible && (
        <div className={"map-pois__pois"}>
          <div className={"map-pois__pois__top"}>
            <div className={"map-pois__pois__top__search"}>
              <SearchInput
                onSearch={(text) => {
                  props.setSearchText(text?.toLowerCase());
                  props.onRefreshPois();
                }}
                placeholder={"Search"}
              />
            </div>
            {componentProps.isExpandableMap && (
              <button
                className={"map-pois__pois__top__collapse"}
                onClick={() => {
                  props.setIsPoisListVisible(false);
                  customEventPublish(MAP_RESIZE, {});
                }}>
                <Chevron position={"left"} />
              </button>
            )}
          </div>

          {props.tabs && (
            <Tabs
              tabs={props.tabs}
              onTabChange={(tabIndex) => props.onTabChange(tabIndex)}
              dynamicTabs
              underlined
            />
          )}
          <div className={"map-pois-list"}>
            {props.isLoadingPois || props.isRefetchingPois ? (
              <Loader color={"#000"} horizontallyCentered padding={"30px"} />
            ) : props.pois && props.pois.length > 0 ? (
              <PoisList
                onRef={(ref) => {
                  props.poisListRef.current = ref;
                }}
                pois={props.pois}
                selectedPoi={props.selectedPoi}
                hoveredPoi={props.hoveredPoi}
                onPoiClick={(poi) => {
                  props.onPoiSelectionChange(poi);
                }}
              />
            ) : (
              <div className={"map-pois-list-message"}>
                No items for the selected category
              </div>
            )}
          </div>

          <div className={"map-pois-list-min"}>
            {props.isLoadingPois || props.isRefetchingPois ? (
              <Loader color={"#000"} horizontallyCentered padding={"30px"} />
            ) : props.pois && props.pois?.length > 0 ? (
              <PoisList
                onRef={(ref) => {
                  props.poisListRef.current = ref;
                }}
                pois={
                  componentProps.hasViewMore
                    ? props.pois.slice(0, 3)
                    : props.pois
                }
                selectedPoi={props.selectedPoi}
                hoveredPoi={props.hoveredPoi}
                onPoiClick={(poi) => {
                  props.onPoiSelectionChange(poi);
                }}
                hasViewMore={componentProps.hasViewMore}
              />
            ) : (
              <div className={"map-pois-list-min-message"}>
                No items for the selected category
              </div>
            )}
            {props.pois && props.pois?.length > 3 && (
              <Link className={"map-pois-list-min-link"} to={"/locations"}>
                View More
              </Link>
            )}
          </div>
        </div>
      )}
      <div
        className={"map-pois__map"}
        style={!props.isPoisListVisible ? { minWidth: "100%" } : {}}>
        {componentProps.isExpandableMap && !props.isPoisListVisible && (
          <button
            className={"map-pois__pois__top__collapse"}
            onClick={() => {
              props.setIsPoisListVisible(true);
            }}>
            <Chevron position={"right"} />
          </button>
        )}
        <Map
          pois={props.pois}
          {...(componentProps.customHeight && {
            height: componentProps.customHeight,
          })}
          selectedPoi={props.selectedPoi}
          onPoiClick={(poi) => {
            props.onPoiSelectionChange(poi);
          }}
          onPoiHover={(poi) => {
            if (!props.selectedPoi) {
              if (poi) props.setHoveredPoi(poi);
              else props.onPoiSelectionChange(null);
            }
          }}
          onViewDetailClick={(poi) => {
            props.setIsPoiDetailVisible(true);
            if (!props.selectedPoi || props.selectedPoi.id !== poi.id) {
              props.onPoiSelectionChange(poi);
            }
          }}
          onSelectionReset={() => {
            props.onPoiSelectionChange(null);
          }}
          onHoverReset={() => {
            props.setHoveredPoi(null);
          }}
          onRefreshPois={props.onRefreshPois}
          {...(componentProps.isExpandableMap && {
            useLoader: true,
          })}
        />
      </div>
    </div>
  );
};

export default MapPois;
