// src/contexts/NavigationContext.tsx
import { createContext, useState, useContext, ReactNode } from "react";

interface NavigationContextProps {
  history: string[];
  addToHistory: (path: string) => void;
  resetHistory: () => void;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error("useNavigation must be used within a NavigationProvider");
  }
  return context;
};

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const [history, setHistory] = useState<string[]>([]);

  const resetPaths = [
    "/",
    "/cms/town-hall",
    "/cms/town-hall-north",
    "/cms/community-activities",
    "/cms/resident-center",
    "/cms/class-schedule",
  ];

  const addToHistory = (path: string) => {
    setHistory((prevHistory) => {
      const lastPath = prevHistory[prevHistory.length - 1];

      if (lastPath === path) {
        return prevHistory;
      }

      const existingIndex = prevHistory.findIndex((h) => h === path);
      if (existingIndex !== -1) {
        return prevHistory.slice(0, existingIndex + 1);
      }

      if (resetPaths.includes(path)) {
        return [path];
      }

      return [...prevHistory, path];
    });
  };

  const resetHistory = () => {
    setHistory([]);
  };

  return <NavigationContext.Provider value={{ history, addToHistory, resetHistory }}>{children}</NavigationContext.Provider>;
};
