import { sendMobileViewModePostMessage } from "@Utils/utils";
import { useEffect, useState } from "react";

const ClassScheduleLogic = () => {
  const [placeholderText] = useState("Hi this is where the schedule goes!");

  useEffect(() => {
    //Let mmobile know that the page is ready
    sendMobileViewModePostMessage({ isLoading: false });
  }, []);

  return {
    placeholderText,
  };
};

export default ClassScheduleLogic;
