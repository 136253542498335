import { useState } from "react";
import "./Checkbox.scss";

interface CheckboxProps {
  text: string;
  defaultChecked?: boolean;
  onChange: (value: boolean) => void;
  dataCy?: string;
}

const Checkbox = ({
  text,
  defaultChecked,
  onChange,
  dataCy,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(defaultChecked ?? false);
  return (
    <div>
      <label className="checkbox">
        {text}
        <input
          type={"checkbox"}
          checked={isChecked}
          onChange={() => {
            setIsChecked(!isChecked);
            onChange(!isChecked);
          }}
          data-cy={dataCy}
        />

        <span className={"checkmark"}></span>
      </label>
    </div>
  );
};

export default Checkbox;
