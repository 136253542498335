import { IconProps } from "../iconTypes";

const User = ({ color = "#868E96", size }: IconProps) => {
  return (
    <svg
      {...(size && {
        style: { width: size, height: size },
      })}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.1039 2.45466C9.1039 2.45466 9.92209 3.27284 9.92209 4.27284C9.92209 5.27284 9.1039 6.09102 8.1039 6.09102C7.1039 6.09102 6.28572 5.27284 6.28572 4.27284C6.28572 3.27284 7.1039 2.45466 8.1039 2.45466ZM8.1039 10.6365C10.5584 10.6365 13.3766 11.8092 13.5584 12.4547V13.3637H2.64936V12.4637C2.83118 11.8092 5.64936 10.6365 8.1039 10.6365ZM8.1039 0.636475C6.09481 0.636475 4.46754 2.26375 4.46754 4.27284C4.46754 6.28193 6.09481 7.9092 8.1039 7.9092C10.113 7.9092 11.7403 6.28193 11.7403 4.27284C11.7403 2.26375 10.113 0.636475 8.1039 0.636475ZM8.1039 8.81829C5.67663 8.81829 0.831177 10.0365 0.831177 12.4547V15.1819H15.3766V12.4547C15.3766 10.0365 10.5312 8.81829 8.1039 8.81829Z"
        fill={color}
      />
    </svg>
  );
};

export default User;
