import userSession from "@App/auth/userSession";
import { SHOW_REGISTRATION_DETAILS } from "@App/constants/userConstants";
import { UserType } from "@App/models/types";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GenericDetailsProps } from "./GenericDetailsPropTypes";

const GenericDetailsLogic = (props: GenericDetailsProps) => {
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [isFav, setIsFav] = useState(props.details.user?.isFavorite ?? false);
  const [isMobileEmbed] = useState(userSession.isMobileViewMode);
  const [showReservationRules, setShowReservationRules] = useState(false);
  const [CTAButtonText, setCTAButtonText] = useState<string | undefined>();
  const [isCTAButtonDisabled, setIsCTAButtonDisabled] = useState(false);

  const storeData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = storeData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showAccountDetails = () => {
    dispatch({
      type: SHOW_REGISTRATION_DETAILS,
      payload: {
        id: userInfo?.id,
        userVerificationCode: userInfo?.userVerificationCode,
      },
    });
  };

  useEffect(() => {
    if (!isUserLogged && !!userInfo?.firstName) {
      setIsUserLogged(true);
    }
    setIsFav(props.details?.user?.isFavorite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, props]);

  useEffect(() => {
    if (!isUserLogged) {
      setCTAButtonText(props.primaryButton?.notLoggedText);
      setIsCTAButtonDisabled(false);
      return;
    }

    // Disable button for Initiated and ResidentPending users
    if (userInfo && isUserLogged) {
      if (userInfo.userType === UserType.ResidentPending) {
        setCTAButtonText("Resident Verification Pending");
        setIsCTAButtonDisabled(true);
        return;
      }

      if (userInfo.userType === UserType.Initiated) {
        setCTAButtonText("Please Complete Account Setup");
        setIsCTAButtonDisabled(false);
        return;
      }

      setCTAButtonText(props.primaryButton?.loggedText);
      setIsCTAButtonDisabled(false);
    }
  }, [userInfo, isUserLogged, props]);

  const handleCTAButton = () => {
    if (!isUserLogged) {
      props.primaryButton?.notLoggedAction();
    } else if (userInfo && isUserLogged) {
      if (userInfo.userType === UserType.Initiated) {
        showAccountDetails();
      } else if (userInfo.userType !== UserType.ResidentPending) {
        props.primaryButton?.loggedAction();
      }
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return {
    CTAButtonText,
    isCTAButtonDisabled,
    isUserLogged,
    isMobileEmbed,
    isFav,
    showReservationRules,

    setShowReservationRules,
    handleCTAButton,
    goBack,
  };
};

export default GenericDetailsLogic;
