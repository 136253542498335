import { Button, Map } from "@Components/index";
import "./MapMini.scss";
import MapMiniLogic from "./MapMiniLogic";
import { MapMarker } from "@Components/icons";
import { MapMiniProps } from "./MapMiniPropTypes";

const MapMini = (componentProps: MapMiniProps) => {
  const props = MapMiniLogic(componentProps.defaultExapnded);
  return (
    <div className={"map-mini"}>
      {props.isMapExpanded ? (
        <div className={"map-mini__map"}>
          <Map
            pois={componentProps.pois}
            selectedPoi={componentProps.selectedPoi}
            onPoiClick={(poi) => {
              componentProps.onPoiClick && componentProps.onPoiClick(poi);
            }}
            onPoiHover={(poi) => {
              if (!componentProps.selectedPoi) {
                componentProps.onPoiHover && componentProps.onPoiHover(poi);
              }
            }}
            onViewDetailClick={(poi) => {
              if (!componentProps.selectedPoi) {
                componentProps.onViewDetailClick &&
                  componentProps.onViewDetailClick(poi);
              }
            }}
            onSelectionReset={() => {
              componentProps.onSelectionReset &&
                componentProps.onSelectionReset();
            }}
            onHoverReset={() => {
              componentProps.onHoverReset && componentProps.onHoverReset();
            }}
            onRefreshPois={componentProps.onRefreshPois}
            useLoader
          />
          <Button
            type={"close"}
            onClick={() => {
              props.setIsMapExpanded(false);
            }}
            style={{ position: "absolute", color: "#000", top: 0, right: 0 }}
          />
        </div>
      ) : (
        <div>
          <Button
            text={"Open Map"}
            icon={<MapMarker color={"#32373F"} />}
            style={{
              borderColor: "#32373F",
              color: "#32373F",
              fontSize: 20,
              height: 45,
              padding: 5,
              width: "100%",
            }}
            isSecondary={true}
            onClick={function (): void {
              props.setIsMapExpanded(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MapMini;
