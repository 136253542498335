import { initiateMembershipPayment } from "@App/api/memberships";
import { MEMBERSHIP_DOGS } from "@App/constants/appConstants";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const MembershipPaymentLogic = () => {
  const [membershipTitle, setMembershipTitle] = useState(String);
  const [parkName, setParkName] = useState(String);
  const [files, setFiles] = useState<any[]>([]);
  const { userMembershipId, rateId } = useParams<{
    userMembershipId: string;
    rateId: string;
  }>();
  const [membershipPurchaseData, setMembershipPurchaseData] =
    useState<any>(null);

  useEffect(() => {
    if (userMembershipId && rateId) {
      try {
        initiateMembershipPayment({
          membershipRequests: [{ userMembershipId, rateId }],
        }).then((res) => {
          setMembershipPurchaseData(res);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [rateId, userMembershipId]);

  useEffect(() => {
    //This can be dogs or anything else. We need to add the other scenarios we need.
    const membershipDetail = localStorage.getItem(MEMBERSHIP_DOGS);
    if (membershipDetail) {
      const membershipData = JSON.parse(membershipDetail);
      setMembershipTitle(membershipData.title);
      setParkName(membershipData.preferredPark);

      setFiles(membershipData.files);
    }
  }, []);
  return {
    membershipTitle,
    parkName,
    membershipPurchaseData,
    files,
  };
};

export default MembershipPaymentLogic;
