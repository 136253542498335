import { useEffect } from "react";

const HorizontalSliderLogic = () => {
  useEffect(() => {
    const horizontalSlider = document.querySelector(
      ".horizontal-slider-wrapper",
    );
    const horizontalSliderContent = document.querySelector(
      ".horizontal-slider-content",
    );
    let mouseDown = false;
    let startX: any, scrollLeft: any;
    if (horizontalSlider && horizontalSliderContent) {
      let startDragging = (e: any) => {
        mouseDown = true;
        startX = e.pageX - (horizontalSlider as any).offsetLeft;
        scrollLeft = horizontalSlider.scrollLeft;
      };

      let stopDragging = (event: any) => {
        mouseDown = false;
      };

      horizontalSlider.addEventListener("mousemove", (e) => {
        if (!mouseDown) {
          (horizontalSliderContent as any).style.pointerEvents = "all";
          return;
        }
        (horizontalSliderContent as any).style.pointerEvents = "none";
        e.preventDefault();
        const x = (e as any).pageX - (horizontalSlider as any).offsetLeft;
        const scroll = x - startX;
        horizontalSlider.scrollLeft = scrollLeft - scroll;
      });
      horizontalSlider.addEventListener("mousedown", startDragging, false);
      horizontalSlider.addEventListener("mouseup", stopDragging, false);
      horizontalSlider.addEventListener("mouseleave", stopDragging, false);
    }
  }, []);

  return {};
};

export default HorizontalSliderLogic;
