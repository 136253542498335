import ReactSelect from "react-select";
import { Option, MultiValue, ToolTip } from "../index";
import "./DropdownCheckbox.scss";

type DropdownCheckboxProps = {
  tabIndex?: number;
  options: any[];
  handleChange: any;
  optionSelected: any;
  label?: string;
  description?: string;
  placeholder?: string;
  optionLabel?: string;
  optionValue?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  isMulti?: boolean;
  className?: string;
  isLoading?: boolean;
  isInsideModal?: boolean;
  blurOnSelect?: boolean;
  hideBorder?: boolean;
  fontSize?: string;
  fontWeight?: string;
  maxMenuHeight?: number;
  dataCy?: string;
  tooltip?: string;
  onTooltipClick?: () => void;
  closeMenuOnSelect?: boolean;
};

const DropdownCheckbox = (props: DropdownCheckboxProps) => {
  return (
    <>
      <div className="dropdown-checkbox-info">
        <div className="dropdown-checkbox-text">
          {props.label && (
            <label className="dropdown-checkbox-label">{`${props.label}${
              props.isRequired ? "*" : ""
            }`}</label>
          )}
          {props.description && (
            <div className="dropdown-checkbox-description">{`${
              props.description
            }${props.isRequired ? "*" : ""}`}</div>
          )}
        </div>
        {props.tooltip && (
          <ToolTip
            onClick={() => props.onTooltipClick && props.onTooltipClick()}
            tooltipText={props.tooltip}
            tooltipPosition={props.isInsideModal ? "left" : "right"}
          />
        )}
      </div>
      <ReactSelect
        data-cy={props.dataCy}
        blurInputOnSelect={props.blurOnSelect ?? false}
        isLoading={props.isLoading}
        className={props.className}
        tabIndex={props.tabIndex}
        options={props.options}
        isMulti={props.isMulti ?? false}
        closeMenuOnSelect={props.closeMenuOnSelect ?? false}
        hideSelectedOptions={false}
        {...(props.isMulti && {
          components: { Option, MultiValue },
        })}
        {...(props.maxMenuHeight && {
          maxMenuHeight: props.maxMenuHeight,
        })}
        onChange={props.handleChange}
        value={props.optionSelected}
        required={props.isRequired}
        placeholder={props.placeholder}
        isClearable={props.isClearable ?? true}
        isDisabled={props.isDisabled ?? false}
        getOptionLabel={(option) => option[props.optionLabel ?? "label"]}
        getOptionValue={(option) => option[props.optionValue ?? "value"]}
        menuPlacement={"auto"}
        {...(props.isInsideModal && {
          menuPortalTarget: document.body,
          menuShouldScrollIntoView: true,
          menuShouldBlockScroll: true,
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "#c5cee0",
            minHeight: "50px",
            ...(props.hideBorder && {
              border: 0,
              boxShadow: "none",
            }),
            ...(props.fontSize && { fontSize: props.fontSize }),
            ...(props.fontWeight && { fontWeight: props.fontWeight }),
          }),
          ...(props.isInsideModal && {
            menuPortal: (baseStyles) => ({
              ...baseStyles,
              zIndex: 9999,
            }),
          }),
        }}
      />
    </>
  );
};

export default DropdownCheckbox;
