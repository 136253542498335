import { IconProps } from "../iconTypes";
//@ts-ignore
import themedStyles from "@Assets/styles/themedStyles.scss";

const Checklist = ({ color = themedStyles.cmsMainIconsColor }: IconProps) => {
  return (
    <svg
      width="80"
      height="56"
      viewBox="0 0 80 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_241_12269" fill="white">
        <rect
          width="6.49998"
          height="5.99999"
          rx="1.03332"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 35.5 23.7012)"
        />
      </mask>
      <rect
        width="6.49998"
        height="5.99999"
        rx="1.03332"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 35.5 23.7012)"
        stroke={color}
        strokeWidth="2.68266"
        mask="url(#path-1-inside-1_241_12269)"
      />
      <mask id="path-2-inside-2_241_12269" fill="white">
        <rect
          width="2.18018"
          height="5.93965"
          rx="1.03332"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 32.1006 20.7002)"
        />
      </mask>
      <rect
        width="2.18018"
        height="5.93965"
        rx="1.03332"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 32.1006 20.7002)"
        stroke="#F6F6F6"
        strokeWidth="2.18018"
        mask="url(#path-2-inside-2_241_12269)"
      />
      <rect
        x="4.43214e-08"
        y="-0.350531"
        width="0.495726"
        height="2.04984"
        rx="0.247863"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 33.0519 21.1038)"
        stroke={color}
        strokeWidth="0.495726"
      />
      <rect
        x="0.323798"
        y="1.36471e-08"
        width="0.457919"
        height="5.48173"
        rx="0.22896"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 32.8458 20.771)"
        stroke={color}
        strokeWidth="0.457919"
      />
      <mask id="path-5-inside-3_241_12269" fill="white">
        <rect
          width="6.49998"
          height="5.99999"
          rx="1.03332"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 35.5 33.2012)"
        />
      </mask>
      <rect
        width="6.49998"
        height="5.99999"
        rx="1.03332"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 35.5 33.2012)"
        stroke={color}
        strokeWidth="2.68266"
        mask="url(#path-5-inside-3_241_12269)"
      />
      <mask id="path-6-inside-4_241_12269" fill="white">
        <rect
          width="2.18018"
          height="5.93965"
          rx="1.03332"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 32.1006 30.2002)"
        />
      </mask>
      <rect
        width="2.18018"
        height="5.93965"
        rx="1.03332"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 32.1006 30.2002)"
        stroke="#F6F6F6"
        strokeWidth="2.18018"
        mask="url(#path-6-inside-4_241_12269)"
      />
      <rect
        x="4.43214e-08"
        y="-0.350531"
        width="0.495726"
        height="2.04984"
        rx="0.247863"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 33.0519 30.6038)"
        stroke={color}
        strokeWidth="0.495726"
      />
      <rect
        x="0.323798"
        y="1.36471e-08"
        width="0.457919"
        height="5.48173"
        rx="0.22896"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 32.8458 30.271)"
        stroke={color}
        strokeWidth="0.457919"
      />
      <mask id="path-9-inside-5_241_12269" fill="white">
        <rect
          width="6.49998"
          height="5.99999"
          rx="1.03332"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 35.5 43.7012)"
        />
      </mask>
      <rect
        width="6.49998"
        height="5.99999"
        rx="1.03332"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 35.5 43.7012)"
        stroke={color}
        strokeWidth="2.68266"
        mask="url(#path-9-inside-5_241_12269)"
      />
      <mask id="path-10-inside-6_241_12269" fill="white">
        <rect
          width="2.18018"
          height="5.93965"
          rx="1.03332"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 32.1016 40.7002)"
        />
      </mask>
      <rect
        width="2.18018"
        height="5.93965"
        rx="1.03332"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 32.1016 40.7002)"
        stroke="#F6F6F6"
        strokeWidth="2.18018"
        mask="url(#path-10-inside-6_241_12269)"
      />
      <rect
        x="4.43214e-08"
        y="-0.350531"
        width="0.495726"
        height="2.04984"
        rx="0.247863"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 33.0519 41.1038)"
        stroke={color}
        strokeWidth="0.495726"
      />
      <rect
        x="0.323798"
        y="1.36471e-08"
        width="0.457919"
        height="5.48173"
        rx="0.22896"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 32.8458 40.771)"
        stroke={color}
        strokeWidth="0.457919"
      />
      <path
        d="M46.5902 2.52209C47.5937 2.82618 48.28 3.75121 48.28 4.79978L48.28 6.40001C48.28 7.71443 47.2145 8.77997 45.9 8.77997L35.6001 8.77997C34.2857 8.77997 33.2201 7.71443 33.2201 6.4L33.2201 4.79977C33.2201 3.75121 33.9064 2.82618 34.9099 2.52209L40.0598 0.961487C40.5098 0.825111 40.9902 0.82511 41.4402 0.961485L46.5902 2.52209Z"
        stroke={color}
        strokeWidth="1.44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6048 6H25.1C23.3879 6 22 7.3879 22 9.09997L22 52.9C22 54.6121 23.3879 56 25.1 56H55.9C57.6121 56 59 54.6121 59 52.9L59 9.09997C59 7.3879 57.6121 6 55.9 6H48.8953C48.9638 6.25655 49 6.52491 49 6.79978V7.44L55.9 7.44C56.8168 7.44 57.56 8.18319 57.56 9.09997L57.56 52.9C57.56 53.8168 56.8168 54.56 55.9 54.56H25.1C24.1832 54.56 23.44 53.8168 23.44 52.9L23.44 9.09997C23.44 8.18319 24.1832 7.43999 25.1 7.43999L32.5001 7.44V6.79977C32.5001 6.5249 32.5363 6.25655 32.6048 6Z"
        fill={color}
      />
      <rect
        x="55.32"
        y="10.72"
        width="40.5598"
        height="29.6398"
        rx="2.37997"
        transform="rotate(90 55.32 10.72)"
        stroke={color}
        strokeWidth="1.44"
      />
      <rect
        x="-0.499999"
        y="-0.499999"
        width="0.999997"
        height="4.99999"
        rx="0.499999"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 44 18)"
        stroke={color}
        strokeWidth="0.999997"
      />
      <rect
        x="-0.499999"
        y="-0.499999"
        width="0.999997"
        height="4.99999"
        rx="0.499999"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 44 27.5)"
        stroke={color}
        strokeWidth="0.999997"
      />
      <rect
        x="-0.499999"
        y="-0.499999"
        width="0.999997"
        height="4.99999"
        rx="0.499999"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 44 38)"
        stroke={color}
        strokeWidth="0.999997"
      />
      <rect
        x="-0.499999"
        y="-0.499999"
        width="0.999997"
        height="12"
        rx="0.499999"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51 31)"
        stroke={color}
        strokeWidth="0.999997"
      />
      <rect
        x="-0.499999"
        y="-0.499999"
        width="0.999997"
        height="12"
        rx="0.499999"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51 21.5)"
        stroke={color}
        strokeWidth="0.999997"
      />
      <rect
        x="-0.499999"
        y="-0.499999"
        width="0.999997"
        height="12"
        rx="0.499999"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51 41.5)"
        stroke={color}
        strokeWidth="0.999997"
      />
    </svg>
  );
};

export default Checklist;
