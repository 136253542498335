import "./Notifications.scss";
import { Navbar } from "@Layouts/index";
import NotificationsLogic from "./NotificationsLogic";
import { NotificationItem, Tag, Loader } from "@Components/index";
import { Fragment } from "react";

/* TODO: Switch notification index for id when backend is connected */
const Notifications = () => {
  const {
    notifcationsData,
    isLoading,
    ref,
    hasNextPage,
    selectedFilter,
    handleSelectFilter,
  } = NotificationsLogic();

  return (
    <div className={"notifications-container"}>
      <Navbar>
        <section className={"notifications-section-wrapper"}>
          <div className={"notifications-section"}>
            <h1 className={"notifications-section__title"}>Notifications</h1>
            <div className={"notifications-section__header"}>
              <Tag
                text="All"
                onClick={() => handleSelectFilter("all")}
                isSelected={selectedFilter === "all"}
              />
              <Tag
                text="Unread"
                onClick={() => handleSelectFilter("unread")}
                isSelected={selectedFilter === "unread"}
              />
              <Tag
                text="Starred"
                onClick={() => handleSelectFilter("starred")}
                isSelected={selectedFilter === "starred"}
              />
            </div>
            {isLoading ? (
              <Loader color="#000" />
            ) : (
              <>
                <div className={"notifications-section__list"}>
                  {notifcationsData &&
                    notifcationsData.pages.map((page, i) => (
                      <Fragment key={i}>
                        {page.items.map((notification) => (
                          <NotificationItem
                            key={notification.id}
                            notification={notification}
                            canExpand={true}
                          />
                        ))}
                      </Fragment>
                    ))}
                  {hasNextPage && (
                    <div ref={ref}>
                      <Loader color={"#000"} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </section>
      </Navbar>
    </div>
  );
};

export default Notifications;
