import { GenericListingRowProps } from "./GenericListingPropTypes";
import { ImageFilesCarousel, Loader, SideScroll } from "@Components/index";
import GenericListingRowLogic from "./GenericListingRowLogic";

const CUSTOM_HEIGHT_DEFAULT = "180px";

const GenericListingRow = ({
  title,
  listingId,
  onClick,
  customHeight = CUSTOM_HEIGHT_DEFAULT,
  getCategoryItems,
  searchText,
  canBeBooked,
  getFilterRequestParams,
  searchFilters,
  moduleName,
  onListLoaded,
}: GenericListingRowProps) => {
  const { items, hasNextPage, ref } = GenericListingRowLogic({
    listingId,
    getCategoryItems,
    searchText,
    searchFilters,
    getFilterRequestParams,
    onClick,
    customHeight,
    canBeBooked,
    moduleName,
    onListLoaded,
  });

  return items && items.length > 0 ? (
    <div
      className={"generic-listing-row"}
      data-cy={`listing-row-${title.replaceAll(" ", "")}`}>
      {title && <div className={"generic-listing-row-title"}>{title}</div>}

      <SideScroll verticalMargin={50}>
        {items?.map((listItem, index) => {
          return (
            <div
              key={`generic_listing_item_${index}`}
              className={"generic-listing-row-item"}
              onClick={() => {
                onClick && onClick(listItem.id);
              }}
              data-cy={`image-link-${title.replaceAll(
                " ",
                "",
              )}-${listItem.title.replaceAll(" ", "")}`}>
              <div
                className={`generic-listing-row-item-image ${
                  customHeight !== CUSTOM_HEIGHT_DEFAULT
                    ? "generic-listing-row-item-image--small"
                    : ""
                }`}>
                <ImageFilesCarousel
                  customHeight={customHeight}
                  files={listItem.files}
                  showArrows={false}
                  showIndicators={false}
                  swipeable={false}
                />
              </div>

              <div className={`generic-listing-row-item-title`}>
                {listItem.title}
              </div>
            </div>
          );
        })}
        {hasNextPage && (
          <div ref={ref} className={"generic-listing-row-loader"}>
            <Loader color={"#000"} />
          </div>
        )}
      </SideScroll>
    </div>
  ) : null;
};

export default GenericListingRow;
