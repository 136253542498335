import { baseServiceRequest } from "./baseService";
import { CommunityGroupRequest } from "./requests/communityGroup";

export const getCommunityGroupById = async (communityGroupId: string) => {
  return await baseServiceRequest({
    path: `/communityGroup/${communityGroupId}`,
    type: "get",
    useAuthHeader: true,
  });
};

export const getCommunityGroups = async (
  requestParams: CommunityGroupRequest,
) => {
  return await baseServiceRequest({
    path: `/communityGroup/Search`,
    type: "get",
    useAuthHeader: true,
    params: requestParams,
  });
};
