import "./UserMenu.scss";
import { UserMenuProps } from "./UserMenuPropTypes";
import { Link } from "react-router-dom";

const UserMenu = (componentProps: UserMenuProps) => {
  return (
    <div className={"user-menu"}>
      <div
        className={
          "user-menu-user-text"
        }>{`Hello ${componentProps.firstname}`}</div>
      <div className={"user-menu-loyalty"}>
        {/* Leaving this for when we add the Loyalty points  */}
        {/*<div className={"user-menu-image-wrapper"}>
          <img
            className={"user-menu-image"}
            src={require("@Assets/images/diamond_x2.png")}
            alt={""}
          />
        </div>
        <div>
          <div>Loyalty Points</div>
          <div className={"user-menu-loyalty-points"}>
            {formatThousands(componentProps.loyaltyPoints)}
          </div>
        </div>*/}
      </div>
      <div className={"user-menu-text"}>
        <Link to="/profile">Profile & Settings</Link>
      </div>
      <div
        className={"user-menu-text"}
        onClick={componentProps.onLogout}
        data-cy={componentProps.dataCy}>
        Log Out
      </div>
    </div>
  );
};

export default UserMenu;
