import { FileMetadata } from "@App/models/File";
import { Dispatch, SetStateAction } from "react";

type ImageThumbnailsProps = {
  files: (File | FileMetadata)[];
  fileInputRef: React.RefObject<HTMLInputElement>;
  setFiles: Dispatch<SetStateAction<(File | FileMetadata)[]>>;
};

export default function ImageThumbnails({
  files,
  fileInputRef,
  setFiles,
}: ImageThumbnailsProps) {
  return (
    <div className="file-upload-preview-wrapper">
      {files.map((file, index) => {
        const isPrimaryImage = index === 0;
        const isLocalBlob = file instanceof File;

        return (
          <div
            key={isLocalBlob ? file.name : file.url}
            className={`file-upload-preview-image-wrapper ${isPrimaryImage ? "file-upload-text" : ""}`}
          >
            {isPrimaryImage && <div>Primary Image</div>}
            <img
              className={`file-upload-preview-image ${index === files.length - 1 ? "file-upload-preview-image-new" : ""}`}
              src={isLocalBlob ? URL.createObjectURL(file) : file.url}
            ></img>
            <button
              className="file-upload-preview-delete"
              style={{ top: isPrimaryImage ? "40px" : "23px" }}
              onClick={() => {
                if (!isLocalBlob) {
                  // TODO: Delete image from server. We should already have this somewhere...
                  alert("Delete image from server");
                  return;
                }

                setFiles((prevFiles) => {
                  const newFiles = prevFiles.filter((f, i) => i !== index);
                  return newFiles;
                });
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
              }}
            >
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                  fill="#32373F"
                />
              </svg>
            </button>
          </div>
        );
      })}
    </div>
  );
}
