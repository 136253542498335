import { Article } from "@App/models/Article";
import { dateISOToLongLocal } from "@Utils/utils";
import { useState } from "react";

type NewsAndArticlesCardProps = {
  article: Article;
};

export const NewsAndArticlesCard = ({ article }: NewsAndArticlesCardProps) => {
  const [areDetailsVisible, setAreDetailsVisible] = useState(false);

  if (!article) {
    return null;
  }

  const { title, previewText, detailHtml, publishedDate } = article;
  return (
    <div className="news-and-articles__card">
      <h2>{title}</h2>
      <span className="news-and-articles__card__date">
        {dateISOToLongLocal(publishedDate)}
      </span>
      {!areDetailsVisible ? (
        <div>{previewText}</div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: detailHtml }}></div>
      )}
      <div
        onClick={() => setAreDetailsVisible(!areDetailsVisible)}
        className="news-and-articles__card__read-more">
        {areDetailsVisible ? "Read Less" : "Read More..."}
      </div>
    </div>
  );
};
