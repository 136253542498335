import "./HorizontalSlider.scss";
import HorizontalSliderLogic from "./HorizontalSliderLogic";

const HorizontalSlider = ({ children }: any) => {
  HorizontalSliderLogic();

  return (
    <div className={"horizontal-slider-wrapper"}>
      <div className={"horizontal-slider-content"}>{children}</div>
    </div>
  );
};

export default HorizontalSlider;
