import "./Basic3ColumnFooterTemplate.scss";
import {
  TenantFooterLinks,
  TenantLogo,
  TenantMobileAppLinks,
  TenantCopyright,
  VersionNumber,
  TenantAdminEmail,
} from "@Components/index";

const Basic3ColumnFooterTemplate = () => {
  return (
    <div className={"basic-3-column-footer-template"}>
      <TenantFooterLinks />
      <TenantLogo />
      <VersionNumber />
      <TenantMobileAppLinks />
      <TenantCopyright />
      <TenantAdminEmail />
    </div>
  );
};

export default Basic3ColumnFooterTemplate;
