import { useState } from "react";

const WaiversModalLogic = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const onCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };
  return {
    termsAccepted,
    onCheckboxChange,
  };
};

export default WaiversModalLogic;
