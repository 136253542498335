import { useMemo, useState } from "react";
import { TicketPurchaseProps } from "./TicketPurchasePropTypes";
import { useRecurringEventDates } from "@App/hooks";
import {
  format,
  startOfMonth,
  endOfMonth,
  formatISO,
  isBefore,
  eachMonthOfInterval,
} from "date-fns";

const TicketPurchaseLogic = (props: TicketPurchaseProps) => {
  const [selectedMonth, setSelectedMonth] = useState<{
    label: string;
    value: Date;
  }>(() => {
    const initialSelectedDate: Date = !!props.initialSelectedDate
      ? new Date(props.initialSelectedDate)
      : new Date();
    initialSelectedDate.setDate(1); // Set to first date of month
    return {
      label: format(initialSelectedDate, "MMMM, yyyy"),
      value: initialSelectedDate,
    };
  });
  const { id: eventId } = props.eventData;
  const memoizedDate = useMemo(() => {
    return new Date();
  }, []);
  const isStartPriorToToday = useMemo(() => {
    return isBefore(startOfMonth(selectedMonth.value), memoizedDate);
  }, [selectedMonth, memoizedDate]);

  const { recurringEventDates, isLoadingRecurringEventDates } =
    useRecurringEventDates({
      eventId,
      startDate: formatISO(
        isStartPriorToToday ? memoizedDate : startOfMonth(selectedMonth.value),
      ),
      endDate: formatISO(endOfMonth(selectedMonth.value)),
    });

  const getLabelValuesMonths = () => {
    const startDate = new Date(props.eventData.startDate);
    const endDate = !!props.eventData.recurring?.terminationDate
      ? new Date(props.eventData.recurring?.terminationDate)
      : new Date();

    const months = eachMonthOfInterval({
      start: startDate,
      end: endDate > startDate ? endDate : startDate,
    }).map((date: Date) => {
      return {
        label: format(date, "MMMM, yyyy"),
        value: date,
      };
    });
    return months;
  };

  return {
    recurringEventDates,
    isLoadingRecurringEventDates,
    selectedMonth,
    setSelectedMonth,
    getLabelValuesMonths,
  };
};

export default TicketPurchaseLogic;
