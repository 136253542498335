import { UserPreferenceMutationParams } from "@App/hooks/api/events/UserPreferenceMutationParams";
import useUpdateEventUserPreference from "@App/hooks/api/events/useUpdateEventUserPreference";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TicketPurchaseRowsProps } from "./TicketPurchaseRowsPropTypes";

const TicketPurchaseRowsLogic = (props: TicketPurchaseRowsProps) => {
  const [selectedTime, setSelectedTime] = useState(-1);
  const navigate = useNavigate();
  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const { userInfo } = userData;

  // TODO: Check if we need to do anything here
  const handleOnMutation = () => undefined;

  const handleOnSuccess = (params: UserPreferenceMutationParams) => {
    props.onClose(params.eventId);
  };

  const updateUserEventPreferenceMutation = useUpdateEventUserPreference({
    eventTitle: props.event.title, //find me
    onMutate: handleOnMutation,
    onSuccess: handleOnSuccess,
  });

  const onBookClick = async (indexSelected?: number) => {
    const { id, eventType } =
      // TODO: Clean this mess up
      props.recurringDates[
        indexSelected !== undefined &&
        Number.isInteger(indexSelected) &&
        indexSelected >= 0
          ? indexSelected
          : selectedTime
      ];

    if (!id || !userInfo) return;

    if (id && eventType === "Ticketed") {
      props.onClose();
      navigate(`/calendar/event-details/tickets?id=${id}`);
      return;
    }

    if (eventType === "General") {
      await updateUserEventPreferenceMutation.mutateAsync({
        eventId: id,
        userId: userInfo.id,
        preference: {
          name: "isRSVPd",
          value: true,
        },
      });
    }
  };

  return {
    selectedTime,
    setSelectedTime,
    onBookClick,
  };
};

export default TicketPurchaseRowsLogic;
