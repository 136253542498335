import { addWaiverUserMembership } from "@App/api/memberships";
import { AddMembershipWaiverParams } from "@App/api/requests/memberships";
import { Membership } from "@App/models/membership";
import { UserType } from "@App/models/types";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import {
  Button,
  Loader,
  MembershipDogForm,
  Modal,
  Stepper,
} from "@Components/index";
import { sendMobileViewModePostMessage } from "@Utils/utils";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./MembershipModal.scss";
import MembershipDogTerms from "./membership-dog-terms/MembershipDogTerms";

export type MembershipModalProps = {
  membership: Membership;
  isVisible: boolean;
  userType: number;
  onClose: () => void;
};

const MembershipModal = ({
  membership,
  isVisible,
  userType,
  onClose,
}: MembershipModalProps) => {
  const navigate = useNavigate();
  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = userData;

  const [newUserMembershipId, setNewUserMembershipId] = useState<string>();
  const [currentStep, setCurrentStep] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    sendMobileViewModePostMessage({
      isLoading: isProcessing,
      text: "Processing, please wait",
    });
  }, [isProcessing]);

  const handleNextStep = async (userMembershipId: string) => {
    setCurrentStep(2);
    setNewUserMembershipId(userMembershipId);
  };

  const addMembershipWaiverMutation = useMutation({
    mutationFn: (params: AddMembershipWaiverParams) =>
      addWaiverUserMembership(params),
    onMutate: () => setIsProcessing(true),
    onSettled: () => setIsProcessing(false),
    onSuccess: () => {
      const rateId = membership.rates.find((rate) =>
        rate.userTypeIds.includes(UserType[userType]),
      )?.id;

      navigate(
        "/memberships/membership-payment/" + newUserMembershipId + "/" + rateId,
      );
    },
  });

  const proceedToPayment = async () => {
    if (!newUserMembershipId || !userInfo?.id) {
      throw new Error(
        "User Membership Id or User Id is missing. Unable to proceed to payment.",
      );
    }

    const addWaiverRequest = {
      id: newUserMembershipId,
      membershipId: membership.id,
      userId: userInfo.id,
      waiverId: membership.waivers[0].id,
    };

    addMembershipWaiverMutation.mutate(addWaiverRequest);
  };

  return (
    <Modal isVisible={isVisible}>
      <div className={"membership-modal"}>
        <div className={"membership-modal-top"}>
          <Button
            type={"close"}
            onClick={onClose}
            style={{ padding: 0, color: "#000" }}
          />
          <Stepper steps={[1, 2]} currentStep={currentStep} />
        </div>
        {currentStep === 1 ? (
          <MembershipDogForm
            membership={membership}
            handleNextStep={(userMembershipId) =>
              handleNextStep(userMembershipId)
            }
          />
        ) : (
          <MembershipDogTerms
            waiver={membership?.waivers[0] ?? ""}
            proceedToPayment={proceedToPayment}
          />
        )}
      </div>
      {isProcessing && <Loader text="Processing, please wait" modal />}
    </Modal>
  );
};

export default MembershipModal;
