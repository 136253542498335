import { LabelWithValueProps } from "./LabelWithValuePropTypes";
import "./LabelWithValue.scss";

const LabelWithValue = (componentProps: LabelWithValueProps) => {
  const valueClassname = componentProps.useBold
    ? "label-with-value-bolder"
    : "";
  return (
    <div className={"label-with-value-cell label-with-value-text"}>
      <div className={"label-with-value-label"}>{componentProps.label}</div>
      {componentProps.value ? (
        <div className={valueClassname}>{componentProps.value}</div>
      ) : (
        componentProps.values?.map((value, index) => {
          return (
            <div key={`value${index}`} className={valueClassname}>
              {value}
            </div>
          );
        })
      )}
      {!componentProps.value && !componentProps.values?.length ? "-" : ""}
    </div>
  );
};

export default LabelWithValue;
