import { DEFAULT_PAGE_SIZE } from "@App/constants/pageParamConstants";
import {
  AxiosGenericPagedResponse,
  GenericPageItem,
} from "@App/models/BasePagedResponse";
import { Loader } from "@aws-amplify/ui-react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  PaginationState,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { MemoizedTableBody, TableBody } from "./TableBody";
import useTablePagedQuery from "./useTablePagedQuery";
import { DEFAULT_TABLE_RESIZE_SPEED } from "@Components/table/utils";
import { TableRequest } from "@Api/requests/table";
import "./Table.scss";
import PaginationControlsV2 from "@Components/table/PaginationControlsV2";

type TableProps<TData, TSortOptions, TFilterOptions> = {
  // Devs haven't fixed the type of columnDef yet
  columns: ColumnDef<GenericPageItem<TData>, any>[];
  queryKey: string[];
  queryFn: (
    request: TableRequest<TSortOptions, TFilterOptions>,
  ) => Promise<AxiosGenericPagedResponse<TData>>;
  filterOptions?: TFilterOptions;
  rowOnClick?: (row: Row<GenericPageItem<TData>>) => void;
  highlightOnHover?: boolean;
  pointerOnHover?: boolean;
  enabled?: boolean;
  resizeTrigger?: boolean;
  id?: string;
  containerWidth?: number;
  resizeSpeed?: number;
  emptyResultMessage?: string;
};

export default function Table<TData, TSortOptions, TFilterOptions>({
  columns,
  queryKey,
  queryFn,
  filterOptions,
  rowOnClick,
  highlightOnHover,
  pointerOnHover,
  enabled,
  resizeTrigger,
  id,
  emptyResultMessage,
  containerWidth = 100, // In percentage
  resizeSpeed = DEFAULT_TABLE_RESIZE_SPEED, // Larger number means slower resizing
}: TableProps<TData, TSortOptions, TFilterOptions>) {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  // TODO: Add to table and concat with filterOptions
  const [sortOptions, setSortOptions] = useState<TSortOptions | undefined>();
  const [filterOptionsState, setFilterOptions] = useState<
    TFilterOptions | undefined
  >(filterOptions);

  const { data, error, isLoading, isFetching, isPreviousData } =
    useTablePagedQuery<TData, TSortOptions, TFilterOptions>({
      queryKey,
      queryFn,
      id,
      pagination,
      sortOptions,
      filterOptions,
      enabled,
    });

  // TODO: Handle default state on load, use blank rows or a spinner
  const table = useReactTable({
    data: data?.items || [],
    columns,
    defaultColumn: {
      // Sizes are set as percentages for responsiveness
      // Resize speed factor slows down resizing by allowing for more values per pixel change
      minSize: columns?.length
        ? ((100 / columns.length) * resizeSpeed) / 2
        : 5 * resizeSpeed,
      // size: 25 * resizeSpeed,
      size: columns?.length
        ? (100 / columns.length) * resizeSpeed
        : 15 * resizeSpeed,
      maxSize: 75 * resizeSpeed,
    },
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    rowCount: data?.totalItemsCount,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    debugTable: true,
  });

  // Use CSS vars to store sizes, this allows us to calc all the widths at once and memoize them.
  // TODO: This needs some more work. Not quite there yet.
  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: string } = {};

    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      const headerSize = `${header.getSize() / resizeSpeed}%`;
      const cellSize = `${header.column.getSize() / resizeSpeed}%`;

      colSizes[`--header-${header.id}-size`] = headerSize;
      colSizes[`--col-${header.column.id}-size`] = cellSize;
    }
    return colSizes;
  }, [
    table.getState().columnSizingInfo,
    table.getState().columnSizing,
    isLoading,
    resizeTrigger,
  ]);

  if (isLoading) {
    return (
      <div className="table-loader-container">
        <Loader
          className=" w-12"
          // TODO: Update UI package to latest and remove this.
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </div>
    );
  }

  const isDataAvailable = !(
    pagination.pageIndex === 0 && data?.items.length === 0
  );

  return (
    <div style={{ width: `${containerWidth}%` }}>
      <table
        style={{
          ...columnSizeVars,
          // width: `${table.getTotalSize() / resizeSpeed}%`,
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="table-header-row">
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="table-header"
                    style={{
                      width: `var(--header-${header?.id}-size)`,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {header.index !== headerGroup.headers.length - 1 && (
                      <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={"table-header-resizer-container"}
                      >
                        <div
                          // TODO: Hover effect doesn't work
                          className="table-header-resizer"
                          style={{
                            backgroundColor: header.column.getIsResizing()
                              ? "rgba(0,0,0,1)"
                              : "rgba(0,0,0,0.5)",
                          }}
                        ></div>
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {!isDataAvailable && (
          <tr>
            <td colSpan={5}>
              <p className={"text-center"}>
                {emptyResultMessage ?? "No data found"}
              </p>
            </td>
          </tr>
        )}
        {isDataAvailable &&
        table.getState().columnSizingInfo.isResizingColumn ? (
          <MemoizedTableBody
            table={table}
            resizeSpeed={resizeSpeed}
            highlightOnHover={highlightOnHover}
            pointerOnHover={pointerOnHover}
            onClick={rowOnClick}
          />
        ) : (
          <TableBody
            table={table}
            resizeSpeed={resizeSpeed}
            highlightOnHover={highlightOnHover}
            pointerOnHover={pointerOnHover}
            onClick={rowOnClick}
          />
        )}
      </table>
      {isDataAvailable && (
        <PaginationControlsV2 table={table} isFetching={isFetching} />
      )}
    </div>
  );
}
