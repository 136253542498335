import { AxiosResponse } from "axios";
import { NotificationsData } from "./requests/notifications";
import { baseServiceRequest } from "./baseService";

type GetNotificationsProps = {
  page?: number;
  pageSize?: number;
  isRead?: boolean;
  isStarred?: boolean;
};

export const getNotifications = async ({
  page = 1,
  pageSize = 10,
  isRead,
  isStarred,
}: GetNotificationsProps) => {
  //console.log("in getNotifications+" + " isStarred: " + isStarred + " isRead: " + isRead);
  const response: AxiosResponse<NotificationsData, any> =
    await baseServiceRequest({
      path: `/Notification/Search?pageSize=${pageSize}&page=${page}${queryParamExcludeIfNull(
        "isRead",
        isRead,
      )}${queryParamExcludeIfNull("isStarred", isStarred)}`,
      type: "get",
      useAuthHeader: true,
    });
  return response;
};

const queryParamExcludeIfNull = (name: string, value?: string | boolean) => {
  if (value !== null && value !== undefined) {
    return `&${name}=${value}`;
  }

  return "";
};

export const deleteNotificationForUserAsync = async (
  notificationId: string,
) => {
  const response: AxiosResponse<NotificationsData, any> =
    await baseServiceRequest({
      path: `/Notification/${notificationId}`,
      type: "delete",
      useAuthHeader: true,
    });
  return response;
};

export const updateIsReadAsync = async (
  notificationId: string,
  isRead: boolean,
) => {
  const response: AxiosResponse<NotificationsData, any> =
    await baseServiceRequest({
      path: `/Notification/${notificationId}`,
      type: "put",
      useAuthHeader: true,
      postData: { isRead: isRead },
    });
  return response;
};

export const updateIsStarredAsync = async (
  notificationId: string,
  isStarred: boolean,
) => {
  const response: AxiosResponse<NotificationsData, any> =
    await baseServiceRequest({
      path: `/Notification/${notificationId}`,
      type: "put",
      useAuthHeader: true,
      postData: { isStarred: isStarred },
    });
  return response;
};
