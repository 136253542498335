import { Navbar } from "@Layouts/index";
import "./MembershipPayment.scss";
import MembershipPaymentLogic from "./MembershipPaymentLogic";
import { Loader, Payments } from "@Components/index";

const MembershipPayment = () => {
  const props = MembershipPaymentLogic();
  return (
    <Navbar>
      <div className={"membership-payment"}>
        {!props.membershipPurchaseData ? (
          <Loader text={"Loading, please wait"} modal />
        ) : (
          <Payments
            items={null}
            initialStep={1}
            paymentData={{
              id: "3",
              title: "Dog Park Membership",
              dogPark: props.parkName,
              files: props.files,
              type: "membership",
            }}
            membership={props.membershipPurchaseData?.data}
          />
        )}
      </div>
    </Navbar>
  );
};

export default MembershipPayment;
