import Button from "@Components/button/Button";
import { PaymentElement } from "@stripe/react-stripe-js";
import "./StripeElements.scss";
import StripeElementsLogic from "./StripeElementsLogic";
import { StripeElementsProps } from "./StripeElementsPropTypes";
import StripePaymentError from "./StripePaymentError";

const StripeElements = (componentProps: StripeElementsProps) => {
  const props = StripeElementsLogic(componentProps);

  return (
    <>
      {props.error && <StripePaymentError message={props.error} />}
      <div className={"stripe-elements"} style={{ padding: "16px" }}>
        <PaymentElement onChange={props.handleChange} />
      </div>
      <Button
        text="Pay Order"
        style={{ maxWidth: "441px" }}
        onClick={props.handleClick}
        isDisabled={
          componentProps.isDisabled ||
          props.isPaymentLoading ||
          props.isPaymentEnabled
        }
        isLoading={props.isPaymentLoading}
      />
    </>
  );
};

export default StripeElements;
