import { config } from "@App/config/config";
import { FileMetadata } from "@App/models/File";
import { Dispatch, SetStateAction, useState } from "react";

const FileUploadLogic = (
  setFiles: Dispatch<SetStateAction<(File | FileMetadata)[]>>,
  hideDefaultInformationMessage?: boolean,
) => {
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null,
  );
  const [informationMessage, setInformationMessage] = useState<string | null>(
    hideDefaultInformationMessage
      ? null
      : `Please check image size(s) before uploading.`,
  );

  const getFiles = (items: DataTransferItem[]) => {
    return items.map((item) => item.getAsFile()).filter((file) => !!file);
  };

  const handleLocalFileUpload = ({
    event,
    browseFiles,
  }: {
    event?: React.DragEvent<HTMLDivElement> | null;
    browseFiles?: FileList | null;
  }) => {
    let files: File[] = [];

    // Either drag and drop or browse files
    if (!!event) {
      event.preventDefault();

      // Type error that only comes up in the console. Think it has problems with the file type
      // @ts-ignore
      files = getFiles([...event.dataTransfer.items]);
    } else if (!!browseFiles) {
      files = Array.from(browseFiles);
    } else {
      return;
    }

    if (!files?.length) {
      return;
    }

    files.forEach((file) => {
      const fileUploadErrorMessage = validateImage(file);

      if (!!fileUploadErrorMessage) {
        setValidationMessage(fileUploadErrorMessage);
      } else {
        setValidationMessage(null);
        setInformationMessage(null);
        setFiles((prevFiles) => [...prevFiles, file]);
      }
    });
  };

  return {
    validationMessage,
    informationMessage,
    handleLocalFileUpload,
  };
};

export default FileUploadLogic;

const getFileSize = (bytes: number, decimals = 2) => {
  if (!+bytes) return { size: 0, type: "Bytes" };

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    type: sizes[i],
  };
};

const validateImage = (file: File): string | null => {
  const supportedImageTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/pdf",
  ];
  const isValidType = supportedImageTypes.indexOf(file.type) > -1;
  let message = null;

  if (!isValidType) {
    message = `"${file.name}" - File should be PNG, JPG, JPEG or PDF.`;
  } else {
    const fileSize = getFileSize(file.size);
    const errorMessage = `"${file.name}" - File size should be less than ${config.maxFileSizeMB} MB`;
    switch (fileSize.type) {
      case "Bytes":
      case "KB":
        break;
      case "MB":
        if (fileSize.size > config.maxFileSizeMB) {
          message = errorMessage;
        }
        break;
      default:
        message = errorMessage;
        break;
    }
  }

  return message;
};
