import { CLOSE_HOVER_MENUS_MIN } from "@App/constants/appConstants";
import {
  customEventPublish,
  customEventSubscribe,
  customEventUnsubscribe,
} from "@Utils/utils";
import { useEffect, useState } from "react";
import { HoverListMenuProps } from "@Components/hover-list-menu/HoverListMenuPropTypes";

const HoverListMenuLogic = (props: HoverListMenuProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    //Check is the style has been already added (hover style for each instance)
    const styleAlreadyAdded =
      document
        .getElementsByTagName("head")[0]
        .innerHTML.indexOf(`hover-list-menu-item-${props.classHooks}`) > -1;

    if (!styleAlreadyAdded) {
      const css = ` @media only screen and (min-width: 768px) {
        .hover-list-menu:hover .hover-list-menu-item-${props.classHooks} { 
          display: flex;
        }
        .hover-list-menu:hover  .hover-list-menu-icon { 
          display: none;
        }
        .hover-list-menu:hover  .hover-list-menu-icon-expanded { 
          display: flex;
        }
      }`;
      var style = document.createElement("style");
      style.appendChild(document.createTextNode(css));
      document.getElementsByTagName("head")[0].appendChild(style);
    }
    customEventSubscribe(CLOSE_HOVER_MENUS_MIN, () => setIsExpanded(false));

    return () => {
      customEventUnsubscribe(CLOSE_HOVER_MENUS_MIN, () => setIsExpanded(false));
    };
  }, [props.classHooks]);

  const onIconClick = () => {
    customEventPublish(CLOSE_HOVER_MENUS_MIN, {});
    setIsExpanded(!isExpanded);
  };

  return { isExpanded, onIconClick, ...props };
};

export default HoverListMenuLogic;
