import { useState } from "react";
import { IconButtonProps } from "./IconButtonProps";

const useIconButtonLogic = (props: IconButtonProps) => {
  const [isProcessingClick, setIsProcessingClick] = useState(false);

  const handleClickAsync = async () => {
    setIsProcessingClick(true);
    await props.onClickAsync();
    setIsProcessingClick(false);
  };

  return {
    isProcessingClick,
    handleClickAsync,
  };
};

export default useIconButtonLogic;
