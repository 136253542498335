import "./Breadcrumb.scss";
import BreadcrumbLogic from "./BreadcrumbLogic";
import { Link } from "react-router-dom";
import { Chevron } from "@Components/icons";
import { BreadcrumbProps } from "./BreadcrumbPropTypes";

const Breadcrumb = ({
  initialSection,
  currentSectionName,
  hasIdentificator,
  ignorePaths, // Ignores all paths
  ignoreArray, // Ignores specific paths
}: BreadcrumbProps) => {
  const { pathSections, pathSectionsTotal, searchParams } = BreadcrumbLogic(
    ignorePaths,
    ignoreArray,
  );
  return (
    <div className={"breadcumb-container"}>
      {initialSection && (
        <div>
          <Link to={`../${initialSection.path}`}>{initialSection.text}</Link>
          <span className={"breadcumb-indicator"}>
            <Chevron size={14} color={"#212529"} position={"right"} />
          </span>
        </div>
      )}
      {pathSections.map((pathSection, index) => {
        const section = pathSection.replaceAll("-", " ");
        let sectionLinkParts: string[] = [];
        let sectionLink = "";
        pathSections.forEach((sec, secIndex) => {
          if (index === secIndex && sec.length > 0 && index > 0) {
            sectionLinkParts.push(pathSections[index - 1]);
            sectionLinkParts.push(sec);
            sectionLink =
              sectionLinkParts.join("/") +
              (index > (initialSection ? 1 : 0) ? searchParams : "");
            if (sectionLink.startsWith("/")) {
              sectionLink = sectionLink.replace(/^./, "");
            }
          }
        });
        const ignorePenultimate =
          !hasIdentificator ||
          (hasIdentificator &&
            pathSection !== pathSections[pathSectionsTotal - 1]);
        return (
          <div key={`breadcrumb_section_${index}`}>
            {index > 1 && ignorePenultimate && (
              <span className={"breadcumb-indicator"}>
                <Chevron size={14} color={"#212529"} position={"right"} />
              </span>
            )}
            {pathSection !== pathSections[pathSectionsTotal] &&
            ignorePenultimate ? (
              <Link to={`../${sectionLink}`}>{section}</Link>
            ) : (
              <label>
                {ignorePenultimate ? currentSectionName ?? section : null}
              </label>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
