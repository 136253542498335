import { IconProps } from "../iconTypes";
import themedStyles from "@Assets/styles/themedStyles.scss";

export default function Notebook({
  color = themedStyles.cmsMainIconsColor,
}: IconProps) {
  return (
    <svg
      width="80"
      height="56"
      viewBox="0 0 80 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52 52C57.0556 52 59 43.2566 59 45.3133C59 45.3133 57.4445 45.3133 55.1111 46.3406C53.2663 47.1529 52 48.9147 52 52Z"
        stroke={color}
        strokeWidth="0.953488"
      />
      <rect
        x="19.72"
        y="4.72"
        width="39.56"
        height="47.56"
        rx="3.09395"
        stroke={color}
        strokeWidth="1.44"
      />
      <path d="M26 53L26 4" stroke={color} strokeWidth="0.85814" />
      <line
        x1="19"
        y1="10.5709"
        x2="59"
        y2="10.5709"
        stroke={color}
        strokeWidth="0.85814"
      />
      <line
        x1="19"
        y1="26.5709"
        x2="59"
        y2="26.5709"
        stroke={color}
        strokeWidth="0.85814"
      />
      <line
        x1="19"
        y1="14.5709"
        x2="59"
        y2="14.5709"
        stroke={color}
        strokeWidth="0.85814"
      />
      <line
        x1="19"
        y1="30.5709"
        x2="59"
        y2="30.5709"
        stroke={color}
        strokeWidth="0.85814"
      />
      <line
        x1="19"
        y1="18.5709"
        x2="59"
        y2="18.5709"
        stroke={color}
        strokeWidth="0.85814"
      />
      <line
        x1="19"
        y1="34.5709"
        x2="59"
        y2="34.5709"
        stroke={color}
        strokeWidth="0.85814"
      />
      <line
        x1="19"
        y1="22.5709"
        x2="59"
        y2="22.5709"
        stroke={color}
        strokeWidth="0.85814"
      />
      <line
        x1="19"
        y1="38.5709"
        x2="59"
        y2="38.5709"
        stroke={color}
        strokeWidth="0.85814"
      />
      <path
        d="M51.9123 23.512L54.7639 25.1008L45.4915 43.4386L42.375 45.9878L42.6398 41.8498L51.9123 23.512Z"
        fill="white"
        stroke={color}
        strokeWidth="0.953488"
      />
    </svg>
  );
}
