import { GenericDetails, Loader, MembershipModal } from "@Components/index";
import { Navbar } from "@Layouts/index";
import MembershipDetailsLogic from "./MembershipDetailsLogic";

const MembershipDetails = () => {
  const {
    membership,
    ctaText,
    dogMembershipModalVisible,
    isLoadingMembership,
    userInfo,
    onApplyClick,
    membershipModalClose,
    handleNotLoggedUserLogin,
  } = MembershipDetailsLogic();

  return (
    <Navbar>
      {isLoadingMembership ? (
        <Loader text={"Loading, please wait"} modal />
      ) : (
        <>
          {!membership ? (
            <div>Membership not found</div>
          ) : (
            <>
              <GenericDetails
                details={membership}
                primaryButton={{
                  loggedText: ctaText,
                  notLoggedText: "Log in to apply",
                  loggedAction: onApplyClick,
                  notLoggedAction: () => handleNotLoggedUserLogin(),
                }}
                pricingTitle={"Membership Pricing"}
              />
              {!!userInfo && (
                <MembershipModal
                  membership={membership}
                  isVisible={dogMembershipModalVisible}
                  onClose={membershipModalClose}
                  userType={userInfo.userType}
                />
              )}
            </>
          )}
        </>
      )}
    </Navbar>
  );
};

export default MembershipDetails;
