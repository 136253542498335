import { useEffect, useState } from "react";
import { config } from "@App/config/config";

const VersionNumberLogic = () => {
  const [footerVersionClicks, setFooterVersionClicks] = useState(0);
  const [showDevMenu, setShowDevMenu] = useState(false);

  useEffect(() => {
    setShowDevMenu(footerVersionClicks >= config.devMenuClicksToShow);
  }, [footerVersionClicks]);

  const onFooterVersionClick = () => {
    setFooterVersionClicks(footerVersionClicks + 1);
  };

  const onDevMenuClose = () => {
    setFooterVersionClicks(0);
  };

  return {
    showDevMenu,
    onFooterVersionClick,
    onDevMenuClose,
  };
};

export default VersionNumberLogic;
