import { getPagedArticles } from "@App/api/articles";
import { QUERY_KEY_ARTICLES } from "@App/constants/queryKeyConstants";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";

export const useInfiniteArticles = () => {
  const {
    data: articles,
    isLoading: isLoadingArticles,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY_ARTICLES],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getPagedArticles({ page: pageParam, pageSize: 10 });
      return res.data;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.isLastPage) {
        return;
      }
      return lastPage.page + 1;
    },
  });

  const articlesList = useMemo(
    () => articles?.pages.flatMap((page) => page.items),
    [articles],
  );

  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
    rootMargin: "100px",
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  return {
    articlesList,
    isLoadingArticles,
    isFetchingNextPage,
    hasNextPage,

    inViewRef,
  };
};
