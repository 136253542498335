import "./ButtonToggle.scss";
import { ButtonToggleProps } from "./ButtonTogglePropTypes";

const ButtonToggle = ({
  text,
  textColor,
  isDisabled,
  size = "small",
  isSelected,
  backgroundColor = "transparent",
  onClick,
  style,
}: ButtonToggleProps) => {
  return (
    <button
      className={`button-toggle  ${isDisabled && "button-toggle__disabled"}  ${
        isSelected ? "button-toggle__selected" : ""
      } ${size === "small" ? "button-toggle__small" : "button-toggle__large"}`}
      style={{ color: textColor, backgroundColor, ...style }}
      disabled={isDisabled}
      onClick={onClick}>
      {text}
    </button>
  );
};

export default ButtonToggle;
