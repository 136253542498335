import { QUERY_KEY_TENANT_FOOTER } from "@App/constants/queryKeyConstants";
import { getTenantFooter } from "@Api/tenant";
import { useQuery } from "@tanstack/react-query";

export default function useTenantFooter() {
  const { data: tenantFooter, isLoading: isLoadingTenantFooter } = useQuery({
    queryKey: [QUERY_KEY_TENANT_FOOTER],
    queryFn: () => getTenantFooter(),
    staleTime: Infinity,
    select: (data) => data.data,
  });

  return {
    tenantFooter,
    isLoadingTenantFooter,
  };
}
