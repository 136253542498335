import { ToolTipProps } from "@Components/tooltip/ToolTipProps";
import { Information } from "@Components/icons";
import "./ToolTip.scss";
const ToolTip = ({
  onClick,
  tooltipText,
  tooltipPosition = "right",
}: ToolTipProps) => {
  return (
    <div
      className={"tooltip"}
      onClick={() => {
        onClick && onClick();
      }}>
      {!!tooltipText && (
        <span className={`tooltiptext tooltip-${tooltipPosition}`}>
          {tooltipText}
        </span>
      )}
      <Information color={"#000"} />
    </div>
  );
};

export default ToolTip;
