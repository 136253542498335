import "./MyMemberships.scss";
import Table from "@Components/table/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { getDefaultColumnSize } from "@Components/table/utils";
import { QUERY_KEY_USER_MEMBERSHIP_LIST } from "@App/constants/queryKeyConstants";
import { formatDate } from "@Utils/date";
import ShrinkableList from "@Components/shrinkable-list/ShrinkableList";
import { getUserMemberships } from "@Api/memberships";
import {
  UserMembership,
  UserMembershipApplicationForm,
} from "@App/models/membership";
import { FileMetadata } from "@App/models/File";
import { ReservationWaiver } from "@App/models/communityAssetReservation";
import {
  findTagBubbleTypeById,
  TagBubbleTypeMapping,
} from "@Components/tag-bubble/types";
import TagBubble from "@Components/tag-bubble/TagBubble";

const defaultGeneralColumnSize = getDefaultColumnSize(
  [
    {
      type: "small",
      size: 5,
      numberOfColumns: 4,
    },
  ],
  8,
);

export const UserMembershipStatusTypes: TagBubbleTypeMapping[] = [
  {
    id: "Pending",
    text: "Pending",
    backgroundColor: "#FFE7B3",
    fontColor: "#725517",
  },
  {
    id: "Approved",
    text: "Approved",
    backgroundColor: "#BFE3BC",
    fontColor: "#313F30",
  },
  {
    id: "OnHold",
    text: "On Hold",
    backgroundColor: "#D0F2FF",
    fontColor: "#1D345E",
  },
  {
    id: "Rejected",
    text: "Rejected",
    backgroundColor: "#FFE5E5",
    fontColor: "#FA3E3E",
  },
  {
    id: "Cancelled",
    text: "Canceled",
    backgroundColor: "#F1F3F5",
    fontColor: "#5F666D",
  },
];

/**
 * Render all form fields for a membership application with their label+value
 */
function renderFormFieldComponents(
  formFields: UserMembershipApplicationForm[],
): JSX.Element[] {
  // map form field to simple string component
  const mapFormField = (formField: UserMembershipApplicationForm) => (
    <span className={"display-block"}>
      {formField.label}: {formField.value}
    </span>
  );

  // recursively render form labels+user-values
  return formFields.flatMap((f, i, arr) =>
    f.groupedItems
      ? [
          ...renderFormFieldComponents(f.groupedItems),
          i < arr.length - 1 ? <span>---</span> : <></>, // add divider in between submissions
        ]
      : mapFormField(f),
  );
}

/**
 * Render all documents kept within an application
 */
const renderDocuments = (
  waivers?: ReservationWaiver[],
  files?: FileMetadata[],
) => {
  if ((!waivers || waivers.length === 0) && (!files || files.length === 0)) {
    return "None";
  }

  // get urls from waives and files
  const urls = [
    ...(waivers ?? []).map((w) => w.acceptedWaiverURL),
    ...(files ?? []).map((f) => f.url),
  ];

  // render all docs
  const components = urls.map((url, i) => (
    <a
      key={i}
      className="events-table-cell-documents display-block"
      target={"_blank"}
      rel={"noreferrer"}
      href={url}
    >
      Document {urls.length > 1 ? i + 1 : ""}
    </a>
  ));
  return <ShrinkableList components={components} maxShown={2} />;
};

/**
 * Column definitions
 */
const columnHelper = createColumnHelper<UserMembership>();
const columns = [
  columnHelper.accessor("receiptId", {
    header: () => "No.",
    size: defaultGeneralColumnSize,
  }),
  columnHelper.accessor("startDate", {
    header: () => "Res. Date",
    cell: (info) => formatDate(info.getValue(), "M/dd/yy"),
    size: defaultGeneralColumnSize,
  }),
  columnHelper.accessor("type", {
    header: () => "Membership",
    size: defaultGeneralColumnSize,
  }),
  columnHelper.accessor("membershipItems.applicationFormFields", {
    header: () => "Details",
    cell: (info) => (
      <ShrinkableList
        components={renderFormFieldComponents(info.getValue())}
        maxShown={2}
      />
    ),
    size: defaultGeneralColumnSize,
  }),
  columnHelper.accessor("status", {
    header: () => "Status",
    cell: (info) => {
      const tagProps = findTagBubbleTypeById(
        UserMembershipStatusTypes,
        info.getValue(),
      );
      return (
        <TagBubble
          text={info.getValue()}
          onClick={() => {}}
          itemId={info.row.original.id}
          className={"display-block"}
          backgroundColor={tagProps.backgroundColor}
          fontColor={tagProps.fontColor}
          fontWeight={400}
        />
      );
    },
    size: 2,
  }),
  columnHelper.accessor("waivers", {
    header: () => "Documents",
    cell: (info) => renderDocuments(info.getValue(), info.row.original.files),
    size: defaultGeneralColumnSize,
  }),
];

const MyMemberships = () => {
  return (
    <div id={"user-profile-my-memberships"}>
      <Table
        columns={columns}
        queryKey={[QUERY_KEY_USER_MEMBERSHIP_LIST]}
        queryFn={getUserMemberships}
        highlightOnHover
      />
    </div>
  );
};

export default MyMemberships;
