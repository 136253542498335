import { IconProps } from "@Components/icons/iconTypes";

const TriangleError = ({ color = "#FA3E3E", size }: IconProps) => {
  return (
    <svg
      {...(size && {
        style: { width: size, height: size },
      })}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"login-error-banner-icon"}>
      <path
        d="M16.0002 7.98669L26.0402 25.3334H5.96016L16.0002 7.98669ZM16.0002 2.66669L1.3335 28H30.6668L16.0002 2.66669ZM17.3335 21.3334H14.6668V24H17.3335V21.3334ZM17.3335 13.3334H14.6668V18.6667H17.3335V13.3334Z"
        fill={color}
      />
    </svg>
  );
};

export default TriangleError;
