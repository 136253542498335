import { Navbar } from "@Layouts/index";
import { Loader, NewsAndArticlesCard } from "@Components/index";
import { Fragment, useEffect } from "react";

import "./NewsAndArticles.scss";
import { useInfiniteArticles } from "@App/hooks";
import { sendMobileViewModePostMessage } from "@Utils/utils";

const NewsAndArticles = () => {
  const {
    articlesList,
    isLoadingArticles,
    hasNextPage,

    inViewRef,
  } = useInfiniteArticles();

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: false });
  }, []);

  return (
    <div>
      <Navbar>
        <div className={"news-and-articles"}>
          <div className={"news-and-articles__header"}>News and Updates</div>
          {isLoadingArticles ? (
            <Loader color={"#000"} />
          ) : (
            <div className={"news-and-articles__content"}>
              {articlesList?.map((article, index) => (
                <Fragment key={index}>
                  <NewsAndArticlesCard article={article} />
                </Fragment>
              ))}
              {hasNextPage && (
                <div ref={inViewRef}>
                  <Loader color={"#000"} />
                </div>
              )}
            </div>
          )}
        </div>
      </Navbar>
    </div>
  );
};

export default NewsAndArticles;
