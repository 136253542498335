import "./PlaceholderRow.scss";
import "./GenericListing.scss";

const PlaceholderRow = () => {
  return (
    <section className="placeholder-row">
      {Array(4)
        .fill(0)
        .map((_, index) => {
          return (
            <div className={"generic-listing-row-item"} key={index}>
              <div className="image-placeholder generic-listing-row-item-image generic-listing-row-item-image--small placeholder-image"></div>
              <div className="image-placeholder placeholder-title"></div>
            </div>
          );
        })}
    </section>
  );
};

export default PlaceholderRow;
