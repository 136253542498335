import {
  COMMUNITY_INFO_REQUEST_GET,
  COMMUNITY_INFO_REQUEST_SUCCESS,
  COMMUNITY_INFO_REQUEST_FAIL,
} from "@App/constants/generalConstants";
import { CommunityInfo } from "@App/models/communityInfo";

export interface GeneralState {
  loading?: boolean;
  error?: string;
  communityInfo?: CommunityInfo;
}

interface GeneralAction {
  type: string;
  payload?: string;
}

export const generalDataReducer = (
  state: GeneralState = {},
  action: GeneralAction,
) => {
  let currentState;
  switch (action.type) {
    case COMMUNITY_INFO_REQUEST_GET:
      currentState = { loading: true };
      break;
    case COMMUNITY_INFO_REQUEST_SUCCESS:
      currentState = { loading: false, communityInfo: action.payload };
      break;
    case COMMUNITY_INFO_REQUEST_FAIL:
      currentState = { loading: false, communityInfo: action.payload };
      break;
    default:
      currentState = state;
      break;
  }
  return currentState;
};
