import userSession from "@App/auth/userSession";
import { sendMobileViewModePostMessage } from "@Utils/utils";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

type HtmlWrapperProps = {
  htmlContent: string;
  className?: string;
  style?: React.CSSProperties;
};

// This wrapper allows us to use normal <a> tags within injected markup and still use react router
// This component assumes all external links will have [target = "_blank"] set.
// If you need to open external links in the current window, you'll need to modify this component
export const HtmlWrapper = ({
  htmlContent,
  className,
  style,
}: HtmlWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLinkClick = (e: Event) => {
      const target = e.target as HTMLAnchorElement;

      if (target.tagName === "A") {
        if (
          target.getAttribute("target") === "_blank" ||
          target.href.substring(0, 6) === "mailto" ||
          target.href.substring(0, 3) === "tel" ||
          target.href.substring(0, 3) === "sms"
        ) {
          return;
        }

        e.preventDefault();

        const path = target.getAttribute("href");
        const eventCategoryId = target.getAttribute("data-event-category-id");

        if (!path) {
          return;
        }

        if (eventCategoryId && userSession.isMobileViewMode) {
          sendMobileViewModePostMessage({
            type: "calendarRedirect",
            eventCategoryId,
          });
          return;
        }
        navigate(path);
      }
    };

    const node = ref.current;
    if (!node) {
      return;
    }
    node.addEventListener("click", handleLinkClick);

    return () => {
      node.removeEventListener("click", handleLinkClick);
    };
  }, [navigate]);

  return (
    <div
      ref={ref}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      className={className}
      style={style}
    />
  );
};
