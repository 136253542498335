import { Navbar } from "@Layouts/index";
import "./ResidentCenterServiceDetails.scss";
import ResidentCenterServiceDetailsLogic from "./ResidentCenterServiceDetailsLogic";
import { BackButton, Breadcrumb, Tag } from "@Components/index";

const ResidentCenterServiceDetails = () => {
  const props = ResidentCenterServiceDetailsLogic();
  return (
    <Navbar>
      {props.details && (
        <div className={"resident-center-details"}>
          <div className={"resident-center-details-breadcrumb"}>
            <Breadcrumb
              initialSection={{ text: "Home", path: "/" }}
              currentSectionName={props.details.title}
              hasIdentificator
            />
          </div>
          <div className={"resident-center-details-back"}>
            <BackButton goBack={props.goBack} />
          </div>

          <div className={"resident-center-details-content"}>
            <div className={"resident-center-details-content-type"}>
              {props.details.type}
            </div>
            <div className={"resident-center-details-content-title"}>
              {props.details.title}
            </div>
            <div className={"resident-center-details-content-dates"}>
              {props.details.openDates}
            </div>
            <div
              className={"resident-center-details-content-description"}
              dangerouslySetInnerHTML={{ __html: props.details.description }}
            />
            {props.details.tag && <Tag text={props.details.tag} />}
          </div>
        </div>
      )}
    </Navbar>
  );
};

export default ResidentCenterServiceDetails;
