// Storage Items
export const RSVP_LOGIN_FLOW = "rsvp-login-flow";
export const EVENT_TICKET_DATE = "event-ticket-date";
export const MEMBERSHIP_DOGS = "membership-dogs";

// Custom Events
export const CLOSE_INFORMATION_MODAL = "CLOSE_INFORMATION_MODAL";
export const SHOW_LOGIN_MODAL = "SHOW_LOGIN_MODAL";
export const SHOW_REGISTRATION_MODAL = "SHOW_REGISTRATION_MODAL";
export const SHOW_RESULT_BANNER = "SHOW_RESULT_BANNER";
export const CLOSE_HOVER_MENUS_MIN = "CLOSE_HOVER_MENUS_MIN";
export const REMEMBER_EMAIL = "REMEMBER_EMAIL";
export const REMEMBER_EMAIL_RESET = "REMEMBER_EMAIL_RESET";
export const MAP_RESIZE = "MAP_RESIZE";

//JS Small vs Large detection
export const DEFAULT_MOBILE_MEDIA_QUERY = "(max-width: 768px)";
