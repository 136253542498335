import { IconProps } from "../iconTypes";
//@ts-ignore
import themedStyles from "@Assets/styles/themedStyles.scss";

const Family = ({ color = themedStyles.cmsMainIconsColor }: IconProps) => {
  return (
    <svg
      width="74"
      height="56"
      viewBox="0 0 74 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="19.7067"
        cy="13.5006"
        r="4.77893"
        stroke={color}
        strokeWidth="1.66847"
      />
      <circle
        cx="48.7943"
        cy="13.5006"
        r="4.77893"
        stroke={color}
        strokeWidth="1.66847"
      />
      <circle
        cx="34.1256"
        cy="24.4672"
        r="3.21247"
        stroke={color}
        strokeWidth="1.66847"
      />
      <path
        d="M38.5884 31.5437V29.8289C38.5884 25.7466 42.9769 21.6643 48.2839 21.6643C54.9176 21.6643 58.9999 25.2363 58.9999 29.8289C58.9999 33.503 58.9999 43.9469 58.9999 48.7096"
        stroke={color}
        strokeWidth="1.66847"
      />
      <path
        d="M9.50098 48.7096V29.8289C10.5216 23.7055 13.8895 21.6643 19.1964 21.6643C25.8302 21.6643 28.8919 24.726 29.9125 29.8289C30.633 33.4316 30 28.5 30.5 32.5"
        stroke={color}
        strokeWidth="1.66847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3611 49.7304V36.1188C27.3611 33.1757 30.5249 30.2327 34.3508 30.2327C39.1333 30.2327 42.0764 32.8078 42.0764 36.1188V49.7304H41.1393V36.9228C41.1393 33.7938 38.4858 31.3601 34.1738 31.3601C30.7243 31.3601 28.3821 34.1415 28.3821 36.9228V49.7304H27.3611Z"
        fill="#33B79F"
      />
      <path
        d="M27.3611 49.7304H27.083V50.0085H27.3611V49.7304ZM42.0764 49.7304V50.0085H42.3544V49.7304H42.0764ZM41.1393 49.7304H40.8612V50.0085H41.1393V49.7304ZM28.3821 49.7304V50.0085H28.6601V49.7304H28.3821ZM27.083 36.1188V49.7304H27.6392V36.1188H27.083ZM34.3508 29.9546C32.3668 29.9546 30.5555 30.7173 29.2388 31.8622C27.9248 33.0048 27.083 34.5477 27.083 36.1188H27.6392C27.6392 34.7468 28.3792 33.3467 29.6037 32.2819C30.8254 31.2196 32.5089 30.5107 34.3508 30.5107V29.9546ZM42.3544 36.1188C42.3544 34.3742 41.5766 32.824 40.1798 31.7162C38.7867 30.6114 36.7902 29.9546 34.3508 29.9546V30.5107C36.694 30.5107 38.5602 31.1415 39.8342 32.152C41.1046 33.1596 41.7983 34.5524 41.7983 36.1188H42.3544ZM42.3544 49.7304V36.1188H41.7983V49.7304H42.3544ZM41.1393 50.0085H42.0764V49.4523H41.1393V50.0085ZM41.4173 49.7304V36.9228H40.8612V49.7304H41.4173ZM41.4173 36.9228C41.4173 35.2743 40.7162 33.8058 39.4513 32.7543C38.1893 31.7052 36.3809 31.0821 34.1738 31.0821V31.6382C36.2787 31.6382 37.953 32.2319 39.0958 33.1819C40.2356 34.1295 40.8612 35.4423 40.8612 36.9228H41.4173ZM34.1738 31.0821C30.5641 31.0821 28.104 33.9946 28.104 36.9228H28.6601C28.6601 34.2883 30.8845 31.6382 34.1738 31.6382V31.0821ZM28.104 36.9228V49.7304H28.6601V36.9228H28.104ZM27.3611 50.0085H28.3821V49.4523H27.3611V50.0085Z"
        fill={color}
      />
    </svg>
  );
};

export default Family;
