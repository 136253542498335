import { getMembershipDetailsById } from "@App/api/memberships";
import { MEMBERSHIP_DOGS, SHOW_LOGIN_MODAL } from "@App/constants/appConstants";
import { QUERY_KEY_MEMBERSHIP_DETAILS } from "@App/constants/queryKeyConstants";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { customEventPublish } from "@Utils/utils";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const MembershipDetailsLogic = () => {
  const { id } = useParams<{ id: string }>();
  const storeData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = storeData;

  const [dogMembershipModalVisible, setDogMembershipModalVisible] =
    useState(false);

  const ctaText = useMemo(
    () => (userInfo?.firstName ? "Proceed to form" : "Log In to Apply"),
    [userInfo],
  );

  const { data: membership, isLoading: isLoadingMembership } = useQuery({
    queryKey: [QUERY_KEY_MEMBERSHIP_DETAILS, id],
    queryFn: () => getMembershipDetailsById(id!),
    select: (data) => data?.data,
    enabled: !!id,
  });

  const membershipModalClose = () => {
    localStorage.removeItem(MEMBERSHIP_DOGS);
    setDogMembershipModalVisible(false);
  };

  const handleNotLoggedUserLogin = () => {
    customEventPublish(SHOW_LOGIN_MODAL, {});
  };

  const onApplyClick = () => {
    if (membership?.isInternal) {
      setDogMembershipModalVisible(true);
    } else {
      //TODO: BE and Designs needed for other memberships different than Dog
      window.open(
        membership?.externalMembershipURL,
        "_blank",
        "noopener,noreferrer",
      );
    }
  };

  return {
    membership,
    userInfo,
    ctaText,

    isLoadingMembership,
    dogMembershipModalVisible,

    onApplyClick,
    membershipModalClose,
    handleNotLoggedUserLogin,
  };
};

export default MembershipDetailsLogic;
