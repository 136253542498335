import { ThreeDots } from "react-loader-spinner";
import "./Loader.scss";
import { LoaderProps } from "./LoaderPropTypes";

const Loader = ({
  text,
  color = "#FFF",
  width = "50",
  height = "50",
  modal,
  className = "",
  noBackground = false,
  fontColor = "#fff",
  horizontallyCentered,
  verticalCentered = false,
  padding = "0px",
  inViewRef = undefined,
}: LoaderProps) => {
  return (
    <div
      className={`loader ${modal ? "loader-modal" : ""}  ${
        noBackground ? "loader-modal-no-background" : ""
      } ${className} ${
        horizontallyCentered ? "loader-horizontally-centered" : ""
      } 
      ${verticalCentered ? "loader-vertically-centered" : ""}
      `}
      style={{ padding }}
      ref={inViewRef}
    >
      <ThreeDots
        width={width}
        height={height}
        radius={"9"}
        color={color}
        ariaLabel={"three-dots-loading"}
        wrapperStyle={{}}
        visible={true}
      />

      <div style={{ color: fontColor }}>{text}</div>
    </div>
  );
};

export default Loader;
