import useEventsSearch from "@App/hooks/api/events/useEventsSearch";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { useSelector } from "react-redux";

type UpcomingOrUserEventsSliderLogicParams = {
  useAllUserEvents?: boolean;
};

export default function upcomingOrUserEventsSliderLogic(
  params: UpcomingOrUserEventsSliderLogicParams,
) {
  const { useAllUserEvents } = params;
  // TODO: Refactor to tanstack
  const userStore = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const useUserEvents = !!userStore.userInfo?.hasInteractedEvents;
  const isLoggedUser = !!userStore.userInfo?.id;

  // If upcoming is enabled, then the user events should be disabled and vice versa
  const {
    eventsList: upcomingEvents,
    isLoadingEvents: isLoadingUpcomingEvents,
    inViewRef: inViewRefUpcoming,
    hasNextPage: hasNextPageUpcoming,
  } = useEventsSearch(
    {
      startDate: new Date(),
      isIncludeAdminEvents: false,
    },
    !useUserEvents,
  );

  const {
    eventsList: attendingEvents,
    isLoadingEvents: isLoadingAttendingEvents,
    inViewRef: inViewRefAttending,
    hasNextPage: hasNextPageAttending,
  } = useEventsSearch(
    {
      startDate: new Date(),
      isIncludeAdminEvents: false,
      isIncludeAttending: true,
      isIncludeRSVPd: true,
    },
    useUserEvents,
  );

  const {
    eventsList: favoriteEvents,
    isLoadingEvents: isLoadingFavoriteEvents,
    inViewRef: inViewRefFavorite,
    hasNextPage: hasNextPageFavorite,
  } = useEventsSearch(
    {
      startDate: new Date(),
      isIncludeAdminEvents: false,
      isIncludeFavorite: true,
    },
    useUserEvents,
  );

  const {
    eventsList: allUserEvents,
    isLoadingEvents: isLoadingAllUserEvents,
    inViewRef: inViewRefAllUserEvents,
    hasNextPage: hasNextPageAllUserEvents,
  } = useEventsSearch(
    {
      startDate: new Date(),
      isIncludeAdminEvents: false,
      isIncludeAttending: true,
      isIncludeRSVPd: true,
      isIncludeFavorite: true,
    },
    useUserEvents && !!useAllUserEvents,
  );

  const isLoadingEnabledUpcomingEvents =
    isLoadingUpcomingEvents && !useUserEvents;
  const isLoadingEnabledAttendingEvents =
    isLoadingAttendingEvents && useUserEvents;
  const isLoadingEnabledFavoriteEvents =
    isLoadingFavoriteEvents && useUserEvents;
  const isLoadingEnabledAllUserEvents =
    isLoadingAllUserEvents && useUserEvents && !!useAllUserEvents;
  const isLoadingAnyEnabledEvents =
    isLoadingEnabledUpcomingEvents ||
    isLoadingEnabledAttendingEvents ||
    isLoadingEnabledFavoriteEvents ||
    isLoadingEnabledAllUserEvents;

  return {
    upcomingEvents,
    attendingEvents,
    favoriteEvents,
    allUserEvents,

    isLoadingEnabledUpcomingEvents,
    isLoadingEnabledAttendingEvents,
    isLoadingEnabledFavoriteEvents,
    isLoadingEnabledAllUserEvents,
    isLoadingAnyEnabledEvents,

    hasNextPageUpcoming,
    hasNextPageAttending,
    hasNextPageFavorite,
    hasNextPageAllUserEvents,
    useUserEvents,
    isLoggedUser,

    inViewRefUpcoming,
    inViewRefAttending,
    inViewRefFavorite,
    inViewRefAllUserEvents,
  };
}
