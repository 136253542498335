import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_BANNER_DETAILS } from "@App/constants/queryKeyConstants";
import { getBanner } from "@Api/banners";
import useTenantConfig from "../tenant/useTenantConfig";

export default function useBannerDetails() {
  const { data: banner, isLoading } = useQuery({
    queryKey: [QUERY_KEY_BANNER_DETAILS],
    queryFn: () => getBanner(),
    select: (data) => data.data,
  });

  const bannerFeatureEnabled =
    useTenantConfig()?.featureToggles?.hasBannerEnabled ?? false;

  return {
    bannerFeatureEnabled,
    banner,
    isLoading,
  };
}
