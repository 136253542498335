import "./Switch.scss";
import { SwitchProps } from "./SwitchPropTypes";

const Switch = ({
  text = "",
  fontColor,
  onChange,
  inline,
  labelAfter = false,
  defaultChecked = false,
  checked = false,
}: SwitchProps) => {
  return (
    <div
      className={`switch-container ${inline ? "switch-container-inline" : ""}`}>
      {!labelAfter && <div className={"switch-label"}>{text}</div>}
      <label className={"switch"} style={{ color: fontColor ?? "inherit" }}>
        <input
          className={"switch-input"}
          type={"checkbox"}
          onChange={(e) => {
            onChange(e.currentTarget.checked);
          }}
          checked={checked ?? false}
        />
        <div className={"switch-fill"}></div>
      </label>
      {labelAfter && <div className={"switch-label"}>{text}</div>}
    </div>
  );
};

export default Switch;
