import { SvgsProps } from "../iconTypes";

const ChevronDown = ({ color = "#C4C4C4", size = "small" }: SvgsProps) => {
  const isSmall = size === "small";
  return (
    <svg
      width={isSmall ? "12" : "21"}
      height={isSmall ? "8" : "13"}
      viewBox={`0 0 ${isSmall ? "12 8" : "21 13"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          isSmall
            ? "M10.2384 1.29L6.35841 5.17L2.47841 1.29C2.08841 0.899998 1.45841 0.899998 1.06841 1.29C0.678409 1.68 0.678409 2.31 1.06841 2.7L5.65841 7.29C6.04841 7.68 6.67841 7.68 7.06841 7.29L11.6584 2.7C12.0484 2.31 12.0484 1.68 11.6584 1.29C11.2684 0.909998 10.6284 0.899998 10.2384 1.29V1.29Z"
            : "M2.43666 0.664991L0.666656 2.43499L10.5667 12.335L20.4667 2.43499L18.6967 0.664991L10.5667 8.79499L2.43666 0.664991Z"
        }
        fill={color}
      />
    </svg>
  );
};

export default ChevronDown;
