import { getEventCategories } from "@App/api/event";
import { QUERY_KEY_EVENT_CATEGORY_LIST } from "@App/constants/queryKeyConstants";
import { useQuery } from "@tanstack/react-query";

export default function useEventCategories() {
  const { data: eventCategories, isLoading: isLoadingEventCategories } =
    useQuery({
      queryKey: [QUERY_KEY_EVENT_CATEGORY_LIST],
      queryFn: () => getEventCategories(),
      select: (data) => data.data,
      staleTime: Infinity,
    });

  return {
    eventCategories,
    isLoadingEventCategories,
  };
}
