import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_NAV_BAR_ITEMS } from "@App/constants/queryKeyConstants";
import { getTenantNavbarItems } from "@Api/tenant";

export default function useNavbarItems() {
  const { data: navbarItems, isLoading: isLoadingNavbarItems } = useQuery({
    queryKey: [QUERY_KEY_NAV_BAR_ITEMS],
    queryFn: () => getTenantNavbarItems(),
    staleTime: Infinity,
    select: (data) => data.data.links,
  });

  return {
    navbarItems,
    isLoadingNavbarItems,
  };
}
