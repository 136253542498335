import "./GenericListing.scss";
import { Loader, SearchInput } from "@Components/index";
import { GenericListingProps } from "./GenericListingPropTypes";
import GenericListingLogic from "./GenericListingLogic";
import GenericListingRow from "./GenericListingRow";
import PlaceholderRow from "@Components/generic-listing/PlaceholderRow";

const GenericListing = (componentProps: GenericListingProps) => {
  const { isMobileEmbed, onSearchText, isListLoading, setIsListLoading } =
    GenericListingLogic(componentProps.onSearch);
  return (
    <div className={"generic-listing"}>
      {!isMobileEmbed && (
        <header className={"generic-listing-header"}>
          <div className={"generic-listing-header-title"}>
            {componentProps.title}
          </div>
          <div className={"generic-listing-header-search"}>
            <SearchInput
              onSearch={onSearchText}
              initialValue={componentProps.searchText}
              filterSections={componentProps.filterSections}
              onFilterChange={(filterParams) => {
                componentProps.onFilterChange &&
                  componentProps.onFilterChange(filterParams);
              }}
            />
          </div>
        </header>
      )}
      {componentProps.isLoading ? (
        <Loader
          text={"Loading, please wait"}
          color={"#000"}
          fontColor={"#000"}
          horizontallyCentered
          padding={"100px 20px"}
        />
      ) : componentProps.listingCategories &&
        componentProps.listingCategories?.length > 0 ? (
        <section
          className={`generic-listing-listing ${
            isMobileEmbed ? "generic-listing-listing-fullscreen" : ""
          }`}>
          {isListLoading && <PlaceholderRow />}
          {componentProps.listingCategories.map((row, index) => {
            return (
              <GenericListingRow
                key={`generic-listing-row-item${index}`}
                title={row.name}
                listingId={row.id}
                searchText={componentProps.searchText}
                searchFilters={componentProps.searchFilters}
                getFilterRequestParams={componentProps.getFilterRequestParams}
                onClick={(id) => componentProps.onClick(id)}
                customHeight={
                  index === 0 && componentProps.firstRowHeight
                    ? componentProps.firstRowHeight
                    : componentProps.customHeight
                }
                getCategoryItems={componentProps.getCategoryItems}
                moduleName={componentProps.moduleName}
                canBeBooked={componentProps.moduleName === "venues"}
                onListLoaded={() => {
                  setIsListLoading(false);
                }}
              />
            );
          })}
        </section>
      ) : null}
    </div>
  );
};

export default GenericListing;
