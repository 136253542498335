import { IconProps } from "../iconTypes";
import themedStyles from "@Assets/styles/themedStyles.scss";

export default function NiceHouse({
  color = themedStyles.cmsMainIconsColor,
}: IconProps) {
  return (
    <svg
      width="80"
      height="56"
      viewBox="0 0 80 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="27.1016"
        y="27.1367"
        width="5.81079"
        height="6.61006"
        rx="0.547813"
        stroke={color}
      />
      <line
        x1="30.2451"
        y1="27.3291"
        x2="30.2451"
        y2="34.2473"
        stroke={color}
      />
      <rect
        x="47.5322"
        y="27.1367"
        width="5.81079"
        height="6.61006"
        rx="0.547813"
        stroke={color}
      />
      <line
        x1="50.6758"
        y1="27.3291"
        x2="50.6758"
        y2="34.2473"
        stroke={color}
      />
      <rect
        x="62.207"
        y="27.1367"
        width="5"
        height="6.61006"
        rx="0.547813"
        stroke={color}
      />
      <line
        x1="64.9766"
        y1="27.3291"
        x2="64.9766"
        y2="34.2473"
        stroke={color}
      />
      <rect
        x="13.207"
        y="27.1367"
        width="5"
        height="6.61006"
        rx="0.547813"
        stroke={color}
      />
      <line
        x1="15.9766"
        y1="27.3291"
        x2="15.9766"
        y2="34.2473"
        stroke={color}
      />
      <path
        d="M20.59 23.0917C20.59 22.8998 20.6616 22.7148 20.7906 22.5727L39.6513 1.81802C39.9575 1.48107 40.4873 1.48107 40.7935 1.81802L59.6542 22.5727C59.7832 22.7148 59.8548 22.8998 59.8548 23.0917V53.4283C59.8548 53.8545 59.5092 54.2 59.083 54.2H21.3618C20.9355 54.2 20.59 53.8545 20.59 53.4283V23.0917Z"
        stroke={color}
        strokeWidth="1.6"
      />
      <mask id="path-10-inside-1_310_10154" fill="white">
        <path d="M9.70703 22.8301H21.233V54.654H10.7548C10.1762 54.654 9.70703 54.1849 9.70703 53.6062V22.8301Z" />
      </mask>
      <path
        d="M9.70703 22.8301H21.233V54.654H10.7548C10.1762 54.654 9.70703 54.1849 9.70703 53.6062V22.8301Z"
        stroke={color}
        strokeWidth="2.88"
        mask="url(#path-10-inside-1_310_10154)"
      />
      <mask id="path-11-inside-2_310_10154" fill="white">
        <path d="M70.2334 22.8301H58.7075V54.654H69.1856C69.7643 54.654 70.2334 54.1849 70.2334 53.6062V22.8301Z" />
      </mask>
      <path
        d="M70.2334 22.8301H58.7075V54.654H69.1856C69.7643 54.654 70.2334 54.1849 70.2334 53.6062V22.8301Z"
        stroke={color}
        strokeWidth="2.88"
        mask="url(#path-11-inside-2_310_10154)"
      />
      <path
        d="M20.0519 23.1756H8.00209C7.47819 23.2909 6.58755 23.1064 7.21623 21.4461C7.84492 19.7857 10.2725 8.76262 11.4077 3.45863C11.495 3.11272 11.8792 2.4209 12.7174 2.4209C13.5557 2.4209 30.181 2.4209 38.3889 2.4209"
        stroke={color}
        strokeWidth="1.44"
      />
      <path
        d="M60.6551 23.1756H72.7049C73.2288 23.2909 74.1195 23.1064 73.4908 21.4461C72.8621 19.7857 70.4346 8.76262 69.2994 3.45863C69.212 3.11272 68.8278 2.4209 67.9896 2.4209C67.1513 2.4209 50.526 2.4209 42.3182 2.4209"
        stroke={color}
        strokeWidth="1.44"
      />
      <mask id="path-14-inside-3_310_10154" fill="white">
        <path d="M44.1973 41.3423C44.1973 41.0529 44.4318 40.8184 44.7212 40.8184H55.7232C56.0126 40.8184 56.2471 41.0529 56.2471 41.3423V54.6548H44.1973V41.3423Z" />
      </mask>
      <path
        d="M44.1973 41.3423C44.1973 41.0529 44.4318 40.8184 44.7212 40.8184H55.7232C56.0126 40.8184 56.2471 41.0529 56.2471 41.3423V54.6548H44.1973V41.3423Z"
        stroke={color}
        strokeWidth="2"
        mask="url(#path-14-inside-3_310_10154)"
      />
      <mask id="path-15-inside-4_310_10154" fill="white">
        <path d="M24.2891 41.3423C24.2891 41.0529 24.5236 40.8184 24.813 40.8184H35.815C36.1044 40.8184 36.3389 41.0529 36.3389 41.3423V54.6548H24.2891V41.3423Z" />
      </mask>
      <path
        d="M24.2891 41.3423C24.2891 41.0529 24.5236 40.8184 24.813 40.8184H35.815C36.1044 40.8184 36.3389 41.0529 36.3389 41.3423V54.6548H24.2891V41.3423Z"
        stroke={color}
        strokeWidth="2"
        mask="url(#path-15-inside-4_310_10154)"
      />
    </svg>
  );
}
