import "./TenantAddress.scss";
import { useTenantFooter } from "@App/hooks";

const TenantAddress = () => {
  const { tenantFooter } = useTenantFooter();

  if (!tenantFooter?.address) return null;
  return (
    <div className={"tenant-address"}>
      <div className={"tenant-address-line-1"}>
        <span>{tenantFooter.address.address1}</span>
        <span>{tenantFooter.address.address2}</span>
      </div>
      <div className={"tenant-address-line-2"}>
        <span>{`${tenantFooter.address.city}, ${tenantFooter.address.state} ${tenantFooter.address.zip}`}</span>
      </div>
    </div>
  );
};

export default TenantAddress;
