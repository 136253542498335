import { IconProps } from "../iconTypes";

const Cross = ({ size, color = "#fff" }: IconProps) => {
  return (
    <svg
      {...(size && {
        style: { width: size, height: size },
      })}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0988 2.22036L15.4496 0.429443L8.91145 7.52958L2.37328 0.429443L0.724121 2.22036L7.26229 9.3205L0.724121 16.4206L2.37328 18.2115L8.91145 11.1114L15.4496 18.2115L17.0988 16.4206L10.5606 9.3205L17.0988 2.22036Z"
        fill={color}
      />
    </svg>
  );
};

export default Cross;
