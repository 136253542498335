import { useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUserAccountInfo } from "@Api/user";
import { QUERY_KEY_USER_DETAILS } from "@App/constants/queryKeyConstants";
import { MyAccountProps, UpdatedInfo } from "./MyAccountPropTypes";
import moment from "moment";
import { customEventPublish } from "@Utils/utils";
import { SHOW_RESULT_BANNER } from "@App/constants/appConstants";

const MyAccountLogic = (props: MyAccountProps) => {
  const { accountInfo } = props;
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const selectedInterestIds = useMemo(
    () =>
      accountInfo?.interests
        .filter((interest) => interest.isFavorite)
        .map(({ id }) => id) ?? [],
    [accountInfo],
  );
  const basePermissions = useMemo(
    () => ({
      isLocationServiceEnabled: accountInfo?.isLocationServiceEnabled,
      isNotificationsEnabled: accountInfo?.isNotificationsEnabled,
      isMarketingEnabled: accountInfo?.isMarketingEnabled,
    }),
    [accountInfo],
  );
  const [interestsUpdate, setInterestsUpdate] = useState(selectedInterestIds);
  const [permissionsUpdate, setPermissionsUpdate] = useState(basePermissions);
  useEffect(() => {
    setPermissionsUpdate(basePermissions);
    setInterestsUpdate(selectedInterestIds);
  }, [accountInfo, basePermissions, selectedInterestIds]);

  const queryClient = useQueryClient();
  const updateAccountMutation = useMutation({
    mutationFn: (updatedInfo: UpdatedInfo) =>
      updateUserAccountInfo(accountInfo!.id, updatedInfo),
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY_USER_DETAILS]);
      customEventPublish(
        SHOW_RESULT_BANNER,
        "Account settings updated successfully",
      );
    },
    onSettled: () => {
      setEnableSaveButton(false);
    },
  });

  const { address1, address2, city, state, zip } = accountInfo?.address ?? {};
  const fullAddress =
    !!address1 || !!address2
      ? `${address1} ${address2},
  ${city},
  ${state} ${zip}`
      : "--";
  const parsedBirthDay = accountInfo?.birthday
    ? moment(accountInfo?.birthday).format("MM/DD/YYYY")
    : "-";
  const handleSelectItem = (id: string) => {
    if (interestsUpdate.includes(id)) {
      setInterestsUpdate((prev) =>
        prev.filter((selectedId) => selectedId !== id),
      );
    } else {
      setInterestsUpdate((prev) => [...prev, id]);
    }
    setEnableSaveButton(true);
  };
  const handleReset = () => {
    setInterestsUpdate(selectedInterestIds);
    setPermissionsUpdate({
      isLocationServiceEnabled: accountInfo?.isLocationServiceEnabled,
      isNotificationsEnabled: accountInfo?.isNotificationsEnabled,
      isMarketingEnabled: accountInfo?.isMarketingEnabled,
    });
  };

  const handleSave = () => {
    const updatedInfo = {
      ...permissionsUpdate,
      interestIds: interestsUpdate.length === 0 ? null : [...interestsUpdate],
    };
    updateAccountMutation.mutate(updatedInfo);
  };
  return {
    handleReset,
    handleSave,
    handleSelectItem,
    parsedBirthDay,
    fullAddress,
    isEditProfileOpen,
    setIsEditProfileOpen,
    accountInfo,
    interestsUpdate,
    permissionsUpdate,
    setPermissionsUpdate,
    isChangePasswordOpen,
    setIsChangePasswordOpen,
    enableSaveButton,
    setEnableSaveButton,
  };
};

export default MyAccountLogic;
