import { Children } from "react";
import { MenuProps } from "./MenuProps";
import "./Menu.scss";

const Menu = ({
  id,
  backgroundColor,
  children,
  maxWidth = "342px",
  fixedPosition = "center",
  onMouseLeave,
}: MenuProps) => {
  const arrayChildren = Children.toArray(children);
  return (
    <div className={`dropdown-menu-container-${fixedPosition}`}>
      <div
        onMouseLeave={onMouseLeave}
        id={id}
        className={`dropdown-menu dropdown-menu-${fixedPosition}`}
        style={{ backgroundColor, maxWidth }}>
        {Children.map(arrayChildren, (child, index) => {
          return (
            <div
              key={`dropdown-menu_item-${index}`}
              className={"dropdown-menu-item"}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Menu;
