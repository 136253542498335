import "./TenantAmenitiesStatus.scss";
import { useTenantFooter } from "@App/hooks";

const AmenityStatus = (props: { name: string; status: string }) => {
  return (
    <div className={"amenity-status"}>
      <span className={"name"}>{`${props.name}:`}</span>
      <span className={"status"}>{props.status}</span>
    </div>
  );
};

const TenantAmenitiesStatus = () => {
  const { tenantFooter } = useTenantFooter();
  if (!tenantFooter?.amenityStatus) return null;
  return (
    <div className={"tenant-amenities-status"}>
      {tenantFooter?.amenityStatus.amenities.map((amenity, key) => (
        <AmenityStatus
          key={`amenity-status-${key}`}
          name={amenity.name}
          status={amenity.status}
        />
      ))}
    </div>
  );
};

export default TenantAmenitiesStatus;
