import "./TagBubble.scss";
import { TagBubbleIcon } from "@Components/icons/tag-bubble/TagBubbleIcon";

interface TagBubbleProps {
  text?: string;
  itemId: string;
  fontColor?: string;
  fontWeight?: number;
  backgroundColor?: string;
  multiple?: boolean;
  isSelected?: boolean;
  readonly?: boolean;
  onClick?: (value: string) => void;
  onRemove?: (value: string) => void;
  size?: "large" | "medium" | "small";
  className?: string;
}

const TagBubble = ({
  text,
  fontColor = "#32373f",
  fontWeight = 600,
  backgroundColor = "#dae4f8",
  multiple,
  isSelected,
  itemId,
  readonly,
  onClick,
  onRemove,
  size = "medium",
  className,
}: TagBubbleProps) => {
  if (isSelected) {
    fontColor = "#F1F3F5";
    backgroundColor = "#353b40";
  }
  return (
    <div
      className={
        `tag-bubble ${multiple && "tag-multiple"} ${
          readonly ? "" : "tag-with-action"
        }` + ` ${className}`
      }
      onClick={() => {
        onClick && onClick(itemId ?? text);
      }}
    >
      <div className={`tag-item ${size}`} style={{ backgroundColor }}>
        <span
          className={`tag-text ${size}`}
          style={{ color: fontColor, fontWeight }}
        >
          {text}
        </span>
        {onRemove && (
          <button
            className={`tag-remove-button`}
            onClick={() => {
              onRemove(itemId);
            }}
          >
            <TagBubbleIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default TagBubble;
