import { Chevron } from "@Components/icons";
import SideScrollLogic from "./SideScrollLogic";
import "./SideScroll.scss";
import { SideScrollPropTypes } from "./SideScrollPropTypes";

const SideScroll = ({ children, verticalMargin = 56 }: SideScrollPropTypes) => {
  const {
    scrollElement,
    scrollX,
    scrollEnd,
    isMobileEmbed,
    scroll,
    scrollCheck,
  } = SideScrollLogic(children);
  return (
    <div className="scroll-element">
      {!isMobileEmbed && (
        <button
          className={`scroll-button scroll-button__right ${
            scrollEnd ? "hidden" : ""
          }`}
          style={{ top: `calc(50% - ${verticalMargin}px)` }}
          onClick={() => scroll(300)}>
          <Chevron position="right" color="#000" />
        </button>
      )}
      {!isMobileEmbed && (
        <button
          className={`scroll-button scroll-button__left ${
            scrollX === 0 ? "hidden" : ""
          }`}
          style={{ top: `calc(50% - ${verticalMargin}px)` }}
          onClick={() => scroll(-300)}>
          <Chevron position="left" color="#000" />
        </button>
      )}
      <div
        className="scroll-element-list-scroller"
        ref={scrollElement}
        onScroll={scrollCheck}>
        {children}
      </div>
    </div>
  );
};

export default SideScroll;
