import { Membership } from "@App/models/membership";
import { CircularInformation, Plus } from "@Components/icons";
import {
  Button,
  DropdownCheckbox,
  ErrorBox,
  FileUpload,
} from "@Components/index";
import MembershipDogRow from "../membership-dog-row/MembershipDogRow";
import "./MembershipDogForm.scss";
import MembershipDogFormLogic from "./MembershipDogFormLogic";

export type MembershipDogFormProps = {
  membership: Membership;
  handleNextStep: (userMembershipId: string) => void;
};

const MembershipDogForm = ({
  membership,
  handleNextStep,
}: MembershipDogFormProps) => {
  const {
    dogs,
    preferredPark,
    files,
    error,
    isDogFormStepValid,
    isProcessing,
    onAddDogRow,
    onDogDetailsChange,
    onDogRemove,
    setPreferredPark,
    handleContinue,
    setFiles,
  } = MembershipDogFormLogic(membership, handleNextStep);

  return (
    <div className={"membership-dog-form"}>
      <div className={"membership-dog-form-title"}>
        Complete your dog&apos;s Profile
      </div>
      <div className={"membership-dog-form-subtitle"}>
        Please provide the following details.
      </div>

      {dogs?.map((dogItem, index) => {
        return (
          <MembershipDogRow
            key={`dog_item_${dogItem.id}`}
            id={dogItem.id}
            index={index}
            dogName={dogItem.dogName}
            dogBreed={dogItem.dogBreed}
            showRemove={dogs.length > 1 && index > 0}
            removeEnabled={index > 0}
            onChange={onDogDetailsChange}
            onRemove={(dogRemoveIndex) => {
              onDogRemove(dogRemoveIndex);
            }}
          />
        );
      })}

      <div className={"membership-dog-form-add"}>
        <button className={"membership-dog-form-add-cta"} onClick={onAddDogRow}>
          <Plus size={15} />
          Add Dog
        </button>
      </div>

      <div className={"membership-dog-form-membership-type"}>
        <DropdownCheckbox
          isClearable={false}
          isMulti={false}
          blurOnSelect={true}
          options={membership.membershipCommunityAssets}
          optionLabel="title"
          optionValue="id"
          label="Select your preferred dog park (note that the annual membership grants access to both)"
          handleChange={setPreferredPark}
          optionSelected={preferredPark}
        />
      </div>

      <FileUpload
        label={"Upload Vaccination certificate"}
        files={files}
        banner={{
          icon: <CircularInformation />,
          text: "An up-to date rabies vaccination certificate is mandatory for all dogs using Greenbrook Paw Park and Bob Gardner Paw Park. Upload them so  we may process your dog park membership and/or renewal.",
        }}
        setFiles={setFiles}
        hideDefaultInformationMessage
      />

      <div className={"membership-dog-form-continue"}>
        {error && <ErrorBox message={error} />}
        <Button
          text={"Continue"}
          isDisabled={!isDogFormStepValid || isProcessing}
          onClick={handleContinue}
          isLoading={isProcessing}
        />
      </div>
    </div>
  );
};

export default MembershipDogForm;
