import "./MyReservations.scss";
import Table from "@Components/table/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { getDefaultColumnSize } from "@Components/table/utils";
import { QUERY_KEY_USER_RESERVATION_LIST } from "@App/constants/queryKeyConstants";
import { getUserReservations } from "@Api/reservation";
import {
  CommunityAssetReservation,
  ReservationWaiver,
} from "@App/models/communityAssetReservation";
import { formatDate } from "@Utils/date";
import ShrinkableList from "@Components/shrinkable-list/ShrinkableList";
import TagBubble from "@Components/tag-bubble/TagBubble";
import {
  findTagBubbleTypeById,
  TagBubbleTypeMapping,
} from "@Components/tag-bubble/types";

const defaultGeneralColumnSize = getDefaultColumnSize(
  [
    {
      type: "small",
      size: 5,
      numberOfColumns: 4,
    },
  ],
  8,
);

export const ReservationStatusTags: TagBubbleTypeMapping[] = [
  {
    id: "Initiated",
    text: "Initiated",
    backgroundColor: "#FFE7B3",
    fontColor: "#725517",
  },
  {
    id: "Confirmed",
    text: "Confirmed",
    backgroundColor: "#BFE3BC",
    fontColor: "#313F30",
  },
  {
    id: "PendingReview",
    text: "Pending Review",
    backgroundColor: "#FFE7B3",
    fontColor: "#725517",
  },
  {
    id: "Rejected",
    text: "Rejected",
    backgroundColor: "#FFE5E5",
    fontColor: "#FA3E3E",
  },
  {
    id: "OnHold",
    text: "On Hold",
    backgroundColor: "#D0F2FF",
    fontColor: "#1D345E",
  },
  {
    id: "Cancelled",
    text: "Canceled",
    backgroundColor: "#F1F3F5",
    fontColor: "#5F666D",
  },
  {
    id: "CancellationInitiated",
    text: "Cancellation Initiated",
    backgroundColor: "#F1F3F5",
    fontColor: "#5F666D",
  },
  {
    id: "Temporary",
    text: "Temporary",
    backgroundColor: "#FFE7B3",
    fontColor: "#725517",
  },
];

/**
 * Handling rendering shrinkable list of waivers if needed
 */
const renderWaivers = (waivers?: ReservationWaiver[]) => {
  if (!waivers || waivers.length === 0) {
    return "None";
  }

  const waiverComps = waivers.map((waiver, i) => (
    <a
      key={i}
      className="events-table-cell-waiver display-block"
      target={"_blank"}
      rel={"noreferrer"}
      href={waiver.acceptedWaiverURL}
    >
      Waiver {waivers.length > 1 ? i + 1 : ""}
    </a>
  ));
  return <ShrinkableList components={waiverComps} maxShown={2} />;
};

/**
 * Columns
 */
const columnHelper = createColumnHelper<CommunityAssetReservation>();
const columns = [
  columnHelper.accessor("receiptId", {
    header: () => "Res. No.",
    size: defaultGeneralColumnSize,
  }),
  columnHelper.accessor("startDate", {
    header: () => "Date",
    cell: (info) => (
      <span>
        {formatDate(info.getValue(), "M/dd/yy h:mm a")}
        {" - "}
        {formatDate(info.row.original.endDate, "h:mm a")}
      </span>
    ),
    size: defaultGeneralColumnSize,
  }),
  columnHelper.accessor("amenity", {
    header: () => "Amenity or Facility",
    size: defaultGeneralColumnSize,
  }),
  columnHelper.accessor("venue", {
    header: () => "Venue",
    size: defaultGeneralColumnSize,
  }),
  columnHelper.accessor("status", {
    header: () => "Status",
    cell: (info) => {
      const tagProps = findTagBubbleTypeById(
        ReservationStatusTags,
        info.getValue(),
      );
      return (
        <TagBubble
          text={info.getValue()}
          onClick={() => {}}
          itemId={info.row.original.id}
          className={"display-block"}
          backgroundColor={tagProps.backgroundColor}
          fontColor={tagProps.fontColor}
          fontWeight={400}
        />
      );
    },
    size: 2,
  }),
  columnHelper.accessor("waivers", {
    header: () => "Waiver",
    cell: (info) => renderWaivers(info.getValue()),
    size: defaultGeneralColumnSize,
  }),
];

const MyReservations = () => {
  return (
    <div id={"user-profile-my-reservations"}>
      <Table
        columns={columns}
        queryKey={[QUERY_KEY_USER_RESERVATION_LIST]}
        queryFn={getUserReservations}
        highlightOnHover
      />
    </div>
  );
};

export default MyReservations;
