import { IconProps } from "../iconTypes";
//@ts-ignore
import themedStyles from "@Assets/styles/themedStyles.scss";

const Handshake = ({ color = themedStyles.cmsMainIconsColor }: IconProps) => {
  return (
    <svg
      width="80"
      height="56"
      viewBox="0 0 80 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="39.8999"
        cy="27.8999"
        r="25.1382"
        stroke={color}
        strokeWidth="1.52353"
      />
      <path
        d="M23.1904 22.8875V19.9634C23.6082 19.5456 24.6943 18.2924 25.6969 18.2924C26.6994 18.2924 27.3678 19.5456 27.7856 19.1279C28.2033 18.7101 27.7856 16.6214 29.4565 16.6214C31.1275 16.6214 31.5452 17.8747 31.963 17.4569C32.3807 17.0392 31.963 16.1839 31.963 15.3682C32.2415 14.8112 33.0491 13.6973 34.0517 13.6973C35.0542 13.6973 35.8619 14.8112 36.1404 15.3682L38.2291 17.4569"
        stroke={color}
        strokeWidth="1.50386"
      />
      <path
        d="M17.047 37.5076H24.861L32.3803 42.5205L39.4819 41.685L45.3302 33.748C45.3302 32.7732 44.7454 30.9073 42.406 31.2415C40.0667 31.5757 37.2539 34.7227 36.1399 36.2544L24.861 26.2286L23.19 22.8867H15.2695"
        stroke={color}
        strokeWidth="1.50386"
      />
      <path
        d="M62.4574 37.9258H52.4316C50.8999 38.7613 47.4187 40.5158 45.7478 40.85C44.0768 41.1842 41.1526 40.9892 39.8994 40.85"
        stroke={color}
        strokeWidth="1.50386"
      />
      <path
        d="M63.7688 22.0515H55.3562M55.3562 22.0515L49.5079 27.4821C49.0901 27.6213 48.0876 27.8163 47.4192 27.4821C46.7508 27.1479 46.5837 25.9504 46.5837 25.3934L44.9127 26.6466C44.0773 27.0644 42.2392 27.6492 41.5708 26.6466C40.9024 25.644 41.2923 24.8364 41.5708 24.5579C41.1531 24.8364 40.0671 25.2263 39.0645 24.5579C38.062 23.8895 38.6468 22.0515 39.0645 21.216C38.0898 21.3552 36.2239 21.2995 36.5581 19.9628C36.8923 18.626 40.0392 15.5069 41.5708 14.1144C42.1279 13.9752 43.242 13.8638 43.242 14.5322C43.242 15.3676 45.3303 14.5322 46.5837 14.9499C47.5864 15.2841 48.1156 16.4816 48.2548 17.0386C48.8118 16.7601 50.0929 16.4538 50.7613 17.4563C51.5968 18.7096 52.8502 18.7096 54.1032 19.9628C55.1056 20.9654 55.3562 21.773 55.3562 22.0515Z"
        stroke={color}
        strokeWidth="1.50386"
      />
    </svg>
  );
};

export default Handshake;
