import { Button, TextInput } from "@Components/index";
import { useTenantFooter } from "@App/hooks";

import "./TenantSubscribe.scss";
import themedStyles from "@Assets/styles/themedStyles.scss";
import { TenantSubscribeLogic } from "./TenantSubscribeLogic";

const TenantSubscribe = () => {
  const {
    formState,
    setFormState,
    formErrors,
    handleSubmit,
    hasSubmitted,
    buttonIsDisabled,
  } = TenantSubscribeLogic();

  const { tenantFooter } = useTenantFooter();

  if (!tenantFooter?.emailSignup) return null;

  return (
    <>
      <div className={"tenant-subscribe"}>
        <span className={"tenant-subscribe-title"}>
          {hasSubmitted
            ? "Thanks for signing up!"
            : tenantFooter.emailSignup.sectionTitle}
        </span>
        <span className={"tenant-subscribe-intro"}>
          {/* TODO: Add some flavor text here */}
          {hasSubmitted ? "" : tenantFooter.emailSignup.instructions}
        </span>
        {!hasSubmitted && (
          <form className={"tenant-subscribe-form"} onSubmit={handleSubmit}>
            <div className={"tenant-subscribe-form-row-1"}>
              <TextInput
                className={"tenant-subscribe-form-row-1-item"}
                type={"text"}
                placeholder={"First Name"}
                onChange={(value) => {
                  setFormState({
                    ...formState,
                    firstName: {
                      value: value ?? "",
                      touched: true,
                    },
                  });
                }}
                inputStyle={{
                  backgroundColor: themedStyles.defaultPrimaryColor,
                  color: "#fff",
                }}
              />
              <TextInput
                className={"tenant-subscribe-form-row-1-item"}
                type={"text"}
                placeholder={"Last Name"}
                onChange={(value) => {
                  setFormState({
                    ...formState,
                    lastName: {
                      value: value ?? "",
                      touched: true,
                    },
                  });
                }}
                inputStyle={{
                  backgroundColor: themedStyles.defaultPrimaryColor,
                  color: "#fff",
                }}
              />
            </div>
            <div className={"tenant-subscribe-form-row-2"}>
              <TextInput
                className={"tenant-subscribe-form-row-2-item"}
                type={"text"}
                placeholder={"Enter Email"}
                onChange={(value) => {
                  setFormState({
                    ...formState,
                    email: {
                      value: value ?? "",
                      touched: true,
                    },
                  });
                }}
                inputStyle={{
                  backgroundColor: themedStyles.defaultPrimaryColor,
                  color: "#fff",
                }}
              />
              <div className={"tenant-subscribe-form-row-2-button"}>
                <Button
                  onClick={() => {}}
                  isSubmit
                  text={tenantFooter.emailSignup.buttonDisplayText}
                  style={{
                    backgroundColor: themedStyles.defaultSecondaryColor,
                    padding: "14px 20px",
                  }}
                  isDisabled={buttonIsDisabled}
                />
              </div>
            </div>
            <div className={"tenant-subscribe-form-error"}>{formErrors}</div>
          </form>
        )}
      </div>
    </>
  );
};

export default TenantSubscribe;
