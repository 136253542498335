import { ResidentCenterInfo } from "@App/models/residentCenterInfo";
import { AxiosResponse } from "axios";
import { baseServiceRequest } from "./baseService";
import { GetResidentCenterSearchByGroupByThemeRequest } from "./requests/residentCenter";

export const getResidentCenterInfo = async (subCommunityId: string) => {
  return (await baseServiceRequest({
    path: `/residentCenterInfo/subCommunity/${subCommunityId}`,
    type: "get",
    useAuthHeader: true,
  })) as AxiosResponse<ResidentCenterInfo, any>;
};

export const getResidentCenterSearchByGroupByTheme = async (
  params: GetResidentCenterSearchByGroupByThemeRequest,
) => {
  return (await baseServiceRequest({
    path: `/residentCenterInfo/Search/GroupByTheme`,
    type: "get",
    useAuthHeader: true,
    params,
  })) as AxiosResponse<ResidentCenterInfo, any>;
};
