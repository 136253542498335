import { useSelector } from "react-redux";
import { RootState } from "@Store/store";
import { UserState } from "@Store/reducers/userReducer";

export default function useAuthUser() {
  const { userInfo } = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  return {
    isUserLoggedIn: !!userInfo,
    userData: userInfo,
  };
}
