import { format, isValid, parseISO } from "date-fns";

export const formatDate = (date: string, dateFormat: string): string => {
  const parsedDate = parseISO(date);
  if (!isValid(parsedDate)) {
    return date;
  }

  return format(parsedDate, dateFormat);
};
