import userSession from "@App/auth/userSession";
import { useState } from "react";

const GenericListingLogic = (onSearch?: (text: string) => void) => {
  const isMobileEmbed = userSession.isMobileViewMode;
  const [isListLoading, setIsListLoading] = useState(true);

  const onSearchText = (searchText: string) => {
    onSearch && onSearch(searchText);
  };

  return {
    isMobileEmbed,
    onSearchText,
    isListLoading,
    setIsListLoading,
  };
};

export default GenericListingLogic;
