import { IconProps } from "../iconTypes";

const MapMarker = ({ color = "#858383", size }: IconProps) => {
  return (
    <svg
      {...(size && {
        style: { width: size, height: size },
      })}
      width="15"
      height="21"
      viewBox="0 0 15 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.63635 5C8.29939 5 8.93528 5.26339 9.40412 5.73223C9.87296 6.20107 10.1364 6.83696 10.1364 7.5C10.1364 7.8283 10.0717 8.15339 9.94605 8.45671C9.82041 8.76002 9.63627 9.03562 9.40412 9.26777C9.17197 9.49991 8.89638 9.68406 8.59306 9.8097C8.28975 9.93534 7.96466 10 7.63635 10C6.97331 10 6.33743 9.73661 5.86859 9.26777C5.39974 8.79893 5.13635 8.16304 5.13635 7.5C5.13635 6.83696 5.39974 6.20107 5.86859 5.73223C6.33743 5.26339 6.97331 5 7.63635 5ZM7.63635 0.5C9.49287 0.5 11.2733 1.2375 12.5861 2.55025C13.8989 3.86301 14.6364 5.64348 14.6364 7.5C14.6364 12.75 7.63635 20.5 7.63635 20.5C7.63635 20.5 0.636353 12.75 0.636353 7.5C0.636353 5.64348 1.37385 3.86301 2.6866 2.55025C3.99936 1.2375 5.77984 0.5 7.63635 0.5ZM7.63635 2.5C6.31027 2.5 5.0385 3.02678 4.10082 3.96447C3.16314 4.90215 2.63635 6.17392 2.63635 7.5C2.63635 8.5 2.63635 10.5 7.63635 17.21C12.6364 10.5 12.6364 8.5 12.6364 7.5C12.6364 6.17392 12.1096 4.90215 11.1719 3.96447C10.2342 3.02678 8.96243 2.5 7.63635 2.5Z"
        fill={color}
      />
    </svg>
  );
};

export default MapMarker;
