import { Tabs } from "@Components/index";
import { Navbar } from "@Layouts/index";
import ProfileLogic from "./ProfileLogic";
import "./Profile.scss";

const Profile = () => {
  const { selectedTab, accountInfo, isLoading, tabs, handleTabChange } =
    ProfileLogic();

  return (
    <Navbar>
      <div className="profile">
        <div className="profile-title">Profile & Settings</div>
        <div className={"profile-tabs"}>
          <Tabs tabs={tabs} onTabChange={handleTabChange} />
        </div>

        <div className="profile-tab-content">{selectedTab.comp}</div>
      </div>
    </Navbar>
  );
};

export default Profile;
