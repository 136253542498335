import { Navbar } from "@Layouts/index";
import "./Venues.scss";
import { GenericListing } from "@Components/index";
import VenuesLogic from "./VenuesLogic";

const Venues = () => {
  const {
    isLoading,
    searchText,
    filterSections,
    listingCategories,
    getCategoryItems,
    onClick,
    onSearch,
    onFilterChange,
    searchFilters,
    getFilterRequestParams,
  } = VenuesLogic();

  return (
    <Navbar>
      <div className={"venues"}>
        <GenericListing
          title={"Venues"}
          onClick={onClick}
          isLoading={isLoading}
          onSearch={onSearch}
          searchText={searchText}
          listingCategories={listingCategories}
          getCategoryItems={getCategoryItems}
          filterSections={filterSections}
          onFilterChange={onFilterChange}
          searchFilters={searchFilters}
          getFilterRequestParams={getFilterRequestParams}
          moduleName={"venues"}
        />
      </div>
    </Navbar>
  );
};

export default Venues;
