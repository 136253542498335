import "./PromptsModal.scss";
import { PromptsModalProps } from "./PromptsModalPropTypes";
import PromptsModalLogic from "./PromptsModalLogic";
import { Button, DropdownCheckbox, Modal, TextInput } from "@Components/index";

const PromptsModal = ({
  prompts,
  isVisible,
  onContinue,
}: PromptsModalProps) => {
  const props = PromptsModalLogic(onContinue);
  return (
    <Modal isVisible={!!isVisible}>
      <div className={"prompts-modal"} data-cy="prompts-modal">
        <div className={"prompts-modal__title"}>
          Please provide the following details.
        </div>
        {prompts.map((promptItem, index) => {
          const isMultiselect = promptItem.responseType === "multiselect";
          return (
            <div key={`prompt_item${index}`}>
              {promptItem.responseType === "text" ? (
                <TextInput
                  type={"text"}
                  label={promptItem.title}
                  onChange={(value) => props.onTextChange(promptItem.id, value)}
                />
              ) : (
                <DropdownCheckbox
                  label={promptItem.title}
                  description={promptItem.description}
                  options={promptItem.responseOptions?.split(";").map((opt) => {
                    return { label: opt, value: opt };
                  })}
                  optionSelected={props.selectedPromptRes(promptItem.id)}
                  handleChange={(sel: any) =>
                    props.onSelectionChange(promptItem.id, sel)
                  }
                  isMulti={isMultiselect}
                  isClearable={false}
                  blurOnSelect={!isMultiselect}
                  fontSize="14px"
                  fontWeight={"bold"}
                  isInsideModal
                />
              )}
            </div>
          );
        })}
        <Button
          text={"Next"}
          isDisabled={props.promptsResponses?.length !== prompts?.length}
          onClick={props.handleContinue}
        />
      </div>
    </Modal>
  );
};

export default PromptsModal;
